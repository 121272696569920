import {
  MassText,
  MassTextAttachment,
  Event,
  SubSelectionType,
  Campaign,
  CampaignType,
  FollowType,
  FollowerStatus,
} from "@markit/common.types";
import { getCountFromServer, getDocs, query, where } from "../firebase";
import {
  getMassTextAttachmentsSnap,
  getMassTextsRef,
  getTextRecipientSnap,
  getTextRecipientsRef,
  getUserFollowersRef,
} from "./FirebaseUtils";
import { API } from "../API";
import {
  dayAfterEventText,
  isEventExternalLink,
  massTextPlaceholder,
  massTextUpcomingEventPlaceholder,
  pregameEventText,
  SUBSCRIBED_CAMPAIGN_NAME,
  ticketTriggerText,
} from "@markit/common.utils";
import { generate } from "shortid";
import {
  fetchUniqueEventTickets,
  getTotalUniqueAttendees,
  getUidsFromEventTicketType,
} from "./eventUtils/eventUtils";
import { GetLongDate } from "./GetLongDate";

/**
 * Get the mass text attachments from a specified mass text
 */
export const getMassTextAttachments = async (
  userId: string,
  massText: MassText
): Promise<MassTextAttachment[]> => {
  const massTextAttachmentsSnap = await getMassTextAttachmentsSnap(
    userId,
    massText.campaignId,
    massText.id
  );
  const massTextAttachments = massTextAttachmentsSnap.docs.map((doc) =>
    doc.data()
  );
  return massTextAttachments;
};

// get the number of texts by a user was sent to one specified user
export const getNumMassTextsToUser = async (
  userId: string,
  recipientId: string
): Promise<number> => {
  const massTextsRef = getMassTextsRef();
  const massTextsQuery = query(massTextsRef, where("sentBy", "==", userId));
  const massTextsSnap = await getDocs(massTextsQuery);
  const numRecipientsArr: number[] = await Promise.all(
    massTextsSnap.docs.map(async (doc) => {
      const massText = doc.data();
      if (massText.campaignId) {
        const recipientsSnap = await getTextRecipientSnap(
          userId,
          massText.campaignId,
          massText.id,
          recipientId
        );
        return recipientsSnap.exists() ? 1 : 0;
      }
      return 0;
    })
  );
  const totalEventTexts = numRecipientsArr.reduce(
    (acc, count) => acc + count,
    0
  );
  return totalEventTexts;
};

/**
 * Get total number of recipients from a specified mass text (trigger and normal campaigns)
 */
export const getNumMassTextRecipients = async (
  userId: string,
  campaign: Campaign,
  massTextId: string
): Promise<number> => {
  if (campaign.type === CampaignType.CAMPAIGN) {
    const massTextRecipientsRef = getTextRecipientsRef(
      userId,
      campaign.id,
      massTextId
    );
    const snapshot = await getCountFromServer(massTextRecipientsRef);
    return snapshot.data().count;
  } else {
    // Clicked Subscribe Automation
    if (campaign.title === SUBSCRIBED_CAMPAIGN_NAME) {
      const subscribedFollowersRef = getUserFollowersRef(userId);
      const followersQuery = query(
        subscribedFollowersRef,
        where("followType", "==", FollowType.PROFILE),
        where("status", "==", FollowerStatus.SUBSCRIBED)
      );
      const snapshot = await getCountFromServer(followersQuery);
      return snapshot.data().count;
    } else {
      // Ticket Automation
      const uniqueAttendees = await getTotalUniqueAttendees(campaign.eventId);
      return uniqueAttendees;
    }
  }
};

/**
 * Get the recipients from a specified mass text
 */
export const getMassTextRecipients = async (
  userId: string,
  campaign: Campaign,
  massTextId?: string
): Promise<string[]> => {
  if (campaign.title === SUBSCRIBED_CAMPAIGN_NAME) {
    const subscribedFollowersRef = getUserFollowersRef(userId);
    const followersQuery = query(
      subscribedFollowersRef,
      where("followType", "==", FollowType.PROFILE)
    );
    const followersSnap = await getDocs(followersQuery);
    const userIds = followersSnap.docs.map((doc) => doc.data().uid);
    return userIds;
  } else if (campaign.eventId) {
    const tickets = await fetchUniqueEventTickets(campaign.eventId);
    const userIds = tickets.map((ticket) => ticket.redeemedBy);
    return userIds;
  } else if (massTextId) {
    const { textRecipients } = await API.text.getTextRecipients({
      userId: userId,
      campaignId: campaign.id,
      massTextId: massTextId,
    });
    return textRecipients;
  }
  return [];
};

export const getMassTextSendRate = async (
  massText: MassText
): Promise<number> => {
  const response = await API.tracking.getMassTextSendRate({
    massText: massText,
  });

  if (response) {
    return response.sendRate;
  }

  return 100;
};

export const getMassTextDeliveryAttemptedRate = async (
  massText: MassText
): Promise<number> => {
  const response = await API.tracking.getMassTextDeliveryAttemptedRate({
    massText: massText,
  });

  if (response) {
    return response.deliveryAttemptedRate;
  }

  return 100;
};

export const generateDefaultEventTexts = (
  userId: string,
  fullName: string,
  event: Event
) => {
  const triggerText = ticketTriggerText(generate(), userId, fullName, "");
  if (isEventExternalLink(event.eventType)) {
    return [triggerText];
  } else {
    const pregameText: MassText = pregameEventText(
      generate(),
      userId,
      event.id,
      event.start,
      event.isVirtual,
      ""
    );
    const dayAfterText: MassText = dayAfterEventText(
      generate(),
      userId,
      event.id,
      event.end,
      ""
    );

    const defaultEventTexts: MassText[] = [
      triggerText,
      pregameText,
      dayAfterText,
    ];
    return defaultEventTexts;
  }
};

// fetch the attendees per ticket type and store the recipients that belong within each ticket type in a map
// Used for mass text selecting by event ticket types
export const fetchAttendeesPerTypeMap = async (selectedEvent: Event) => {
  if (selectedEvent) {
    const attendeesPerTicketTypeArray = await Promise.all(
      selectedEvent.customTickets.map(async (ticket) => {
        const ticketAttendees = await getUidsFromEventTicketType(
          selectedEvent.id,
          ticket.id
        );
        return {
          id: ticket.id,
          attendees: ticketAttendees,
        };
      })
    );
    const attendeesPerTicketType = new Map(
      attendeesPerTicketTypeArray.map((ticketItem) => [
        ticketItem.id,
        ticketItem.attendees,
      ])
    );
    return attendeesPerTicketType;
  }
  return new Map<string, string[]>();
};

// Returns the default message we want to start the message with based on the suggestedActionType
export const massTextDefaultMessage = (
  event: Event | undefined,
  fullName: string,
  suggestedActionType: SubSelectionType | undefined
) => {
  return event && suggestedActionType === SubSelectionType.ALL_FOLLOWERS
    ? massTextUpcomingEventPlaceholder(
        fullName,
        GetLongDate(event.start, false, true, true, false),
        event.id
      )
    : massTextPlaceholder(fullName);
};
