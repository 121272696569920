import { Event, TicketV2 } from "@markit/common.types";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import {
  friendlyRoleName,
  getTimezone,
  isEventExternalLink,
  isExternalEventbrite,
  isExternalGenericLink,
  isMultipleDays,
  truncateString,
} from "@markit/common.utils";
import { useCallback, useContext, useMemo } from "react";
import { GetLongDate } from "../../utils/GetLongDate";
import { GetTime } from "../../utils/GetTime";
import { useTheme } from "../../hooks/useTheme";
import { MixpanelContext } from "../../context/AnalyticsService";
import { Icon } from "@iconify/react";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { VerticalDivider } from "../Dividers/VerticalDivider";
import { DataLoaders } from "../../redux/slices/dataSlice";
import CustomLinkify from "../Links/CustomLinkify";
import { useNavigate } from "../../hooks/useNavigate";
import FatHeaderActionButton from "../Buttons/FatHeaderActionButton";

type EventDashboardHeaderProps = {
  event: Event;
  userTicket: TicketV2 | undefined;
};

export const EventDashboardHeader = (props: EventDashboardHeaderProps) => {
  const { event, userTicket } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isEventbrite = useMemo(
    () => isExternalEventbrite(event.eventType),
    [event]
  );

  const roleName = useMemo(
    () =>
      isEventExternalLink(event.eventType)
        ? event.externalLink
        : friendlyRoleName(userTicket),
    [event.eventType, event.externalLink, userTicket]
  );

  const eventEdit = useMemo(
    () =>
      accountData.isAdmin || (userTicket ? userTicket.role.eventEdit : false),
    [accountData.isAdmin, userTicket]
  );

  const styles = {
    containerView: {
      padding: "12px 14px",
    },

    mainText: {
      color: theme.PrimaryText.color,
      fontSize: 14,
      fontWeight: "600",
    },

    secondaryText: {
      color: theme.LabelText.color,
      fontSize: 12,
    },

    buttonContainer: {
      whiteSpace: "nowrap",
      borderRadius: 100,
      paddingBlock: 10,
      paddingInline: 12,
    },
  };

  const editOnPress = useCallback(() => {
    if (eventEdit && event) {
      mixpanel.track("Pressed Event Dashboard Edit", {
        distinct_id: accountData.uid,
        event_id: event.id,
        platform: "webapp",
        event_type: event.eventType,
      });
      if (isEventExternalLink(event.eventType)) {
        navigate(`/e/${event.id}/editLink`, {
          state: { confirmState: true, event: event },
        });
      } else {
        dispatch(DataLoaders.event(event.id));
        navigate(`/e/${event.id}/edit`, {
          state: { editEvent: event },
        });
      }
    }
  }, [accountData.uid, dispatch, event, eventEdit, mixpanel, navigate]);

  return (
    <>
      <div className="RowNormalSpaced" style={{ marginBottom: 24 }}>
        <div style={{ fontSize: 32, fontWeight: "600" }}>{event.title}</div>
        {!isEventbrite ? (
          <div className="AlignedRow" style={{ marginLeft: 20, gap: 10 }}>
            {eventEdit ? (
              <FatHeaderActionButton
                title="Edit"
                onPress={editOnPress}
                icon={
                  <Icon
                    icon={"ion:create"}
                    height={17}
                    style={{ marginTop: -2 }}
                  />
                }
              />
            ) : null}
          </div>
        ) : null}
      </div>
      <StandardBorderedContainer>
        <div className="AlignedRow">
          <div
            style={{
              ...styles.containerView,
              maxWidth: 240,
            }}
          >
            <CustomLinkify noUnderline altColor={theme.PrimaryText.color}>
              <span
                className="TextOverflowEllipsis"
                style={{ ...styles.mainText }}
              >
                {roleName}
              </span>
            </CustomLinkify>
          </div>
          <VerticalDivider height={41} />
          <div className="AlignedRow" style={styles.containerView}>
            <Icon
              icon={"ion:calendar"}
              height={12}
              style={{
                color: theme.LabelText.color,
              }}
            />
            <div style={{ ...styles.mainText, marginLeft: 7 }}>
              {GetLongDate(event.start, true, true, true, false)}
            </div>
            <div style={{ ...styles.secondaryText, marginLeft: 7 }}>
              {GetTime(event.start)} to{" "}
              {isMultipleDays(event.start, event.end) &&
                GetLongDate(event.end, true, true, true, false)}{" "}
              {GetTime(event.end)} {getTimezone()}
            </div>
          </div>
          {!isExternalGenericLink(event.eventType) &&
          !isExternalEventbrite(event.eventType) ? (
            <>
              <VerticalDivider height={41} />
              <div
                className="AlignedRow"
                style={styles.containerView}
                onClick={() => {
                  if (!event.isVirtual) {
                    window.open(
                      "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=" +
                        event.googlePlaceId
                    );
                  }
                }}
              >
                <Icon
                  icon={event.isVirtual ? "mdi:video" : "mdi:map-marker"}
                  height={15}
                  style={{
                    color: theme.LabelText.color,
                  }}
                />
                <div style={{ ...styles.mainText, marginLeft: 7 }}>
                  {event.isVirtual
                    ? "Virtual"
                    : truncateString(event.googleDescription, 35, "...")}
                </div>
                <div style={{ ...styles.secondaryText, marginLeft: 7 }}>
                  {event.isVirtual
                    ? ""
                    : truncateString(event.formattedAddress, 50, "...")}
                </div>
                {!event.isVirtual ? (
                  <Icon
                    icon={"ion:open-outline"}
                    height={12}
                    style={{
                      color: theme.LabelText.color,
                      marginLeft: 4,
                    }}
                  />
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </StandardBorderedContainer>
    </>
  );
};
