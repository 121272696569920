import { memo, ReactNode } from "react";
import { useTheme } from "../hooks/useTheme";
import { Colors } from "../utils/colors";
import FlatList from "flatlist-react/lib";
import RectangleButton from "./Buttons/RectangleButton";

type FlatlistLoadMoreProp = {
  fullList: any[];
  currentList: any[];
  renderItem: (item: any) => ReactNode;
  renderWhenEmpty: ReactNode;
  showLoadMore: boolean;
  loadMoreOnPress: () => void;
};

// Flatlist with a load more button to manually load more items
const FlatlistLoadMore = memo(function FullEventInfoFn(
  props: FlatlistLoadMoreProp
) {
  const {
    fullList,
    currentList,
    renderItem,
    renderWhenEmpty,
    showLoadMore,
    loadMoreOnPress,
  } = props;
  const { theme } = useTheme();

  return (
    <div className="ColumnNormal">
      <FlatList
        list={currentList}
        renderItem={(item: any) => renderItem(item)}
        renderWhenEmpty={() => renderWhenEmpty}
      />
      {fullList.length > 0 ? (
        <div className="Centering" style={{ paddingBlock: 20 }}>
          {showLoadMore ? (
            <RectangleButton
              buttonLabel={<span style={{ fontWeight: 500 }}>Load More</span>}
              onPress={loadMoreOnPress}
              altColor={Colors.WHITE1}
              altTextColor={Colors.BLACK}
              altBorderRadius={100}
              altPaddingHorz={14}
              altPaddingVert={10}
              containerStyles={{
                border: `1px solid ${Colors.GRAY1}`,
                width: 120,
              }}
            />
          ) : currentList.length > 0 ? (
            <span style={{ fontSize: 14, ...theme.LabelText }}>
              Showing All Results
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
});

export default FlatlistLoadMore;
