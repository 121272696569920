import { useMemo } from "react";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { getCampaignState } from "../../../../redux/slices/campaignSlice";
import { LessThanDate } from "@markit/common.utils";
import { MassTextMessagingScreenType } from "../MassTextPanel";
import { Colors } from "../../../../utils/colors";
import RectangleButton from "../../../Buttons/RectangleButton";
import { MassText, MassTextType } from "@markit/common.types";
import { CampaignMassTextItemSettings } from "../../../DisplayItem/CampaignItem/CampaignMassTextItemSettings";
import { AnimatePresence, domAnimation, LazyMotion, m } from "framer-motion";

type MassTextsHeaderPreviewProps = {
  screen: MassTextMessagingScreenType;
  setEditingCampaign?: (editingCampaign: boolean) => void;
  duplicateOnPress?: (massText: MassText) => void;
  massText: MassText;
  headerLabel: string;
  handleBackNavigation: () => void;
};

const MassTextsHeaderPreview = (props: MassTextsHeaderPreviewProps) => {
  const {
    screen,
    setEditingCampaign,
    duplicateOnPress,
    massText,
    headerLabel,
    handleBackNavigation,
  } = props;
  const { campaigns, campaignDetails } = useSelector(getCampaignState);

  const campaign = useMemo(
    () => campaigns.find((c) => c.id === massText.campaignId),
    [campaigns, massText.campaignId]
  );

  const numCampaignTexts = useMemo(
    () =>
      campaignDetails.find((detail) => campaign?.id === detail.campaignId)
        ?.massTextDetails.length ?? 0,
    [campaign?.id, campaignDetails]
  );

  const campaignDetail = useMemo(
    () => campaignDetails.find((detail) => campaign?.id === detail.campaignId),
    [campaign?.id, campaignDetails]
  );

  const massTextDetail = useMemo(
    () =>
      campaignDetail?.massTextDetails.find(
        (text) => text.massText.id === massText.id
      ),
    [campaignDetail?.massTextDetails, massText.id]
  );

  // The initial text in the campaign, if this text is part of an existing campaign
  const initialCampaignText = useMemo(() => {
    if (campaignDetail && campaign) {
      const foundInitialText = campaignDetail.massTextDetails.find(
        (text) => text.massText.id === campaign.initialTextId
      );
      return foundInitialText?.massText;
    }
  }, [campaignDetail, campaign]);

  const isSendTimePassed = useMemo(
    () => LessThanDate(massText.sentAt, new Date().toISOString()),
    [massText.sentAt]
  );

  const isTriggerText = useMemo(
    () => massText.type === MassTextType.TRIGGERTEXT,
    [massText.type]
  );

  return (
    <AnimatePresence>
      <LazyMotion features={domAnimation}>
        <m.div
          key="textheaderpreview"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: "easeIn" }}
        >
          <div
            className="AlignedRowSpaced"
            style={{
              height: 58,
              backgroundColor: Colors.WHITE,
              paddingInline: 20,
            }}
          >
            <div className="AlignedRow" style={{ gap: 14 }}>
              {screen !== MassTextMessagingScreenType.SEND_SUMMARY ? (
                <div
                  className="LargePopupPanelClose"
                  onClick={handleBackNavigation}
                >
                  <Icon
                    icon={"ion:chevron-back"}
                    height={24}
                    color={Colors.GRAY3}
                  />
                </div>
              ) : null}
              <span className="sectionTitle">{headerLabel}</span>
            </div>
            <div className="AlignedRow" style={{ gap: 10 }}>
              {campaign && massTextDetail ? (
                <CampaignMassTextItemSettings
                  campaign={campaign}
                  massTextDetail={massTextDetail}
                  campaignInitialText={initialCampaignText}
                  numCampaignTexts={numCampaignTexts}
                  duplicateOnPress={duplicateOnPress}
                  showSaveToList={
                    !isTriggerText && (campaignDetail?.numRecipients ?? 0) > 0
                  }
                />
              ) : null}
              {(!isSendTimePassed ||
                massText.type === MassTextType.TRIGGERTEXT) &&
              setEditingCampaign ? (
                <RectangleButton
                  buttonLabel="Edit"
                  onPress={() => setEditingCampaign(true)}
                  altPaddingHorz={14}
                  altBorderRadius={100}
                  altColor={Colors.BLACK}
                  altTextColor={Colors.WHITE}
                />
              ) : null}
            </div>
          </div>
        </m.div>
      </LazyMotion>
    </AnimatePresence>
  );
};

export default MassTextsHeaderPreview;
