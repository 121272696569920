import React, { useEffect, useMemo, useState } from "react";
import {
  AccountData,
  Campaign,
  Event,
  FollowerStatus,
  MassText,
  MassTextType,
  SubSelectionItem,
  SubSelectionType,
} from "@markit/common.types";
import SelectRecipientsScreens from "./SelectRecipientsFlow/SelectRecipientsScreens";
import {
  addHoursToTimestamp,
  filterFollowersByStatus,
  isSubscriptionPaymentFailed,
  LessThanDate,
  roundTimeInterval,
} from "@markit/common.utils";
import "../../../css/CreatorDashboardMassTextsPanel.css";
import { useMassTextManagement } from "../../../hooks/useMassTextManagement";
import MassTextMessagingScreens from "./MassTextMessagingScreens";
import {
  getMassTextRecipients,
  massTextDefaultMessage,
} from "../../../utils/textingUtils";
import PaymentFailedButtonModal from "../../DisplayModal/UpdatePaymentInfoModal";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import FullProfilePreviewModal from "../../FollowerProfile/FullProfilePreviewModal";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import { getCampaignMassTextData } from "../../../utils/FirebaseUtils";
import MassTextWrapperManager, {
  MassTextSelectedType,
  MassTextsWrapperType,
} from "./MassTextWrapperManager";
import { CircularProgress } from "@mui/material";
import { Colors } from "../../../utils/colors";

export enum MassTextScreenType {
  SELECT_RECIPIENTS = "Select Recipients",
  OVERVIEW = "Overview",
}

export enum MassTextMessagingScreenType {
  SUMMARY = "Mass Text Summary",
  SEND_SUMMARY = "Mass Text Send Summary",
  SEND_SCHEDULE = "Mass Text Send Schedule",
  RECIPIENTS_SEND_SUMMARY = "Recipients Send Summary",
  LINK_ANALYTICS = "Link Analytics",
}

export type MassTextsSharedProps = {
  wrapperType: MassTextsWrapperType;
  campaign: Campaign | undefined;
  massTextSettings: MassText;
  updateMassTextSettings: (massTextSettings: Partial<MassText>) => void;
  setMainScreen: (mainScreen: MassTextScreenType) => void;
  setProfileSelected: (profileSelected: AccountData) => void;
};

type MassTextPanelProps = {
  wrapperType: MassTextsWrapperType;
  campaign: Campaign | undefined; // defined campaign if starting new mass text on existing campaign
  selectedTextType: MassTextSelectedType;
  setSelectedTextType: (selectedTextType: MassTextSelectedType) => void;
  setEditingCampaign?: (editingCampaign: boolean) => void;
  duplicateOnPress?: (massText: MassText) => void;
  event?: Event;
  suggestedAction?: SubSelectionItem;
  defaultScheduled?: boolean; // for event texts scheduled default
};

const MassTextPanel = (props: MassTextPanelProps) => {
  const {
    wrapperType,
    selectedTextType,
    setSelectedTextType,
    setEditingCampaign,
    duplicateOnPress,
    campaign,
    event,
    suggestedAction,
    defaultScheduled,
  } = props;
  const { accountData, followersData } = useSelector(getAccountState).account;
  const { massTextSettings, updateMassTextSettings, resetMassText, loaded } =
    useMassTextManagement({
      massText: selectedTextType.massText,
      eventId: event ? event.id : "",
      campaignId: campaign?.id,
      isDuplicateText: selectedTextType.isDuplicateText,
    });

  const [screenType, setScreenType] = useState<MassTextScreenType>(
    MassTextScreenType.OVERVIEW
  );
  const [textSuggestedAction, setTextSuggestedAction] = useState<
    SubSelectionItem | undefined
  >(!selectedTextType.massText ? suggestedAction : undefined);

  // keep track of currently selected recipients
  const [allSelectedPeople, setAllSelectedPeople] = useState<string[]>([]);
  const [allExcludedPeople, setAllExcludedPeople] = useState<string[]>([]);
  const [loadingInitialRecipients, setLoadingInitialRecipients] =
    useState(true);

  const [profileSelected, setProfileSelected] = useState<AccountData>();
  const [paymentFailedVisible, setPaymentFailedVisible] = useState(false);

  // Editing if the selected text is defined and is not a duplicate text
  const isEditing = useMemo(
    () =>
      selectedTextType.massText !== undefined &&
      !selectedTextType.isDuplicateText,
    [selectedTextType.isDuplicateText, selectedTextType.massText]
  );

  const allSubscribedSelectedPeople = useMemo(
    () =>
      filterFollowersByStatus(
        allSelectedPeople,
        followersData,
        FollowerStatus.SUBSCRIBED
      ),
    [allSelectedPeople, followersData]
  );

  const netRecipients = useMemo(
    () =>
      allSubscribedSelectedPeople.filter(
        (item) => !allExcludedPeople.includes(item)
      ),
    [allSubscribedSelectedPeople, allExcludedPeople]
  );

  useEffect(() => {
    // Whether to display the payment failed popup
    if (
      wrapperType !== MassTextsWrapperType.SIDE_PANEL_PREVIEW &&
      isSubscriptionPaymentFailed(accountData.customer.state)
    ) {
      setPaymentFailedVisible(true);
    }
  }, [accountData.customer.state, wrapperType]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    // Set up step for some of the mass text / scheduleTextInfo data (not editing case)
    if (event && !selectedTextType.massText) {
      updateMassTextSettings({
        message: massTextDefaultMessage(
          event,
          accountData.fullName,
          suggestedAction?.type
        ),
      });
    }
    const roundedSendTime = roundTimeInterval(new Date().toISOString(), 5);
    if (defaultScheduled) {
      updateMassTextSettings({
        scheduled: defaultScheduled,
        sentAt: roundedSendTime.toISOString(),
      });
    }

    if (campaign) {
      const [textRecipients, initialMassText] = await Promise.all([
        getMassTextRecipients(
          massTextSettings.sentBy,
          campaign,
          campaign.initialTextId
        ),
        getCampaignMassTextData(
          massTextSettings.sentBy,
          campaign.id,
          campaign.initialTextId
        ),
      ]);
      // Need to handle the all followers case to get the user ids for campaign text
      // Only enter if it's a non-event automation or if it's a trigger text
      if (
        selectedTextType.massText?.type === MassTextType.TRIGGERTEXT ||
        !campaign.eventId
      ) {
        const isAllFollowers = initialMassText?.subSelectionIds.some(
          (category) => category.type === SubSelectionType.ALL_FOLLOWERS
        );
        setAllSelectedPeople(
          isAllFollowers ? followersData.map((fol) => fol.uid) : textRecipients
        );
      }
      // Set the send time if the initial mass text has not sent yet, set it one hour ahead of it
      if (
        !selectedTextType.massText &&
        initialMassText &&
        initialMassText.scheduled &&
        LessThanDate(new Date().toISOString(), initialMassText.sentAt)
      ) {
        updateMassTextSettings({
          scheduled: true,
          sentAt: addHoursToTimestamp(initialMassText.sentAt, 1).toISOString(),
        });
      }
    } else if (
      suggestedAction &&
      suggestedAction.type === SubSelectionType.ALL_FOLLOWERS
    ) {
      setAllSelectedPeople(followersData.map((fol) => fol.uid));
    }

    setLoadingInitialRecipients(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTextType.massText]);

  // If newMassText is defined, a new text was created within the campaign dashboard
  const resetState = (newMassText?: MassText) => {
    if (newMassText) {
      setSelectedTextType({
        massText: newMassText,
        isVisible: true,
        isDuplicateText: false,
      });
    } else {
      setSelectedTextType({
        massText: undefined,
        isVisible: false,
        isDuplicateText: false,
      });
      resetMassText();
    }
  };

  const sharedPropsPack = {
    wrapperType: wrapperType,
    campaign: campaign,
    massTextSettings: massTextSettings,
    updateMassTextSettings: updateMassTextSettings,
    setProfileSelected: setProfileSelected,
    setMainScreen: setScreenType,
  };

  return (
    <>
      <MassTextWrapperManager wrapperType={wrapperType}>
        {loaded ? (
          <>
            {screenType === MassTextScreenType.OVERVIEW ? (
              <MassTextMessagingScreens
                {...sharedPropsPack}
                setEditingCampaign={setEditingCampaign}
                duplicateOnPress={duplicateOnPress}
                isEditing={isEditing}
                event={event}
                netSelectedPeople={netRecipients}
                suggestedAction={textSuggestedAction}
                editingMassText={selectedTextType.massText}
                resetState={resetState}
                loadingRecipients={loadingInitialRecipients}
              />
            ) : screenType === MassTextScreenType.SELECT_RECIPIENTS ? (
              <SelectRecipientsScreens
                {...sharedPropsPack}
                allSelectedPeople={allSubscribedSelectedPeople}
                setAllSelectedPeople={setAllSelectedPeople}
                allExcludedPeople={allExcludedPeople}
                setAllExcludedPeople={setAllExcludedPeople}
                setSuggestedAction={setTextSuggestedAction}
              />
            ) : null}
          </>
        ) : (
          <div className="Centering" style={{ height: "100%" }}>
            <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
          </div>
        )}
      </MassTextWrapperManager>
      {profileSelected ? (
        <FullProfilePreviewModal
          profileSelected={profileSelected}
          setProfileSelected={setProfileSelected}
        />
      ) : null}
      {paymentFailedVisible ? (
        <PaymentFailedButtonModal
          isVisible={paymentFailedVisible}
          setIsVisible={() => {
            setPaymentFailedVisible(false);
            setSelectedTextType({ ...selectedTextType, isVisible: false });
          }}
          location="Mass Text"
        />
      ) : null}
    </>
  );
};

export default MassTextPanel;
