import React, { useCallback, useMemo, useState } from "react";
import { useLoadUserList } from "../../../hooks/useLoadUserList";
import { useSelector } from "react-redux";
import FlatList from "flatlist-react/lib";
import { MassTextsPersonItem } from "./Items/MassTextsPersonItem";
import { CircularProgress } from "@mui/material";
import { useOnMount } from "../../../hooks/useOnMount";
import { AccountData, MassText } from "@markit/common.types";
import filter from "lodash.filter";
import SearchBoxContainer from "../../Containers/SearchBoxContainer";
import SelectRecipientCategoryItem from "./Items/SelectRecipientCategoryItem";
import { getEventState } from "../../../redux/slices/eventSlice";
import { Colors } from "../../../utils/colors";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { EmptyStateFlatlist } from "../../EmptyStates/EmptyStateFlatlist";
import EmptyStateButton from "../../Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";

type MassTextsRecipientsProps = {
  massText: MassText;
  allSelectedPeople: string[];
  setProfileSelected: (profileSelected: AccountData) => void;
};

export enum RecipientOption {
  SELECTED = "selected",
  EXCLUDED = "excluded",
}

const MassTextsRecipients = (props: MassTextsRecipientsProps) => {
  const { massText, allSelectedPeople, setProfileSelected } = props;
  const { events } = useSelector(getEventState).events;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loadingRecipients, setLoadingRecipients] = useState(true);

  const styles = {
    sectionContainer: {
      padding: 14,
      backgroundColor: Colors.WHITE1,
      borderRadius: 8,
      marginBottom: 14,
    },
    mainText: { fontSize: 14, fontWeight: "500" },
  };

  const {
    fetchedUserData,
    loadMoreUsers,
    isFinished,
    isLoading,
    loadSearchResultsThrottled,
  } = useLoadUserList({
    userIdList: allSelectedPeople,
    windowSize: 30,
  });

  useOnMount(() => {
    loadMoreUsers();
    setLoadingRecipients(false);
  });

  const foundEvent = useMemo(
    () => events.find((event) => event.id === massText?.eventRefId),
    [events, massText?.eventRefId]
  );

  const hasCategories = useMemo(
    () => massText.subSelectionIds.length > 0,
    [massText.subSelectionIds.length]
  );

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
    loadSearchResultsThrottled(text.toLowerCase());
  };

  const containsUser = useCallback((item: AccountData, query: string) => {
    return item.fullName.toLowerCase().includes(query);
  }, []);

  const recipientsToShow: AccountData[] = useMemo(() => {
    const usersToShow: AccountData[] = fetchedUserData;
    let userList: AccountData[] = usersToShow;
    userList = filter(userList, (user: AccountData) => {
      return containsUser(user, searchTerm.toLowerCase());
    });
    return userList;
  }, [fetchedUserData, searchTerm, containsUser]);

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={loadingRecipients}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={"No Recipients Found"}
            icon={<Icon icon={"ion:person"} height={60} />}
            iconBox={70}
            containerStyles={{ paddingTop: 120 }}
          />
        }
      />
    );
  }, [loadingRecipients, searchTerm]);

  return (
    <div
      className="ColumnNormal PreventScroll"
      style={{ padding: 20, paddingBottom: 0, height: "100%" }}
    >
      {!hasCategories ? (
        <SearchBoxContainer
          placeholder="Search People"
          onChange={(e) => handleSearch(e.target.value)}
          value={searchTerm}
          containerStyles={{ marginTop: 0, marginBottom: 10 }}
        />
      ) : null}
      <div style={styles.sectionContainer}>
        <span
          style={{
            ...styles.mainText,
            color: hasCategories ? Colors.PURPLE7 : Colors.BLACK,
          }}
        >
          {hasCategories
            ? `${massText.subSelectionIds.length} ${
                massText.subSelectionIds.length !== 1
                  ? "Categories"
                  : "Category"
              }`
            : `${allSelectedPeople.length} Recipient${
                allSelectedPeople.length !== 1 ? "s" : ""
              }`}
        </span>
        {massText.excludedSubSelectionIds.length > 0 ? (
          <>
            <HorizontalDivider altMargin={10} />
            <span style={{ ...styles.mainText, color: Colors.RED3 }}>
              {massText.excludedSubSelectionIds.length}{" "}
              {massText.excludedSubSelectionIds.length !== 1
                ? "Categories"
                : "Category"}{" "}
              Excluded
            </span>
          </>
        ) : null}
      </div>
      <div style={{ height: "100%", overflowY: "scroll" }}>
        {hasCategories ? (
          <>
            {massText.subSelectionIds.map((category) => {
              return (
                <SelectRecipientCategoryItem
                  item={category}
                  onPress={() => {}}
                  excludingMode={false}
                  isSelected
                  event={foundEvent}
                  key={category.id}
                />
              );
            })}
            {massText.excludedSubSelectionIds.map((category) => {
              return (
                <SelectRecipientCategoryItem
                  item={category}
                  onPress={() => {}}
                  excludingMode={true}
                  isSelected
                  event={foundEvent}
                  key={category.id}
                />
              );
            })}
          </>
        ) : (
          <FlatList
            list={recipientsToShow}
            renderItem={(user) => {
              return (
                <MassTextsPersonItem
                  user={user}
                  unsavedPeople={[]}
                  setUnsavedPeople={() => {}}
                  setProfileSelected={setProfileSelected}
                  disableCheck
                />
              );
            }}
            renderWhenEmpty={() => renderEmptyView}
            hasMoreItems={!isFinished}
            loadMoreItems={() => loadMoreUsers()}
            paginationLoadingIndicator={() =>
              isLoading && !isFinished ? (
                <CircularProgress
                  style={{ color: "#929292", alignSelf: "center" }}
                  size={20}
                />
              ) : null
            }
          />
        )}
      </div>
    </div>
  );
};

export default MassTextsRecipients;
