import React, { memo, useMemo } from "react";
import { SavedFormResponse } from "@markit/common.types";
import { useTheme } from "../../hooks/useTheme";
import { CopyCustomLinkify } from "../Links/CopyCustomLinkify";
import CustomLinkify from "../Links/CustomLinkify";

type EssentialDataResponseProps = {
  formResponse: SavedFormResponse | null | undefined;
  copyable?: boolean; // also means it's in view profile and should be bolder
  detailView?: boolean; // used to show full response and not one line preview
};

const EssentialDataResponse = memo(function EssentialDataResponseFn(
  props: EssentialDataResponseProps
) {
  const { formResponse, copyable, detailView } = props;
  const { theme } = useTheme();

  const noResponse = useMemo(
    () =>
      formResponse === null ||
      formResponse === undefined ||
      (formResponse &&
        formResponse.responses.every((response: any) => response === "")),
    [formResponse]
  );

  const styles = {
    bodyText: {
      color: noResponse
        ? theme.SubText.color
        : copyable
        ? theme.PrimaryText.color
        : theme.SubText.color,
      fontSize: 14,
      fontWeight: copyable ? 500 : 400,
    },
  };

  const formResponseToShow = useMemo(
    () =>
      noResponse
        ? "Not Collected"
        : formResponse
        ? formResponse.responses.join(", ")
        : "",
    [formResponse, noResponse]
  );

  return copyable ? (
    <CopyCustomLinkify
      text={formResponseToShow}
      textStyles={{ ...styles.bodyText, width: "360px" }}
      disableCopy={noResponse}
      showFullContent={detailView}
    />
  ) : (
    <div style={{ maxWidth: "18vw" }}>
      <CustomLinkify>
        <span
          className={!detailView ? "TextOverflowEllipsis" : ""}
          style={styles.bodyText}
        >
          {formResponseToShow}
        </span>
      </CustomLinkify>
    </div>
  );
});

export default EssentialDataResponse;
