import {
  Campaign,
  Event,
  MassText,
  MassTextAttachment,
  SubSelectionItem,
  SubSelectionType,
} from "@markit/common.types";
import {
  MassTextMessagingScreenType,
  MassTextScreenType,
} from "../MassTextPanel";
import { MassTextMessageDrafting } from "../Message/MassTextMessageDrafting";
import { ThemeStyle } from "../../../../hooks/useTheme";
import { getMassTextAttachments } from "../../../../utils/textingUtils";
import { useState } from "react";
import MassTextsSendDetails from "./MassTextsSendDetails";
import useAsyncEffect from "../../../../hooks/useAsyncEffect";

export type MassTextMessageCompositionProps = {
  campaign: Campaign | undefined;
  finalRecipients: string[];
  netIndividualRecipients: string[];
  massText: MassText;
  updateMassTextSettings: (massTextSettings: Partial<MassText>) => void;
  setMainScreen: (mainScreen: MassTextScreenType) => void;
  screen: MassTextMessagingScreenType;
  setScreen: (screen: MassTextMessagingScreenType) => void;
  isEditing: boolean;
  suggestedAction: SubSelectionItem | undefined;
  loadingRecipients: boolean;
  event?: Event;
  theme?: ThemeStyle;
};

const MassTextMessageComposition = (props: MassTextMessageCompositionProps) => {
  const {
    finalRecipients,
    netIndividualRecipients,
    massText,
    updateMassTextSettings,
    setMainScreen,
    screen,
    setScreen,
    isEditing,
    suggestedAction,
    loadingRecipients,
    event,
    theme,
  } = props;
  const [massTextAttachments, setMassTextAttachments] = useState<
    MassTextAttachment[] | undefined
  >();

  // Should probably move this somewhere else to one main fetching location and utilize the useLiveUpdatingEventTexts hook
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (!massTextAttachments && massText.sentBy !== "") {
      if (isEditing) {
        const attachments = await getMassTextAttachments(
          massText.sentBy,
          massText
        );
        setMassTextAttachments(attachments);
      } else {
        setMassTextAttachments([]);
      }
    }
  }, [isEditing, massText, massTextAttachments]);

  return (
    <div className="ColumnNormal" style={{ height: "100%" }}>
      <MassTextsSendDetails
        massText={massText}
        setMainScreen={setMainScreen}
        screen={screen}
        setScreen={setScreen}
        netRecipients={finalRecipients.length}
        suggestedAction={suggestedAction}
        loadingRecipients={loadingRecipients}
        event={event}
        isEditing={isEditing}
      />
      <MassTextMessageDrafting
        massText={massText}
        updateMassTextSettings={updateMassTextSettings}
        finalRecipients={finalRecipients}
        disableEventLinks={
          netIndividualRecipients.length > 0 ||
          suggestedAction?.type === SubSelectionType.ALL_FOLLOWERS
        }
        massTextAttachments={massTextAttachments}
        event={event}
        theme={theme}
      />
    </div>
  );
};

export default MassTextMessageComposition;
