import { useMemo } from "react";
import { CreatorPanelTabs } from "../Tabs/CreatorPanelTabs";

type EventDashboardTabsProps = {
  dashboardValue: number;
  setDashboardValue: (dashboardValue: number) => void;
  viewTextsTab: boolean;
  viewAnalyticsTab: boolean;
  viewFormsTab: boolean;
  viewPromosTab: boolean;
  viewSettingsTab: boolean;
  isEventbrite: boolean;
};

export const EventDashboardTabs = (props: EventDashboardTabsProps) => {
  const {
    dashboardValue,
    setDashboardValue,
    viewTextsTab,
    viewAnalyticsTab,
    viewFormsTab,
    viewPromosTab,
    viewSettingsTab,
    isEventbrite,
  } = props;

  const headerTabsNames = useMemo(() => {
    const tabNames = ["Dashboard"];
    if (!isEventbrite) {
      if (viewTextsTab) {
        tabNames.push("Texts");
      }
      if (viewAnalyticsTab) {
        tabNames.push("Analytics");
      }
      if (viewFormsTab) {
        tabNames.push("Forms");
      }
      if (viewPromosTab) {
        tabNames.push("Promos");
      }
      if (viewSettingsTab) {
        tabNames.push("Settings");
      }
    }
    return tabNames;
  }, [
    isEventbrite,
    viewAnalyticsTab,
    viewFormsTab,
    viewPromosTab,
    viewSettingsTab,
    viewTextsTab,
  ]);

  return (
    <div style={{ marginTop: 20, marginBottom: 30 }}>
      <CreatorPanelTabs
        tabValue={dashboardValue}
        setTabValue={setDashboardValue}
        tabNames={headerTabsNames}
      />
    </div>
  );
};
