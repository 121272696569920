import { Campaign, CampaignType, MassText } from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import { LessThanDate } from "@markit/common.utils";
import { Icon } from "@iconify/react";
import { DropdownMenu, DropdownMenuItem } from "../../DropdownMenu";
import { useCallback, useMemo, useState } from "react";
import { MassTextConfirmDeleteModal } from "../../CreatorDashboard/MassTexts/Items/MassTextConfirmDeleteModal";
import { useNavigate } from "react-router-dom";
import { NavigationId } from "../../../navigation/AppParamList";
import { MassTextDetails } from "../../../redux/slices/campaignSlice";
import AddToListModal from "../../CreatorDashboard/CreatorProfile/AudienceLists/AddToListModal";
import FatHeaderActionButton from "../../Buttons/FatHeaderActionButton";
import {
  displayTextAnalytics,
  displayTextConversions,
} from "../../../utils/campaignUtils";
import { useSelector } from "react-redux";
import { getEventState } from "../../../redux/slices/eventSlice";
import { getMassTextRecipients } from "../../../utils/textingUtils";
import { API } from "../../../API";

export enum SaveToListType {
  NONE = -1,
  CONVERTED = 0,
  CLICKED = 1,
  ALL_RECIPIENTS = 2,
}

type CampaignMassTextItemSettingsProps = {
  massTextDetail: MassTextDetails;
  campaign: Campaign;
  numCampaignTexts: number;
  campaignInitialText: MassText | undefined;
  duplicateOnPress?: (massText: MassText) => void;
  viewOnPress?: () => void;
  showSaveToList?: boolean;
};

export const CampaignMassTextItemSettings = (
  props: CampaignMassTextItemSettingsProps
) => {
  const {
    massTextDetail,
    campaign,
    viewOnPress,
    duplicateOnPress,
    numCampaignTexts,
    campaignInitialText,
    showSaveToList,
  } = props;
  const navigate = useNavigate();
  const { events } = useSelector(getEventState).events;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [listModalVisible, setListModalVisible] = useState<SaveToListType>(
    SaveToListType.NONE
  );
  const [allRecipients, setAllRecipients] = useState<string[]>([]);
  const [clickedRecipients, setClickedRecipients] = useState<string[]>([]);
  const [convertedRecipients, setConvertedRecipients] = useState<string[]>([]);
  const [loadingRecipients, setLoadingRecipients] = useState(false);

  const massText = useMemo(
    () => massTextDetail.massText,
    [massTextDetail.massText]
  );

  const isScheduledMassText = useMemo(
    () =>
      massText.scheduled &&
      LessThanDate(new Date().toISOString(), massText.sentAt),
    [massText.scheduled, massText.sentAt]
  );

  const showSaveClickedOption = useMemo(
    () =>
      displayTextAnalytics(massTextDetail.attachments) &&
      massTextDetail.attachments.reduce(
        (prev, curr) => prev + curr.numOpens,
        0
      ),
    [massTextDetail.attachments]
  );

  const showSaveConvertedOption = useMemo(
    () =>
      displayTextConversions(massTextDetail.attachments, events) &&
      massTextDetail.attachments.reduce(
        (prev, curr) => prev + curr.numConversions,
        0
      ),
    [events, massTextDetail.attachments]
  );

  const saveToListUsers = useMemo(
    () =>
      listModalVisible === SaveToListType.ALL_RECIPIENTS
        ? allRecipients
        : listModalVisible === SaveToListType.CLICKED
        ? clickedRecipients
        : convertedRecipients,
    [allRecipients, clickedRecipients, convertedRecipients, listModalVisible]
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const items: DropdownMenuItem[] = [];
    if (viewOnPress) {
      items.push({
        title: "View",
        icon: "ion:arrow-forward",
        key: "view",
        onPress: viewOnPress,
      });
    }
    if (duplicateOnPress && campaign.type !== CampaignType.AUTOMATION) {
      items.push({
        title: "Duplicate Text",
        icon: "ion:copy",
        key: "duplicate_text",
        onPress: () => duplicateOnPress(massText),
      });
    }
    if (isScheduledMassText) {
      items.push({
        title: "Delete",
        icon: "ion:trash",
        key: "delete",
        onPress: () => setShowDeleteModal(true),
        isDestructive: true,
      });
    }
    return items;
  }, [
    campaign.type,
    duplicateOnPress,
    isScheduledMassText,
    massText,
    viewOnPress,
  ]);

  const saveToListItems: DropdownMenuItem[] = useMemo(() => {
    const items: DropdownMenuItem[] = [];
    if (!isScheduledMassText) {
      if (showSaveConvertedOption) {
        items.push({
          title: "Save Converted",
          icon: "mdi:playlist-plus",
          key: "save_converted",
          onPress: () => setListModalVisible(SaveToListType.CONVERTED),
          loading: loadingRecipients,
        });
      }
      if (showSaveClickedOption) {
        items.push({
          title: "Save Clicked",
          icon: "mdi:playlist-plus",
          key: "save_clicked",
          onPress: () => setListModalVisible(SaveToListType.CLICKED),
          loading: loadingRecipients,
        });
      }
    }
    items.push({
      title: "Save All Recipients",
      icon: "mdi:playlist-plus",
      key: "save_all_recipients",
      onPress: () => setListModalVisible(SaveToListType.ALL_RECIPIENTS),
      loading: loadingRecipients,
    });
    return items;
  }, [
    isScheduledMassText,
    loadingRecipients,
    showSaveClickedOption,
    showSaveConvertedOption,
  ]);

  // Load the recipient lists once the dropdown button is pressed
  const loadSaveToListRecipients = useCallback(async () => {
    // Don't need to fetch again if already loaded
    if (allRecipients.length > 0) {
      return;
    }
    setLoadingRecipients(true);
    const attachmentIds = massTextDetail.attachments.map((att) => att.id);
    const [allRecipientIds, clickedResponse, convertedResponse] =
      await Promise.all([
        await getMassTextRecipients(campaign.createdBy, campaign, massText.id),
        await API.tracking.getTextAnalyticRecipients({
          userId: campaign.createdBy,
          campaignId: campaign.id,
          massTextId: massText.id,
          attachmentIds: attachmentIds,
          type: "clicked",
        }),
        await API.tracking.getTextAnalyticRecipients({
          userId: campaign.createdBy,
          campaignId: campaign.id,
          massTextId: massText.id,
          attachmentIds: attachmentIds,
          type: "converted",
        }),
      ]);
    setAllRecipients(allRecipientIds);
    setClickedRecipients(clickedResponse.userIds);
    setConvertedRecipients(convertedResponse.userIds);
    setLoadingRecipients(false);
  }, [allRecipients.length, campaign, massText.id, massTextDetail.attachments]);

  return (
    <>
      {dropdownItems.length > 0 ? (
        <DropdownMenu
          dropdownItems={dropdownItems}
          trigger={["click"]}
          placement="bottomLeft"
          altWidth={204}
        >
          <Icon
            icon={"mdi:dots-horizontal"}
            height={22}
            style={{ color: Colors.BLACK }}
          />
        </DropdownMenu>
      ) : null}
      {showSaveToList ? (
        <DropdownMenu
          dropdownItems={saveToListItems}
          trigger={["click"]}
          placement="bottomLeft"
          altWidth={204}
        >
          <FatHeaderActionButton
            title="Save to List"
            onPress={loadSaveToListRecipients}
            containerStyles={{ paddingInline: 14, paddingBlock: 10 }}
          />
        </DropdownMenu>
      ) : null}
      {listModalVisible !== SaveToListType.NONE ? (
        <AddToListModal
          isVisible={true}
          closeOnPress={() => setListModalVisible(SaveToListType.NONE)}
          userIds={saveToListUsers}
        />
      ) : null}
      {showDeleteModal && campaignInitialText ? (
        <MassTextConfirmDeleteModal
          massText={massText}
          campaignInitialText={campaignInitialText}
          showDeleteModal={showDeleteModal}
          isOnlyMassText={numCampaignTexts === 1}
          setShowDeleteModal={setShowDeleteModal}
          handleAfterDelete={() => {
            // Leave the dashboard if initial text is deleted
            if (campaign.initialTextId === massText.id) {
              navigate(NavigationId.HOME_CAMPAIGNS);
            }
          }}
        />
      ) : null}
    </>
  );
};
