import { useCallback, useMemo, useRef, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  where,
  startAfter,
} from "../firebase";
import { filterUndefinedValues, uniqueVals } from "@markit/common.utils";
import {
  AccountData,
  OrganizerType,
  RequestStatus,
  TicketV2,
  Event,
  FollowerStatus,
} from "@markit/common.types";
import { getTicketsRef } from "../utils/FirebaseUtils";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";
import { useDispatch, useSelector } from "react-redux";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import { fetchSingleFollower, fetchSingleUser } from "../utils/FetchSingleData";
import useOnUnmount from "./useOnUnmount";

type useLoadUserTicketListProps = {
  event?: Event;
  followerStatus?: FollowerStatus;
  windowSize: number;
  ticketType: string; // empty string if none specified and want to not filter by a ticket type
};
export const useLoadUserTicketList = (props: useLoadUserTicketListProps) => {
  const { event, followerStatus, windowSize, ticketType } = props;

  const { followingAccountData, followersData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [fetchedUserData, setFetchedUserData] = useState<AccountData[]>([]);
  const [fetchedTicketData, setFetchedTicketData] = useState<TicketV2[]>([]);
  const [fetchedOldestTicketData, setFetchedOldestTicketData] = useState<
    TicketV2[]
  >([]);
  const [fetchedOrderedTicketData, setFetchedOrderedTicketData] = useState<
    TicketV2[]
  >([]);
  const [fetchedOrderedTicketMap, setFetchedOrderedTicketMap] = useState(
    new Map<TicketV2, string>()
  );
  const unsubscribeArrayRef = useRef<(() => void)[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  useOnUnmount(
    (latestDeps) => {
      dispatch(
        accountActions.addMultipleFollowingAccountData(
          latestDeps[0] as AccountData[]
        )
      );
      unsubscribeArrayRef.current.forEach(
        (unsubscribe) => unsubscribe && unsubscribe()
      );
      unsubscribeArrayRef.current = []; // Clear the array after cleanup
    },
    [fetchedUserData]
  );

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUserData((fetchedUserData) =>
      uniqueVals(
        newUserData.concat(fetchedUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const addTicketData = useCallback((newTicketData: TicketV2[]) => {
    setFetchedTicketData((fetchedTicketData) =>
      uniqueVals(
        newTicketData.concat(fetchedTicketData),
        (ticketData) => ticketData.id
      ).sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    );
  }, []);

  const removeTicketData = useCallback((ticketData: TicketV2) => {
    setFetchedTicketData((fetchedTicketData) => {
      const index = fetchedTicketData.findIndex(
        (fetched) => fetched.id === ticketData.id
      );
      if (index !== -1) {
        fetchedTicketData.splice(index, 1);
      }
      return [...fetchedTicketData];
    });
  }, []);

  const addOldestTicketData = useCallback((newTicketData: TicketV2[]) => {
    setFetchedOldestTicketData((fetchedOldestTicketData) =>
      uniqueVals(
        fetchedOldestTicketData.concat(newTicketData),
        (ticketData) => ticketData.id
      )
    );
  }, []);

  const removeOldestTicketData = useCallback((ticketData: TicketV2) => {
    setFetchedOldestTicketData((fetchedOldestTicketData) => {
      const index = fetchedOldestTicketData.findIndex(
        (fetched) => fetched.id === ticketData.id
      );
      if (index !== -1) {
        fetchedOldestTicketData.splice(index, 1);
      }
      return fetchedOldestTicketData;
    });
  }, []);

  // TODO (jonathan): this still uses old logic, but doesn't really matter, so not refactoring to account for fullName sorting
  const addOrderedTicketData = useCallback(
    (newTicketData: TicketV2, fullName: string) => {
      setFetchedOrderedTicketData((fetchedOrderedTicketData) => {
        const index = fetchedOrderedTicketData.findIndex(
          (fetched) => fetched.id === newTicketData.id
        );
        if (index !== -1) {
          fetchedOrderedTicketData.splice(index, 1, newTicketData);
          return uniqueVals(
            fetchedOrderedTicketData,
            (fetchedOrderedTicketData) => fetchedOrderedTicketData.id
          ).sort();
        } else {
          const newTicketMap = fetchedOrderedTicketMap.set(
            newTicketData,
            fullName
          );
          setFetchedOrderedTicketMap(newTicketMap);
          return Array.from(
            new Map(Array.from(newTicketMap.entries()).sort()).keys()
          );
        }
      });
    },
    [fetchedOrderedTicketMap]
  );

  const removeOrderedTicketData = useCallback(
    (newTicketData: TicketV2) => {
      setFetchedOrderedTicketData((fetchedOrderedTicketData) => {
        const index = fetchedOrderedTicketData.findIndex(
          (fetched) => fetched.id === newTicketData.id
        );
        if (index !== -1) {
          fetchedOrderedTicketMap.delete(newTicketData);
          setFetchedOrderedTicketMap(fetchedOrderedTicketMap);
          return Array.from(
            new Map(Array.from(fetchedOrderedTicketMap.entries()).sort()).keys()
          );
        }

        return uniqueVals(
          fetchedOrderedTicketData,
          (fetchedOrderedTicketData) => fetchedOrderedTicketData.id
        );
      });
    },
    [fetchedOrderedTicketMap]
  );

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const updateData = useCallback(
    async (foundTicketData: TicketV2[]) => {
      if (event) {
        const membersData = await Promise.all(
          foundTicketData.map(async (ticketData) => {
            if (
              ticketType === "organizers" ||
              (ticketData &&
                ticketData.customTicketId !== "" &&
                ticketData.uid !== "")
            ) {
              if (
                (ticketType === "organizers" && ticketData.uid !== "") ||
                (ticketType !== "organizers" && ticketData.redeemedBy !== "") ||
                (ticketData.uid &&
                  !fetchedUserData.find(
                    (fetched) => fetched.uid === ticketData.uid
                  ))
              ) {
                const userData = await fetchSingleUser(
                  ticketData.uid,
                  followingAccountData
                );
                if (followerStatus) {
                  const followerData = await fetchSingleFollower(
                    event.createdBy,
                    ticketData.uid,
                    followersData
                  );
                  return {
                    membersTicketData: ticketData,
                    membersUserData:
                      userData &&
                      followerData &&
                      followerStatus === followerData.status
                        ? userData
                        : undefined,
                  };
                } else {
                  return {
                    membersTicketData: ticketData,
                    membersUserData: userData,
                  };
                }
              } else {
                return {
                  membersTicketData: ticketData,
                  membersUserData: undefined,
                };
              }
            } else {
              return undefined;
            }
          })
        );

        const definedMembersData: {
          membersTicketData: TicketV2;
          membersUserData: AccountData | undefined;
        }[] = filterUndefinedValues(membersData);

        const followerStatusMembersTicketData: (TicketV2 | undefined)[] =
          definedMembersData.map((member) => member.membersTicketData);
        const followerStatusMembersUserData: (AccountData | undefined)[] =
          definedMembersData.map((member) => member.membersUserData);

        addTicketData(filterUndefinedValues(followerStatusMembersTicketData));
        addUserData(filterUndefinedValues(followerStatusMembersUserData));
      }
    },
    [
      addTicketData,
      addUserData,
      event,
      fetchedUserData,
      followerStatus,
      followersData,
      followingAccountData,
      ticketType,
    ]
  );

  const initialUpdateData = useCallback(
    async (foundTicketData: TicketV2[]) => {
      if (foundTicketData.length) {
        setLastVisible(foundTicketData[foundTicketData.length - 1].createdAt);
      }
      if (foundTicketData.length < windowSize) {
        setLoadedAllMessages(true);
      }

      if (foundTicketData.length > 0) {
        await updateData(foundTicketData);
      }
    },
    [updateData, windowSize]
  );

  const liveUpdateData = useCallback(
    async (
      type: "added" | "modified" | "removed",
      ticketData: TicketV2,
      foundTicketData: TicketV2[]
    ) => {
      if (
        (type === "added" &&
          !foundTicketData.some((found) => found.id === ticketData.id)) ||
        type === "modified"
      ) {
        updateData([ticketData]);
      } else if (type === "removed") {
        removeTicketData(ticketData);
      }
    },
    [removeTicketData, updateData]
  );

  const loadTicketUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      if (!event) {
        setFetchedUserData([]);
        setFetchedTicketData([]);
        return;
      }
      setIsLoading(true);

      const ticketsRef = getTicketsRef(event.id);
      const ticketUsersQuery =
        ticketType === "organizers"
          ? query(
              ticketsRef,
              where("customTicketId", "==", ""),
              orderBy("createdAt", "desc"),
              limit(windowSize)
            )
          : ticketType !== ""
          ? query(
              ticketsRef,
              where("role.type", "==", OrganizerType.ATTENDEE),
              where("requestStatus", "==", ticketType),
              orderBy("createdAt", "desc"),
              limit(windowSize)
            )
          : query(
              ticketsRef,
              where("role.type", "==", OrganizerType.ATTENDEE),
              orderBy("createdAt", "desc"),
              limit(windowSize)
            );

      const foundTicketUsers = (await getDocs(ticketUsersQuery)).docs.map(
        (doc) => doc.data()
      );
      await initialUpdateData(foundTicketUsers);

      const unsubscribe = onSnapshot(ticketUsersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const ticketData = message.doc.data();
          liveUpdateData(message.type, ticketData, foundTicketUsers);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setIsLoading(false);
    }
  }, [
    event,
    initialUpdateData,
    isFinished,
    isLoading,
    liveUpdateData,
    ticketType,
    windowSize,
  ]);

  const loadMoreTicketUsers = useCallback(async () => {
    if (!isFinished) {
      if (!event) {
        setFetchedUserData([]);
        setFetchedTicketData([]);
        return;
      }
      setIsLoading(true);
      const ticketsRef = getTicketsRef(event.id);
      const ticketUsersQuery =
        ticketType === "organizers"
          ? query(
              ticketsRef,
              where("customTicketId", "==", ""),
              orderBy("createdAt", "desc"),
              startAfter(lastVisible),
              limit(windowSize)
            )
          : ticketType !== ""
          ? query(
              ticketsRef,
              where("role.type", "==", OrganizerType.ATTENDEE),
              where("requestStatus", "==", ticketType),
              orderBy("createdAt", "desc"),
              startAfter(lastVisible),
              limit(windowSize)
            )
          : query(
              ticketsRef,
              where("role.type", "==", OrganizerType.ATTENDEE),
              orderBy("createdAt", "desc"),
              startAfter(lastVisible),
              limit(windowSize)
            );

      const foundTicketUsers = (await getDocs(ticketUsersQuery)).docs.map(
        (doc) => doc.data()
      );
      await initialUpdateData(foundTicketUsers);

      const unsubscribe = onSnapshot(ticketUsersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const ticketData = message.doc.data();
          liveUpdateData(message.type, ticketData, foundTicketUsers);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setIsLoading(false);
    }
  }, [
    event,
    initialUpdateData,
    isFinished,
    lastVisible,
    liveUpdateData,
    ticketType,
    windowSize,
  ]);

  /*
   * Start searching utils
   */
  // need to return account data for ordered user data case
  const checkIfAddUserData = useCallback(
    async (uid: string): Promise<AccountData | undefined> => {
      const userData = await fetchSingleUser(uid, followingAccountData);
      if (event && userData) {
        if (followerStatus) {
          const followerData = await fetchSingleFollower(
            event.createdBy,
            userData.uid,
            followersData
          );
          if (followerData) {
            if (followerData.status === followerStatus) {
              addUserData([userData]);
              return userData;
            }
          }
        } else {
          addUserData([userData]);
          return userData;
        }
      }
    },
    [addUserData, event, followerStatus, followersData, followingAccountData]
  );

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      if (!event) {
        setFetchedUserData([]);
        setFetchedTicketData([]);
      } else {
        const fetchEventUsers = async (userIds: string[]) => {
          setIsLoading(true);
          const unsubscribeArray = await Promise.all(
            userIds.map(async (uid) => {
              const ticketsRef = getTicketsRef(event.id);
              const query_ =
                ticketType !== ""
                  ? query(
                      ticketsRef,
                      where("requestStatus", "==", ticketType),
                      where("uid", "==", uid)
                    )
                  : query(ticketsRef, where("uid", "==", uid));

              const unsubscribe = onSnapshot(query_, (snapshot) => {
                snapshot.docChanges().forEach(async (message) => {
                  if (message.type === "added" || message.type === "modified") {
                    const ticketUserData = message.doc.data();
                    if (
                      ticketUserData &&
                      ((ticketUserData.customTicketId !== "" &&
                        ticketUserData.uid !== "") ||
                        ticketType === "organizers")
                    ) {
                      addTicketData([ticketUserData]);
                      if (
                        ticketType === "organizers" ||
                        ticketUserData.requestStatus === RequestStatus.PENDING
                          ? ticketUserData.uid !== ""
                          : ticketUserData.redeemedBy !== "" &&
                            ticketUserData.redeemedBy !== "manual redeemed" &&
                            ticketUserData.redeemedBy !==
                              "manual redeemed scanned"
                      ) {
                        checkIfAddUserData(
                          ticketUserData.requestStatus === RequestStatus.PENDING
                            ? ticketUserData.uid
                            : ticketUserData.redeemedBy
                        );
                      }
                    }
                  } else if (message.type === "removed") {
                    const ticketUserData = message.doc.data();
                    removeTicketData(ticketUserData);
                  }
                });
              });
              return unsubscribe;
            })
          );
          unsubscribeArrayRef.current.push(...unsubscribeArray);

          setIsLoading(false);
        };
        await loadAlgoliaUsersSearchResults(
          searchTerm,
          `eventIds:${event.id}`,
          (userIds: string[]) => fetchEventUsers(userIds)
        );
      }
    },
    [addTicketData, checkIfAddUserData, event, removeTicketData, ticketType]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );
  /*
   * End searching utils
   */

  // used to sort the list alphabetically. Instead of incremental fetching, need
  // to fetch the whole data set in this case.
  const loadAlphabeticalTicketUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      if (!event) {
        setFetchedUserData([]);
        setFetchedTicketData([]);
        return;
      }
      setIsLoading(true);
      const ticketsRef = getTicketsRef(event.id);
      const ticketUsersQuery =
        ticketType === "organizers"
          ? query(
              ticketsRef,
              where("customTicketId", "==", ""),
              where("redeemedBy", "!=", "")
            )
          : query(
              ticketsRef,
              where("role.type", "==", OrganizerType.ATTENDEE),
              where("uid", "!=", ""),
              where("requestStatus", "==", ticketType)
            );
      const unsubscribe = onSnapshot(ticketUsersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const ticketUserData = message.doc.data();
            if (ticketType === "organizers") {
              if (ticketUserData.redeemedBy !== "") {
                const userData = await checkIfAddUserData(ticketUserData.uid);
                if (userData) {
                  addOrderedTicketData(ticketUserData, userData.fullName);
                }
              }
            } else {
              if (
                ticketUserData &&
                ticketUserData.customTicketId !== "" &&
                ticketUserData.uid !== ""
              ) {
                // need to add ticket data after fetching user data here so we can sort by full name
                // Also different conditional than on rest because we need to always return userData
                // so we can return the full name to apply to the ordered ticket data
                const userData = await checkIfAddUserData(ticketUserData.uid);
                if (userData) {
                  addOrderedTicketData(ticketUserData, userData.fullName);
                }
              }
            }
          } else if (message.type === "removed") {
            const ticketUserData = message.doc.data();
            removeOrderedTicketData(ticketUserData);
          }
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      return unsubscribe;
    }
  }, [
    addOrderedTicketData,
    checkIfAddUserData,
    event,
    isFinished,
    isLoading,
    removeOrderedTicketData,
    ticketType,
  ]);

  // used to sort the list alphabetically. Instead of incremental fetching, need
  // to fetch the whole data set in this case.
  const loadByOldestTicketUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      if (!event) {
        setFetchedUserData([]);
        setFetchedTicketData([]);
        return;
      }
      setIsLoading(true);
      const ticketsRef = getTicketsRef(event.id);
      const ticketUsersQuery =
        ticketType === "organizers"
          ? query(
              ticketsRef,
              where("customTicketId", "==", ""),
              orderBy("createdAt", "desc")
            )
          : query(
              ticketsRef,
              where("role.type", "==", OrganizerType.ATTENDEE),
              where("requestStatus", "==", ticketType),
              orderBy("createdAt", "asc")
            );

      const unsubscribeOldest = onSnapshot(ticketUsersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const ticketUserData = message.doc.data();
            if (ticketType === "organizers") {
              addOldestTicketData([ticketUserData]);
              if (ticketUserData.uid !== "") {
                addTicketData([ticketUserData]);
                if (ticketUserData.redeemedBy !== "") {
                  checkIfAddUserData(ticketUserData.uid);
                }
              }
            } else {
              if (
                ticketUserData &&
                ticketUserData.customTicketId !== "" &&
                ticketUserData.uid !== ""
              ) {
                addOldestTicketData([ticketUserData]);

                if (ticketUserData.redeemedBy !== "") {
                  checkIfAddUserData(ticketUserData.uid);
                } else if (
                  !fetchedUserData.find(
                    (fetched) => fetched.uid === ticketUserData.uid
                  )
                ) {
                  checkIfAddUserData(ticketUserData.uid);
                }
              }
            }
          } else if (message.type === "removed") {
            const ticketUserData = message.doc.data();
            removeOldestTicketData(ticketUserData);
          }
        });
      });
      unsubscribeArrayRef.current.push(unsubscribeOldest);

      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      return unsubscribeOldest;
    }
  }, [
    addOldestTicketData,
    addTicketData,
    checkIfAddUserData,
    event,
    fetchedUserData,
    isFinished,
    isLoading,
    removeOldestTicketData,
    ticketType,
  ]);

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    fetchedTicketData,
    fetchedOldestTicketData,
    fetchedOrderedTicketData,
    loadTicketUsers,
    loadMoreTicketUsers,
    loadSearchResultsThrottled,
    loadAlphabeticalTicketUsers,
    loadByOldestTicketUsers,
  };
};
