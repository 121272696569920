import { MassText, NotificationType } from "@markit/common.types";
import { Icon } from "@iconify/react";
import { useCallback, useMemo } from "react";
import ConfirmDeleteModal from "../../../Containers/ConfirmPopups/ConfirmDeleteModal";
import { API } from "../../../../API";
import { Colors } from "../../../../utils/colors";
import { useDispatch } from "react-redux";
import { campaignActions } from "../../../../redux/slices/campaignSlice";
import { showNotificationBanner } from "../../../../utils/notificationUtils";

type MassTextConfirmDeleteModalProps = {
  massText: MassText;
  campaignInitialText: MassText | undefined;
  showDeleteModal: boolean;
  isOnlyMassText: boolean;
  setShowDeleteModal: (showDeleteModal: boolean) => void;
  handleAfterDelete: () => void;
};

export const MassTextConfirmDeleteModal = (
  props: MassTextConfirmDeleteModalProps
) => {
  const {
    massText,
    campaignInitialText,
    showDeleteModal,
    isOnlyMassText,
    setShowDeleteModal,
    handleAfterDelete,
  } = props;
  const dispatch = useDispatch();

  const isInitialText = useMemo(
    () => campaignInitialText && campaignInitialText.id === massText.id,
    [campaignInitialText, massText.id]
  );

  const deleteMassText = useCallback(async () => {
    setShowDeleteModal(false);
    if (massText) {
      await API.text
        .deleteMassTextMessage({
          userId: massText.sentBy,
          campaignId: massText.campaignId,
          massTextId: massText.id,
        })
        .then((response) => {
          if (!response.success) {
            alert("Failed to delete the mass text at this time.");
          } else {
            if (isInitialText && campaignInitialText) {
              dispatch(
                campaignActions.removeCampaign(campaignInitialText.campaignId)
              );
            }
            showNotificationBanner(
              dispatch,
              `${
                isInitialText && campaignInitialText ? "Campaign" : "Text"
              } Deleted!`,
              NotificationType.NEGATIVE,
              "ion:trash-outline"
            );
            handleAfterDelete();
          }
        });
    }
  }, [
    campaignInitialText,
    dispatch,
    handleAfterDelete,
    isInitialText,
    massText,
    setShowDeleteModal,
  ]);

  return (
    <>
      <ConfirmDeleteModal
        heading={
          isInitialText || isOnlyMassText
            ? "Delete Text & Campaign?"
            : "Delete Text"
        }
        subtext={`${
          isInitialText && !isOnlyMassText
            ? "This is the initial text in the campaign. "
            : ""
        } Are you sure you want to delete this text? ${
          isInitialText || isOnlyMassText
            ? "This will also delete this entire campaign. "
            : ""
        }This cannot be undone.`}
        deleteButtonText="Delete"
        icon={
          <Icon icon={"ion:trash"} height={35} style={{ color: Colors.RED3 }} />
        }
        hideModal={!showDeleteModal}
        setIsVisible={setShowDeleteModal}
        deleteOnPress={deleteMassText}
      />
    </>
  );
};
