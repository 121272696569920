import { memo, useMemo } from "react";
import { handlePluralString } from "@markit/common.utils";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import { Icon } from "@iconify/react";
import RectangleButton from "../../../Buttons/RectangleButton";
import { Colors } from "../../../../utils/colors";
import TextCreditsNotice from "./TextCreditsNotice";

type ConfirmSendTextModalProps = {
  setModalVisible: (modalVisible: boolean) => void;
  confirmOnPress: () => void;
  isTriggerText: boolean;
  isScheduled: boolean;
  numCredits: number;
  numRecipients: number;
  hasMedia: boolean;
  isSending: boolean;
  showCategories: boolean;
};

export const ConfirmSendTextModal = memo(function ConfirmSendTextModalFn(
  props: ConfirmSendTextModalProps
) {
  const {
    setModalVisible,
    confirmOnPress,
    isTriggerText,
    isScheduled,
    numCredits,
    numRecipients,
    hasMedia,
    isSending,
    showCategories,
  } = props;

  const showCategoryView = useMemo(
    () => showCategories && isScheduled,
    [isScheduled, showCategories]
  );

  return (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 14, fontWeight: 400 }}>
          <Icon
            icon={
              !isTriggerText && isScheduled ? "ion:timer" : "ion:paper-plane"
            }
            height={35}
          />
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="sectionTitle">
              {isTriggerText
                ? "Save Trigger"
                : isScheduled
                ? "Ready to Schedule"
                : "Ready to Send"}
              ?
            </span>
            {numRecipients > 0 && !showCategoryView ? (
              <span className="smallBodySubtext">
                This text will send to {numRecipients}{" "}
                {handlePluralString("recipient", numRecipients)}
                {hasMedia ? " with media pricing applied" : ""}. This cannot be
                undone.
              </span>
            ) : null}
          </div>
          <TextCreditsNotice numCredits={numCredits} />
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <Icon icon={"ion:prism"} height={15} />
              <span className="bodyMedium">
                {showCategoryView || isTriggerText
                  ? `This text will use ${numCredits} ${handlePluralString(
                      "credit",
                      numCredits
                    )} per recipient`
                  : `Sending this text will use ${
                      numCredits * numRecipients
                    } ${handlePluralString(
                      "credit",
                      numCredits * numRecipients
                    )}`}
              </span>
            </div>
            {isTriggerText ? (
              <span className="smallBodySubtext">
                The final credit usage of this text will depend on the total
                number of recipients.
              </span>
            ) : showCategoryView ? (
              <span className="smallBodySubtext">
                The total credit number may fluctuate based on the total number
                of recipients at time of send.
              </span>
            ) : (
              <span className="smallBodySubtext">
                {numCredits} {handlePluralString("credit", numCredits)} per text
                for {numRecipients}{" "}
                {handlePluralString("recipient", numRecipients)}
              </span>
            )}
          </div>
        </div>
      }
      valueComp={
        <div className="AlignedRow" style={{ gap: 10 }}>
          <RectangleButton
            buttonLabel="Cancel"
            onPress={() => setModalVisible(false)}
            altTextColor={Colors.BLACK}
            altColor={Colors.GRAY6}
            altPaddingVert={14}
          />
          <RectangleButton
            buttonLabel={
              isTriggerText ? "Save" : isScheduled ? "Schedule" : "Send Text"
            }
            onPress={confirmOnPress}
            altTextColor={Colors.WHITE}
            altColor={Colors.BLACK}
            altPaddingVert={14}
            loading={isSending}
          />
        </div>
      }
      noExit
      closeOnOutsidePress
      closeModal={() => setModalVisible(false)}
    />
  );
});
