import "../../../css/Containers/ContainerStyles.css";
import { Icon } from "@iconify/react";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import React, { useEffect, useMemo } from "react";
import { Colors } from "../../../utils/colors";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotificationState,
  notificationActions,
} from "../../../redux/slices/notificationSlice";
import { NotificationType } from "@markit/common.types";

type ConfirmActionPopupProps = {};

// Small popup that slides in from the bottom to confirm an action and fades out after a few seconds
const ConfirmActionPopup = (props: ConfirmActionPopupProps) => {
  const { notificationBanner } = useSelector(getNotificationState);
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (notificationBanner.confirmAction) {
        const timer = setTimeout(() => {
          dispatch(notificationActions.clearNotificationBanner());
        }, 3000);
        return () => {
          clearTimeout(timer);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationBanner.lastTriggered]
  );

  const bannerColor = useMemo(
    () =>
      notificationBanner.type === NotificationType.NEGATIVE
        ? Colors.RED3
        : Colors.GREEN2,
    [notificationBanner.type]
  );

  const bannerIcon = useMemo(
    () =>
      notificationBanner.altIcon !== ""
        ? notificationBanner.altIcon
        : notificationBanner.type === NotificationType.NEGATIVE
        ? "ion:close-circle"
        : "ion:checkmark-circle",
    [notificationBanner.altIcon, notificationBanner.type]
  );

  return (
    <>
      {notificationBanner.confirmAction ? (
        <AnimatePresence>
          <LazyMotion features={domAnimation}>
            <m.div
              className="ConfirmActionContainer"
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 40 }}
              transition={{ duration: 0.4, ease: "easeIn" }}
              style={{
                backgroundColor: bannerColor,
                x: "-50%",
              }}
            >
              <Icon icon={bannerIcon} height={18} />
              <span>{notificationBanner.title}</span>
            </m.div>
          </LazyMotion>
        </AnimatePresence>
      ) : null}
    </>
  );
};

export default ConfirmActionPopup;
