import { useCallback } from "react";
import LargePopupModalContainer from "../../Containers/LargePopupModalContainer";
import "../../../css/CreatorDashboardMassTextsPanel.css";
import ShareProfilePanel from "./ShareProfilePanel";
import ShareProfilePanelHeader from "./ShareProfilePanelHeader";

type ShareProfilePopupPanelProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  showBackIcon?: boolean;
};

const ShareProfilePopupPanel = (props: ShareProfilePopupPanelProps) => {
  const { isVisible, setIsVisible, showBackIcon } = props;

  const closeOnPress = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  return (
    <LargePopupModalContainer
      showModal={isVisible}
      headerComp={
        <ShareProfilePanelHeader
          showBackIcon={showBackIcon}
          closeOnPress={closeOnPress}
        />
      }
      valueComp={<ShareProfilePanel setIsVisible={setIsVisible} />}
      closeOnOutsidePress={() => setIsVisible(false)}
    />
  );
};

export default ShareProfilePopupPanel;
