import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import "../../../../css/MassTexts.css";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AccountData,
  Event,
  MassText,
  MassTextAttachment,
  MassTextAttachmentType,
  MassTextType,
  SubSelectionType,
} from "@markit/common.types";
import {
  LessThanDate,
  dateIsCurrentYear,
  getCategoryLabelName,
  getTimezone,
  handlePluralString,
  isEventExternalLink,
  massTextMessageConverter,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import {
  getMassTextAttachments,
  getMassTextDeliveryAttemptedRate,
  getMassTextSendRate,
} from "../../../../utils/textingUtils";
import { GetLongDate } from "../../../../utils/GetLongDate";
import { GetTime } from "../../../../utils/GetTime";
import { MassTextMessagingScreenType } from "../MassTextPanel";
import ProgressBar from "@ramonak/react-progress-bar";
import CustomLinkify from "../../../Links/CustomLinkify";
import { onSnapshot } from "../../../../firebase";
import {
  getCampaignMassTextRef,
  getUserData,
} from "../../../../utils/FirebaseUtils";
import { CircularProgress } from "@mui/material";
import { MassTextMessageCompositionProps } from "./MassTextMessageComposition";
import { getEventState } from "../../../../redux/slices/eventSlice";
import MassTextsLinkAnalytics from "./MassTextLinkAnalytics";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import TextCreditsTagModal from "../Message/TextCreditsTagModal";
import { getMassTextAttachmentsLinkAnalytics } from "../../../../utils/trackingUtils";
import useAsyncOnMount from "../../../../hooks/useAsyncEffectOnMount";

type MassTextsSummaryProps = MassTextMessageCompositionProps;

const MassTextsSummary = (props: MassTextsSummaryProps) => {
  const {
    campaign,
    finalRecipients,
    massText,
    screen,
    setScreen,
    theme,
    loadingRecipients,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData, savedQuestions } = account;
  const { events } = useSelector(getEventState);
  const { events: userEvents } = events;
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLive, setLoadingLive] = useState<boolean>(true);
  const [liveMassText, setLiveMassText] = useState<MassText>(massText);
  const [massTextAttachments, setMassTextAttachments] = useState<
    MassTextAttachment[]
  >([]);
  const [deliveryAttemptedRate, setDeliveryAttemptedRate] = useState<number>(0);
  const [sendRate, setSendRate] = useState<number>(100);
  const [numOpensMap, setNumOpensMap] = useState<Map<string, number>>(
    new Map<string, number>()
  );
  const [numConversionsMap, setNumConversionsMap] = useState<
    Map<string, number>
  >(new Map<string, number>());
  const [selectedAttachment, setSelectedAttachment] =
    useState<MassTextAttachment>();
  const liveQueuedRef = useRef<boolean | null>(null);

  const [aspectRatio, setAspectRatio] = useState(1);

  useEffect(() => {
    if (liveMassText.mediaUrl) {
      const img = new Image();
      img.onload = () => {
        setAspectRatio(img.width / img.height);
      };
      img.src = liveMassText.mediaUrl;
    }
  }, [liveMassText.mediaUrl]);

  useEffect(() => {
    // Set ref to the live queued status to use in the setInterval
    liveQueuedRef.current = liveMassText.queued;
  }, [liveMassText.queued]);

  const styles = {
    coverImage: {
      width: 260,
      borderRadius: 12,
    },

    sectionContainer: {
      paddingBlock: 14,
      backgroundColor: Colors.GRAY6,
      borderRadius: 12,
    },

    showcaseBody: {
      paddingInline: 14,
      paddingBlock: 7,
      gap: 7,
    },
  };

  const isTriggerText = useMemo(
    () => massText.type === MassTextType.TRIGGERTEXT,
    [massText.type]
  );

  const foundMassTextEvent = useMemo(
    () => userEvents.find((event) => event.id === massText.eventRefId),
    [massText.eventRefId, userEvents]
  );

  const hasCategories = useMemo(
    () => massText.subSelectionIds.length > 0,
    [massText.subSelectionIds.length]
  );

  const isScheduledText = useMemo(
    () =>
      LessThanDate(new Date().toISOString(), liveMassText.sentAt) &&
      liveMassText.scheduled,
    [liveMassText.scheduled, liveMassText.sentAt]
  );

  /**
   * Text is finished sending if:
   * 1. text queued and all messages have been sent on twilio
   * 2. text queued and there were no recipients on the text (event text)
   * 3. it is a trigger text
   */
  const isTextFinishedSending = useMemo(
    () =>
      (liveMassText.queued && deliveryAttemptedRate === 100) ||
      liveMassText.queued === undefined ||
      (campaign?.eventId &&
        liveMassText.queued &&
        finalRecipients.length === 0) ||
      liveMassText.type === MassTextType.TRIGGERTEXT,
    [
      campaign?.eventId,
      deliveryAttemptedRate,
      finalRecipients.length,
      liveMassText.queued,
      liveMassText.type,
    ]
  );

  const showLinkAnalytics = useMemo(
    () => isTextFinishedSending && !isScheduledText,
    [isScheduledText, isTextFinishedSending]
  );

  const massTextAttachmentsToShow = useMemo(
    () =>
      massTextAttachments.filter(
        (attachment) =>
          attachment.massTextType !== MassTextAttachmentType.TICKET_LINK
      ),
    [massTextAttachments]
  );

  const categoryLabel = useMemo(() => {
    const tickets = foundMassTextEvent ? foundMassTextEvent.customTickets : [];
    return massText.subSelectionIds.length > 0
      ? massText.subSelectionIds.length > 1 ||
        (massText.subSelectionIds.length === 1 &&
          massText.subSelectionIds[0].type === SubSelectionType.FORM_QUESTION)
        ? "Custom Selection"
        : getCategoryLabelName(
            massText.subSelectionIds[0],
            tickets,
            savedQuestions
          )
      : "No Categories";
  }, [foundMassTextEvent, massText.subSelectionIds, savedQuestions]);

  useAsyncOnMount(async () => {
    let finalUserData: AccountData = accountData;
    // for viewing other user's texting analytics
    if (massText.sentBy !== accountData.uid) {
      const userData = await getUserData(massText.sentBy);
      if (userData) {
        finalUserData = userData;
      }
    }
    const unsubscribe = onSnapshot(
      getCampaignMassTextRef(accountData.uid, massText.campaignId, massText.id),
      async (doc) => {
        const currMassText = doc.data();
        if (currMassText) {
          setLiveMassText(currMassText);
          const massTextAttachments = await getMassTextAttachments(
            finalUserData.uid,
            currMassText
          );
          setMassTextAttachments(massTextAttachments);
          await Promise.all(
            massTextAttachments.map(async (attachment) => {
              const { numOpens, numConversions } =
                await getMassTextAttachmentsLinkAnalytics(
                  finalUserData.uid,
                  currMassText,
                  attachment
                );
              setNumOpensMap(numOpensMap.set(attachment.id, numOpens));
              setNumConversionsMap(
                numConversionsMap.set(attachment.id, numConversions)
              );
            })
          );
        }
        setLoadingLive(false);
      }
    );

    let interval: NodeJS.Timeout;
    // Ignore send rate and default 100% if trigger text (TODO: get actual calculation for trigger texts)
    if (!isTriggerText) {
      // can use massText and not liveMassText since id and sentAt are the only fields used
      const massTextDeliveryAttemptedRate =
        await getMassTextDeliveryAttemptedRate(massText);
      setDeliveryAttemptedRate(massTextDeliveryAttemptedRate);
      if (
        (massTextDeliveryAttemptedRate === 100 && massText.queued) ||
        massText.queued === undefined
      ) {
        const massTextSendRate = await getMassTextSendRate(massText);
        setSendRate(massTextSendRate);
      } else {
        interval = setInterval(async () => {
          const massTextDeliveryAttemptedRate =
            await getMassTextDeliveryAttemptedRate(liveMassText);
          setDeliveryAttemptedRate(massTextDeliveryAttemptedRate);
          if (
            (massTextDeliveryAttemptedRate === 100 && liveQueuedRef.current) ||
            liveQueuedRef.current === undefined
          ) {
            const massTextSendRate = await getMassTextSendRate(liveMassText);
            setSendRate(massTextSendRate);
            if (interval) {
              clearInterval(interval);
            }
          }
        }, 5000);
      }
    }
    setLoading(false);
    return () => {
      unsubscribe();
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const renderEventItemPreview = useCallback(
    (attachment: MassTextAttachment, foundEvent: Event | undefined) => {
      return (
        <div className="AlignedRowSelect" style={{ gap: 8 }}>
          <img
            src={foundEvent?.photoURL ?? ""}
            alt={"LinkAnalyticsEventPic"}
            style={{
              width: 40,
              height: 40,
              borderRadius: 8,
            }}
          />
          <div className="ColumnNormalSelect">
            <span style={{ fontSize: 14, fontWeight: "500" }}>
              {attachment.massTextType === MassTextAttachmentType.LINK
                ? "Event Link"
                : attachment.massTextType}
            </span>
            <span className="bodySubtext">
              {foundEvent?.title ?? "Markit Event"}
            </span>
          </div>
        </div>
      );
    },
    []
  );

  const renderDateAndCredits = useMemo(
    () => (
      <>
        <span className="bodyMedium" style={theme?.PrimaryText}>
          {isTriggerText
            ? "Sent on Trigger"
            : `${GetLongDate(
                liveMassText.sentAt,
                true,
                true,
                true,
                !dateIsCurrentYear(liveMassText.sentAt)
              )}, ${GetTime(liveMassText.sentAt)} ${getTimezone()}`}
        </span>
        <TextCreditsTagModal
          message={liveMassText.message}
          mediaUrl={liveMassText.mediaUrl}
          event={foundMassTextEvent}
        />
      </>
    ),
    [
      foundMassTextEvent,
      isTriggerText,
      liveMassText.mediaUrl,
      liveMassText.message,
      liveMassText.sentAt,
      theme?.PrimaryText,
    ]
  );

  // 1. Scheduled
  // 1. Sent - Show the sent and send rate details
  // 2. In Progress - Show the progress bar while the messages finish getting delivered
  const renderTextRecipients = useMemo(
    () =>
      finalRecipients.length > 0 || hasCategories || isTriggerText ? (
        <div className="ColumnNormal">
          <HorizontalDivider altMargin={14} />
          <div
            className="AlignedRowSpaced"
            style={{ paddingInline: 14, paddingBlock: 7 }}
          >
            <div className="AlignedRow" style={{ gap: 14 }}>
              {hasCategories ? (
                <span className="sectionTitle">{categoryLabel}</span>
              ) : (
                <div className="AlignedRow" style={{ gap: 7 }}>
                  <span className="sectionTitle">
                    {loadingRecipients ? "--" : finalRecipients.length}
                  </span>
                  <span className="bodySubtext">
                    {isTriggerText ? "Live " : ""}
                    {handlePluralString("Recipient", finalRecipients.length)}
                  </span>
                </div>
              )}
            </div>
            {hasCategories || finalRecipients.length > 0 ? (
              <div
                className="AlignedRowSelect"
                onClick={() => {
                  setScreen(
                    MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY
                  );
                }}
                style={{ gap: 2 }}
              >
                <span style={{ fontSize: 14 }}>See All</span>
                <Icon icon="ion:chevron-forward" height={18} />
              </div>
            ) : null}
          </div>
        </div>
      ) : null,
    [
      categoryLabel,
      finalRecipients.length,
      hasCategories,
      isTriggerText,
      loadingRecipients,
      setScreen,
    ]
  );

  // Three cases: 1. Scheduled Text, 2. Sent Text, 3. Sending Text
  const renderTextDetails = useMemo(
    () =>
      isScheduledText ? (
        <div className="ColumnNormal" style={styles.sectionContainer}>
          <div className="ColumnNormal" style={styles.showcaseBody}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <Icon icon="ion:timer" height={16} color={Colors.BLUE5} />
              <span className="sectionTitle" style={{ color: Colors.BLUE5 }}>
                Scheduled
              </span>
            </div>
            {renderDateAndCredits}
          </div>
          {renderTextRecipients}
        </div>
      ) : isTextFinishedSending ? (
        <div className="ColumnNormal" style={styles.sectionContainer}>
          <div className="ColumnNormal" style={styles.showcaseBody}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <Icon icon="ion:paper-plane" height={16} />
              <span className="sectionTitle">
                {isTriggerText
                  ? campaign?.eventId
                    ? foundMassTextEvent &&
                      isEventExternalLink(foundMassTextEvent.eventType)
                      ? "Welcome Text"
                      : "Ticket Text"
                    : "Follow Text"
                  : "Sent"}
              </span>
            </div>
            {renderDateAndCredits}
          </div>
          <HorizontalDivider altMargin={14} />
          <div className="AlignedRow" style={styles.showcaseBody}>
            <span className="sectionTitle">{sendRate}%</span>
            <span className="bodySubtext">Send Rate</span>
          </div>
          {renderTextRecipients}
        </div>
      ) : (
        <div
          className="ColumnNormal"
          style={{ ...styles.sectionContainer, gap: 7 }}
        >
          <div className="ColumnNormal" style={{ paddingInline: 14, gap: 7 }}>
            <div className="AlignedRowSpaced">
              <div className="AlignedRow" style={{ gap: 7 }}>
                <Icon
                  icon={"ion:paper-plane"}
                  height={16}
                  style={{ color: Colors.BLUE5 }}
                />
                <span className="sectionTitle" style={{ color: Colors.BLUE5 }}>
                  {!liveMassText.queued ? "Queued..." : "Sending..."}
                </span>
              </div>
              {/* <Icon
                icon="mdi:information-outline"
                height={16}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  alert("To implement: info popup modal");
                }}
              /> */}
            </div>
            <ProgressBar
              completed={!liveMassText.queued ? 0 : deliveryAttemptedRate}
              maxCompleted={100}
              isLabelVisible={false}
              bgColor={Colors.BLUE5}
              baseBgColor={Colors.WHITE}
              height="16px"
              width="100%"
              borderRadius="4px"
            />
          </div>
          {renderTextRecipients}
        </div>
      ),
    [
      campaign?.eventId,
      deliveryAttemptedRate,
      foundMassTextEvent,
      isScheduledText,
      isTextFinishedSending,
      isTriggerText,
      liveMassText.queued,
      renderDateAndCredits,
      renderTextRecipients,
      sendRate,
      styles.sectionContainer,
      styles.showcaseBody,
    ]
  );

  const renderShowcaseBox = (attachment: MassTextAttachment) => {
    const foundEvent = userEvents.find(
      (event) => event.id === attachment.eventId
    );
    const hideConversions =
      foundEvent && isEventExternalLink(foundEvent.eventType);
    return (
      <div
        key={attachment.id}
        onClick={() => {
          setSelectedAttachment(attachment);
          setScreen(MassTextMessagingScreenType.LINK_ANALYTICS);
        }}
        className="MassTextsSectionContainer"
        style={{
          paddingBlock: 14,
          paddingInline: 0,
          pointerEvents: showLinkAnalytics ? "all" : "none",
        }}
      >
        <div className="AlignedRowSpacedSelect" style={{ paddingInline: 14 }}>
          {renderEventItemPreview(attachment, foundEvent)}
          {showLinkAnalytics ? (
            <Icon
              icon="ion:chevron-forward-outline"
              height={20}
              color={Colors.GRAY1}
            />
          ) : null}
        </div>
        {showLinkAnalytics ? (
          <>
            <HorizontalDivider altMargin={14} />
            <div
              className="AlignedRowSelect"
              style={{ gap: 24, paddingInline: 14 }}
            >
              <div className="ColumnNormalSelect">
                <span className="sectionTitle">
                  {numOpensMap.get(attachment.id) || 0}
                </span>
                <span className="smallBodySubtext">
                  Click
                  {numOpensMap.get(attachment.id) === 1 ? "" : "s"}
                </span>
              </div>
              {!hideConversions ? (
                <div className="ColumnNormalSelect">
                  <span className="sectionTitle">
                    {numConversionsMap.get(attachment.id) || 0}
                  </span>
                  <span className="smallBodySubtext">
                    Conversion
                    {numConversionsMap.get(attachment.id) === 1 ? "" : "s"}
                  </span>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    );
  };

  const renderMessage = useMemo(
    () => (
      <div className="ColumnCenter" style={{ gap: 14 }}>
        <span className="sectionTitle" style={theme?.PrimaryText}>
          Message
        </span>
        <CustomLinkify>
          <span
            className="bodySubtext"
            style={{
              whiteSpace: "break-spaces", // TODO (jonathan): text here to fix overflow text
            }}
          >
            {massTextMessageConverter(
              liveMassText.message,
              massTextAttachments
            )}
          </span>
        </CustomLinkify>
        {massText.mediaUrl ? (
          <img
            src={massText.mediaUrl}
            alt={"text media"}
            style={{ ...styles.coverImage, ...{ aspectRatio: aspectRatio } }}
          />
        ) : null}
      </div>
    ),
    [
      aspectRatio,
      liveMassText.message,
      massText.mediaUrl,
      massTextAttachments,
      styles.coverImage,
      theme?.PrimaryText,
    ]
  );

  return (
    <>
      {!selectedAttachment ||
      screen === MassTextMessagingScreenType.SEND_SUMMARY ? (
        <div
          className="ColumnNormal"
          style={{ gap: 20, padding: 20, height: "100%" }}
        >
          {!loadingLive && !loading ? (
            <>
              {renderTextDetails}
              {!isTriggerText ? (
                <div className="ColumnNormal" style={{ gap: 14 }}>
                  <span className="sectionTitle">
                    {isTextFinishedSending ? "Link Analytics" : "Links"}
                  </span>
                  {massTextAttachmentsToShow.length > 0 ? (
                    massTextAttachmentsToShow.map((attachment) =>
                      renderShowcaseBox(attachment)
                    )
                  ) : (
                    <div
                      style={{
                        ...styles.sectionContainer,
                        paddingInline: 14,
                        paddingBlock: 24,
                      }}
                    >
                      <span className="bodySubtext">No Links to Show</span>
                    </div>
                  )}
                </div>
              ) : null}
              {renderMessage}
              {/** TODO: Hidden after new automation stuff, re-add it down the road when we want this functionality back */}
              {/* {hasCategories ? (
                <MassTextCatchUp
                  massText={liveMassText}
                  catchUpChecked={liveMassText.catchUpEnabled}
                  setCatchUpChecked={(catchUp: boolean) =>
                    updateMassTextSettings({ catchUpEnabled: catchUp })
                  }
                  theme={theme}
                />
              ) : null} */}
              <div style={{ paddingBlock: 50 }} />
            </>
          ) : (
            <div className="Centering" style={{ height: "80%" }}>
              <CircularProgress
                style={{ color: Colors.GRAY1, marginTop: 14 }}
                size={24}
              />
            </div>
          )}
        </div>
      ) : (
        <MassTextsLinkAnalytics
          massText={liveMassText}
          massTextAttachment={selectedAttachment}
        />
      )}
    </>
  );
};

export default MassTextsSummary;
