import { AttachmentSourceType, Event } from "@markit/common.types";
import { Colors } from "../../../../utils/colors";
import ContentViolationTagModal from "../../../DeliveryScore/ContentViolationTagModal";
import { LightTheme, useTheme } from "../../../../hooks/useTheme";
import { AttachmentsActionBarHoldItem } from "./AttachmentsActionBarHoldItem";
import { MenuProps } from "antd";
import { AttachmentsMediaButton } from "./AttachmentsMediaButton";
import { Icon } from "@iconify/react";
import TextCreditsTagModal from "./TextCreditsTagModal";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useMemo } from "react";
import {
  calculateCharactersPerTextCredit,
  deliveryScoreGuidelineOne,
} from "@markit/common.utils";
import { calculateMessagePluginsNumCharacters } from "@markit/common.utils/dist/eventMassTextUtils";

type AttachmentsActionBarProps = {
  message: string;
  image?: string;
  setImage?: (image: string) => void;
  actionItemOnPress?: MenuProps["onClick"];
  attachmentSourceType?: AttachmentSourceType;
  disableEventLinks?: boolean;
  event?: Event;
};

export const AttachmentsActionBar = (props: AttachmentsActionBarProps) => {
  const {
    message,
    image,
    setImage,
    actionItemOnPress,
    attachmentSourceType,
    disableEventLinks,
    event,
  } = props;
  const { theme } = useTheme();

  const messageNumCharacters = useMemo(
    () => calculateMessagePluginsNumCharacters(message, event),
    [event, message]
  );

  const numCharactersPerCredit = useMemo(
    () => calculateCharactersPerTextCredit(message, messageNumCharacters),
    [message, messageNumCharacters]
  );

  return (
    <StandardBorderedContainer
      containerStyles={{
        backgroundColor: Colors.WHITE1,
        padding: 14,
        borderRadius: 12,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div className="AlignedRow" style={{ gap: 14 }}>
        <div style={{ width: 40, height: 40, position: "relative" }}>
          <CircularProgressbar
            value={
              messageNumCharacters > 1500
                ? 100
                : ((((messageNumCharacters - 1) % numCharactersPerCredit) + 1) /
                    numCharactersPerCredit) *
                  100
            }
            strokeWidth={10}
            styles={buildStyles({
              strokeLinecap: "round",
              pathColor: deliveryScoreGuidelineOne(message).color,
              trailColor: Colors.GRAY2,
            })}
          />
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <span
              className="sectionTitle"
              style={{ fontSize: messageNumCharacters > 999 ? 11 : 12 }}
            >
              {messageNumCharacters > 1500
                ? 1500 - messageNumCharacters
                : messageNumCharacters}
            </span>
          </div>
        </div>
        <div className="ColumnNormal" style={{ gap: 4 }}>
          <ContentViolationTagModal
            message={message}
            theme={theme ?? LightTheme}
          />
          <TextCreditsTagModal
            message={message}
            mediaUrl={image}
            event={event}
            showCreditsNotice
          />
        </div>
      </div>
      <div className="AlignedRow">
        {setImage ? (
          <AttachmentsMediaButton
            image={image ?? ""}
            setImage={setImage}
            theme={theme}
          >
            <div
              className="MassTextsAttachmentButton"
              style={{
                alignSelf: "flex-end",
                backgroundColor: theme.ButtonSecondaryBG.backgroundColor,
                marginRight: 10,
              }}
            >
              <Icon
                icon="ion:image"
                height={20}
                style={{
                  color: theme?.ButtonText.color,
                }}
              />
            </div>
          </AttachmentsMediaButton>
        ) : null}
        {actionItemOnPress && attachmentSourceType ? (
          <AttachmentsActionBarHoldItem
            onClick={actionItemOnPress}
            attachmentSourceType={attachmentSourceType}
            theme={theme}
            invertedColor={true}
            disabledEventLinks={disableEventLinks}
            event={event}
          />
        ) : null}
      </div>
    </StandardBorderedContainer>
  );
};
