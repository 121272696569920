import { useCallback, useMemo, useState } from "react";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import {
  SelectRecipientGroupType,
  SelectRecipientType,
  SubSelectionItem,
} from "@markit/common.types";
import ConfirmDeleteModal from "../../../Containers/ConfirmPopups/ConfirmDeleteModal";
import { SelectRecipientsMainSharedProps } from "./SelectRecipientsScreens";
import { MassTextsWrapperType } from "../MassTextWrapperManager";

type SelectRecipientsHeaderProps = SelectRecipientsMainSharedProps & {
  backToOverview: (numRecipients?: number) => void;
  selectType: SelectRecipientGroupType;
  updateSelectType: (selectType: Partial<SelectRecipientGroupType>) => void;
  setAllSelectedPeople: (allSelectedPeople: string[]) => void;
  setAllExcludedPeople: (allExcludedPeople: string[]) => void;
  unsavedRecipients: string[];
  setUnsavedRecipients: (unsavedRecipients: string[]) => void;
  unsavedCategories: SubSelectionItem[];
  setUnsavedCategories: (unsavedCategories: SubSelectionItem[]) => void;
  madeChanges: boolean;
  alternateTitle?: string;
};

const SelectRecipientsHeader = (props: SelectRecipientsHeaderProps) => {
  const {
    wrapperType,
    backToOverview,
    selectType,
    updateSelectType,
    updateMassTextSettings,
    setAllSelectedPeople,
    setAllExcludedPeople,
    unsavedRecipients,
    setUnsavedRecipients,
    unsavedCategories,
    setUnsavedCategories,
    excludingMode,
    setExcludingMode,
    setShowDoneButton,
    showCategories,
    madeChanges,
    alternateTitle,
  } = props;
  const [showConfirmBackModal, setShowConfirmBackModal] =
    useState<boolean>(false);

  const styles = {
    headerTitle: { fontSize: 12, fontWeight: "500" },
    headerPosition: {
      right: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      zIndex: 0,
    },
  };

  const titleToDisplay = useMemo(
    () => alternateTitle || (showCategories ? "Categories" : "Recipients"),
    [alternateTitle, showCategories]
  );

  const saveSelection = useCallback(() => {
    if (excludingMode) {
      if (showCategories && updateMassTextSettings) {
        updateMassTextSettings({
          excludedSubSelectionIds: unsavedCategories,
        });
      } else {
        setAllExcludedPeople(unsavedRecipients);
      }
    } else {
      if (showCategories && updateMassTextSettings) {
        updateMassTextSettings({
          subSelectionIds: unsavedCategories,
        });
      } else {
        setAllSelectedPeople(unsavedRecipients);
      }
    }
    setUnsavedRecipients([]);
    setUnsavedCategories([]);
    updateSelectType({
      type: SelectRecipientType.NONE,
      selectedItem: undefined,
      eventTabValue: 0,
    });
    setShowDoneButton(false);
  }, [
    excludingMode,
    setUnsavedRecipients,
    setUnsavedCategories,
    updateSelectType,
    setShowDoneButton,
    showCategories,
    updateMassTextSettings,
    unsavedCategories,
    setAllExcludedPeople,
    unsavedRecipients,
    setAllSelectedPeople,
  ]);

  const handleBackNavigation = useCallback(() => {
    if (selectType.type !== SelectRecipientType.NONE) {
      if (selectType.selectedItem && !selectType.isPreview) {
        updateSelectType({ selectedItem: undefined });
      } else {
        setUnsavedRecipients([]);
        setUnsavedCategories([]);
        updateSelectType({
          type: SelectRecipientType.NONE,
          selectedItem: undefined,
          eventTabValue: 0,
          isPreview: false,
        });
      }
    } else {
      if (excludingMode) {
        setShowDoneButton(false);
        setExcludingMode(false);
      } else {
        backToOverview();
      }
    }
  }, [
    selectType.type,
    selectType.selectedItem,
    selectType.isPreview,
    updateSelectType,
    setUnsavedRecipients,
    setUnsavedCategories,
    excludingMode,
    setShowDoneButton,
    setExcludingMode,
    backToOverview,
  ]);

  const backOnPress = useCallback(() => {
    if (!madeChanges || (selectType.selectedItem && !selectType.isPreview)) {
      handleBackNavigation();
    } else {
      setShowConfirmBackModal(true);
    }
  }, [
    madeChanges,
    selectType.selectedItem,
    selectType.isPreview,
    handleBackNavigation,
  ]);

  const showTopRightButton = useMemo(
    () =>
      selectType.type !== SelectRecipientType.NONE &&
      (selectType.selectedItem || (!selectType.selectedItem && madeChanges)),
    [madeChanges, selectType.selectedItem, selectType.type]
  );

  return (
    <>
      <div
        className="AlignedRowSpaced"
        style={{
          position: "relative",
          paddingBlock: 14,
          paddingInline: wrapperType === MassTextsWrapperType.MODAL ? 14 : 20,
        }}
      >
        <div
          className="LargePopupPanelClose"
          style={{ zIndex: 1 }}
          onClick={backOnPress}
        >
          <Icon icon={"ion:chevron-back"} height={24} color={Colors.GRAY3} />
        </div>
        {selectType.type === SelectRecipientType.RECIPIENTS ? (
          <div style={{ ...styles.headerPosition, position: "absolute" }}>
            <span className="sectionTitle">
              {excludingMode ? "Excluded " : "Selected "}
              {titleToDisplay}
            </span>
          </div>
        ) : showCategories ? (
          selectType.type !== SelectRecipientType.NONE ? (
            <div
              className="SelectedRecipientsBanner AlignedRow"
              style={{ gap: 4 }}
            >
              <span
                style={{
                  ...styles.headerTitle,
                  color: excludingMode ? Colors.RED3 : Colors.PURPLE7,
                }}
              >
                {unsavedCategories.length > 0
                  ? `${unsavedCategories.length} ${
                      unsavedCategories.length !== 1 ? "Categories" : "Category"
                    } ${excludingMode ? "Excluded" : "Selected"}`
                  : `${excludingMode ? "Excluding" : "Selecting"} by Category`}
              </span>
            </div>
          ) : wrapperType !== MassTextsWrapperType.MODAL ? (
            <div style={{ ...styles.headerPosition, position: "absolute" }}>
              <span className="sectionTitle">Select Recipients</span>
            </div>
          ) : null
        ) : selectType.type !== SelectRecipientType.NONE ? (
          unsavedRecipients.length > 0 ? (
            <div
              className="SelectedRecipientsBanner AlignedRow"
              style={{ gap: 4 }}
            >
              <h4 style={{ fontWeight: 600, fontSize: 14 }}>
                {unsavedRecipients.length}
              </h4>
              <h4 style={{ fontWeight: 400, fontSize: 14 }}>
                {titleToDisplay} {excludingMode ? "Excluded" : "Selected"}
              </h4>
            </div>
          ) : null
        ) : (
          <div style={{ ...styles.headerPosition, position: "absolute" }}>
            <span className="sectionTitle">
              {excludingMode ? "Exclude " : ""}
              {titleToDisplay}
            </span>
          </div>
        )}
        <div
          className="LargePopupPanelBlackButton AlignedRowSelect"
          onClick={saveSelection}
          style={{
            visibility: showTopRightButton ? undefined : "hidden",
            pointerEvents: !madeChanges ? "none" : "all",
            opacity: !madeChanges ? 0.2 : 1,
            gap: 5,
          }}
        >
          <span className="bodyMedium" style={{ color: Colors.WHITE }}>
            Done
          </span>
        </div>
      </div>
      <ConfirmDeleteModal
        heading="Are you sure you want to exit?"
        subtext="Your edits won't be saved. This cannot be undone."
        deleteButtonText="Exit"
        hideModal={!showConfirmBackModal}
        setIsVisible={setShowConfirmBackModal}
        deleteOnPress={() => {
          setShowConfirmBackModal(false);
          handleBackNavigation();
        }}
        icon={<Icon icon="ion:exit-outline" height={40} />}
      />
    </>
  );
};

export default SelectRecipientsHeader;
