import { useCallback, useMemo, useRef, useState } from "react";
import { getDocs, onSnapshot, query, where } from "../firebase";
import { uniqueVals } from "@markit/common.utils";
import { Campaign, CampaignType } from "@markit/common.types";
import { getUserCampaignsRef } from "../utils/FirebaseUtils";
import { useSelector, useDispatch, batch } from "react-redux";
import {
  campaignActions,
  CampaignDetails,
  getCampaignState,
} from "../redux/slices/campaignSlice";
import useOnUnmount from "./useOnUnmount";
import { useOnMount } from "./useOnMount";
import { fetchCampaignDetails } from "../utils/campaignUtils";

type useLoadAutomationItemsProps = {
  userId: string;
};

export const useLoadAutomationItems = (props: useLoadAutomationItemsProps) => {
  const { userId } = props;
  const dispatch = useDispatch();
  const { campaigns, campaignDetails } = useSelector(getCampaignState);
  const [fetchedAutomations, setFetchedAutomations] = useState<Campaign[]>([]);
  const [fetchedAutomationDetails, setFetchedAutomationDetails] = useState<
    CampaignDetails[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const unsubscribeArrayRef = useRef<(() => void)[]>([]);

  const filteredAutomations = useMemo(
    () =>
      campaigns.filter((campaign) => campaign.type === CampaignType.AUTOMATION),
    [campaigns]
  );

  useOnMount(() => {
    setFetchedAutomations(filteredAutomations);
    setFetchedAutomationDetails(campaignDetails);
  });

  useOnUnmount(
    (latestDeps) => {
      batch(() => {
        dispatch(
          campaignActions.addMultipleCampaigns(latestDeps[0] as Campaign[])
        );
        dispatch(
          campaignActions.addMultipleCampaignDetails(
            latestDeps[1] as CampaignDetails[]
          )
        );
      });
      unsubscribeArrayRef.current.forEach(
        (unsubscribe) => unsubscribe && unsubscribe()
      );
      unsubscribeArrayRef.current = []; // Clear the array after cleanup
    },
    [fetchedAutomations, fetchedAutomationDetails]
  );

  const addCampaignData = useCallback((newCampaignData: Campaign[]) => {
    setFetchedAutomations((fetchedAutomations) =>
      uniqueVals(
        newCampaignData.concat(fetchedAutomations),
        (campaign) => campaign.id
      ).sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    );
  }, []);

  const removeCampaignData = useCallback((campaign: Campaign) => {
    setFetchedAutomations((fetchedAutomations) => {
      const index = fetchedAutomations.findIndex(
        (fetched) => fetched.id === campaign.id
      );
      if (index !== -1) {
        fetchedAutomations.splice(index, 1);
      }
      return [...fetchedAutomations];
    });
  }, []);

  const addCampaignDetailsData = useCallback(
    (newCampaignDetails: CampaignDetails[]) => {
      setFetchedAutomationDetails((fetchedAutomationDetails) =>
        uniqueVals(
          newCampaignDetails.concat(fetchedAutomationDetails),
          (detail) => detail.campaignId
        )
      );
    },
    []
  );

  const removeCampaignDetailsData = useCallback((campaignId: string) => {
    setFetchedAutomationDetails((fetchedAutomationDetails) => {
      const index = fetchedAutomationDetails.findIndex(
        (fetched) => fetched.campaignId === campaignId
      );
      if (index !== -1) {
        fetchedAutomationDetails.splice(index, 1);
      }
      return [...fetchedAutomationDetails];
    });
  }, []);

  const updateData = useCallback(
    async (foundCampaignData: Campaign[]) => {
      addCampaignData(foundCampaignData);
      const campaignDetails = await Promise.all(
        foundCampaignData.map(async (campaign) => {
          return await fetchCampaignDetails(userId, campaign);
        })
      );
      addCampaignDetailsData(campaignDetails);

      batch(() => {
        dispatch(campaignActions.addMultipleCampaigns(foundCampaignData));
        dispatch(campaignActions.addMultipleCampaignDetails(campaignDetails));
      });
    },
    [addCampaignData, addCampaignDetailsData, dispatch, userId]
  );

  const initialUpdateData = useCallback(
    async (foundCampaignData: Campaign[]) => {
      // Only add any new campaigns or campaigns that have been updated since initial load
      const newCampaigns = foundCampaignData.filter(
        (loadedCampaign) =>
          !filteredAutomations.some(
            (campaign) =>
              campaign.id === loadedCampaign.id &&
              campaign.createdAt === loadedCampaign.createdAt
          )
      );

      if (foundCampaignData.length > 0) {
        await updateData(newCampaigns);
      }
      setIsLoading(false);
    },
    [filteredAutomations, updateData]
  );

  const liveUpdateCampaigns = useCallback(
    async (
      type: "added" | "modified" | "removed",
      campaign: Campaign,
      foundCampaigns: Campaign[]
    ) => {
      if (
        (type === "added" &&
          !foundCampaigns.some(
            (campaignData) => campaignData.id === campaign.id
          )) ||
        type === "modified"
      ) {
        updateData([campaign]);
      } else if (type === "removed") {
        removeCampaignData(campaign);
        removeCampaignDetailsData(campaign.id);
      }
    },
    [removeCampaignData, removeCampaignDetailsData, updateData]
  );

  const loadAutomations = async () => {
    setIsLoading(true);
    const campaignsRef = getUserCampaignsRef(userId);
    const campaignsQuery = query(
      campaignsRef,
      where("type", "==", CampaignType.AUTOMATION)
    );

    const foundCampaigns = (await getDocs(campaignsQuery)).docs.map((doc) =>
      doc.data()
    );
    await initialUpdateData(foundCampaigns);

    const unsubscribe = onSnapshot(campaignsQuery, (snapshot) => {
      snapshot.docChanges().forEach(async (message) => {
        const campaign = message.doc.data();
        await liveUpdateCampaigns(message.type, campaign, foundCampaigns);
      });
    });
    unsubscribeArrayRef.current.push(unsubscribe);
  };

  return {
    fetchedAutomations,
    fetchedAutomationDetails,
    isLoading,
    loadAutomations,
  };
};
