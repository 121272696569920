import { useCallback, useEffect, useMemo, useState } from "react";
import { useLoadUserList } from "../../../hooks/useLoadUserList";
import FlatList from "flatlist-react/lib";
import { MassTextsPersonItem } from "./Items/MassTextsPersonItem";
import { CircularProgress } from "@mui/material";
import { AccountData, Campaign, FollowerStatus } from "@markit/common.types";
import filter from "lodash.filter";
import SearchBoxContainer from "../../Containers/SearchBoxContainer";
import { Colors } from "../../../utils/colors";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";
import { handlePluralString } from "@markit/common.utils";
import FullProfilePreviewModal from "../../FollowerProfile/FullProfilePreviewModal";
import { getMassTextRecipients } from "../../../utils/textingUtils";
import { EmptyStateFlatlist } from "../../EmptyStates/EmptyStateFlatlist";
import EmptyStateButton from "../../Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";

type TriggerTextRecipientsProps = {
  campaign: Campaign;
};

const TriggerTextRecipients = (props: TriggerTextRecipientsProps) => {
  const { campaign } = props;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [recipients, setRecipients] = useState<string[]>([]);
  const [profileSelected, setProfileSelected] = useState<AccountData>();
  const [loadingRecipients, setLoadingRecipients] = useState(true);

  const styles = {
    sectionContainer: {
      padding: 14,
      backgroundColor: Colors.WHITE1,
      borderRadius: 8,
      marginBottom: 14,
    },
    mainText: { fontSize: 14, fontWeight: "500" },
  };

  const {
    fetchedUserData,
    loadMoreUsers,
    isFinished,
    isLoading,
    loadSearchResultsThrottled,
  } = useLoadUserList({
    userIdList: recipients,
    windowSize: 30,
    followerStatus: FollowerStatus.SUBSCRIBED,
    creatorUid: campaign.createdBy,
  });

  useEffect(() => {
    if (recipients.length > 0 && loadingRecipients) {
      loadMoreUsers();
      setLoadingRecipients(false);
    }
  }, [loadMoreUsers, loadingRecipients, recipients.length]);

  // Determine the recipients to show
  useAsyncOnMount(async () => {
    const textRecipients = await getMassTextRecipients(
      campaign.createdBy,
      campaign
    );
    setRecipients(textRecipients);
  });

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
    loadSearchResultsThrottled(text.toLowerCase());
  };

  const containsUser = useCallback((item: AccountData, query: string) => {
    return item.fullName.toLowerCase().includes(query);
  }, []);

  const recipientsToShow: AccountData[] = useMemo(() => {
    const usersToShow: AccountData[] = fetchedUserData;
    let userList: AccountData[] = usersToShow;
    userList = filter(userList, (user: AccountData) => {
      return containsUser(user, searchTerm.toLowerCase());
    });
    return userList;
  }, [fetchedUserData, searchTerm, containsUser]);

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={loadingRecipients}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={"No Recipients Found"}
            icon={<Icon icon={"ion:person"} height={60} />}
            iconBox={70}
            containerStyles={{ paddingTop: 120 }}
          />
        }
      />
    );
  }, [loadingRecipients, searchTerm]);

  return (
    <>
      <div className="ColumnNormal PreventScroll" style={{ padding: 20 }}>
        <SearchBoxContainer
          placeholder="Search People"
          onChange={(e) => handleSearch(e.target.value)}
          value={searchTerm}
          containerStyles={{ marginTop: 0, marginBottom: 10 }}
        />
        <div style={styles.sectionContainer}>
          <span style={{ ...styles.mainText }}>
            {recipients.length > 0 ? recipients.length : "--"}{" "}
            {handlePluralString("Recipient", recipients.length)}
          </span>
        </div>
        <div className="AllowScroll" style={{ paddingBottom: 200 }}>
          <FlatList
            list={recipientsToShow}
            renderItem={(user) => {
              return (
                <MassTextsPersonItem
                  user={user}
                  unsavedPeople={[]}
                  setUnsavedPeople={() => {}}
                  setProfileSelected={setProfileSelected}
                  disableCheck
                />
              );
            }}
            renderWhenEmpty={() => renderEmptyView}
            hasMoreItems={!isFinished}
            loadMoreItems={() => loadMoreUsers()}
            paginationLoadingIndicator={() =>
              isLoading && !isFinished ? (
                <CircularProgress
                  style={{ color: "#929292", alignSelf: "center" }}
                  size={20}
                />
              ) : null
            }
          />
        </div>
      </div>
      {profileSelected ? (
        <FullProfilePreviewModal
          profileSelected={profileSelected}
          setProfileSelected={setProfileSelected}
        />
      ) : null}
    </>
  );
};

export default TriggerTextRecipients;
