import { ReactNode } from "react";
import IntermediaryModalContainer from "../../Containers/IntermediaryModalContainer";
import { MassTextsWrapperType } from "./MassTextWrapperManager";
import { AnimatePresence, domAnimation, LazyMotion, m } from "framer-motion";

type MassTextBodyWrapperProps = {
  wrapperType: MassTextsWrapperType;
  children: ReactNode;
};

const MassTextBodyWrapper = (props: MassTextBodyWrapperProps) => {
  const { wrapperType, children } = props;

  switch (wrapperType) {
    case MassTextsWrapperType.MODAL:
      return (
        <IntermediaryModalContainer
          body={children}
          containerStyles={{ overflow: "hidden", flexGrow: 1, height: "100%" }}
        />
      );
    case MassTextsWrapperType.SIDE_PANEL:
    case MassTextsWrapperType.SIDE_PANEL_PREVIEW:
      return (
        <AnimatePresence>
          <LazyMotion features={domAnimation}>
            <m.div
              key="sidepanelwrapper"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: "easeIn" }}
              className="ColumnNormal AllowScroll"
            >
              {children}
            </m.div>
          </LazyMotion>
        </AnimatePresence>
      );
  }
};

export default MassTextBodyWrapper;
