import { Campaign, MassText, MassTextType } from "@markit/common.types";
import { useTheme } from "../../../hooks/useTheme";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { Colors } from "../../../utils/colors";
import {
  dateIsCurrentYear,
  GetTime,
  handlePluralString,
  isEventExternalLink,
  LessThanDate,
  massTextMessageConverter,
  SUBSCRIBED_CAMPAIGN_NAME,
} from "@markit/common.utils";
import { Icon } from "@iconify/react";
import { useMemo, useState } from "react";
import { getMassTextAttachmentsLinkAnalytics } from "../../../utils/trackingUtils";
import { ModifiedTextMessageDisplay } from "../ModifiedTextMessageDisplay";
import ExpandableBox from "../../Containers/ExpandableBox";
import { MassTextDetails } from "../../../redux/slices/campaignSlice";
import { GetLongDate } from "../../../utils/GetLongDate";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";
import { CampaignMassTextItemSettings } from "./CampaignMassTextItemSettings";
import {
  displayTextAnalytics,
  displayTextConversions,
} from "../../../utils/campaignUtils";
import { useSelector } from "react-redux";
import { getEventState } from "../../../redux/slices/eventSlice";

type CampaignMassTextItemPreviewProps = {
  massTextDetail: MassTextDetails;
  campaign: Campaign;
  viewOnPress: () => void;
  duplicateOnPress: (massText: MassText) => void;
  numCampaignTexts: number;
  campaignInitialText: MassText | undefined;
};

export const CampaignMassTextItemPreview = (
  props: CampaignMassTextItemPreviewProps
) => {
  const {
    massTextDetail,
    campaign,
    viewOnPress,
    duplicateOnPress,
    numCampaignTexts,
    campaignInitialText,
  } = props;
  const { theme } = useTheme();
  const events = useSelector(getEventState).events.events;
  const [numClicks, setNumClicks] = useState(0);
  const [numConversions, setNumConversions] = useState(0);
  const [loadingAnalytics, setLoadingAnalytics] = useState(true);

  const massText = useMemo(
    () => massTextDetail.massText,
    [massTextDetail.massText]
  );

  // A text should be queued if the text is scheduled or if it's immediate and queued is true. Otherwise, show the queued status for sending
  const textQueued = useMemo(
    () => massText.scheduled || (!massText.scheduled && massText.queued),
    [massText]
  );

  useAsyncOnMount(async () => {
    // Get the link analytics
    const linkAnalytics = await Promise.all(
      massTextDetail.attachments.map(async (attachment) => {
        const { numOpens, numConversions } =
          await getMassTextAttachmentsLinkAnalytics(
            massText.sentBy,
            massText,
            attachment
          );
        return { numOpens, numConversions };
      })
    );
    const totalOpens = linkAnalytics.reduce((sum, r) => sum + r.numOpens, 0);
    const totalConversions = linkAnalytics.reduce(
      (sum, r) => sum + r.numConversions,
      0
    );
    setNumClicks(totalOpens);
    setNumConversions(totalConversions);
    setLoadingAnalytics(false);
  });

  const isScheduledMassText = useMemo(
    () =>
      massText.scheduled &&
      LessThanDate(new Date().toISOString(), massText.sentAt),
    [massText.scheduled, massText.sentAt]
  );

  const showTextAnalytics = useMemo(
    () =>
      massText.type !== MassTextType.TRIGGERTEXT &&
      displayTextAnalytics(massTextDetail.attachments),
    [massText.type, massTextDetail.attachments]
  );

  const showTextConversions = useMemo(
    () => displayTextConversions(massTextDetail.attachments, events),
    [events, massTextDetail.attachments]
  );

  const foundEvent = useMemo(
    () => events.find((event) => event.id === campaign.eventId),
    [campaign.eventId, events]
  );

  return (
    <ExpandableBox>
      <StandardBorderedContainer
        containerStyles={{
          backgroundColor: Colors.WHITE,
          paddingBlock: 14,
          borderRadius: 14,
          width: 307,
        }}
      >
        <div className="ColumnNormal" style={{ paddingInline: 14 }}>
          <div
            className="AlignedRowSpaced"
            style={{ alignItems: "flex-start" }}
          >
            <div
              onClick={viewOnPress}
              className="ColumnNormalSelect"
              style={{ gap: 7, flex: 1 }}
            >
              <span className="bodyMedium">
                {massText.type === MassTextType.TRIGGERTEXT
                  ? campaign.title === SUBSCRIBED_CAMPAIGN_NAME
                    ? "Follow Text"
                    : foundEvent && isEventExternalLink(foundEvent.eventType)
                    ? "Welcome Text"
                    : "Ticket Text"
                  : `${GetLongDate(
                      massText.sentAt,
                      true,
                      false,
                      false,
                      !dateIsCurrentYear(massText.sentAt)
                    )}, ${GetTime(new Date(massText.sentAt)).toUpperCase()}`}
              </span>
              <div
                className="AlignedRowSelect"
                style={{
                  gap: 4,
                  color: isScheduledMassText
                    ? Colors.BLUE5
                    : theme.PrimaryText.color,
                }}
              >
                <Icon
                  icon={isScheduledMassText ? "ion:timer" : "ion:paper-plane"}
                  height={14}
                />
                <span style={{ fontWeight: 500, fontSize: 12 }}>
                  {massText.type === MassTextType.TRIGGERTEXT
                    ? "Sent on Trigger"
                    : isScheduledMassText
                    ? "Scheduled Text"
                    : !textQueued
                    ? "Queued"
                    : "Sent"}
                </span>
              </div>
            </div>
            <CampaignMassTextItemSettings
              campaign={campaign}
              massTextDetail={massTextDetail}
              viewOnPress={viewOnPress}
              duplicateOnPress={() => duplicateOnPress(massText)}
              numCampaignTexts={numCampaignTexts}
              campaignInitialText={campaignInitialText}
            />
          </div>
          <div
            className="ColumnNormalSelect"
            onClick={viewOnPress}
            style={{ gap: 14, paddingTop: 14 }}
          >
            <span className="TwoLineText" style={{ fontSize: 12 }}>
              <ModifiedTextMessageDisplay
                message={massTextMessageConverter(
                  massText.message,
                  massTextDetail.attachments
                )}
              />
            </span>
            {!isScheduledMassText && showTextAnalytics ? (
              <StandardBorderedContainer
                containerStyles={{
                  paddingInline: 7,
                  paddingBlock: 9,
                  borderRadius: 8,
                  backgroundColor: Colors.WHITE1,
                  width: "fit-content",
                  cursor: "pointer",
                }}
              >
                <div className="AlignedRowSelect" style={{ gap: 7 }}>
                  <div className="AlignedRowSelect" style={{ gap: 4 }}>
                    <span className="bodyMedium">
                      {loadingAnalytics ? "--" : numClicks}
                    </span>
                    <span className="smallBodySubtext">
                      {handlePluralString("Click", numClicks)}
                    </span>
                  </div>
                  {showTextConversions ? (
                    <div className="AlignedRowSelect" style={{ gap: 4 }}>
                      <span className="bodyMedium">
                        {loadingAnalytics ? "--" : numConversions}
                      </span>
                      <span className="smallBodySubtext">
                        {handlePluralString("Conversion", numConversions)}
                      </span>
                    </div>
                  ) : null}
                </div>
              </StandardBorderedContainer>
            ) : null}
          </div>
        </div>
      </StandardBorderedContainer>
    </ExpandableBox>
  );
};
