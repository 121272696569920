import { CircularProgress } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Campaign } from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import filter from "lodash.filter";
import SearchBoxContainer from "../../Containers/SearchBoxContainer";
import { TabPanel } from "../../FullEventSubComponents/TabPanel";
import EmptyStateButton from "../../Buttons/EmptyStateButton";
import { EmptyStateFlatlist } from "../../EmptyStates/EmptyStateFlatlist";
import { CampaignItemPreview } from "../../DisplayItem/CampaignItem/CampaignItemPreview";
import StandardListContainerNew from "../../Containers/StandardListContainerNew";
import { CampaignConfirmDeleteModal } from "./Items/CampaignConfirmDeleteModal";
import FlatList from "flatlist-react/lib";
import RectangleButton from "../../Buttons/RectangleButton";
import { useLoadCampaignItems } from "../../../hooks/useLoadCampaignItems";
import { useOnMount } from "../../../hooks/useOnMount";
import { useTheme } from "../../../hooks/useTheme";
import { DropdownMenu, DropdownMenuItem } from "../../DropdownMenu";

const enum CampaignFilterState {
  ALL = "All Campaigns",
  SINGLE = "Single Text",
  MULTI = "Multi Text",
}

type CampaignsListPanelProps = {
  tabValue: number;
  newMassTextOnPress: (campaignId?: string) => void;
};

const CampaignsListPanel = (props: CampaignsListPanelProps) => {
  const { tabValue, newMassTextOnPress } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showDeleteCampaign, setShowDeleteCampaign] = useState<Campaign>();
  const [filterState, setFilterState] = useState<CampaignFilterState>(
    CampaignFilterState.ALL
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const styles = {
    filterContainer: {
      gap: 10,
      borderRadius: 8,
      paddingInline: 14,
      paddingBlock: 12,
      backgroundColor: Colors.WHITE1,
      border: `0.8px solid ${Colors.GRAY2}`,
    },
  };

  const {
    fetchedCampaigns,
    fetchedCampaignDetails,
    isFinished: loadedAllCampaigns,
    isLoading: isLoadingCampaigns,
    loadCampaigns,
    loadMoreCampaigns,
  } = useLoadCampaignItems({
    userId: accountData.uid,
    windowSize: 15,
  });

  useOnMount(() => {
    loadCampaigns();
  });

  const contains = (campaign: Campaign, query: string) => {
    return campaign.title.toLowerCase().includes(query);
  };

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
  };

  const currentItemsToShow: Campaign[] = useMemo(() => {
    let filteredCampaignIds: string[] = [];
    if (filterState !== CampaignFilterState.ALL) {
      filteredCampaignIds = fetchedCampaignDetails
        .filter((detail) =>
          filterState === CampaignFilterState.SINGLE
            ? detail.massTextDetails.length === 1
            : detail.massTextDetails.length > 1
        )
        .map((detail) => detail.campaignId);
    }
    let campaignItems: Campaign[] =
      filterState !== CampaignFilterState.ALL
        ? fetchedCampaigns.filter((campaign) =>
            filteredCampaignIds.includes(campaign.id)
          )
        : fetchedCampaigns;
    if (searchTerm !== "") {
      campaignItems = filter(campaignItems, (campaign: Campaign) => {
        return contains(campaign, searchTerm);
      });
    }
    return campaignItems;
  }, [fetchedCampaignDetails, fetchedCampaigns, filterState, searchTerm]);

  const preventCampaignDeletion = useMemo(
    () =>
      (fetchedCampaignDetails &&
        fetchedCampaignDetails
          .find((detail) => detail.campaignId === showDeleteCampaign?.id)
          ?.massTextDetails.some((textDetail) => textDetail.massText.queued)) ??
      false,
    [fetchedCampaignDetails, showDeleteCampaign?.id]
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: CampaignFilterState.ALL,
        icon:
          filterState === CampaignFilterState.ALL ? "ion:checkmark" : undefined,
        key: "all_campaigns",
        onPress: () => setFilterState(CampaignFilterState.ALL),
      },
      {
        title: CampaignFilterState.SINGLE,
        icon:
          filterState === CampaignFilterState.SINGLE
            ? "ion:checkmark"
            : undefined,
        key: "single_campaigns",
        onPress: () => setFilterState(CampaignFilterState.SINGLE),
      },
      {
        title: CampaignFilterState.MULTI,
        icon:
          filterState === CampaignFilterState.MULTI
            ? "ion:checkmark"
            : undefined,
        key: "multi_campaigns",
        onPress: () => setFilterState(CampaignFilterState.MULTI),
      },
    ],
    [filterState]
  );

  const renderEmptyCampaigns = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoadingCampaigns}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={"Send a text to get started"}
            description={
              "Campaigns are created when you send or schedule a text"
            }
            icon={
              <Icon
                icon={"ion:paper-plane"}
                height={60}
                style={{ color: Colors.BLUE5 }}
              />
            }
            iconBox={70}
            btnText={"New Text"}
            onPress={() => newMassTextOnPress()}
            containerStyles={{ paddingTop: 120 }}
          />
        }
      />
    );
  }, [isLoadingCampaigns, newMassTextOnPress, searchTerm]);

  const renderCampaignItem = useCallback(
    (item: Campaign) => {
      const foundCampaignDetail = fetchedCampaignDetails.find(
        (detail) => detail.campaignId === item.id
      );
      return (
        <div key={item.id}>
          <CampaignItemPreview
            campaignItem={item}
            campaignDetail={foundCampaignDetail}
            newMassTextOnPress={newMassTextOnPress}
            setShowDeleteCampaign={setShowDeleteCampaign}
          />
        </div>
      );
    },
    [fetchedCampaignDetails, newMassTextOnPress]
  );

  return (
    <TabPanel value={tabValue} index={0}>
      <StandardListContainerNew
        searchComp={
          <div className="AlignedRow" style={{ gap: 14 }}>
            <SearchBoxContainer
              placeholder={"Search"}
              onChange={(e) => handleSearch(e.target.value)}
              containerStyles={{ marginTop: 0, width: 394 }}
            />
            {/* <DropdownMenu
              dropdownItems={dropdownItems}
              trigger={["click"]}
              placement="bottom"
              onOpenChange={(isOpen: boolean) => setDropdownVisible(isOpen)}
              altWidth={170}
            > */}
            <div
              className="AlignedRowSelect"
              onClick={() => alert("Coming soon...")}
              style={styles.filterContainer}
            >
              <span className="bodyMedium">{filterState}</span>
              <Icon
                icon={dropdownVisible ? "mdi-chevron-up" : "mdi:chevron-down"}
                height={18}
              />
            </div>
            {/* </DropdownMenu> */}
          </div>
        }
        listComp={
          <div className="ColumnNormal">
            <FlatList
              list={currentItemsToShow}
              renderItem={(item: Campaign) => renderCampaignItem(item)}
              renderWhenEmpty={() => renderEmptyCampaigns}
            />
            {fetchedCampaigns.length > 0 ? (
              <div className="Centering" style={{ paddingBlock: 20 }}>
                {searchTerm.trim() === "" && !loadedAllCampaigns ? (
                  !isLoadingCampaigns ? (
                    <RectangleButton
                      buttonLabel={
                        <span style={{ fontWeight: 500 }}>Load More</span>
                      }
                      onPress={loadMoreCampaigns}
                      altColor={Colors.WHITE1}
                      altTextColor={Colors.BLACK}
                      altBorderRadius={100}
                      altPaddingHorz={14}
                      altPaddingVert={10}
                      containerStyles={{
                        border: `1px solid ${Colors.GRAY1}`,
                        width: 120,
                      }}
                    />
                  ) : (
                    <CircularProgress
                      style={{ color: Colors.GRAY1 }}
                      size={24}
                    />
                  )
                ) : currentItemsToShow.length > 0 ? (
                  <span style={{ fontSize: 14, ...theme.LabelText }}>
                    Showing All Results
                  </span>
                ) : null}
              </div>
            ) : null}
          </div>
        }
      />
      <CampaignConfirmDeleteModal
        campaign={showDeleteCampaign}
        preventDelete={preventCampaignDeletion}
        showDeleteModal={showDeleteCampaign !== undefined}
        setShowDeleteModal={() => setShowDeleteCampaign(undefined)}
      />
    </TabPanel>
  );
};

export default CampaignsListPanel;
