import { Icon } from "@iconify/react";
import { useCallback, useMemo, useState } from "react";
import { NotificationType, ScheduleTextInfo } from "@markit/common.types";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import { datesAreOnSameDay, dayNameArray } from "@markit/common.utils";
import CustomCheckbox from "../../../CustomCheckbox";
import { Colors } from "../../../../utils/colors";
import RectangleButton from "../../../Buttons/RectangleButton";
import { showNotificationBanner } from "../../../../utils/notificationUtils";
import { useDispatch } from "react-redux";
import { useOnMount } from "../../../../hooks/useOnMount";

type QuietHoursSendingModalProps = {
  scheduleTextInfo: ScheduleTextInfo;
  setScheduleTextInfo: (scheduleTextInfo: ScheduleTextInfo) => void;
  setIsVisible: (isVisible: boolean) => void;
};

const QuietHoursSendingModal = (props: QuietHoursSendingModalProps) => {
  const { scheduleTextInfo, setScheduleTextInfo, setIsVisible } = props;
  const dispatch = useDispatch();
  const [optionSelected, setOptionSelected] = useState(0);

  useOnMount(() => {
    setOptionSelected(
      scheduleTextInfo.scheduleToggle && firstSendTimeOption ? 0 : 1
    );
  });

  const firstSendTime = useMemo(() => {
    const sevenPMDate = new Date(scheduleTextInfo.sendTime);
    sevenPMDate.setHours(19, 30, 0, 0);
    return sevenPMDate;
  }, [scheduleTextInfo.sendTime]);

  const secondSendTime = useMemo(() => {
    const nextTime = new Date(scheduleTextInfo.sendTime);
    nextTime.setHours(8, 0, 0, 0);
    if (nextTime.getTime() <= new Date().getTime()) {
      nextTime.setDate(nextTime.getDate() + 1);
    }
    return nextTime;
  }, [scheduleTextInfo.sendTime]);

  // Same day at 7:30PM
  const firstSendTimeOption = useMemo(() => {
    // Current time is before 7:30PM
    if (new Date().getTime() < firstSendTime.getTime()) {
      const sameDay = datesAreOnSameDay(firstSendTime, new Date());
      const dayNum = firstSendTime.getDay();
      const weekday = dayNameArray[dayNum];
      return `${sameDay ? "Tonight" : weekday + " night"} at 7:30PM`;
    }
    return "";
  }, [firstSendTime]);

  // Next/same day at 8:00AM
  const secondSendTimeOption = useMemo(() => {
    const sameDay = datesAreOnSameDay(secondSendTime, new Date());
    const dayNum = secondSendTime.getDay();
    const weekday = dayNameArray[dayNum];
    return `${sameDay ? "Today" : weekday + " morning"} at 8:00AM`;
  }, [secondSendTime]);

  const updateSendTime = useCallback(() => {
    setScheduleTextInfo({
      ...scheduleTextInfo,
      scheduleToggle: true,
      sendTime: optionSelected === 0 ? firstSendTime : secondSendTime,
    });
    showNotificationBanner(
      dispatch,
      "Time Updated",
      NotificationType.AFFIRMATIVE
    );
    setIsVisible(false);
  }, [
    dispatch,
    firstSendTime,
    optionSelected,
    scheduleTextInfo,
    secondSendTime,
    setIsVisible,
    setScheduleTextInfo,
  ]);

  return (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 14 }}>
          <Icon icon="ion:moon" height={35} />
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="sectionTitle">Sending During Quiet Hours</span>
            <span className="bodySubtext">
              You are trying to send/schedule this text during quiet hours.
              Schedule your text to send outside of quiet hours to avoid
              disruptions. Below are some suggested send times.
            </span>
          </div>
          <div className="ColumnNormal bodySubtext" style={{ gap: 7 }}>
            {scheduleTextInfo.scheduleToggle && firstSendTimeOption ? (
              <div
                onClick={() => setOptionSelected(0)}
                className="AlignedRowSelect"
                style={{ gap: 10 }}
              >
                <CustomCheckbox
                  checked={optionSelected === 0}
                  sx={{ padding: 0 }}
                  altColor={Colors.BLUE5}
                />
                <span style={{ fontSize: 14 }}>Send {firstSendTimeOption}</span>
              </div>
            ) : null}
            <div
              onClick={() => setOptionSelected(1)}
              className="AlignedRowSelect"
              style={{ gap: 10 }}
            >
              <CustomCheckbox
                checked={optionSelected === 1}
                sx={{ padding: 0 }}
                altColor={Colors.BLUE5}
              />
              <span style={{ fontSize: 14 }}>Send {secondSendTimeOption}</span>
            </div>
          </div>
        </div>
      }
      valueComp={
        <div className="AlignedRow" style={{ gap: 10 }}>
          <RectangleButton
            buttonLabel="Cancel"
            onPress={() => setIsVisible(false)}
            altTextColor={Colors.BLACK}
            altColor={Colors.GRAY6}
            altPaddingVert={14}
          />
          <RectangleButton
            buttonLabel="Update Send Time"
            onPress={updateSendTime}
            altTextColor={Colors.WHITE}
            altColor={Colors.BLACK}
            altPaddingVert={14}
          />
        </div>
      }
      noExit
      closeOnOutsidePress
      closeModal={() => setIsVisible(false)}
    />
  );
};

export default QuietHoursSendingModal;
