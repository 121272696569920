import { useCallback, useMemo, useRef, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  query,
  startAfter,
  where,
  orderBy,
} from "../firebase";
import { filterUndefinedValues, uniqueVals } from "@markit/common.utils";
import { AccountData, TextRecipient, TrackingData } from "@markit/common.types";
import { getTextRecipientsRef } from "../utils/FirebaseUtils";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleUser } from "../utils/FetchSingleData";
import useOnUnmount from "./useOnUnmount";

type useLoadTextRecipientsProps = {
  userId: string;
  massTextId: string;
  campaignId: string;
  attachmentId?: string;
  windowSize: number;
};
export const useLoadTextRecipients = (props: useLoadTextRecipientsProps) => {
  const { userId, massTextId, campaignId, attachmentId, windowSize } = props;

  const { followingAccountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [fetchedTextRecipients, setFetchedTextRecipients] = useState<
    TextRecipient[]
  >([]);
  const [fetchedUserTrackerData, setFetchedUserTrackerData] = useState<
    TrackingData[]
  >([]);
  const [fetchedUserData, setFetchedUserData] = useState<AccountData[]>([]);
  const unsubscribeArrayRef = useRef<(() => void)[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  useOnUnmount(
    (latestDeps) => {
      dispatch(
        accountActions.addMultipleFollowingAccountData(
          latestDeps[0] as AccountData[]
        )
      );
      unsubscribeArrayRef.current.forEach(
        (unsubscribe) => unsubscribe && unsubscribe()
      );
      unsubscribeArrayRef.current = []; // Clear the array after cleanup
    },
    [fetchedUserData]
  );

  const addTextRecipientData = useCallback(
    (newTextRecipient: TextRecipient[]) => {
      setFetchedTextRecipients((fetchedRecipient) =>
        uniqueVals(
          fetchedRecipient.concat(newTextRecipient),
          (recipient) => recipient.uid
        )
      );
    },
    []
  );

  const addUserTrackingData = useCallback((newTrackingData: TrackingData[]) => {
    setFetchedUserTrackerData((fetchedTrackingData) =>
      uniqueVals(
        fetchedTrackingData.concat(newTrackingData),
        (tracker) => tracker.id
      )
    );
  }, []);

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUserData((fetchedUserData) =>
      uniqueVals(
        newUserData.concat(fetchedUserData),
        (userData) => userData.uid
      ).sort((a, b) => {
        if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
          return 1;
        }
        if (b.fullName.toLowerCase() > a.fullName.toLowerCase()) {
          return -1;
        }
        return 0;
      })
    );
  }, []);

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const updateData = useCallback(
    async (foundMemberData: TextRecipient[]) => {
      addTextRecipientData(foundMemberData);

      const membersUserData = await Promise.all(
        foundMemberData.map((member) =>
          fetchSingleUser(member.uid, followingAccountData)
        )
      );
      const definedMembersUserData: AccountData[] =
        filterUndefinedValues(membersUserData);
      addUserData(definedMembersUserData);
    },
    [addTextRecipientData, addUserData, followingAccountData]
  );

  const initialUpdateData = useCallback(
    async (foundMemberData: TextRecipient[]) => {
      if (foundMemberData.length) {
        setLastVisible(foundMemberData[foundMemberData.length - 1].uid);
      }
      if (foundMemberData.length < windowSize) {
        setLoadedAllMessages(true);
      }

      if (foundMemberData.length > 0) {
        await updateData(foundMemberData);
      }
    },
    [updateData, windowSize]
  );

  const liveUpdateData = useCallback(
    async (
      type: "added" | "modified" | "removed",
      member: TextRecipient,
      foundMemberData: TextRecipient[]
    ) => {
      if (
        (type === "added" &&
          !foundMemberData.some(
            (memberData) => memberData.uid === member.uid
          )) ||
        type === "modified"
      ) {
        updateData([member]);
      }
    },
    [updateData]
  );

  const loadTextRecipients = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const textRecipientsRef = getTextRecipientsRef(
        userId,
        campaignId,
        massTextId
      );
      const textRecipientsQuery = attachmentId
        ? query(
            textRecipientsRef,
            orderBy(`trackingOverviews.${attachmentId}.numOpens`, "desc"),
            limit(windowSize)
          )
        : query(textRecipientsRef, orderBy("uid", "desc"), limit(windowSize));

      const foundTextRecipients = (await getDocs(textRecipientsQuery)).docs.map(
        (doc) => doc.data()
      );
      await initialUpdateData(foundTextRecipients);

      const unsubscribe = onSnapshot(textRecipientsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const textRecipient = message.doc.data();
          liveUpdateData(message.type, textRecipient, foundTextRecipients);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setIsLoading(false);
    }
  }, [
    attachmentId,
    campaignId,
    initialUpdateData,
    isFinished,
    isLoading,
    liveUpdateData,
    massTextId,
    userId,
    windowSize,
  ]);

  const loadMoreTextRecipients = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const textRecipientsRef = getTextRecipientsRef(
        userId,
        campaignId,
        massTextId
      );
      const textRecipientsQuery = attachmentId
        ? query(
            textRecipientsRef,
            orderBy(`trackingOverviews.${attachmentId}.numOpens`, "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          )
        : query(
            textRecipientsRef,
            orderBy("uid", "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          );

      const foundTextRecipients = (await getDocs(textRecipientsQuery)).docs.map(
        (doc) => doc.data()
      );
      await initialUpdateData(foundTextRecipients);

      const unsubscribe = onSnapshot(textRecipientsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const textRecipient = message.doc.data();
          liveUpdateData(message.type, textRecipient, foundTextRecipients);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setIsLoading(false);
    }
  }, [
    attachmentId,
    campaignId,
    initialUpdateData,
    isFinished,
    isLoading,
    lastVisible,
    liveUpdateData,
    massTextId,
    userId,
    windowSize,
  ]);

  /*
   * Start searching utils
   */
  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchTextRecipients = async (userIds: string[]) => {
        setIsLoading(true);
        const unsubscribeArray = await Promise.all(
          userIds.map(async (uid) => {
            const textRecipientsRef = getTextRecipientsRef(
              userId,
              campaignId,
              massTextId
            );
            const query_ = query(textRecipientsRef, where("uid", "==", uid));

            const unsubscribe = onSnapshot(query_, (snapshot) => {
              snapshot.docChanges().forEach(async (message) => {
                if (message.type === "added" || message.type === "modified") {
                  const textRecipient = message.doc.data();

                  addTextRecipientData([textRecipient]);
                  fetchSingleUser(
                    textRecipient.uid,
                    followingAccountData,
                    (userData) => addUserData([userData])
                  );
                }
              });
            });
            return unsubscribe;
          })
        );
        unsubscribeArrayRef.current.push(...unsubscribeArray);
        setIsLoading(false);
      };

      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchTextRecipients(userIds)
      );
    },
    [
      addTextRecipientData,
      addUserData,
      campaignId,
      followingAccountData,
      massTextId,
      userId,
    ]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          // start algolia search after the first character search term
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );
  /*
   * End searching utils
   */

  return {
    isFinished,
    isLoading,
    fetchedTextRecipients,
    fetchedUserTrackerData,
    fetchedUserData,
    loadTextRecipients,
    loadMoreTextRecipients,
    loadSearchResultsThrottled,
  };
};
