import { useCallback, useContext, useEffect, useState } from "react";
import {
  CloneEventDetails,
  Event,
  NotificationType,
} from "@markit/common.types";
import { usePartialEventUpdates } from "./usePartialEventUpdates";
import {
  canPublishEvent,
  deepCopy,
  isPaidTickets,
  validateEvent,
} from "@markit/common.utils";
import { difference } from "../utils/stringUtils";
import { makeEmptyEvent } from "../utils/makeEvent";
import { MixpanelContext } from "../context/AnalyticsService";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../redux/slices/accountSlice";
import { createEventData, updateEventData } from "../redux/slices/eventSlice";
import { addToCurrUserWishList } from "../redux/slices/wishMarkSlice";
import { DataLoaders } from "../redux/slices/dataSlice";
import { generateDefaultEventTexts } from "../utils/textingUtils";
import { useNavigate } from "./useNavigate";
import { onCreateLinkNavigatePath } from "../utils/navigationUtils";
import { showNotificationBanner } from "../utils/notificationUtils";

export type GoogleLocationData = {
  latitude: number;
  longitude: number;
  googlePlaceId?: string;
  googleDescription?: string;
  formattedAddress?: string;
};

type useCreateEventManagementProps = {
  isEditing: boolean;
  event?: Event;
  currentLocation?: GoogleLocationData;
  cloneDetails?: CloneEventDetails;
  resetStates?: () => void;
  setAlertText: (alertText: string) => void;
};

export const useCreateEventManagement = (
  props: useCreateEventManagementProps
) => {
  const {
    event,
    isEditing,
    currentLocation,
    cloneDetails,
    resetStates,
    setAlertText,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;

  const [initialEventSettings, setInitialEventSettings] = useState<Event>(
    event
      ? deepCopy(event)
      : makeEmptyEvent(
          accountData.uid,
          accountData.fullName,
          accountData.isAdmin,
          currentLocation
        )
  );

  const { eventSettings, updateEventSettings, resetEventSettings } =
    usePartialEventUpdates({
      event,
      accountData,
      currentLocation,
    });

  const [postingEvent, setPostingEvent] = useState(false);
  const [showError, setShowError] = useState(false);

  const resetEvent = useCallback(() => {
    if (resetStates) {
      resetStates();
    }
    resetEventSettings();
  }, [resetEventSettings, resetStates]);

  const onFinalizeSaveEvent = useCallback(async () => {
    setPostingEvent(true);
    const event = deepCopy(eventSettings);
    event.isDraft = false;

    try {
      const convertingFromDraft =
        initialEventSettings.isDraft && !event.isDraft;
      await dispatch(updateEventData(event, convertingFromDraft));

      // if converting a draft to a live event, call the event created mixpanel event from post event
      if (convertingFromDraft) {
        mixpanel.track("Event Created", {
          distinct_id: accountData.uid,
          event_id: event.id,
          event_location: event.googleDescription,
          event_is_paid: isPaidTickets(event),
          event_is_crowdfunded: event.crowdfundingGoal !== 0,
          event_is_university: event.universityId,
          event_host_name: accountData.fullName,
          event_host_username: accountData.username,
          event_host_phone_name: accountData.phoneNumber,
          event_host_email: accountData.email,
          event_partner: accountData.customer.phone !== "",
          num_event_texts: 2, // event texts removed from desktop creation so is always default 2
          creation_source: "webapp",
        });
        mixpanel.people_increment({ Events: 1 });
      }

      // get the difference in event object when event was edited, if any.
      const diff = difference(initialEventSettings, event);
      mixpanel.identify(accountData.uid);
      mixpanel.track("Event Updated", {
        event_id: event.id,
        event_location: event.googleDescription,
        event_is_paid: isPaidTickets(event),
        event_is_crowdfunded: event.crowdfundingGoal !== 0,
        differences_in_event: diff,
      });
      showNotificationBanner(
        dispatch,
        "Event Updated!",
        NotificationType.AFFIRMATIVE
      );
      navigate(`/e/${event.id}/dashboard`, {
        state: { eventId: event.id },
      });
    } catch (error: any) {
      const { response } = error;
      if (response) {
        console.log(response.data);
      }
    }

    setPostingEvent(false);
  }, [
    accountData.customer.phone,
    accountData.email,
    accountData.fullName,
    accountData.phoneNumber,
    accountData.uid,
    accountData.username,
    dispatch,
    eventSettings,
    initialEventSettings,
    mixpanel,
    navigate,
  ]);

  const onSaveEventPress = useCallback(async () => {
    if (!canPublishEvent(eventSettings)) {
      setShowError(true);
      return;
    }
    const validateEventMessage = validateEvent(eventSettings);
    if (validateEventMessage !== "") {
      setAlertText(validateEventMessage);
      return;
    }
    onFinalizeSaveEvent();
  }, [eventSettings, onFinalizeSaveEvent, setAlertText]);

  const onPostEventPress = useCallback(async () => {
    try {
      if (!canPublishEvent(eventSettings)) {
        setShowError(true);
        return;
      }
      const validateEventMessage = validateEvent(eventSettings);
      if (validateEventMessage !== "") {
        setAlertText(validateEventMessage);
        return;
      }
      mixpanel.track("Completed Event Details Step");
      setPostingEvent(true);

      const event = eventSettings;
      // If the user is not logged in/signed up, store the event locally in redux
      if (loggedIn !== LoginState.LOGGED_IN) {
        dispatch(accountActions.setCurrentEvent(event));
        navigate("/welcome");
        return;
      }
      const defaultEventTexts = generateDefaultEventTexts(
        accountData.uid,
        accountData.fullName,
        event
      );
      const dispatchPromises = [
        dispatch(
          createEventData(event, mixpanel, defaultEventTexts, cloneDetails)
        ),
        dispatch(addToCurrUserWishList(event.id, accountData.uid)),
      ];
      await Promise.all(dispatchPromises);

      showNotificationBanner(
        dispatch,
        "Event Created!",
        NotificationType.AFFIRMATIVE
      );
      navigate(`/e/${event.id}/dashboard`, {
        state: { eventId: event.id, fromCreate: true },
      });
    } catch (error: any) {
      const { response } = error;
      if (response) {
        console.log(response.data);
      }

      setAlertText("Error creating event, please try again later");
      if (mixpanel) {
        mixpanel.track("Failure Creating Event", {
          distinct_id: eventSettings.createdBy,
          event_id: eventSettings.id,
          error: error.message,
        });
      }
    }
    setPostingEvent(false);
  }, [
    accountData.fullName,
    accountData.uid,
    cloneDetails,
    dispatch,
    eventSettings,
    loggedIn,
    mixpanel,
    navigate,
    setAlertText,
  ]);

  const onDraftEventPress = useCallback(
    async (newDraft: boolean) => {
      try {
        setPostingEvent(true);
        const event = deepCopy(eventSettings);
        event.isDraft = true;

        if (newDraft) {
          const dispatchPromises = [
            dispatch(createEventData(event, mixpanel, undefined, cloneDetails)),
            dispatch(addToCurrUserWishList(event.id, accountData.uid)),
          ];
          await Promise.all(dispatchPromises);
        } else {
          await dispatch(updateEventData(event));
        }
        if (mixpanel) {
          mixpanel.track("Event Draft Created", {
            distinct_id: accountData.uid,
            event_id: event.id,
            draft_type: newDraft ? "created" : "updated",
          });
        }
        showNotificationBanner(
          dispatch,
          "Draft Created!",
          NotificationType.AFFIRMATIVE
        );

        if (accountData.inCreatorMode) {
          navigate("/home/events");
        } else {
          dispatch(DataLoaders.user(accountData.uid));
          navigate(`/u/${accountData.username}`, {
            state: { username: accountData.username },
          });
        }
      } catch (error: any) {
        const { response } = error;
        if (response) {
          console.log(response.data);
        }

        setPostingEvent(false);
        if (newDraft) {
          setAlertText("Error drafting event, please try again later");
          if (mixpanel) {
            mixpanel.track("Failure Creating Event", {
              distinct_id: eventSettings.createdBy,
              event_id: eventSettings.id,
              error: error.message,
              is_draft: true,
            });
          }
        }
      }
    },
    [
      accountData.inCreatorMode,
      accountData.uid,
      accountData.username,
      cloneDetails,
      dispatch,
      eventSettings,
      mixpanel,
      navigate,
      setAlertText,
    ]
  );

  const onNextExternalPress = useCallback(() => {
    if (!canPublishEvent(eventSettings)) {
      setShowError(true);
      return;
    }
    const validateMessage = validateEvent(eventSettings);
    if (validateMessage) {
      setAlertText(validateMessage);
      return;
    }
    navigate(
      isEditing
        ? `/e/${eventSettings.id}/editLink`
        : onCreateLinkNavigatePath(eventSettings.eventType),
      {
        state: { confirmState: true, event: eventSettings },
      }
    );
  }, [eventSettings, isEditing, navigate, setAlertText]);

  const setRouteParams = useCallback(() => {
    const eventSettingsUpdates: Partial<Event> = {};

    updateEventSettings({ ...event, ...eventSettingsUpdates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, isEditing]);

  useEffect(() => setRouteParams(), [setRouteParams]);

  // whenever we change the editing settings, we want to reset initial event
  useEffect(
    () =>
      setInitialEventSettings(
        event
          ? deepCopy(event)
          : makeEmptyEvent(
              accountData.uid,
              accountData.fullName,
              accountData.isAdmin,
              currentLocation
            )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditing]
  );

  return {
    updateEventSettings,
    resetEvent,
    postingEvent,
    isEditing,
    showError,
    setShowError,
    eventSettings,
    onPostEventPress,
    onSaveEventPress,
    onNextExternalPress,
    onDraftEventPress,
  };
};
