import { Dispatch } from "@reduxjs/toolkit";
import { notificationActions } from "../redux/slices/notificationSlice";
import { NotificationBanner, NotificationType } from "@markit/common.types";

export const showNotificationBanner = async (
  dispatch: Dispatch,
  title: string,
  type: NotificationType,
  icon?: string
) => {
  const notificationBanner: NotificationBanner = {
    lastTriggered: new Date().toISOString(),
    confirmAction: true,
    title,
    type,
    altIcon: icon ?? "",
  };
  dispatch(notificationActions.setNotificationBanner(notificationBanner));
};
