import { useTheme } from "../../../../hooks/useTheme";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../../../Containers/PopupModalContainer";
import CustomTextField from "../../../CustomTextField";
import { Colors } from "../../../../utils/colors";
import RectangleButton from "../../../Buttons/RectangleButton";
import { useCallback, useState } from "react";
import { Campaign, CampaignType } from "@markit/common.types";
import { API } from "../../../../API";
import { getCountFromServer, query, where } from "../../../../firebase";
import { getUserCampaignsRef } from "../../../../utils/FirebaseUtils";
import { SUBSCRIBED_CAMPAIGN_NAME } from "@markit/common.utils";

type CampaignRenameModalProps = {
  campaign: Campaign;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};

// Used to display the type of campaign (ie. Single or Multi)
const CampaignRenameModal = (props: CampaignRenameModalProps) => {
  const { campaign, showModal, setShowModal } = props;
  const { theme } = useTheme();
  const [newCampaignName, setNewCampaignName] = useState(campaign.title);
  const [campaignNameError, setCampaignNameError] = useState("");

  const renameCampaignOnPress = useCallback(async () => {
    if (!newCampaignName) {
      setCampaignNameError("The campaign name cannot be empty.");
      return;
    }
    if (newCampaignName === SUBSCRIBED_CAMPAIGN_NAME) {
      setCampaignNameError("Cannot rename the campaign to this name.");
      return;
    }
    const campaignsRef = getUserCampaignsRef(campaign.createdBy);
    const campaignsQuery = query(
      campaignsRef,
      where("title", "==", newCampaignName)
    );
    const numCampaignsFound = (await getCountFromServer(campaignsQuery)).data()
      .count;
    if (numCampaignsFound > 0) {
      setCampaignNameError("A campaign already exists with this name.");
      return;
    }
    const newCampaign: Campaign = {
      ...campaign,
      title: newCampaignName,
    };
    const { success } = await API.text.updateTextCampaign({
      userId: campaign.createdBy,
      campaign: newCampaign,
    });
    if (!success) {
      alert("An error occurred when renaming the campaign name.");
    } else {
      setShowModal(false);
      setNewCampaignName("");
    }
  }, [campaign, newCampaignName, setShowModal]);

  return (
    <PopupModalContainer
      valueComp={
        <div className="ColumnNormal" style={{ gap: 20 }}>
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <span className="bodyMedium">
              Edit{" "}
              {campaign.type === CampaignType.AUTOMATION
                ? "Automation"
                : "Campaign"}{" "}
              Name
            </span>
            <div className="ColumnNormal" style={{ gap: 4 }}>
              <CustomTextField
                value={newCampaignName}
                placeholder="Campaign Name"
                inputMode="text"
                borderRadius={12}
                onChange={(name: any) => {
                  setCampaignNameError("");
                  setNewCampaignName(name.target.value);
                }}
                maxLength={60}
                error={campaignNameError !== ""}
              />
              {campaignNameError ? (
                <span
                  className="smallBodySubtext"
                  style={{ color: Colors.RED3 }}
                >
                  {campaignNameError}
                </span>
              ) : null}
            </div>
            <span className="smallBodySubtext">
              {newCampaignName.length}/60
            </span>
          </div>
          <RectangleButton
            buttonLabel="Save"
            onPress={renameCampaignOnPress}
            altTextColor={Colors.WHITE}
            altColor={Colors.BLACK}
            altPaddingVert={14}
            disabled={campaign.title === newCampaignName}
          />
        </div>
      }
      noExit
      noDivider
      closeOnOutsidePress
      closeModal={() => setShowModal(false)}
      hideModal={!showModal}
      theme={theme}
      modalContentWidth={PopupModalContainerWidth.SMALL}
    />
  );
};

export default CampaignRenameModal;
