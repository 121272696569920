import { Icon } from "@iconify/react";
import { useTheme } from "../../../../hooks/useTheme";

type CampaignTypeItemProps = {
  numMassTexts: number;
  automation?: boolean;
  preventPointer?: boolean;
};

// Used to display the type of campaign (ie. Single, Multi, Automation)
const CampaignTypeItem = (props: CampaignTypeItemProps) => {
  const { numMassTexts, automation, preventPointer } = props;
  const { theme } = useTheme();

  return (
    <div
      className="AlignedRow"
      style={{ gap: 7, cursor: preventPointer ? "default" : "pointer" }}
    >
      <Icon
        icon={
          automation
            ? "mdi:local-area-network"
            : numMassTexts > 1
            ? "ion:git-network-sharp"
            : "ion:git-commit"
        }
        height={15}
        style={theme.SubText}
      />
      <span className="bodySubtext">
        {automation
          ? "Automation"
          : numMassTexts > 1
          ? "Multi Text"
          : "Single Text"}
      </span>
    </div>
  );
};

export default CampaignTypeItem;
