import { AccountData, FollowerStatus } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import React, { useCallback, useMemo, useState } from "react";
import filter from "lodash.filter";
import FlatList from "flatlist-react/lib";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import { SelectRecipientsSharedProps } from "./SelectRecipientsScreens";
import { useLoadUserFollowList } from "../../../../hooks/useLoadUserFollowList";
import { useOnMount } from "../../../../hooks/useOnMount";
import { MassTextsPersonItem } from "../Items/MassTextsPersonItem";
import { EmptySearchState } from "../../../EmptyStates/EmptySearchState";
import { CircularProgress } from "@mui/material";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { MassTextsActionItem } from "../Items/MassTextsActionItem";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import CustomCheckbox from "../../../CustomCheckbox";
import { EmptyStateFlatlist } from "../../../EmptyStates/EmptyStateFlatlist";

type SelectRecipientsFollowersProps = SelectRecipientsSharedProps & {};

const SelectRecipientsFollowers = (props: SelectRecipientsFollowersProps) => {
  const {
    unsavedRecipients,
    setUnsavedRecipients,
    setProfileSelected,
    excludingMode,
  } = props;
  const { accountData, followersData, loadedAllFollowers } =
    useSelector(getAccountState).account;
  const [searchTerm, setSearchTerm] = useState("");

  const {
    fetchedUserData: fetchedFollowerData,
    loadMoreUsers: loadMoreFollowerUsers,
    isLoading: isFetchedFollowersLoading,
    isFinished: isFinishedFollowers,
    loadSearchResultsThrottled,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 20,
  });

  useOnMount(() => {
    loadMoreFollowerUsers();
  });

  const containsUser = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query.toLowerCase());
  }, []);

  const handleSearch = (text: string) => {
    if (text !== "") {
      loadSearchResultsThrottled(text.toLowerCase());
    }
    setSearchTerm(text);
  };

  const subscribedFollowers = useMemo(
    () =>
      followersData.filter(
        (follower) => follower.status === FollowerStatus.SUBSCRIBED
      ),
    [followersData]
  );

  const itemsToShow: AccountData[] = useMemo(() => {
    let searchResults: AccountData[] = fetchedFollowerData;
    if (searchTerm !== "") {
      searchResults = filter(fetchedFollowerData, (user: AccountData) => {
        return containsUser(user, searchTerm);
      });
    }
    return searchResults;
  }, [fetchedFollowerData, searchTerm, containsUser]);

  const selectAllFollowers = useCallback(() => {
    const followerIds = subscribedFollowers.map((follower) => follower.uid);
    setUnsavedRecipients(
      unsavedRecipients.length === followerIds.length ? [] : followerIds
    );
  }, [subscribedFollowers, setUnsavedRecipients, unsavedRecipients.length]);

  return (
    <>
      <SearchBoxContainer
        value={searchTerm}
        placeholder={"Search People..."}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
        containerStyles={{ marginTop: 0, marginBottom: 14 }}
      />
      <div style={{ paddingBottom: 100 }} className="HideScrollbar AllowScroll">
        {!excludingMode && itemsToShow.length > 0 && searchTerm === "" ? (
          <div>
            <MassTextsActionItem
              title={"Select All"}
              icon={<Icon icon="ion:people" height={32} color={Colors.BLUE5} />}
              onPress={selectAllFollowers}
              subtext={`${subscribedFollowers.length} People`}
              alternateRightIcon={
                !loadedAllFollowers ? (
                  <CircularProgress style={{ color: Colors.GRAY1 }} size={18} />
                ) : (
                  <CustomCheckbox
                    checked={
                      subscribedFollowers.length === unsavedRecipients.length
                    }
                    onChange={selectAllFollowers}
                    sx={{ padding: 0 }}
                    altColor={Colors.BLUE5}
                  />
                )
              }
              disabled={!loadedAllFollowers}
              loading={!loadedAllFollowers}
            />
            <HorizontalDivider altMargin={10} />
          </div>
        ) : null}
        <FlatList
          list={itemsToShow}
          renderItem={(item: AccountData) => (
            <MassTextsPersonItem
              user={item}
              unsavedPeople={unsavedRecipients}
              setUnsavedPeople={setUnsavedRecipients}
              excludingMode={excludingMode}
              setProfileSelected={setProfileSelected}
            />
          )}
          renderWhenEmpty={() => (
            <EmptyStateFlatlist
              containerStyles={{ paddingTop: 200 }}
              isLoading={isFetchedFollowersLoading}
              searchTerm={searchTerm}
              nonSearchEmptyView={
                <EmptySearchState mainText="No people to show" />
              }
            />
          )}
          hasMoreItems={!isFinishedFollowers}
          loadMoreItems={() => {
            if (
              searchTerm.trim() === "" &&
              !isFetchedFollowersLoading &&
              !isFinishedFollowers
            ) {
              loadMoreFollowerUsers();
            }
          }}
          paginationLoadingIndicator={() =>
            isFetchedFollowersLoading && !isFinishedFollowers ? (
              <CircularProgress
                style={{ color: "#929292", alignSelf: "center" }}
                size={20}
              />
            ) : null
          }
        />
      </div>
    </>
  );
};

export default SelectRecipientsFollowers;
