import {
  AccountData,
  Campaign,
  CampaignType,
  Event,
  MassText,
  MassTextAttachment,
  MassTextAttachmentType,
  MassTextType,
} from "@markit/common.types";
import {
  CampaignDetails,
  MassTextDetails,
} from "../redux/slices/campaignSlice";
import {
  Batch,
  getCampaignMassTextRef,
  getCampaignMassTextsRef,
  getCampaignMassTextsSnap,
  getUserCampaignRef,
  getUserCampaignsRef,
} from "./FirebaseUtils";
import {
  getMassTextAttachments,
  getNumMassTextRecipients,
} from "./textingUtils";
import {
  subscribeTriggerPlaceholder,
  isEventExternalLink,
  makeEmptyCampaign,
  makeEmptyMassText,
  SUBSCRIBED_CAMPAIGN_NAME,
  LessThanDate,
  hasSubscription,
} from "@markit/common.utils";
import { getCountFromServer, getDocs, limit, query, where } from "../firebase";
import { generate } from "shortid";

export const fetchCampaignDetails = async (
  userId: string,
  campaign: Campaign
): Promise<CampaignDetails> => {
  const massTextsSnap = await getCampaignMassTextsSnap(userId, campaign.id);
  const numRecipients = await getNumMassTextRecipients(
    userId,
    campaign,
    campaign.initialTextId
  );
  const massTextDetails: MassTextDetails[] = await Promise.all(
    massTextsSnap.docs.map(async (doc) => {
      const massText = doc.data();
      const attachments = await getMassTextAttachments(userId, massText);
      return {
        massText,
        attachments,
      };
    })
  );
  return { campaignId: campaign.id, numRecipients, massTextDetails };
};

// Fetched the campaign associated with an event
export const fetchEventCampaignData = async (
  userId: string,
  eventId: string
): Promise<Campaign | undefined> => {
  const campaignsRef = getUserCampaignsRef(userId);
  const query_ = query(campaignsRef, where("eventId", "==", eventId), limit(1));
  const snapshot = await getDocs(query_);
  if (!snapshot.empty) {
    const campaign = snapshot.docs.map((doc) => doc.data())[0];
    return campaign;
  }
};

// Fetches the subscribe campaign and the mass text
export const fetchSubscribeCampaignData = async (
  userId: string
): Promise<{
  campaign: Campaign | undefined;
  massText: MassText | undefined;
}> => {
  const campaignsRef = getUserCampaignsRef(userId);
  const query_ = query(
    campaignsRef,
    where("title", "==", SUBSCRIBED_CAMPAIGN_NAME),
    limit(1)
  );
  const snapshot = await getDocs(query_);
  if (!snapshot.empty) {
    const subscribeCampaign = snapshot.docs.map((doc) => doc.data())[0];
    const massTextsRef = getCampaignMassTextsRef(userId, subscribeCampaign.id);
    const massTextSnap = await getDocs(
      query(
        massTextsRef,
        where("campaignId", "==", subscribeCampaign.id),
        limit(1)
      )
    );
    if (!massTextSnap.empty) {
      const massText = massTextSnap.docs.map((doc) => doc.data())[0];
      return { campaign: subscribeCampaign, massText: massText };
    }
  }
  return { campaign: undefined, massText: undefined };
};

// Determines whether to show the link clicks and conversions
export const displayTextAnalytics = (attachments: MassTextAttachment[]) => {
  return (
    attachments.length > 1 ||
    (attachments.length === 1 &&
      attachments[0].massTextType !== MassTextAttachmentType.TICKET_LINK &&
      attachments[0].massTextType !== MassTextAttachmentType.CALENDAR_INVITE)
  );
};

// Determines whether to show the link conversions when displaying the analytics
// Use this logic if the event link being shared on the message is an external link, then hide conversions
export const displayTextConversions = (
  attachments: MassTextAttachment[],
  events: Event[]
) => {
  if (attachments.length === 1) {
    const foundEvent = events.find(
      (event) => event.id === attachments[0].eventId
    );
    if (foundEvent && isEventExternalLink(foundEvent.eventType)) {
      return false;
    }
  }
  return true;
};

// Creates the subscribe campaign on app load, if it doesn't exist already
export const createSubscribeTriggerCampaign = async (userData: AccountData) => {
  const campaignsRef = getUserCampaignsRef(userData.uid);
  const subscribedCampaignQuery = query(
    campaignsRef,
    where("title", "==", SUBSCRIBED_CAMPAIGN_NAME)
  );
  const snapshot = await getCountFromServer(subscribedCampaignQuery);
  if (snapshot.data().count === 0) {
    const campaignId = generate();
    const date = new Date().toISOString();
    const subscribedTriggerText: MassText = makeEmptyMassText(
      generate(),
      userData.uid,
      userData.fullName,
      {
        campaignId: campaignId,
        type: MassTextType.TRIGGERTEXT,
        message:
          userData.contactCustomMessage !== ""
            ? userData.contactCustomMessage
            : subscribeTriggerPlaceholder(hasSubscription(userData)),
        mediaUrl: userData.contactCustomMediaUrl, // backsupport
        queued: true,
        createdAt: date,
        sentAt: date,
        isDefault: userData.contactCustomMessage === "", // only default if there wasn't backsupport message existing
      }
    );
    const subscribedCampaign: Campaign = makeEmptyCampaign(
      campaignId,
      userData.uid,
      {
        initialTextId: subscribedTriggerText.id,
        title: SUBSCRIBED_CAMPAIGN_NAME,
        type: CampaignType.AUTOMATION,
      }
    );
    const batch = new Batch("Error creating subscribe trigger campaign");
    const massTextRef = getCampaignMassTextRef(
      userData.uid,
      campaignId,
      subscribedTriggerText.id
    );
    const campaignRef = getUserCampaignRef(userData.uid, campaignId);
    batch.set(massTextRef, subscribedTriggerText);
    batch.set(campaignRef, subscribedCampaign);
    await batch.commit();
  }
};

// Determines if the automation campaign is Active
// If it's the Clicked Subscribe trigger campaign, last automation text within a day of sending, event end still upcoming
export const isAutomationActive = (
  campaignTitle: string,
  massTexts: MassText[],
  foundEvent: Event | undefined
) => {
  if (campaignTitle === SUBSCRIBED_CAMPAIGN_NAME) {
    return true;
  }
  const lastMassText = [...massTexts].sort(
    (a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime()
  )[0];
  const oneDayAfter = new Date(lastMassText.sentAt);
  oneDayAfter.setDate(oneDayAfter.getDate() + 1);
  // Checks if current date is less than one day prior to the last mass text sent
  if (LessThanDate(new Date().toISOString(), oneDayAfter.toISOString())) {
    return true;
  }
  // Checks if event trigger campaign and the event end is still upcoming
  if (foundEvent && LessThanDate(new Date().toISOString(), foundEvent.end)) {
    return true;
  }
  return false;
};
