import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import { Campaign } from "@markit/common.types";
import { CampaignDetails } from "../../redux/slices/campaignSlice";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { AutomationItemPreview } from "../DisplayItem/CampaignItem/AutomationItemPreview";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import RectangleButton from "../Buttons/RectangleButton";

type TextsPanelProps = {
  campaign: Campaign | undefined;
  campaignDetail: CampaignDetails | undefined;
  isExternalLink: boolean;
  inviteGuestsOnPress: () => void;
  textReminderOnPress: () => void;
};

export const TextsPanel = (props: TextsPanelProps) => {
  const {
    campaign,
    campaignDetail,
    isExternalLink,
    inviteGuestsOnPress,
    textReminderOnPress,
  } = props;
  const { uid } = useSelector(getAccountState).account.accountData;

  const isHost = useMemo(
    () => uid === campaign?.createdBy,
    [campaign?.createdBy, uid]
  );

  return (
    <div className="ColumnNormal">
      {campaign && campaignDetail ? (
        <StandardBorderedContainer
          containerStyles={{
            backgroundColor: Colors.WHITE,
            padding: 14,
            borderRadius: 14,
            flex: 1,
          }}
        >
          <AutomationItemPreview
            campaignItem={campaign}
            campaignDetail={campaignDetail}
            onEventDashboard
          />
        </StandardBorderedContainer>
      ) : null}
      <EmptyStateButton
        title={
          isHost ? "Send and Schedule Reminder Texts" : "Text Your Following"
        }
        description={
          isHost
            ? "Send texts and schedule texts for this event in automations."
            : "Only the host of this event can manage text reminders. Text your following to promote this event."
        }
        icon={
          <Icon
            icon={isHost ? "mdi:local-area-network" : "ion:chatbubble-ellipses"}
            height={55}
          />
        }
        iconBox={80}
        iconBackgroundColor={Colors.WHITE}
        secondaryAction={
          <div className="AlignedRow" style={{ gap: 10 }}>
            <RectangleButton
              onPress={inviteGuestsOnPress}
              buttonLabel={
                isExternalLink ? "Invite People" : "Invite Following"
              }
              iconLeft={
                <Icon
                  icon={"ion:person-add"}
                  height={15}
                  color={Colors.WHITE}
                />
              }
              altColor={Colors.BLACK}
              altTextColor={Colors.WHITE}
              altBorderRadius={100}
              containerStyles={{ padding: 14, whiteSpace: "nowrap" }}
            />
            <RectangleButton
              onPress={textReminderOnPress}
              buttonLabel="Text Reminder"
              iconLeft={
                <Icon
                  icon={"ion:add-circle"}
                  height={15}
                  color={Colors.WHITE}
                />
              }
              altColor={Colors.BLACK}
              altTextColor={Colors.WHITE}
              altBorderRadius={100}
              containerStyles={{ padding: 14, whiteSpace: "nowrap" }}
            />
          </div>
        }
        containerStyles={{ paddingTop: 120 }}
      />
    </div>
  );
};
