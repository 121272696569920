import { Icon } from "@iconify/react";
import { LightTheme, useTheme } from "../../../hooks/useTheme";
import { Colors } from "../../../utils/colors";
import SwitchToggleItem from "../../SwitchToggleItem";
import {
  Event,
  PromoCode,
  PromoCodeType,
  TicketV2,
  NotificationType,
} from "@markit/common.types";
import NumberInput from "../../NumberInput";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  fetchAllFreeTicketsWithAlias,
  findFreeTicketAlias,
} from "../../../utils/eventUtils/eventUtils";
import { API } from "../../../API";
import { MixpanelContext } from "../../../context/AnalyticsService";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import CustomTextField from "../../CustomTextField";
import RectangleButton from "../../Buttons/RectangleButton";
import AlertContainer from "../../Containers/AlertContainer";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import { useDispatch } from "react-redux";

type PromoCodeModalProps = {
  setIsPromoModalVisible: (promoModalVisible: boolean) => void;
  event: Event;
  isEditingPromo: boolean;
  setIsEditingPromo: (isEditingPromo: boolean) => void;
  item: PromoCode | TicketV2 | undefined;
  setItem: (item: PromoCode | TicketV2 | undefined) => void;
};

export const PromoCodeModal = (props: PromoCodeModalProps) => {
  const {
    setIsPromoModalVisible,
    event,
    isEditingPromo,
    setIsEditingPromo,
    item,
    setItem,
  } = props;
  const { theme } = useTheme();
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const [selectedTicketIndex, setSelectedTicketIndex] = useState<number>(0);
  const [promoCodeModalText, setPromoCodeModalText] = useState<string>("");
  const [promoModalDiscount, setPromoModalDiscount] = useState<
    number | undefined
  >(
    isEditingPromo && item
      ? (item as PromoCode).type
        ? (item as PromoCode).constants[0]
        : 100
      : 20
  );
  const [promoModalMinUses, setPromoModalMinUses] = useState<
    number | undefined
  >(1);
  const [promoModalFreeTicket, setPromoModalFreeTicket] =
    useState<boolean>(false);

  // for editing free tickets
  const [usedFreeTickets, setUsedFreeTickets] = useState<TicketV2[]>([]);
  const [promoModalUses, setPromoModalUses] = useState<number | undefined>(1);

  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });

  const styles = {
    textLabel: {
      fontSize: 14,
      fontWeight: 600,
    },
  };

  useEffect(() => {
    if (isEditingPromo && item) {
      setPromoCodeModalText(item.alias);
    }
  }, [isEditingPromo, item]);

  const resetFields = useCallback(() => {
    setItem(undefined);
    setIsPromoModalVisible(false);
    setPromoCodeModalText("");
    setPromoModalDiscount(20);
    setPromoModalUses(1);
    setPromoModalFreeTicket(false);
    setIsEditingPromo(false);
  }, [setIsEditingPromo, setIsPromoModalVisible, setItem]);

  const isPromoCode = useMemo(() => {
    if (item) {
      return (item as PromoCode).type;
    } else {
      return false;
    }
  }, [item]);

  const isFreePromo = useMemo(
    () => (item ? !(item as PromoCode).type : false),
    [item]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (item) {
      if (isFreePromo) {
        const freeTickets = await fetchAllFreeTicketsWithAlias(event.id);
        const filtered = freeTickets.filter((ticket) => {
          return ticket.alias === item.alias;
        });
        setPromoModalUses(filtered.length);
        const usedTix = filtered.filter((ticket) => {
          return ticket.role.type !== "unassigned";
        });
        setUsedFreeTickets(usedTix);
        setPromoModalMinUses(usedTix.length);
      } else {
        setPromoModalUses((item as PromoCode).maxUseCount);
        setPromoModalMinUses((item as PromoCode).usedCount);
      }
    }
  }, [event, item, isFreePromo]);

  const promoAlreadyUsed = useMemo(() => {
    if (item) {
      if (isPromoCode) {
        const promoCode = item as PromoCode;
        return promoCode.usedCount > 0;
      } else {
        return usedFreeTickets.length > 0;
      }
    }
    return false;
  }, [item, usedFreeTickets, isPromoCode]);

  const savePromoCodePress = useCallback(async () => {
    if (promoModalUses === undefined) {
      setAlertText({
        heading: "Promo code / free ticket must have at least 1 use.",
        subHeading: "",
      });
      return;
    }
    if (!promoModalFreeTicket && promoModalDiscount === undefined) {
      setAlertText({
        heading: "Discount must be between 1% and 99%",
        subHeading: "",
      });
      return;
    }

    const codeNameUppercase = promoCodeModalText.toUpperCase();
    const found = event.promoCodes.find(
      (code) => code.alias && code.alias.toUpperCase() === codeNameUppercase
    );

    const foundTicketName = await findFreeTicketAlias(
      event.id,
      codeNameUppercase
    );

    if (found || foundTicketName[0]) {
      setAlertText({
        heading: "Promo code / free ticket with name already exists.",
        subHeading: "Please choose a new name.",
      });
      return;
    }

    if (promoModalFreeTicket) {
      API.promoCodes
        .createFreeTicketCode({
          eventId: event.id,
          uid: event.createdBy,
          customTicketId: event.customTickets[selectedTicketIndex].id,
          alias: codeNameUppercase,
          maxUseCount: promoModalUses,
        })
        .then((response) => {
          if (response.freeTicketCodes.length !== promoModalUses) {
            setAlertText({
              heading: "Uses exceeds available ticket quantity.",
              subHeading: "Please try again.",
            });
          } else {
            mixpanel.track("Generated Free Ticket Code", {
              event_id: event.id,
              ticket_id: response.freeTicketCodes[0].id,
            });
            resetFields();
          }
        })
        .catch(() => {
          setAlertText({
            heading: "Something went wrong.",
            subHeading: "Please try again.",
          });
        });
    } else {
      await API.promoCodes
        .createPromoCode({
          eventId: event.id,
          type: PromoCodeType.PERCENT_OFF,
          constants: [promoModalDiscount ?? 1],
          redeemedBy: "",
          alias: codeNameUppercase,
          maxUseCount: promoModalUses,
        })
        .then((response) => {
          if (response.promoCodes.length !== 1) {
            setAlertText({
              heading: "Promo Code Created Incorrectly.",
              subHeading: "Please try again.",
            });
          } else {
            mixpanel.track("Generated Named Promo Code", {
              event_id: event.id,
              isUnlimited: false,
            });
            resetFields();
          }
        })
        .catch(() => {
          setAlertText({
            heading: "Something went wrong.",
            subHeading: "Please try again.",
          });
        });
    }
  }, [
    promoModalUses,
    promoModalFreeTicket,
    promoModalDiscount,
    promoCodeModalText,
    event.promoCodes,
    event.id,
    event.createdBy,
    event.customTickets,
    selectedTicketIndex,
    mixpanel,
    resetFields,
  ]);

  const saveEditsPromoCodePress = useCallback(async () => {
    if (item && promoModalDiscount && promoModalUses) {
      await API.promoCodes
        .updatePromoCode({
          eventId: event.id,
          promoCodeId: item.id,
          updatedAlias: promoCodeModalText,
          updatedDiscount: [promoModalDiscount],
          updatedMaxUseCount: promoModalUses,
        })
        .then(() => {
          console.log("Successfully saved edits to promo code.");
          resetFields();
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [
    item,
    promoModalDiscount,
    promoModalUses,
    event.id,
    promoCodeModalText,
    resetFields,
  ]);

  const saveEditsFreeTicketPress = useCallback(async () => {
    if (promoModalUses && item) {
      await API.promoCodes
        .updateFreePromo({
          eventId: event.id,
          existingAlias: item.alias,
          updatedAlias: promoCodeModalText,
          updatedMaxUseCount: promoModalUses,
        })
        .then(() => {
          resetFields();
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [promoModalUses, item, event.id, promoCodeModalText, resetFields]);

  const onSavePress = useCallback(async () => {
    if (/\s/.test(promoCodeModalText)) {
      setAlertText({
        heading: "Promo codes can't include spaces",
        subHeading: "",
      });
    } else {
      if (isEditingPromo) {
        if (isFreePromo) {
          await saveEditsFreeTicketPress();
        } else {
          await saveEditsPromoCodePress();
        }
      } else {
        await savePromoCodePress();
      }
      showNotificationBanner(
        dispatch,
        "Promo Code " + (isEditingPromo ? "Updated!" : "Created!"),
        NotificationType.AFFIRMATIVE
      );
    }
  }, [
    promoCodeModalText,
    isEditingPromo,
    isFreePromo,
    saveEditsFreeTicketPress,
    saveEditsPromoCodePress,
    savePromoCodePress,
    dispatch,
  ]);

  return (
    <PopupModalContainer
      headerComp={isEditingPromo ? "Edit Promo Code" : "New Promo Code"}
      headerLeftIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingInline: "14px",
            marginBottom: "10px",
          }}
        >
          <Icon
            icon={"ion:pricetags"}
            height={35}
            style={{
              color: Colors.BLACK,
            }}
          />
        </div>
      }
      subHeaderComp={
        isEditingPromo ? undefined : (
          <div
            style={{
              fontWeight: "400",
              fontSize: 14,
              color: Colors.GRAY1,
              paddingTop: 10,
            }}
          >
            Create a new promo code/discount
          </div>
        )
      }
      noExit={true}
      closeOnOutsidePress
      valueComp={
        <>
          <CustomTextField
            value={promoCodeModalText}
            placeholder="Name your promo code..."
            inputMode="text"
            borderRadius={8}
            backgroundColor={Colors.WHITE}
            onChange={(change: any) => {
              setPromoCodeModalText(change.target.value);
            }}
            altMarginBottom={0}
            maxLength={25}
          />
          <div className="AlignedRowRight">
            <span
              style={{
                fontWeight: 400,
                fontSize: 14,
                color: Colors.GRAY1,
                marginTop: 4,
              }}
            >
              {promoCodeModalText.length}/25
            </span>
          </div>
          <div style={{ marginTop: 14 }}>
            <SwitchToggleItem
              title="Free Ticket"
              description=""
              toggleValue={promoModalFreeTicket}
              onChange={() => {
                if (
                  !promoModalFreeTicket &&
                  promoModalUses &&
                  promoModalUses > 200
                ) {
                  setPromoModalUses(200);
                }
                setPromoModalFreeTicket(!promoModalFreeTicket);
              }}
              theme={theme}
              disabled={isEditingPromo}
            />
          </div>
          {promoModalFreeTicket ? (
            <div
              style={{
                marginLeft: 10,
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                gap: 7,
              }}
            >
              {event.customTickets.map((customTicket, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (selectedTicketIndex !== index) {
                      setSelectedTicketIndex(index);
                    }
                  }}
                >
                  <Icon
                    icon={
                      selectedTicketIndex === index
                        ? "mdi:radiobox-marked"
                        : "mdi:radiobox-blank"
                    }
                    height={24}
                    style={{
                      color: theme === LightTheme ? Colors.BLACK : Colors.WHITE,
                      marginRight: 5,
                    }}
                  />
                  <div style={{ ...theme.PrimaryText, ...styles.textLabel }}>
                    {customTicket.label}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="AlignedRowSpaced"
              style={{ width: "100%", marginTop: 14 }}
            >
              <div style={{ ...theme.PrimaryText, ...styles.textLabel }}>
                Percent Off
              </div>
              <div style={{ width: "30%" }}>
                <NumberInput
                  placeholder="20"
                  min={1}
                  max={99}
                  value={promoModalDiscount}
                  onChange={setPromoModalDiscount}
                  disabled={isEditingPromo && (isFreePromo || promoAlreadyUsed)}
                />
              </div>
            </div>
          )}
          <div
            className="AlignedRowSpaced"
            style={{ width: "100%", marginBlock: 14 }}
          >
            <div style={{ ...theme.PrimaryText, ...styles.textLabel }}>
              Set uses
            </div>
            <div style={{ width: "30%" }}>
              {(isEditingPromo && promoModalUses) || !isEditingPromo ? (
                <NumberInput
                  placeholder="1"
                  min={promoModalMinUses ?? 1}
                  max={isFreePromo || promoModalFreeTicket ? 200 : 500}
                  value={promoModalUses}
                  onChange={setPromoModalUses}
                />
              ) : null}
            </div>
          </div>
          <RectangleButton
            buttonLabel={isEditingPromo ? "Save" : "Create Promo Code"}
            onPress={onSavePress}
            altColor={Colors.BLACK}
            disabled={promoCodeModalText.trim().length === 0}
            altPaddingVert={14}
            containerStyles={{ marginTop: 14 }}
          />
          <AlertContainer
            headerComp={alertText.heading}
            subHeaderComp={
              alertText.subHeading !== "" ? alertText.subHeading : undefined
            }
            theme={theme}
            closeModal={() => setAlertText({ heading: "", subHeading: "" })}
            hideModal={alertText.heading === "" && alertText.subHeading === ""}
          />
        </>
      }
      theme={theme}
      closeModal={() => {
        setPromoCodeModalText("");
        setIsPromoModalVisible(false);
        setItem(undefined);
        resetFields();
      }}
    />
  );
};
