import "../../css/Containers/ContainerStyles.css";
import React, { ReactNode } from "react";
import { useTheme } from "../../hooks/useTheme";
import { CircularProgress } from "@mui/material";
import GridTableHeader from "../Headers/GridTableHeader";
import { Colors } from "../../utils/colors";

type StandardListContainerNewProps = {
  searchComp: ReactNode;
  gridTable?: {
    flexSections: number[];
    sectionTitles: string[];
  };
  listComp: ReactNode;
  isLoading?: boolean;
};

/* Used for a standard list container for displaying items in a list view, ie. CampaignsListPanel. Standardize to ensure Flatlist scrolling behavior works */
const StandardListContainerNew = (props: StandardListContainerNewProps) => {
  const { searchComp, gridTable, isLoading, listComp } = props;
  const { theme } = useTheme();

  return (
    <div
      className="ColumnNormal"
      style={{ width: "100%", height: "100%", gap: 14 }}
    >
      <div className="RowNormal">{searchComp}</div>
      {gridTable ? (
        <GridTableHeader
          flexSections={gridTable.flexSections}
          sectionTitles={gridTable.sectionTitles}
          containerStyles={{
            borderRadius: 10,
            border: `1.5px solid ${theme.DividerColor.borderColor}`,
          }}
        />
      ) : null}
      {!isLoading ? (
        <div style={{ paddingBottom: 100 }}>{listComp}</div>
      ) : (
        <div className="Centering" style={{ minHeight: "50vh" }}>
          <CircularProgress style={{ color: Colors.GRAY1 }} size={30} />
        </div>
      )}
    </div>
  );
};

export default StandardListContainerNew;
