import { CSSProperties } from "react";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";

type ClickedSubscribeTriggerIconProps = {
  iconSize?: number;
  containerStyles?: CSSProperties;
};

// Helper icon used in multiple places in the automation campaign items
const ClickedSubscribeTriggerIcon = (
  props: ClickedSubscribeTriggerIconProps
) => {
  const { iconSize, containerStyles } = props;

  return (
    <div
      className="Centering"
      style={{
        padding: 7,
        borderRadius: 8,
        backgroundColor: Colors.WHITE1,
        ...containerStyles,
      }}
    >
      <Icon icon="mdi:cursor-default-click" height={iconSize ?? 17} />
    </div>
  );
};

export default ClickedSubscribeTriggerIcon;
