import { useTheme } from "../../hooks/useTheme";
import React, { useCallback } from "react";
import { Colors } from "../../utils/colors";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  FormAnswersV2,
  FormInputType,
  SavedFormQuestion,
} from "@markit/common.types";
import { Icon } from "@iconify/react";
import checkbox from "@iconify/icons-mdi/checkbox-outline";
import CustomLinkify from "../Links/CustomLinkify";
import { changeFormAnswerV2 } from "@markit/common.utils";
import CustomTextField from "../../components/CustomTextField";

type SingularQuestionInputProps = {
  question: SavedFormQuestion;
  formQuestions: SavedFormQuestion[];
  answers: FormAnswersV2;
  setAnswers: (newAnswers: FormAnswersV2) => void;
  formErrorIds: string[];
  setFormErrorIds: (errorIds: string[]) => void;
  disabled: boolean;
};

/**
 * To be used for text fields with the verified text in the right of the text box
 */
const SingularQuestionInput = (props: SingularQuestionInputProps) => {
  const {
    question,
    formQuestions,
    answers,
    setAnswers,
    formErrorIds,
    setFormErrorIds,
    disabled,
  } = props;

  const { theme } = useTheme();

  const handleChange = useCallback(
    (questionId: string, answer: string[]) => {
      if (formErrorIds.includes(questionId))
        setFormErrorIds(formErrorIds.filter((id) => id !== questionId));
      const newFormAnswers = changeFormAnswerV2({
        answers: answers,
        questions: formQuestions,
        questionId: questionId,
        answer: answer,
      });
      setAnswers(newFormAnswers);
    },
    [answers, formErrorIds, formQuestions, setAnswers, setFormErrorIds]
  );

  const getElement = useCallback(
    (question: SavedFormQuestion, disabled: boolean) => {
      const isError = formErrorIds.includes(question.id);
      const answerInputArr = answers[question.id] ?? [];

      switch (question.formInput.inputType) {
        case FormInputType.TEXT_INPUT: {
          return (
            <CustomTextField
              value={answerInputArr[0]}
              placeholder={question.formInput.placeholder || "Answer here..."}
              disabled={disabled}
              inputMode="text"
              borderRadius={12}
              onChange={(event: any) => {
                handleChange(question.id, [event.target.value]);
              }}
              error={isError}
            />
          );
        }
        case FormInputType.EMAIL: {
          return (
            <CustomTextField
              value={answerInputArr[0]}
              placeholder={question.formInput.placeholder || "Answer here..."}
              disabled={disabled}
              inputMode="email"
              borderRadius={12}
              onChange={(event: any) => {
                handleChange(question.id, [event.target.value]);
              }}
              error={isError}
            />
          );
        }
        case FormInputType.CHECK_BOX: {
          return question.formInput.options.map((option, index) => (
            <FormControlLabel
              key={index}
              style={{ marginLeft: 15 }}
              control={
                <Checkbox
                  checked={answerInputArr.includes(option)}
                  onChange={(event: any) =>
                    handleChange(
                      question.id,
                      event.target.checked
                        ? answerInputArr.concat(option)
                        : answerInputArr.filter((val) => val !== option)
                    )
                  }
                  inputProps={{
                    color: theme.PrimaryText.color,
                  }}
                  style={{ paddingLeft: 0 }}
                  color="default"
                  sx={
                    isError
                      ? { color: Colors.RED1 }
                      : { color: theme.PrimaryText.color }
                  }
                  checkedIcon={<Icon icon={checkbox} height={24} />}
                  disabled={disabled}
                />
              }
              label={<span style={theme.PrimaryText}>{option}</span>}
            />
          ));
        }
        case FormInputType.RADIO: {
          return (
            <RadioGroup
              value={answers[question.id]}
              onChange={(event: any) => {
                handleChange(question.id, [event.target.value]);
              }}
              style={{ marginLeft: 15 }}
            >
              {question.formInput.options.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={
                    <Radio
                      color="default"
                      sx={{
                        color: isError ? Colors.RED1 : theme.PrimaryText.color,
                        "&.Mui-disabled": {
                          color: theme.PrimaryText.color,
                        },
                      }}
                    />
                  }
                  label={<span style={theme.PrimaryText}>{option}</span>}
                  disabled={disabled}
                />
              ))}
            </RadioGroup>
          );
        }
      }
    },
    [answers, formErrorIds, handleChange, theme.PrimaryText]
  );

  return (
    <div key={question.id} style={{ opacity: disabled ? 0.3 : 1 }}>
      <FormControl
        style={{ width: "100%", marginTop: 15 }}
        error={formErrorIds.includes(question.id)}
      >
        {question.formInput.label !== undefined &&
        question.formInput.label !== "" ? (
          <FormLabel
            id="demo-radio-buttons-group-label"
            required={question.formInput.isRequired}
            style={{
              ...theme.PrimaryText,
              fontSize: 16,
              fontWeight: "500",
              paddingBottom: 5,
            }}
          >
            <CustomLinkify>
              <span style={theme.PrimaryText}>{question.formInput.label}</span>
            </CustomLinkify>
          </FormLabel>
        ) : undefined}
        {getElement(question, disabled)}
      </FormControl>
    </div>
  );
};

export default SingularQuestionInput;
