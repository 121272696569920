import React, { memo, useCallback, useState } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import {
  AccountData,
  Follower,
  FormInputType,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import SingleCustomData from "./SingleCustomData";
import EssentialDataResponse from "./EssentialDataResponse";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";

type SingleCustomDataItemProps = {
  savedQuestion: SavedFormQuestion;
  formResponse: SavedFormResponse | undefined;
  userData: AccountData;
  followerData: Follower | undefined;
  pressable?: boolean;
  lastItem?: boolean;
  detailView?: boolean;
};

const SingleCustomDataItem = memo(function SingleCustomDataItemFn(
  props: SingleCustomDataItemProps
) {
  const {
    savedQuestion,
    formResponse,
    userData,
    followerData,
    pressable,
    lastItem,
    detailView,
  } = props;

  const { theme } = useTheme();
  const [singleCustomDataOpen, setSingleCustomDataOpen] = useState(false);

  const styles = {
    alignedRow: {
      alignItems: "center",
      display: "flex",
    },

    divider: {
      height: 1,
      marginVertical: 14,
      borderBottomWidth: 1,
      borderBottomColor: Colors.BLACK1,
    },

    subSectionContainer: {
      marginInline: 14,
    },
  };

  const singleCustomDataOnPress = useCallback(() => {
    setSingleCustomDataOpen(true);
  }, []);

  const closeSingleCustomData = useCallback(() => {
    setSingleCustomDataOpen(false);
  }, []);

  return (
    <>
      {singleCustomDataOpen ? (
        <SingleCustomData
          savedQuestion={savedQuestion}
          formResponse={formResponse ?? undefined}
          userData={userData}
          followerData={followerData}
          closeSingleCustomData={closeSingleCustomData}
        />
      ) : null}
      <div>
        <div
          className="AlignedRowSpaced"
          style={{ ...styles.subSectionContainer }}
        >
          <div style={{ flex: 1 }}>
            <div
              className="AlignedRow"
              style={{
                marginBottom: 4,
              }}
            >
              {savedQuestion.formInput.inputType === FormInputType.EMAIL ? (
                <Icon
                  icon={"ion:mail-open"}
                  height={15}
                  color={theme.SubText.color}
                  style={{ marginRight: 10 }}
                />
              ) : null}
              <div>
                <span
                  className={
                    !detailView
                      ? "TextOverflowEllipsis bodySubtext"
                      : "bodySubtext"
                  }
                  style={{ width: "360px" }}
                >
                  {savedQuestion.formInput.label}
                </span>
              </div>
            </div>
            <EssentialDataResponse
              formResponse={formResponse}
              copyable
              detailView={detailView ?? false}
            />
          </div>
          {pressable ? (
            <Icon
              icon="mdi:chevron-right"
              height={26}
              color={theme.SubText.color}
              onClick={pressable ? singleCustomDataOnPress : () => {}}
            />
          ) : null}
        </div>
        {!lastItem ? <HorizontalDivider altMargin={14} /> : null}
      </div>
    </>
  );
});

export default SingleCustomDataItem;
