import { Colors } from "../../utils/colors";
import CustomLinkify from "./CustomLinkify";
import { Icon } from "@iconify/react";
import { useTheme } from "../../hooks/useTheme";
import React, { CSSProperties, memo, useCallback, useState } from "react";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { showNotificationBanner } from "../../utils/notificationUtils";
import { useDispatch } from "react-redux";
import { NotificationType } from "@markit/common.types";

type CopyCustomLinkifyProps = {
  text: string;
  textStyles?: React.CSSProperties;
  disableCopy?: boolean;
  showFullContent?: boolean;
};

// Used on top of CustomLinkify by adding the copy icon at the start of the link
export const CopyCustomLinkify = (props: CopyCustomLinkifyProps) => {
  const { text, textStyles, disableCopy, showFullContent } = props;
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const [copyOnHover, setCopyOnHover] = useState(false);

  const copyOnPress = useCallback(() => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showNotificationBanner(
          dispatch,
          "Copied!",
          NotificationType.AFFIRMATIVE
        );
      })
      .catch((err) => {
        alert(err);
      });
  }, [dispatch, text]);

  return (
    <div className="AlignedRow" style={{ gap: 4 }}>
      {!disableCopy ? (
        <div>
          <Icon
            onClick={copyOnPress}
            icon={"ion:copy-outline"}
            height={14}
            color={Colors.BLUE5}
            onMouseEnter={() => setCopyOnHover(true)}
            onMouseLeave={() => setCopyOnHover(false)}
            style={{
              cursor: "pointer",
              marginTop: 3,
              borderRadius: 3,
              boxShadow: copyOnHover ? "0px 0px 1px 4px #EDEDED" : "",
            }}
          />
        </div>
      ) : null}
      <CustomLinkify>
        <span
          className={!showFullContent ? "TextOverflowEllipsis" : ""}
          style={{
            color: disableCopy ? theme.SubText.color : theme.PrimaryText.color,
            fontSize: 14,
            ...textStyles,
          }}
        >
          {text}
        </span>
      </CustomLinkify>
    </div>
  );
};

type EventDashboardExternalLinkCopyProps = {
  copyText: string;
  onCopyPress?: () => void;
  altTextColor?: string;
  containerStyles?: CSSProperties;
};

// TODO: give this a better name since it's now used on MarkitPlusNumberModal too
export const EventDashboardExternalLinkCopy = memo(
  function EventDashboardExternalLinkCopyFn(
    props: EventDashboardExternalLinkCopyProps
  ) {
    const { copyText, onCopyPress, altTextColor, containerStyles } = props;
    const dispatch = useDispatch();
    const [copyOnHover, setCopyOnHover] = useState(false);

    const copyExternalLink = useCallback(async () => {
      if (onCopyPress) {
        onCopyPress();
      }
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          showNotificationBanner(
            dispatch,
            "Copied!",
            NotificationType.AFFIRMATIVE
          );
        })
        .catch((err) => {
          alert(err);
        });
    }, [copyText, dispatch, onCopyPress]);

    return (
      <StandardBorderedContainer containerStyles={containerStyles}>
        <div style={{ width: "100%", gap: 7 }} className="AlignedRowSpaced">
          <span
            className="TextOverflowEllipsis"
            style={{ fontSize: 14, color: altTextColor ?? Colors.GRAY1 }}
          >
            {copyText}
          </span>
          <div
            onMouseEnter={() => setCopyOnHover(true)}
            onMouseLeave={() => setCopyOnHover(false)}
            onClick={copyExternalLink}
            className="AlignedRowSelect"
            style={{ gap: 4, borderRadius: 10 }}
          >
            <Icon icon={"ion:copy-outline"} height={14} color={Colors.BLUE5} />
            <span style={{ color: Colors.BLUE5, fontSize: 16 }}>Copy</span>
          </div>
        </div>
      </StandardBorderedContainer>
    );
  }
);
