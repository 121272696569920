import { ReactNode } from "react";
import LargePopupModalContainer from "../../Containers/LargePopupModalContainer";
import { Colors } from "../../../utils/colors";
import { MassText } from "@markit/common.types";

export enum MassTextsWrapperType {
  MODAL = "modal",
  SIDE_PANEL = "sidePanel",
  SIDE_PANEL_PREVIEW = "sidePanelPreview",
}

export type MassTextSelectedType = {
  massText: MassText | undefined; // undefined means new text, defined means existing
  isVisible: boolean; // whether to show the text panel or not
  isDuplicateText?: boolean; // needed another state to check if duplicating text
};

type MassTextWrapperManagerProps = {
  wrapperType: MassTextsWrapperType;
  children: ReactNode;
};

const MassTextWrapperManager = (props: MassTextWrapperManagerProps) => {
  const { wrapperType, children } = props;

  switch (wrapperType) {
    case MassTextsWrapperType.MODAL:
      return (
        <LargePopupModalContainer
          showModal={true}
          containerStyles={{ padding: 0, height: "100%" }}
          valueComp={children}
        />
      );
    case MassTextsWrapperType.SIDE_PANEL:
    case MassTextsWrapperType.SIDE_PANEL_PREVIEW:
      return (
        <div
          className="PreventScroll"
          style={{ flex: 1, backgroundColor: Colors.WHITE }}
        >
          {children}
        </div>
      );
  }
};

export default MassTextWrapperManager;
