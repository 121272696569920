import "../../css/Subscription.css";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  MARKIT_HOTLINE_NUMBER,
  hasSubscription,
  mostRecentSubscription,
  mostRecentSubscriptionPlan,
} from "@markit/common.utils";
import { testStripe } from "../../components/FullEvent/FullEventInputForm";
import { API } from "../../API";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { isDesktop } from "react-device-detect";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import { MixpanelContext } from "../../context/AnalyticsService";
import {
  PlanTypeV4,
  SubscriptionPlanType,
  subscriptionPricesV4,
  testSubscriptionPricesV4,
} from "@markit/common.types";
import { useNavigate } from "../../hooks/useNavigate";
import SubscriptionPlanCard from "../../components/Subscription/SubscriptionPlanCard";
import RectangleButton from "../../components/Buttons/RectangleButton";
import SubscriptionCheckout from "../../components/Subscription/SubscriptionCheckout";
import SubscriptionReceipt from "../../components/Subscription/SubscriptionReceipt";
import Footer from "../../components/Footer";
import ConfirmActionModal from "../../components/Containers/ConfirmPopups/ConfirmActionModal";
import { FlatMobileTabs } from "../../components/Tabs/FlatMobileTabs";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";
import TopHeader from "../../components/TopHeader";

export enum SubscriptionStage {
  OVERVIEW = "Overview",
  CHECKOUT = "Checkout",
  RECEIPT = "Receipt",
}

type SubscriptionPlanProps = {};

const SubscriptionPlan = memo(function SubscriptionPlanFn(
  props: SubscriptionPlanProps
) {
  const navigate = useNavigate();
  const mixpanel = useContext(MixpanelContext);
  const { changePlan } = useLocation().state || {};
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn, appInitialized } = account;
  const [selectedCardOption, setSelectedCardOption] = useState<number>(1);
  const [mostRecentPriceAmount, setMostRecentPriceAmount] = useState<number>(0);
  const [email, setEmail] = useState<string>(accountData.email);
  const [loading, setLoading] = useState(true);
  const [subscriptionStage, setSubscriptionStage] = useState<SubscriptionStage>(
    SubscriptionStage.OVERVIEW
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const mostRecentSubPlan = useMemo(
    () => mostRecentSubscriptionPlan(accountData) ?? PlanTypeV4.FREE,
    [accountData]
  );

  useEffect(() => {
    if (!appInitialized) {
      return;
    }
    if (loggedIn !== LoginState.LOGGED_IN) {
      navigate("/welcome");
    }
    if (loading) {
      const subscription = mostRecentSubscription(accountData);
      if (subscription) {
        const mostRecentPrice = subscription.price[0];
        if (
          mostRecentPrice.id === PlanTypeV4.ESSENTIALS.priceId ||
          mostRecentPrice.id === PlanTypeV4.ESSENTIALS.testPriceId
        ) {
          setSelectedCardOption(1);
        } else if (
          mostRecentPrice.id === PlanTypeV4.PROFESSIONAL.priceId ||
          mostRecentPrice.id === PlanTypeV4.PROFESSIONAL.testPriceId
        ) {
          setSelectedCardOption(2);
        } else {
          setSelectedCardOption(3);
        }
        setMostRecentPriceAmount(mostRecentPrice.amount);
      }
    }
    setLoading(false);
  }, [accountData, appInitialized, loading, loggedIn, navigate]);

  const subscriptionCard = useMemo(
    () =>
      selectedCardOption === 1
        ? PlanTypeV4.ESSENTIALS
        : selectedCardOption === 2
        ? PlanTypeV4.PROFESSIONAL
        : selectedCardOption === 3
        ? PlanTypeV4.BUSINESS
        : PlanTypeV4.FREE,
    [selectedCardOption]
  );

  const renderButtonTitle = useCallback(
    (plan: SubscriptionPlanType) => {
      if (changePlan) {
        if (mostRecentPriceAmount > plan.price) {
          return "Downgrade to " + plan.name;
        } else if (mostRecentPriceAmount < plan.price) {
          return "Upgrade to " + plan.name;
        } else {
          return "Current Plan";
        }
      } else {
        if (plan === mostRecentSubPlan) {
          return "Current Plan";
        } else {
          return (
            "Continue with " + (selectedCardOption === 0 ? "Free" : plan.name)
          );
        }
      }
    },
    [changePlan, mostRecentPriceAmount, mostRecentSubPlan, selectedCardOption]
  );

  const upgradeSubscription = useCallback(async () => {
    try {
      setLoading(true);
      if (testStripe) {
        const { id, overflow } =
          testSubscriptionPricesV4[selectedCardOption - 1];
        await API.user.updateTestSubscription(accountData.uid, {
          action: "upgrade",
          baseline: id,
          overflow: overflow,
        });
      } else {
        const { id, overflow } = subscriptionPricesV4[selectedCardOption - 1];
        await API.user.updateSubscription(accountData.uid, {
          action: "upgrade",
          baseline: id,
          overflow: overflow,
        });
      }
    } catch (err: any) {
      alert(
        "There was an error changing your subscription. Please try again later"
      );
      console.log(err.message);
    } finally {
      setLoading(false);
      navigate("/home");
    }
  }, [accountData.uid, navigate, selectedCardOption]);

  /**
   * @TODO - Subscripton updating probably should not be here.
   */
  const subscribe = useCallback(
    async (plan: SubscriptionPlanType, cardOption: number) => {
      if (loading) {
        return;
      }
      if (hasSubscription(accountData) && !changePlan) {
        alert("You already have a subscription!");
        return;
      }
      const action = changePlan
        ? mostRecentPriceAmount > plan.price
          ? "downgrade"
          : "upgrade"
        : "";
      // TODO (Peter): Do not allow manual downgrades, until behavior is verified
      if (action === "downgrade") {
        alert(
          `To downgrade your subscription plan, please contact us at ${formatPhoneNumber(
            MARKIT_HOTLINE_NUMBER
          )}. Thank you.`
        );
        return;
      }
      // if upgrading/downgrading plan
      if (action !== "") {
        setSelectedCardOption(cardOption);
        setShowConfirmModal(true);
      } else {
        if (selectedCardOption === 0) {
          mixpanel.track("Webapp: Pressed Subscription Plan Option Free", {
            distinct_id: accountData.uid,
          });
          navigate("/home");
        } else {
          // normal subscription checkout
          mixpanel.track("Webapp: Pressed Subscription Plan Option", {
            distinct_id: accountData.uid,
            subscriptionPlan: testStripe
              ? subscriptionCard.testPriceId
              : subscriptionCard.priceId,
            subscriptionPrice: subscriptionCard.price,
          });
          setSelectedCardOption(cardOption);
          setSubscriptionStage(SubscriptionStage.CHECKOUT);
        }
      }
    },
    [
      loading,
      accountData,
      changePlan,
      mostRecentPriceAmount,
      selectedCardOption,
      navigate,
      mixpanel,
      subscriptionCard.testPriceId,
      subscriptionCard.priceId,
      subscriptionCard.price,
    ]
  );

  const renderContent = (
    <>
      {subscriptionStage === SubscriptionStage.RECEIPT ? (
        <SubscriptionReceipt
          subscriptionPlan={subscriptionCard}
          email={email}
        />
      ) : subscriptionStage === SubscriptionStage.CHECKOUT ? (
        <SubscriptionCheckout
          subscriptionPlan={subscriptionCard}
          setSubscriptionStage={setSubscriptionStage}
          email={email}
          setEmail={setEmail}
        />
      ) : (
        <div className="ColumnNormal">
          <div
            className="ColumnCentering"
            style={{
              gap: 24,
              paddingInline: 14,
              paddingBlock: isDesktop ? 48 : 0,
            }}
          >
            <span style={{ fontSize: 32, fontWeight: 500 }}>Select a Plan</span>
            <span
              style={{
                color: Colors.GRAY1,
                fontSize: 14,
                fontWeight: 500,
                textAlign: "center",
                paddingInline: 24,
              }}
            >
              <span style={{ color: Colors.BLACK }}>
                Markit<span style={{ color: Colors.BLUE5 }}>+</span>
              </span>{" "}
              offers additional features, texting, and 24/7 assistance.
              {isDesktop ? <br /> : " "}
              For more texting or questions, contact our sales team at{" "}
              {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}.
            </span>
            {!isDesktop ? (
              <FlatMobileTabs
                tabValue={selectedCardOption}
                setTabValue={setSelectedCardOption}
                tabNames={[
                  PlanTypeV4.FREE.name,
                  PlanTypeV4.ESSENTIALS.name,
                  PlanTypeV4.PROFESSIONAL.name,
                  PlanTypeV4.BUSINESS.name,
                ]}
                spacing={16}
                containerStyles={{ paddingLeft: 10, marginTop: 14 }}
              />
            ) : null}
          </div>
          <div
            className="ColumnNormal HideScrollbar"
            style={{
              backgroundColor: Colors.WHITE1,
              padding: isDesktop
                ? "30px 80px 80px 80px"
                : "24px 14px 24px 14px",
            }}
          >
            {isDesktop ? (
              <div className="AlignedRow" style={{ gap: 24 }}>
                {[
                  PlanTypeV4.FREE,
                  PlanTypeV4.ESSENTIALS,
                  PlanTypeV4.PROFESSIONAL,
                  PlanTypeV4.BUSINESS,
                ].map((subscriptionPlan, index) => (
                  <div style={{ flex: 1 }} key={index}>
                    <SubscriptionPlanCard
                      cardButton={
                        <RectangleButton
                          buttonLabel={renderButtonTitle(subscriptionPlan)}
                          onPress={() => subscribe(subscriptionPlan, index)}
                          altColor={
                            mostRecentSubPlan.name === subscriptionPlan.name
                              ? Colors.GRAY6
                              : Colors.BLACK
                          }
                          disabled={
                            mostRecentSubPlan.name === subscriptionPlan.name
                          }
                        />
                      }
                      subscriptionPlan={subscriptionPlan}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <SubscriptionPlanCard
                cardButton={
                  <RectangleButton
                    buttonLabel={renderButtonTitle(subscriptionCard)}
                    onPress={() =>
                      subscribe(subscriptionCard, selectedCardOption)
                    }
                    altColor={
                      mostRecentSubPlan.name === subscriptionCard.name
                        ? Colors.GRAY6
                        : Colors.BLACK
                    }
                    disabled={mostRecentSubPlan.name === subscriptionCard.name}
                    altPaddingHorz={14}
                    altPaddingVert={14}
                  />
                }
                subscriptionPlan={subscriptionCard}
              />
            )}
            <div className="ColumnCentering" style={{ marginBlock: 24 }}>
              <span
                style={{
                  color: Colors.GRAY1,
                  fontSize: 14,
                  textAlign: "center",
                  paddingInline: 48,
                }}
              >
                For enterprise or other questions contact sales at{" "}
                <span style={{ color: Colors.BLACK }}>
                  {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
                </span>
              </span>
            </div>
          </div>
        </div>
      )}
      {!isDesktop ? <Footer /> : null}
    </>
  );

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <>
      {isDesktop ? (
        <CreatorModeWrapper hideSidebar>{renderContent}</CreatorModeWrapper>
      ) : (
        <div className="ColumnNormal">
          <TopHeader hideCreate />
          {renderContent}
          <Footer />
        </div>
      )}
      <ConfirmActionModal
        heading={"Upgrade Subscription?"}
        subtext={
          "Are you sure you want to upgrade your subscription? This cannot be undone."
        }
        confirmButtonText="Yes"
        hideModal={!showConfirmModal}
        setIsVisible={setShowConfirmModal}
        confirmOnPress={upgradeSubscription}
      />
    </>
  );
});

export default SubscriptionPlan;
