import { m } from "framer-motion";
import { Colors } from "../../../utils/colors";
import { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changeHideProfileEvents,
  changeProfilePasswordProtect,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import FormBuilderWrapper from "../../Form/FormBuilderWrapper";
import PreviewBoxContainer from "../../Containers/PreviewBoxContainer";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { useTheme } from "../../../hooks/useTheme";
import SwitchToggleItem from "../../SwitchToggleItem";
import RectangleButton from "../../Buttons/RectangleButton";
import CustomTextField from "../../CustomTextField";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import { onCampaignNavigatePath } from "../../../utils/navigationUtils";
import { showNotificationBanner } from "../../../utils/notificationUtils";
import { Campaign, MassText, NotificationType } from "@markit/common.types";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";
import { fetchSubscribeCampaignData } from "../../../utils/campaignUtils";

export type ShareProfilePanelProps = {
  setIsVisible: (isVisible: boolean) => void;
  setIsProfileVisible?: (isProfileVisible: boolean) => void;
};

const ShareProfilePanel = (props: ShareProfilePanelProps) => {
  const { setIsVisible, setIsProfileVisible } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const [profilePassword, setProfilePassword] = useState(
    accountData.profilePasswordProtect
  );
  const [followCampaign, setFollowCampaign] = useState<Campaign>();
  const [followMassText, setFollowMassText] = useState<MassText>();
  const [loading, setLoading] = useState(true);

  const styles = {
    headerText: { fontSize: 14, fontWeight: "500" },
    subtext: { fontSize: 12, color: Colors.GRAY1 },
    sectionContainer: {
      padding: 14,
      borderRadius: 12,
      backgroundColor: Colors.GRAY6,
    },
  };

  useAsyncOnMount(async () => {
    const { campaign, massText } = await fetchSubscribeCampaignData(
      accountData.uid
    );
    setFollowCampaign(campaign);
    setFollowMassText(massText);
    setLoading(false);
  });

  const disableSetPassword = useMemo(
    () =>
      !profilePassword ||
      profilePassword === accountData.profilePasswordProtect,
    [accountData.profilePasswordProtect, profilePassword]
  );

  const editFollowText = useCallback(() => {
    if (followCampaign) {
      if (pathname === onCampaignNavigatePath(followCampaign.id)) {
        setIsVisible(false);
        if (setIsProfileVisible) {
          setIsProfileVisible(false);
        }
      } else {
        navigate(onCampaignNavigatePath(followCampaign.id), {
          state: { campaignId: followCampaign.id },
        });
      }
    } else {
      alert("Unable to edit the follow text at this time.");
    }
  }, [followCampaign, navigate, pathname, setIsProfileVisible, setIsVisible]);

  const toggleHideProfileEvents = useCallback(() => {
    dispatch(
      changeHideProfileEvents(accountData.uid, !accountData.hideProfileEvents)
    );
  }, [accountData.hideProfileEvents, accountData.uid, dispatch]);

  const changeRequirePassword = useCallback(() => {
    dispatch(changeProfilePasswordProtect(accountData.uid, profilePassword));
    showNotificationBanner(
      dispatch,
      "Password Changed!",
      NotificationType.AFFIRMATIVE
    );
  }, [accountData.uid, dispatch, profilePassword]);

  const togglePasswordProtectProfile = useCallback(() => {
    const password = !profilePassword ? "Password" : "";
    dispatch(changeProfilePasswordProtect(accountData.uid, password));
    setProfilePassword(password);
  }, [accountData.uid, dispatch, profilePassword]);

  return (
    <m.div
      key="masstextstextsummary"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, type: "tween" }}
      className="HideScrollbar ColumnNormal"
      style={{
        height: "calc(100vh - 120px)",
        overflow: "scroll",
        gap: 14,
      }}
    >
      <div className="ColumnNormal" style={{ gap: 7 }}>
        <span className="AboutSubtitle" style={{ fontSize: 18 }}>
          Share Profile
        </span>
        <span style={styles.subtext}>
          Customize the experience of following your profile and collect info
          upon follow.
        </span>
      </div>
      <PreviewBoxContainer
        message={
          followMassText?.message ??
          "Unable to display the follow text at this time."
        }
        containerStyles={{
          ...styles.sectionContainer,
          border: `1.5px solid ${theme.DividerColor.borderColor}`,
        }}
        headerText={"Follow Text"}
        subHeaderComp={
          <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
            Sent when someone follows you. Edit in Automations.
          </span>
        }
        loading={loading}
        headerRightComp={
          <RectangleButton
            buttonLabel="Edit"
            onPress={editFollowText}
            altBorderRadius={100}
            altPaddingHorz={14}
            altPaddingVert={10}
            containerStyles={{ border: `1px solid ${Colors.GRAY2}` }}
            altColor={Colors.WHITE}
            altTextColor={Colors.BLACK}
            iconRight={
              <Icon
                icon={"ion:arrow-forward"}
                height={16}
                style={{ transform: "rotateY(0deg) rotate(-45deg)" }}
              />
            }
          />
        }
      />
      <StandardBorderedContainer containerStyles={styles.sectionContainer}>
        <FormBuilderWrapper isProfileQuestions hostId={accountData.uid} />
      </StandardBorderedContainer>
      <StandardBorderedContainer containerStyles={styles.sectionContainer}>
        <SwitchToggleItem
          title="Hide Events Section"
          description="Hide the events section of your profile"
          toggleValue={accountData.hideProfileEvents}
          onChange={toggleHideProfileEvents}
        />
      </StandardBorderedContainer>
      <StandardBorderedContainer
        containerStyles={{ ...styles.sectionContainer, paddingInline: 0 }}
      >
        <div
          className="AlignedRowSpaced"
          style={{ gap: 14, paddingInline: 14 }}
        >
          <SwitchToggleItem
            title="Password Protect Profile"
            description="Require a password to view events and links displayed on your
              profile."
            toggleValue={accountData.profilePasswordProtect !== ""}
            onChange={togglePasswordProtectProfile}
          />
        </div>
        {accountData.profilePasswordProtect !== "" ? (
          <>
            <HorizontalDivider altMargin={18} />
            <div className="ColumnNormal" style={{ gap: 7, paddingInline: 14 }}>
              <span style={{ fontSize: 12, fontWeight: 500 }}>
                Set Password
              </span>
              <div className="AlignedRow" style={{ gap: 10 }}>
                <CustomTextField
                  value={profilePassword}
                  placeholder="password"
                  inputMode="text"
                  borderRadius={12}
                  noAutocomplete
                  backgroundColor={Colors.WHITE}
                  onChange={(change: any) => {
                    setProfilePassword(change.target.value);
                  }}
                  altMarginBottom={0}
                  altPadding="10px 10px"
                />
                <RectangleButton
                  buttonLabel="Set Password"
                  onPress={changeRequirePassword}
                  altColor={Colors.BLACK}
                  altTextColor={Colors.WHITE}
                  altPaddingVert={12}
                  width={220}
                  containerStyles={{
                    pointerEvents: disableSetPassword ? "none" : "all",
                    opacity: disableSetPassword ? 0.3 : 1,
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
      </StandardBorderedContainer>
    </m.div>
  );
};

export default ShareProfilePanel;
