import { useCallback, useMemo } from "react";
import { Campaign, Event, MassText } from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import { CampaignMassTextItemPreview } from "../../DisplayItem/CampaignItem/CampaignMassTextItemPreview";
import { VerticalDivider } from "../../../components/Dividers/VerticalDivider";
import { dateIsCurrentYear, GetTime } from "@markit/common.utils";
import { MassTextDetails } from "../../../redux/slices/campaignSlice";
import { GetLongDate } from "../../../utils/GetLongDate";
import { MassTextSelectedType } from "../MassTexts/MassTextWrapperManager";

type TimelineItem = {
  id: string;
  label: string;
  timestamp: string;
};

type CampaignFlowTimelineProps = {
  campaign: Campaign;
  sortedMassTextDetails: MassTextDetails[];
  selectedTextType: MassTextSelectedType;
  setSelectedTextType: (selectedType: MassTextSelectedType) => void;
  duplicateTextOnPress: (massText: MassText) => void;
  setTriggerItemSelected: (triggerItemSelected: boolean) => void;
  foundEvent: Event | undefined;
};

const CampaignFlowTimeline = (props: CampaignFlowTimelineProps) => {
  const {
    campaign,
    sortedMassTextDetails,
    selectedTextType,
    setSelectedTextType,
    duplicateTextOnPress,
    setTriggerItemSelected,
    foundEvent,
  } = props;

  const renderVerticalLine = useMemo(
    () => (
      <div style={{ paddingBlock: 4 }}>
        <VerticalDivider height={30} color={Colors.GRAY2} />
      </div>
    ),
    []
  );

  // Adds the Start and End labels if event trigger campaign
  const sortedTimelineToShow: (MassTextDetails | TimelineItem)[] =
    useMemo(() => {
      const timelineItems = foundEvent
        ? [
            ...sortedMassTextDetails,
            { id: "start", label: "Event Start", timestamp: foundEvent.start },
            { id: "end", label: "Event End", timestamp: foundEvent.end },
          ]
        : sortedMassTextDetails;
      return timelineItems.sort((a, b) => {
        const firstSentAt =
          "massText" in a ? a.massText.sentAt : (a as TimelineItem).timestamp;
        const secondSentAt =
          "massText" in b ? b.massText.sentAt : (b as TimelineItem).timestamp;
        return (
          new Date(firstSentAt).getTime() - new Date(secondSentAt).getTime()
        );
      });
    }, [foundEvent, sortedMassTextDetails]);

  const initialMassText = useMemo(
    () => sortedMassTextDetails[0].massText,
    [sortedMassTextDetails]
  );

  const isSelectedText = useCallback(
    (massTextId: string) =>
      selectedTextType.massText && selectedTextType.massText.id === massTextId,
    [selectedTextType.massText]
  );

  const viewTextOnPress = useCallback(
    (text: MassText) => {
      setSelectedTextType({
        massText: text,
        isVisible: true,
      });
      setTriggerItemSelected(false);
    },
    [setSelectedTextType, setTriggerItemSelected]
  );

  return (
    <div>
      {sortedTimelineToShow.map((item, index) => {
        const isLabelItem =
          (item as TimelineItem).id === "start" ||
          (item as TimelineItem).id === "end";
        return (
          <div className="ColumnCentering" key={(item as any).id}>
            {isLabelItem ? (
              <div className="AlignedRow" style={{ gap: 7, paddingBlock: 4 }}>
                <span className="bodyMedium">
                  {(item as TimelineItem).label}
                </span>
                <span className="bodySubtext">
                  {`${GetLongDate(
                    (item as TimelineItem).timestamp,
                    true,
                    true,
                    true,
                    !dateIsCurrentYear((item as TimelineItem).timestamp)
                  )}, ${GetTime(new Date((item as TimelineItem).timestamp))} `}
                </span>
              </div>
            ) : (
              <div
                style={{
                  border: isSelectedText((item as MassTextDetails).massText.id)
                    ? "2px solid black"
                    : "",
                  borderRadius: 18,
                  padding: isSelectedText((item as MassTextDetails).massText.id)
                    ? 4
                    : 0,
                }}
              >
                <CampaignMassTextItemPreview
                  massTextDetail={item as MassTextDetails}
                  campaign={campaign}
                  viewOnPress={() =>
                    viewTextOnPress((item as MassTextDetails).massText)
                  }
                  duplicateOnPress={duplicateTextOnPress}
                  numCampaignTexts={sortedMassTextDetails.length}
                  campaignInitialText={initialMassText}
                />
              </div>
            )}
            {sortedTimelineToShow.length - 1 !== index
              ? renderVerticalLine
              : null}
          </div>
        );
      })}
    </div>
  );
};

export default CampaignFlowTimeline;
