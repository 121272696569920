import { Colors } from "../../../../utils/colors";
import "../../../../css/MassTexts.css";
import React, { useCallback, useMemo, useState } from "react";
import {
  AccountData,
  MassText,
  MassTextAttachment,
  TrackingOverview,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import {
  getUserCampaignData,
  getUserData,
} from "../../../../utils/FirebaseUtils";
import { getEventState } from "../../../../redux/slices/eventSlice";
import { useLoadTextRecipients } from "../../../../hooks/useLoadTextRecipients";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import filter from "lodash.filter";
import FlatList from "flatlist-react/lib";
import { CircularProgress } from "@mui/material";
import {
  handlePluralString,
  isEventExternalLink,
  objectToMap,
} from "@markit/common.utils";
import { API } from "../../../../API";
import { getNumMassTextRecipients } from "../../../../utils/textingUtils";
import { MassTextLinkAnalyticsItem } from "../Items/MassTextLinkAnalyticsItem";
import { EmptyStateFlatlist } from "../../../EmptyStates/EmptyStateFlatlist";
import { getMassTextAttachmentsLinkAnalytics } from "../../../../utils/trackingUtils";
import useAsyncOnMount from "../../../../hooks/useAsyncEffectOnMount";
import { useOnMount } from "../../../../hooks/useOnMount";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";

type MassTextsLinkAnalyticsProps = {
  massText: MassText;
  massTextAttachment: MassTextAttachment;
};

const MassTextsLinkAnalytics = (props: MassTextsLinkAnalyticsProps) => {
  const { massText, massTextAttachment } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const { events } = useSelector(getEventState);
  const { events: userEvents } = events;
  const [numClicks, setNumClicks] = useState(0);
  const [numConversions, setNumConversions] = useState(0);
  const [openRate, setOpenRate] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRecipient, setSelectedRecipient] = useState<AccountData>();
  const [loadingAnalytics, setLoadingAnalytics] = useState(true);

  const styles = {
    rowContainer: { paddingInline: 14, gap: 14 },
  };

  const {
    isFinished,
    isLoading,
    fetchedUserData,
    fetchedTextRecipients,
    loadTextRecipients,
    loadMoreTextRecipients,
    loadSearchResultsThrottled,
  } = useLoadTextRecipients({
    userId: massText.sentBy,
    massTextId: massText.id,
    campaignId: massText.campaignId,
    attachmentId: massTextAttachment.id,
    windowSize: 30,
  });

  useAsyncOnMount(async () => {
    let finalUserData: AccountData = accountData;
    // for viewing other user's texting analytics
    if (massText.sentBy !== accountData.uid) {
      const userData = await getUserData(massText.sentBy);
      if (userData) {
        finalUserData = userData;
      }
    }

    const campaign = await getUserCampaignData(
      finalUserData.uid,
      massText.campaignId
    );
    if (campaign) {
      const numRecipients = await getNumMassTextRecipients(
        finalUserData.uid,
        campaign,
        massText.id
      );

      const { numOpens, numConversions } =
        await getMassTextAttachmentsLinkAnalytics(
          finalUserData.uid,
          massText,
          massTextAttachment
        );
      setNumClicks(numOpens);
      setNumConversions(numConversions);
      const responses = await Promise.all([
        API.tracking.getAttachmentOpenRate({
          userId: finalUserData.uid,
          massTextId: massText.id,
          campaignId: massText.campaignId,
          attachmentId: massTextAttachment.id,
          numRecipients: numRecipients,
        }),
        API.tracking.getAttachmentConversionRate({
          userId: finalUserData.uid,
          massTextId: massText.id,
          campaignId: massText.campaignId,
          attachmentId: massTextAttachment.id,
          numRecipients: numRecipients,
        }),
      ]);
      setOpenRate(responses[0].totalNum);
      setConversionRate(responses[1].totalNum);
    }
    setLoadingAnalytics(false);
  });

  useOnMount(() => {
    loadTextRecipients();
  });

  const contains = useCallback((user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
    loadSearchResultsThrottled(text.toLowerCase());
  };

  const itemsToShow: AccountData[] = useMemo(() => {
    let userList: AccountData[] = [];
    userList = fetchedUserData;
    if (searchTerm !== "") {
      userList = filter(userList, (user: AccountData) => {
        return contains(user, searchTerm);
      });
    }
    return userList;
  }, [fetchedUserData, searchTerm, contains]);

  const renderEventItemPreview = useCallback(
    (attachment: MassTextAttachment) => {
      const foundEvent = userEvents.find(
        (event) => event.id === attachment.eventId
      );
      return (
        <div className="AlignedRow" style={{ gap: 8 }}>
          <img
            src={foundEvent?.photoURL ?? ""}
            alt={"LinkAnalyticsEventPic"}
            style={{
              width: 40,
              height: 40,
              borderRadius: 8,
            }}
          />
          <div className="ColumnNormal">
            <span style={{ fontSize: 14, fontWeight: "500" }}>
              {attachment.massTextType}
            </span>
            <span
              style={{
                fontSize: 14,
                color: Colors.GRAY2,
              }}
            >
              {foundEvent?.title ?? "Markit Event"}
            </span>
          </div>
        </div>
      );
    },
    [userEvents]
  );

  const renderEmptyList = useMemo(
    () => (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isLoading}
        nonSearchEmptyView={
          <CircularProgress
            style={{ color: "#929292", alignSelf: "center" }}
            size={20}
          />
        }
        containerStyles={{ paddingTop: 60 }}
      />
    ),
    [isLoading, searchTerm]
  );

  const foundEvent = useMemo(
    () => userEvents.find((event) => event.id === massTextAttachment.eventId),
    [massTextAttachment.eventId, userEvents]
  );

  const hideConversions = useMemo(
    () => foundEvent && isEventExternalLink(foundEvent.eventType),
    [foundEvent]
  );

  return (
    <div className="ColumnNormal" style={{ height: "100%", padding: 20 }}>
      <StandardBorderedContainer
        containerStyles={{ paddingBlock: 14, backgroundColor: Colors.WHITE1 }}
      >
        <div style={styles.rowContainer} className="ColumnNormal">
          {renderEventItemPreview(massTextAttachment)}
        </div>
        <HorizontalDivider altMargin={14} />
        <div style={styles.rowContainer} className="AlignedRow">
          <div className="AlignedRow" style={{ gap: 7 }}>
            <span className="sectionTitle">
              {loadingAnalytics ? "--" : numClicks}
            </span>
            <span className="bodySubtext">
              {handlePluralString("Click", numClicks)}
            </span>
          </div>
          <div className="AlignedRow" style={{ gap: 7 }}>
            <span className="sectionTitle">
              {loadingAnalytics ? "--" : openRate + "%"}
            </span>
            <span className="bodySubtext">Click Rate</span>
          </div>
        </div>
        {!hideConversions ? (
          <>
            <HorizontalDivider altMargin={14} />
            <div style={styles.rowContainer} className="AlignedRow">
              <div className="AlignedRow" style={{ gap: 7 }}>
                <span className="sectionTitle">
                  {loadingAnalytics ? "--" : numConversions}
                </span>
                <span className="bodySubtext">
                  {handlePluralString("Conversion", numConversions)}
                </span>
              </div>
              <div className="AlignedRow" style={{ gap: 7 }}>
                <span className="sectionTitle">
                  {loadingAnalytics ? "--" : conversionRate + "%"}
                </span>
                <span className="bodySubtext">Conversion Rate</span>
              </div>
            </div>
          </>
        ) : null}
      </StandardBorderedContainer>
      <SearchBoxContainer
        placeholder="Search"
        onChange={(e) => handleSearch(e.target.value)}
        value={searchTerm}
        containerStyles={{ backgroundColor: Colors.WHITE1, marginTop: 20 }}
      />
      <div
        className="HideScrollbar"
        style={{
          height: "100%",
          overflowY: "scroll",
          marginTop: 14,
          paddingBottom: 100,
        }}
      >
        <FlatList
          list={itemsToShow}
          renderItem={(user) => {
            const textRecipientData = fetchedTextRecipients.find(
              (recipient) => recipient.uid === user.uid
            );
            const trackingOverview: TrackingOverview = objectToMap(
              textRecipientData!.trackingOverviews
            ).get(massTextAttachment.id);
            return (
              <MassTextLinkAnalyticsItem
                item={user}
                onPress={() => {
                  setSelectedRecipient(
                    selectedRecipient && selectedRecipient.uid === user.uid
                      ? undefined
                      : user
                  );
                }}
                massText={massText}
                attachmentId={massTextAttachment.id}
                isSelected={
                  selectedRecipient ? selectedRecipient.uid === user.uid : false
                }
                trackingOverview={trackingOverview}
                hideConversions={hideConversions}
              />
            );
          }}
          renderWhenEmpty={() => renderEmptyList}
          hasMoreItems={!isFinished}
          loadMoreItems={() => {
            if (searchTerm === "") {
              loadMoreTextRecipients();
            }
          }}
          paginationLoadingIndicator={() =>
            isLoading && !isFinished ? (
              <CircularProgress
                style={{ color: "#929292", alignSelf: "center" }}
                size={20}
              />
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default MassTextsLinkAnalytics;
