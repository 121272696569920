import React from "react";
import CustomCheckbox from "../CustomCheckbox";
import { Colors } from "../../utils/colors";
import {
  MARKIT_PRIVACY_POLICY,
  MARKIT_TERMS_OF_USE,
} from "@markit/common.utils";

type ComplianceConsentTermsProps = {
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  preText: string;
};

// Compliance checkbox for a user when they first want to upload contacts form an outside source
const ComplianceConsentTerms = (props: ComplianceConsentTermsProps) => {
  const { isChecked, setIsChecked, preText } = props;

  return (
    <div className="AlignedRow" style={{ paddingBlock: 14, gap: 14 }}>
      <CustomCheckbox
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        sx={{ padding: 0 }}
        altColor={Colors.BLUE5}
      />
      <span className="smallBodySubtext">
        {preText} that you have obtained explicit consent from all the
        individuals whose phone numbers you are uploading and that you have
        reviewed our{" "}
        <a
          href={MARKIT_TERMS_OF_USE}
          target="_blank"
          rel="noreferrer"
          style={{ textDecorationLine: "underline", color: Colors.BLACK }}
        >
          Terms of Use
        </a>{" "}
        and{" "}
        <a
          href={MARKIT_PRIVACY_POLICY}
          target="_blank"
          rel="noreferrer"
          style={{ textDecorationLine: "underline", color: Colors.BLACK }}
        >
          Privacy Policy
        </a>{" "}
        again, paying close attention to Terms of Use section 2.6 Upload Legal
        Notice.
      </span>
    </div>
  );
};

export default ComplianceConsentTerms;
