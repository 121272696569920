import { API } from "../API";
import { Mixpanel } from "../context/AnalyticsService";
import {
  Campaign,
  DefaultLinkTrackerName,
  Event,
  MassText,
  MassTextAttachment,
} from "@markit/common.types";
import { getCountFromServer, query, where } from "../firebase";
import { getUserEventLinkTrackersRef } from "./FirebaseUtils";
import { LessThanDate } from "@markit/common.utils";
import { MassTextDetails } from "../redux/slices/campaignSlice";

/**
 * Handles the link open for all types of link tracking provided the linkId.
 * Called when the user views the event link with the link id attached.
 * 1. Mass Text Link Tracking:
 *    Updates the specified user in the link data their TextRecipient open tracking.
 * 2. Event Link Tracking:
 *    Updates the EventLinkTracker related to the link and adds an open action.
 */
export const updateLinkTrackerOpens = async (
  eventId: string,
  eventUserId: string,
  linkId: string
) => {
  try {
    if (linkId !== "") {
      await API.tracking.updateLinkTrackerOpens({
        eventId: eventId,
        eventUserId: eventUserId,
        linkId: linkId,
      });
    }
  } catch (err: any) {
    console.error(err.message);
  }
};

/**
 * Increments the num opens field on the default markit event tracker used for
 * tracking an event's total link views.
 */
export const updateTotalEventLinkViews = async (
  eventId: string,
  eventUserId: string
) => {
  try {
    await API.tracking.updateTotalEventLinkViews({
      eventId: eventId,
      eventUserId: eventUserId,
    });
  } catch (err: any) {
    console.error(err.message);
  }
};

// callback function for generating / saving changes to tracking links
export const generateTrackingLinksOnPress = async (
  event: Event,
  linkTrackerId: string | undefined,
  trackingLinkModalText: string,
  mixpanel: Mixpanel,
  userId: string
) => {
  if (linkTrackerId) {
    await API.tracking
      .updateEventLinkTracker({
        userId: event.createdBy,
        eventId: event.id,
        linkTrackerId: linkTrackerId,
        newTitle: trackingLinkModalText,
      })
      .then(() => {
        mixpanel.track("Edit Event Link Tracker", {
          new_link_name: trackingLinkModalText,
          event_id: event.id,
        });
      });
  } else {
    await API.tracking
      .generateNewEventLinkTracker({
        title: trackingLinkModalText,
        eventUserId: event.createdBy,
        eventId: event.id,
        sentBy: userId,
      })
      .then(() => {
        mixpanel.track("Generate New Event Link Tracker", {
          link_name: trackingLinkModalText,
          event_id: event.id,
        });
      });
  }
};

// gets the number of event link trackers a user has for an event
export const getTotalNumUserEventLinkTrackers = async (
  eventUserId: string,
  eventId: string
): Promise<number> => {
  const userEventLinkTrackersRef = await getUserEventLinkTrackersRef(
    eventUserId,
    eventId
  );
  // filter out the default event tracker
  const query_ = query(
    userEventLinkTrackersRef,
    where("alias", "!=", DefaultLinkTrackerName.DEFAULT_EVENT_LINK_TRACKER)
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

// Gets the total numOpens and numConversions for the campaign accumulated of all the mass texts
export const getCampaignLinkAnalytics = async (
  massTextDetails: MassTextDetails[],
  campaign: Campaign
): Promise<{
  totalOpens: number;
  totalConversions: number;
}> => {
  if (LessThanDate("2024-11-26T17:00:00.000Z", campaign.createdAt)) {
    let numTotalOpens = 0;
    let numTotalConversions = 0;
    for (let i = 0; i < massTextDetails.length; i++) {
      const { attachments } = massTextDetails[i];
      numTotalOpens += attachments.reduce(
        (prev, curr) => prev + curr.numOpens,
        0
      );
      numTotalConversions += attachments.reduce(
        (prev, curr) => prev + curr.numConversions,
        0
      );
    }
    return { totalOpens: numTotalOpens, totalConversions: numTotalConversions };
  } else {
    const results = await Promise.all(
      massTextDetails.map(async (massTextDetail) => {
        let numOpens = 0;
        let numConversions = 0;
        const { massText, attachments } = massTextDetail;
        const promises = attachments.map(async (attachment) => {
          const [opensResponse, conversionsResponse] = await Promise.all([
            API.tracking.getAttachmentNumOpens({
              userId: massText.sentBy,
              massTextId: massText.id,
              campaignId: massText.campaignId,
              attachmentId: attachment.id,
            }),
            API.tracking.getAttachmentNumConversions({
              userId: massText.sentBy,
              massTextId: massText.id,
              campaignId: massText.campaignId,
              attachmentId: attachment.id,
            }),
          ]);
          return {
            opens: opensResponse.totalNum,
            conversions: conversionsResponse.totalNum,
          };
        });
        const results = await Promise.all(promises);
        results.forEach(({ opens, conversions }) => {
          numOpens += opens;
          numConversions += conversions;
        });
        return { massTextOpens: numOpens, massTextConversions: numConversions };
      })
    );
    const totalOpens = results.reduce((sum, r) => sum + r.massTextOpens, 0);
    const totalConversions = results.reduce(
      (sum, r) => sum + r.massTextConversions,
      0
    );
    return { totalOpens, totalConversions };
  }
};

// Gets the numOpens and numConversions of the attachment
export const getMassTextAttachmentsLinkAnalytics = async (
  userId: string,
  massText: MassText,
  attachment: MassTextAttachment
): Promise<{ numOpens: number; numConversions: number }> => {
  let numOpens = 0;
  let numConversions = 0;
  // If the mass text was sent after the specified timestamp, get the opens and conversions from the massTextAttachment itself.
  // The else condition gets the link analytics for mass texts sent prior to Campaigns V1
  if (LessThanDate("2024-11-26T17:00:00.000Z", massText.sentAt)) {
    numOpens = attachment.numOpens;
    numConversions = attachment.numConversions;
  } else {
    const responses = await Promise.all([
      API.tracking.getAttachmentNumOpens({
        userId: userId,
        massTextId: massText.id,
        campaignId: massText.campaignId,
        attachmentId: attachment.id,
      }),
      API.tracking.getAttachmentNumConversions({
        userId: userId,
        massTextId: massText.id,
        campaignId: massText.campaignId,
        attachmentId: attachment.id,
      }),
    ]);
    numOpens = responses[0].totalNum;
    numConversions = responses[1].totalNum;
  }
  return {
    numOpens: numOpens,
    numConversions: numConversions,
  };
};
