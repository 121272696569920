import { useDispatch, useSelector } from "react-redux";
import {
  accountActions,
  changeSignedAgreement,
  getAccountState,
} from "../redux/slices/accountSlice";
import { useCallback, useContext } from "react";
import {
  AccountData,
  PhoneInfo,
  Event,
  NotificationType,
} from "@markit/common.types";
import { MixpanelContext } from "../context/AnalyticsService";
import { UserCredential } from "firebase/auth";
import {
  Batch,
  getPhoneNumberRef,
  getUserRef,
  getUserSnap,
} from "../utils/FirebaseUtils";
import { generate } from "shortid";
import { getUrlParamState } from "../redux/slices/urlParamSlice";
import { createEventData } from "../redux/slices/eventSlice";
import { addToCurrUserWishList } from "../redux/slices/wishMarkSlice";
import { DataLoaders } from "../redux/slices/dataSlice";
import { generateDefaultEventTexts } from "../utils/textingUtils";
import { getUrlParamsFromString } from "../redux/sagas/initializationSaga";
import { useNavigate } from "react-router-dom";
import { makeEmptyAccount } from "@markit/common.utils";
import { showNotificationBanner } from "../utils/notificationUtils";

export const useLogin = () => {
  const {
    redirectPath,
    currentEvent,
    currentRoleTicket,
    followersData,
    numFollowing,
  } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mixpanel = useContext(MixpanelContext);
  const { referral } = useSelector(getUrlParamState);

  // Finish creating the temp event if the user is logging in after creating event
  // also accounts for the case of directing to accept an event role
  // TODO (jonathan): noticed logic here is a bit messy. Don't really understand why finishTempCreation has conditional logic
  //                  on whether it's logging in or signing up. Feels like that should get moved to within loginWithCredential
  const finishTempCreation = useCallback(
    async (userData: AccountData, isLogin: boolean) => {
      if (currentRoleTicket) {
        if (isLogin) {
          // Take user to confirmation page which will have a button telling them to accept role
          // did it this way since the confirmation page will figure out if this account already has a role or not
          // to avoid getting double roles
          window.location.replace(
            `https://markitai.com/e/${currentRoleTicket.eventId}/i/${userData.uid}?ticketId=${currentRoleTicket.id}`
          );
          dispatch(accountActions.setCurrentRoleTicket(undefined));
        }
      } else if (currentEvent) {
        const finalizedEvent: Event = {
          ...currentEvent,
          createdBy: userData.uid,
        };

        // generate default event texts
        const defaultEventTexts = generateDefaultEventTexts(
          userData.uid,
          userData.fullName,
          finalizedEvent
        );

        await Promise.all([
          dispatch(
            createEventData(finalizedEvent, mixpanel, defaultEventTexts)
          ),
          dispatch(addToCurrUserWishList(finalizedEvent.id, userData.uid)),
        ]);
        showNotificationBanner(
          dispatch,
          "Event Created",
          NotificationType.AFFIRMATIVE
        );
        if (isLogin) {
          // might not be needed because we are storing it into creator events not the data slice events
          dispatch(DataLoaders.event(currentEvent.id));
          navigate(`/e/${currentEvent.id}/dashboard`, {
            state: { eventId: currentEvent.id },
          });
        }
        dispatch(accountActions.setCurrentEvent(undefined));

        mixpanel.track("Webapp: Event Created By Unspecified User", {
          distinct_id: userData.uid,
          creation_type: isLogin ? "login" : "sign-up",
          event_id: currentEvent.id,
        });
      }
      if (!isLogin) {
        navigate("/welcome/onboarding");
      }
    },
    [currentEvent, currentRoleTicket, dispatch, mixpanel, navigate]
  );

  const loginWithCredential = async (
    credential: UserCredential,
    signIn: boolean,
    tempFullName?: string
  ) => {
    if (credential.user !== null) {
      const uid = credential.user.uid;
      const accountSnap = await getUserSnap(uid);
      const accountData = accountSnap.data();

      if (accountData) {
        // initialize mixpanel user properties if not created already
        mixpanel.identify(accountData.uid);
        mixpanel.people.set_once({
          $email: accountData.email,
          $phone: accountData.phoneNumber,
          $name: accountData.fullName,
          Following: numFollowing,
          Followers: followersData.length,
          PaidTicketsSold: 0,
          TotalEventRevenue: 0,
          NetEventRevenue: 0,
          SubscriptionState: "INACTIVE",
          SubscriptionMRR: 0,
        });
        // change signed agreement to true if false for existing user
        if (!accountData.signedAgreement) {
          dispatch(changeSignedAgreement(true, uid));
        }
        // if account has been created for user, login
        if (credential.user) {
          mixpanel.identify(uid);
        }
        mixpanel.track("Webapp: User Login");
        mixpanel.people_increment({ Logins: 1 });
        mixpanel.people.set({
          "Last Login": new Date().toISOString(),
        });
        dispatch(accountActions.login(accountData));
        // don't want to redirect folks on rsvp/follow/external link
        if (signIn) {
          if (currentEvent || currentRoleTicket) {
            await finishTempCreation(
              accountData,
              !accountData.haveNotFinishedInitialStartup
            );
          } else if (referral !== "" && accountData.customer.phone === "") {
            navigate("/subscription");
          } else if (accountData.haveNotFinishedInitialStartup) {
            // if account has not set own username (finished sign up flow)
            navigate("/welcome/onboarding");
          } else if (redirectPath !== "") {
            // need to reset the url params
            const params = getUrlParamsFromString(redirectPath);
            navigate(redirectPath, {
              replace: true,
              state: {
                eventId: params.e,
                username: params.u,
                ticketId: params.ticketId,
                qrCodeId: params.qrCodeId,
                userId: params.i,
              },
            });
          } else if (accountData.inCreatorMode) {
            navigate("/home", { replace: true });
          } else {
            navigate("/", { replace: true });
          }

          window.scrollTo(0, 0);
        }
      } else {
        // if an account has not been created yet for the user, create account
        // create all account data if necessary
        const email = credential.user.email;
        const phoneNumber = credential.user.phoneNumber;
        const formattedPhoneNumber = phoneNumber || "";
        if (email || phoneNumber) {
          const batch = new Batch();

          const data: AccountData = {
            ...makeEmptyAccount(),
            uid: uid,
            fullName: tempFullName ?? "", // defined when account creation when rsvp/follow
            email: email ?? "",
            username: generate().toLowerCase(),
            inCreatorMode: signIn, // if signIn and new acount default to true, false on rsvp/follow/external link
            phoneNumber: formattedPhoneNumber,
            haveNotFinishedStartup: true,
            haveNotFinishedInitialStartup: true,
            signedAgreement: true,
          };

          const userRef = getUserRef(uid);
          batch.set(userRef, data);

          if (formattedPhoneNumber !== "") {
            const phoneNumberRef = getPhoneNumberRef(formattedPhoneNumber);

            const phoneInfo: PhoneInfo = {
              lastFreeCreator: "",
              phoneNumber: formattedPhoneNumber,
              userId: uid,
              sentCompliance: false,
              sentComplianceFreeCreatorPhone: false,
              optOut: false,
              optOutFreeCreatorPhone: false,
              optOutTimestamp: "",
            };
            batch.set(phoneNumberRef, phoneInfo);
          }

          // set completedCreatorSetupTimestamp to true
          batch.update(userRef, {
            completedCreatorSetupTimestamp: new Date().toISOString(),
          });

          try {
            await batch.commit();
          } catch (e: any) {
            console.log(e.message);
          }

          mixpanel.track("Webapp: User Created New Account", {
            phone_number: phoneNumber,
            email: email,
            full_name: data.fullName,
            sign_up: "web-app",
          });
          // initialize mixpanel user properties
          mixpanel.register(uid);
          mixpanel.identify(data.uid);
          mixpanel.people.set_once({
            $email: data.email,
            $phone: data.phoneNumber,
            $name: data.fullName,
            Following: 0,
            Followers: 0,
            PaidTicketsSold: 0,
            TotalEventRevenue: 0,
            NetEventRevenue: 0,
            SubscriptionState: "INACTIVE",
            SubscriptionMRR: 0,
          });

          // don't want to redirect folks on rsvp/follow/external link
          if (signIn) {
            await finishTempCreation(data, false);
          }
        } else {
          alert("An error has occurred");
        }
      }
    }

    return false;
  };

  return { loginWithCredential };
};
