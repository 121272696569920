import { Icon } from "@iconify/react";
import { useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import {
  Event,
  PromoCode,
  TicketV2,
  NotificationType,
} from "@markit/common.types";
import React, { useCallback, useMemo, useState } from "react";
import { API } from "../../API";
import { PromoCodeModal } from "./DashboardModals/PromoCodeModal";
import { PROMO_FLEX_SECTIONS, PromoCodeItem } from "./PromoCodes/PromoCodeItem";
import { onSnapshot, query, where } from "../../firebase";
import { getTicketsRef } from "../../utils/FirebaseUtils";
import { eventHasNotFreeTickets, uniqueVals } from "@markit/common.utils";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import SearchBoxContainer from "../Containers/SearchBoxContainer";
import filter from "lodash.filter";
import BlackHoverButton from "../Buttons/BlackHoverButton";
import GridTableHeader from "../Headers/GridTableHeader";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import AlertContainer from "../Containers/AlertContainer";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import { DropdownMenuItem } from "../DropdownMenu";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";
import { showNotificationBanner } from "../../utils/notificationUtils";
import { useDispatch } from "react-redux";

type PromoCodePanelProps = {
  event: Event;
};

export const PromoCodePanel = (props: PromoCodePanelProps) => {
  const { event } = props;
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [freeTicketCodes, setFreeTicketCodes] = useState<TicketV2[]>([]);
  const [isPromoModalVisible, setIsPromoModalVisible] =
    useState<boolean>(false);
  const [isEditingPromo, setIsEditingPromo] = useState<boolean>(false);
  const [promoItem, setPromoItem] = useState<PromoCode | TicketV2 | undefined>(
    undefined
  );
  const [freePromoRedeemed, setFreePromoRedeemed] = useState<boolean>(false);
  const [freeTicketsTotalCount, setFreeTicketsTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState("");

  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const [deletePromoConfirm, setDeletePromoConfirm] = useState(false);
  const [redeemedPromoConfirm, setRedeemedPromoConfirm] = useState(false);

  const styles = {
    containerView: {
      paddingInline: 14,
    },

    overallContainerView: {
      width: "100%",
      backgroundColor: theme.SecondaryBG.backgroundColor,
      borderRadius: 20,
    },
  };

  // Get all promo codes
  const allCodes = useMemo(() => {
    const namedPromoCodes = event
      ? event.promoCodes.filter((code) => code.alias)
      : [];
    const codes: (TicketV2 | PromoCode)[] = [...namedPromoCodes];
    const uniqueFreeTicketAliases = uniqueVals(
      freeTicketCodes,
      (code) => code.alias
    );
    return codes.concat(uniqueFreeTicketAliases);
  }, [event, freeTicketCodes]);

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
  };

  const contains = useCallback((item: PromoCode | TicketV2, query: string) => {
    return item.alias.toLowerCase().includes(query);
  }, []);

  const promoCodesToShow = useMemo(() => {
    if (searchTerm === "") {
      return allCodes;
    } else {
      return filter(allCodes, (code: PromoCode | TicketV2) => {
        return contains(code, searchTerm);
      });
    }
  }, [contains, allCodes, searchTerm]);

  useAsyncOnMount(async () => {
    const ticketsRef = getTicketsRef(event.id);
    const querySnapshot = query(
      ticketsRef,
      where("originalOwner", "==", event.createdBy),
      where("alias", "!=", "")
    );
    const unsubscribe = onSnapshot(querySnapshot, (snapshot) => {
      setFreeTicketsTotalCount(snapshot.size);

      snapshot.docChanges().forEach(async (change) => {
        const newTicketCode = change.doc.data();
        if (change.type === "added" || change.type === "modified") {
          setFreeTicketCodes((freeTicketCodes) => {
            const index = freeTicketCodes.findIndex(
              (fetched) => fetched.id === newTicketCode.id
            );
            if (index !== -1) {
              freeTicketCodes.splice(index, 1, newTicketCode);
            } else {
              freeTicketCodes = freeTicketCodes.concat(newTicketCode);
            }
            return uniqueVals(
              freeTicketCodes,
              (freeTicketCodes) => freeTicketCodes.id
            );
          });
        } else if (change.type === "removed") {
          setFreeTicketCodes((freeTicketCodes) => {
            const index = freeTicketCodes.findIndex(
              (fetched) => fetched.alias === newTicketCode.alias
            );
            if (index !== -1) {
              freeTicketCodes.splice(index, 1);
            }
            return [...freeTicketCodes];
          });
        }
      });
    });
    return () => unsubscribe();
  });

  const promoLink = useMemo(() => {
    if (promoItem) {
      // todo (jonathan): not the cleanest way to do this
      const promoCodeItem = promoItem as PromoCode;

      const link = promoCodeItem.type
        ? `https://markitai.com/e/${event.id}?promoCodeId=${promoItem.id}`
        : `Use code ${promoItem.alias} during checkout for free ticket. https://markitai.com/e/${event.id}`;

      return link;
    } else {
      return "";
    }
  }, [event.id, promoItem]);

  const copyPromoLink = useCallback(
    async (fullLink?: boolean) => {
      if (promoItem) {
        // todo (jonathan): not the cleanest way to do this

        navigator.clipboard
          .writeText(fullLink ? promoLink : promoItem.alias)
          .then(() => {
            showNotificationBanner(
              dispatch,
              "Copied!",
              NotificationType.AFFIRMATIVE
            );
          })
          .catch((err) => {
            setAlertText({ heading: err, subHeading: "" });
          });
      }
    },
    [dispatch, promoItem, promoLink]
  );

  const editPromo = useCallback(() => {
    setIsEditingPromo(true);
    setIsPromoModalVisible(true);
  }, []);

  const deletePromoCode = useCallback(async () => {
    if (promoItem) {
      // todo (jonathan): not the cleanest way to do this
      const promoCodeItem = promoItem as PromoCode;
      if (promoCodeItem.type) {
        await API.promoCodes
          .deletePromoCode({
            eventId: event.id,
            promoCodeId: promoItem.id,
          })
          .then((response) => {
            if (!response.success) {
              setAlertText({
                heading: "Error deleting promo code.",
                subHeading: "Please try again.",
              });
            } else {
              showNotificationBanner(
                dispatch,
                "Promo Code Deleted",
                NotificationType.NEGATIVE,
                "ion:trash-outline"
              );
            }
          })
          .catch(() => {
            setAlertText({
              heading: "Error deleting promo code.",
              subHeading: "Please try again.",
            });
          });
      } else {
        if (!freePromoRedeemed) {
          await API.promoCodes
            .deleteFreeTicketCode({
              eventId: event.id,
              alias: promoItem.alias,
            })
            .then((response) => {
              if (!response.success) {
                setAlertText({
                  heading: "Error deleting free promo.",
                  subHeading: "Please try again.",
                });
              }
            })
            .catch(() => {
              setAlertText({
                heading: "Error deleting free promo.",
                subHeading: "Please try again.",
              });
            });
        } else {
          setRedeemedPromoConfirm(true);
        }
      }
    }
  }, [dispatch, event.id, freePromoRedeemed, promoItem]);

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "Copy Name",
        icon: "feather:link-2",
        key: "copy_link",
        onPress: () => copyPromoLink(),
      },
      {
        title: "Share Link",
        icon: "mdi:share",
        key: "share_link",
        onPress: () => copyPromoLink(true),
      },
      {
        title: "Edit Promo",
        icon: "feather:edit",
        key: "edit_promo",
        onPress: editPromo,
      },
      {
        title: "Delete Promo",
        icon: "ion:trash-outline",
        key: "delete_promo",
        onPress: () => setDeletePromoConfirm(true),
        isDestructive: true,
      },
    ],
    [copyPromoLink, editPromo]
  );

  return (
    <div>
      <StandardBorderedContainer
        containerStyles={{
          ...styles.overallContainerView,
          paddingBottom: 14,
        }}
      >
        <div className="ColumnNormal" style={{ minHeight: 770 }}>
          <div
            className="AlignedRowSpaced"
            style={{ ...styles.containerView, paddingBlock: 14, gap: 12 }}
          >
            <SearchBoxContainer
              placeholder="Search Promo Codes..."
              onChange={(e) => handleSearch(e.target.value)}
              containerStyles={{ marginTop: 0 }}
            />
            <BlackHoverButton
              title={"New Promo"}
              iconName="ion:add-circle"
              iconSize={19}
              onPress={() => setIsPromoModalVisible(true)}
              permanentHover
              containerStyles={{
                paddingInline: 14,
                paddingBlock: 12,
                whiteSpace: "nowrap",
              }}
              disabled={!eventHasNotFreeTickets(event)}
            />
          </div>
          <HorizontalDivider />
          <GridTableHeader
            flexSections={PROMO_FLEX_SECTIONS}
            sectionTitles={["Promo Code", "Percent Off", "Uses"]}
          />
          <HorizontalDivider />
          <div style={{ marginTop: 14 }}>
            {allCodes.length > 0 ? (
              promoCodesToShow.length === 0 ? (
                <EmptyStateButton
                  title="No Results"
                  description="Check for typos or search for something else"
                  icon={
                    <Icon icon="ion:search" height={40} color={Colors.GRAY1} />
                  }
                  btnText={undefined}
                  onPress={() => {}}
                  containerStyles={{ paddingTop: 120 }}
                />
              ) : (
                promoCodesToShow.map((item, index) => {
                  return (
                    <PromoCodeItem
                      key={index}
                      item={item}
                      event={event}
                      index={index}
                      totalItems={allCodes.length}
                      setItem={setPromoItem}
                      setFreePromoRedeemed={setFreePromoRedeemed}
                      freeTicketsTotalCount={freeTicketsTotalCount}
                      dropdownItems={dropdownItems}
                    />
                  );
                })
              )
            ) : (
              <EmptyStateButton
                title={"No Promo Codes"}
                btnText={eventHasNotFreeTickets(event) ? "New Promo" : ""}
                description={
                  eventHasNotFreeTickets(event)
                    ? "Create a new promo code"
                    : "You can't create promo codes for a free event"
                }
                icon={
                  <Icon
                    icon={"ion:pricetags"}
                    height={49}
                    style={{ color: Colors.GRAY1 }}
                  />
                }
                iconBox={73}
                onPress={() => setIsPromoModalVisible(true)}
                containerStyles={{ paddingTop: 120 }}
              />
            )}
          </div>
        </div>
      </StandardBorderedContainer>
      {isPromoModalVisible ? (
        <PromoCodeModal
          event={event}
          setIsPromoModalVisible={setIsPromoModalVisible}
          isEditingPromo={isEditingPromo}
          setIsEditingPromo={setIsEditingPromo}
          item={promoItem}
          setItem={setPromoItem}
        />
      ) : null}

      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        theme={theme}
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
      <ConfirmDeleteModal
        heading="Are you sure you wish to delete this promo?"
        subtext="Purchasers will no longer be able to use this."
        deleteButtonText="Delete"
        hideModal={!deletePromoConfirm}
        setIsVisible={setDeletePromoConfirm}
        deleteOnPress={deletePromoCode}
        theme={theme}
      />
      <ConfirmDeleteModal
        heading="At least one user has redeemed this promo code."
        subtext="Are you sure you want to delete this promo?"
        deleteButtonText="Delete"
        hideModal={!redeemedPromoConfirm}
        setIsVisible={setRedeemedPromoConfirm}
        deleteOnPress={async () => {
          if (promoItem) {
            await API.promoCodes
              .deleteFreeTicketCode({
                eventId: event.id,
                alias: promoItem.alias,
              })
              .then((response) => {
                if (!response.success) {
                  setAlertText({
                    heading: "Error deleting free promo.",
                    subHeading: "Please try again.",
                  });
                }
              })
              .catch(() => {
                setAlertText({
                  heading: "Error deleting free promo.",
                  subHeading: "Please try again.",
                });
              });
          }
        }}
        theme={theme}
      />
    </div>
  );
};
