import "../../../../css/GlobalStyles.css";
import React, { useCallback, useMemo, useState } from "react";
import { Event } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { FeaturedEventCardMobile } from "../../../../components/LandingPage/RenderedEvents";
import { getEventState } from "../../../../redux/slices/eventSlice";
import {
  MARKITAI,
  hostedEvents,
  hostingDraftEvents,
  hostingLiveEvents,
  uniqueVals,
} from "@markit/common.utils";
import CreateDropdownMenu from "../../../../components/DropdownsAndTabs/CreateDropdownMenu";
import EmptyStateButton from "../../../../components/Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import RectangleButtonCreatorPanel from "../../../../components/Buttons/RectangleButtonCreatorPanel";
import AlertContainer from "../../../../components/Containers/AlertContainer";
import { useNavigate } from "../../../../hooks/useNavigate";
import MarkitPlusActionButton from "../../../../components/Subscription/MarkitPlusActionButton";
import { NavigationId } from "../../../../navigation/AppParamList";
import CreatorModePanelWrapper from "../../../../components/CreatorDashboard/CreatorModePanelWrapper";
import FatHeaderActionButton from "../../../../components/Buttons/FatHeaderActionButton";

const EventsPanel = () => {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const { events: eventList } = useSelector(getEventState);
  const { events } = eventList;
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [alertText, setAlertText] = useState<string>("");
  const [downloading, setDownloading] = useState<boolean>(false);

  const exportSpreadsheetOnPress = useCallback(async () => {
    setAlertText("Coming soon...");
  }, []);
  const userEvents = useMemo(
    () => hostingLiveEvents(events, accountData.uid),
    [accountData.uid, events]
  );

  const currentUserEvents = useMemo(
    () => userEvents.filter((event) => event.end >= new Date().toISOString()),
    [userEvents]
  );

  const pastUserEvents = useMemo(
    () => hostedEvents(events, accountData.uid).reverse(),
    [accountData.uid, events]
  );

  const draftUserEvents = useMemo(
    () => hostingDraftEvents(events, accountData.uid),
    [accountData.uid, events]
  );

  const numAllCreatorEvents = useMemo(
    () =>
      uniqueVals(
        userEvents.concat(pastUserEvents).concat(draftUserEvents),
        (event) => event.id
      ).length,
    [draftUserEvents, pastUserEvents, userEvents]
  );

  const navigateCreateOnPress = useCallback(
    () => (window.location.href = `${MARKITAI}/create`),
    []
  );

  const navigateCreateLinkOnPress = useCallback(
    () => navigate(NavigationId.CREATE_LINK_EVENT),
    [navigate]
  );

  const renderSubPanel = useMemo(() => {
    const renderEvents = (events: Event[], mainText: string) => {
      return events.length > 0 ? (
        events.map((event) => (
          <FeaturedEventCardMobile
            event={event}
            altNavigationPath={
              event.isDraft ? "/create" : `/e/${event.id}/dashboard`
            }
            myEvent
            key={event.id}
          />
        ))
      ) : (
        <EmptyStateButton
          title={numAllCreatorEvents === 0 ? "Grow with Events" : mainText}
          description={
            numAllCreatorEvents === 0
              ? "Create an event page from scratch or link an existing event link"
              : ""
          }
          icon={<Icon icon={"ion:calendar"} height={63} color={Colors.GRAY1} />}
          iconBackgroundColor={Colors.WHITE1}
          iconBox={80}
          btnText="Create an Event"
          onPress={navigateCreateOnPress}
          containerStyles={{ paddingTop: 200 }}
          secondaryAction={
            <div
              className="AlignedRowSelect"
              style={{ gap: 4 }}
              onClick={navigateCreateLinkOnPress}
            >
              <span style={{ fontSize: 12, color: Colors.BLUE5 }}>
                Link an existing event
              </span>
              <Icon
                icon={"ion:chevron-right"}
                height={12}
                color={Colors.BLUE5}
              />
            </div>
          }
        />
      );
    };

    return (
      <div className="ColumnNormal" style={{ gap: "14px", paddingBottom: 100 }}>
        {value === 0 && renderEvents(currentUserEvents, "No Upcoming Events")}
        {value === 1 && renderEvents(pastUserEvents, "No Past Events")}
        {value === 2 && renderEvents(draftUserEvents, "No Event Drafts")}
      </div>
    );
  }, [
    value,
    currentUserEvents,
    pastUserEvents,
    draftUserEvents,
    numAllCreatorEvents,
    navigateCreateOnPress,
    navigateCreateLinkOnPress,
  ]);

  return (
    <CreatorModePanelWrapper
      title="Events"
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <MarkitPlusActionButton />
          <FatHeaderActionButton
            title="Export Data"
            onPress={exportSpreadsheetOnPress}
            loading={downloading}
          />
          <CreateDropdownMenu
            navCreateLinkOnPress={navigateCreateLinkOnPress}
            navCreateOnPress={navigateCreateOnPress}
            sourceLocation="events_panel"
          >
            <RectangleButtonCreatorPanel
              title="Create Event"
              iconName="ion:add-circle"
              onPress={() => {}}
            />
          </CreateDropdownMenu>
        </div>
      }
      headerTabs={{
        tabNames: [
          `Current (${currentUserEvents.length})`,
          `Past (${pastUserEvents.length})`,
          `Drafts (${draftUserEvents.length})`,
        ],
        tabValue: value,
        setTabValue: setValue,
      }}
    >
      <div
        style={{ padding: 30, height: "100%", backgroundColor: Colors.WHITE }}
      >
        {renderSubPanel}
      </div>
      <AlertContainer
        headerComp={alertText}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
    </CreatorModePanelWrapper>
  );
};

export default EventsPanel;
