import {
  AccountData,
  Event,
  TicketV2,
  RequestStatus,
  SubSelectionItem,
  SubSelectionType,
  SubSelectionStatusId,
  FormResponseV2,
  SelectRecipientGroupType,
  FollowerStatus,
} from "@markit/common.types";
import { CircularProgress } from "@mui/material";
import FlatList from "flatlist-react/lib";
import filter from "lodash.filter";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { MassTextsPersonItem } from "../../Items/MassTextsPersonItem";
import { RenderedEventMassTextsModal } from "../../../../LandingPage/RenderedEvents";
import { useLoadUserTicketList } from "../../../../../hooks/useLoadUserTicketList";
import { Colors } from "../../../../../utils/colors";
import { fetchUniqueEventTickets } from "../../../../../utils/eventUtils/eventUtils";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../../redux/slices/accountSlice";
import {
  addOrFilterCategories,
  addOrFilterRecipients,
  eventHasRequestTickets,
  isAllAttendeesCategorySelected,
  isAllPeopleCategorySelected,
  isEventExternalLink,
  isExternalEventbrite,
  isItemGroupSelected,
  uniqueVals,
} from "@markit/common.utils";
import { EmptySearchState } from "../../../../EmptyStates/EmptySearchState";
import { RecipientsAddAllItem } from "../../Items/RecipientsAddAllItem";
import { getEventFormResponses } from "../../../../../utils/eventUtils/formUtils";
import SelectRecipientItemsFormQuestion from "../SelectCollectedData/SelectRecipientsItemsFormQuestion";
import SearchBoxContainer from "../../../../Containers/SearchBoxContainer";
import { MassTextsActionItem } from "../../Items/MassTextsActionItem";
import ListTabs from "../../../../DropdownsAndTabs/ListTabs";
import { SelectRecipientsSharedProps } from "../SelectRecipientsScreens";
import { RecipientsAddAllItemDropdown } from "../../Items/RecipientsAddAllItemDropdown";
import { HorizontalDivider } from "../../../../Dividers/HorizontalDivider";
import { fetchAttendeesPerTypeMap } from "../../../../../utils/textingUtils";
import useAsyncOnMount from "../../../../../hooks/useAsyncEffectOnMount";
import { useOnMount } from "../../../../../hooks/useOnMount";

type SelectRecipientsEventProps = SelectRecipientsSharedProps & {
  selectedType: SelectRecipientGroupType;
};

const SelectRecipientsEvent = (props: SelectRecipientsEventProps) => {
  const {
    unsavedRecipients,
    setUnsavedRecipients,
    unsavedCategories,
    setUnsavedCategories,
    selectedType,
    excludingMode,
    showCategories,
    setProfileSelected,
  } = props;
  const { savedQuestions, followersData } =
    useSelector(getAccountState).account;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [listView, setListView] = useState(0);
  const [ticketTypeAttendees, setTicketTypeAttendees] = useState<
    Map<string, string[]>
  >(new Map<string, string[]>());
  const [formResponses, setFormResponses] = useState<FormResponseV2[]>([]);
  const [allAttendeeTickets, setAllAttendeeTickets] = useState<TicketV2[]>([]);
  const [showStatusSubOptions, setShowStatusSubOptions] = useState(true);

  const styles = { optionSubtext: { fontSize: 12, marginTop: 3 } };

  const addAllItemSubtext = useMemo(
    () => (showCategories ? undefined : "People"),
    [showCategories]
  );

  const selectedEvent = useMemo(
    () =>
      (selectedType.selectedItem as unknown as Event)
        ? (selectedType.selectedItem as unknown as Event)
        : undefined,
    [selectedType.selectedItem]
  );

  const isExternalLink = useMemo(
    () => selectedEvent && isEventExternalLink(selectedEvent.eventType),
    [selectedEvent]
  );

  const isEventbriteExternal = useMemo(
    () => selectedEvent && isExternalEventbrite(selectedEvent.eventType),
    [selectedEvent]
  );

  // filter down by the subscribed attendees
  const allSubscribedAttendeeTickets = useMemo(
    () =>
      allAttendeeTickets.filter(
        (tickets) =>
          followersData.find((follower) => follower.uid === tickets.uid)
            ?.status === FollowerStatus.SUBSCRIBED
      ),
    [allAttendeeTickets, followersData]
  );

  // filter down by the subscribed form responses
  const subscribedFormResponses = useMemo(
    () =>
      formResponses.filter(
        (form) =>
          followersData.find((follower) => follower.uid === form.uid)
            ?.status === FollowerStatus.SUBSCRIBED
      ),
    [followersData, formResponses]
  );

  const subscribedTicketTypeAttendees = useMemo(() => {
    // Create a new map to store the filtered results
    const subscribedAttendeesPerTicketType = new Map();

    // Iterate over the map
    ticketTypeAttendees.forEach((valueArray, key) => {
      const filteredArray = valueArray.filter(
        (uid) =>
          followersData.find((follower) => follower.uid === uid)?.status ===
          FollowerStatus.SUBSCRIBED
      );

      // Only add the key to the new map if the filtered array is not empty
      if (filteredArray.length > 0) {
        subscribedAttendeesPerTicketType.set(key, filteredArray);
      }
    });
    return subscribedAttendeesPerTicketType;
  }, [followersData, ticketTypeAttendees]);

  const allAttendeeUsers = useMemo(
    () => allSubscribedAttendeeTickets.map((ticket) => ticket.uid),
    [allSubscribedAttendeeTickets]
  );

  const allAcceptedAttendeeTickets = useMemo(
    () =>
      allSubscribedAttendeeTickets.filter(
        (ticket) => ticket.requestStatus === RequestStatus.ACCEPTED
      ),
    [allSubscribedAttendeeTickets]
  );

  const allAcceptedAttendeeIds = useMemo(
    () => allAcceptedAttendeeTickets.map((ticket) => ticket.uid),
    [allAcceptedAttendeeTickets]
  );

  const allRequestedAttendeeIds = useMemo(
    () =>
      allSubscribedAttendeeTickets
        .filter((ticket) => ticket.requestStatus === RequestStatus.PENDING)
        .map((ticket) => ticket.uid),
    [allSubscribedAttendeeTickets]
  );

  // To be used for isItemGroupSelected
  const totalListToShow = useCallback(
    (recipientList: string[]) => {
      return showCategories ? unsavedCategories : recipientList;
    },
    [showCategories, unsavedCategories]
  );

  const eventFormQuestions = useMemo(
    () =>
      selectedEvent
        ? savedQuestions.filter(
            (question) => question.eventIds.includes(selectedEvent.id),
            []
          )
        : [],
    [savedQuestions, selectedEvent]
  );

  const iconColor = useMemo(
    () =>
      excludingMode
        ? Colors.RED3
        : showCategories
        ? Colors.PURPLE7
        : Colors.BLUE5,
    [excludingMode, showCategories]
  );

  const {
    isFinished: isAttendeeFinished,
    isLoading: isAttendeeLoading,
    fetchedUserData: fullAttendeeAccountData,
    loadTicketUsers: loadAttendeeUsers,
    loadMoreTicketUsers: loadMoreAttendeeUsers,
    loadSearchResultsThrottled,
  } = useLoadUserTicketList({
    event: selectedEvent,
    followerStatus: FollowerStatus.SUBSCRIBED,
    windowSize: 15,
    ticketType: "",
  });

  useAsyncOnMount(async () => {
    if (selectedEvent) {
      const [attendeesPerTicketType, eventFormResponses, attendeeTickets] =
        await Promise.all([
          fetchAttendeesPerTypeMap(selectedEvent),
          getEventFormResponses(selectedEvent.id),
          fetchUniqueEventTickets(selectedEvent.id),
        ]);
      setTicketTypeAttendees(attendeesPerTicketType); // for by ticket type
      setFormResponses(eventFormResponses); // for by form response
      setAllAttendeeTickets(attendeeTickets);
    }
    setLoading(false);
  });

  // To handle pre-setting the focused tab when selecting by event
  useEffect(() => {
    setListView(selectedType.eventTabValue);
  }, [selectedType.eventTabValue]);

  useOnMount(() => {
    loadAttendeeUsers();
  });

  const containsUser = useCallback((item: AccountData, query: string) => {
    return item.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    if (text !== "") {
      loadSearchResultsThrottled(text.toLowerCase());
    }
    setSearchTerm(text);
  };

  const attendeeTicketsToShow: AccountData[] = useMemo(() => {
    let searchResults: AccountData[] = fullAttendeeAccountData;
    if (searchTerm !== "") {
      searchResults = filter(fullAttendeeAccountData, (user: AccountData) => {
        return containsUser(user, searchTerm);
      });
    }
    return searchResults;
  }, [fullAttendeeAccountData, searchTerm, containsUser]);

  const getCheckInRecipientIds = useCallback(
    (isScanned: boolean) => {
      return uniqueVals(
        allAcceptedAttendeeTickets
          .filter((ticket) => (isScanned ? ticket.scanned : !ticket.scanned))
          .map((ticket) => ticket.uid)
      );
    },
    [allAcceptedAttendeeTickets]
  );

  const selectAllOnPress = useCallback(() => {
    if (showCategories) {
      const allPeopleCategory: SubSelectionItem = {
        type: SubSelectionType.ALL_PEOPLE,
        id: "",
        formOption: "",
      };
      addOrFilterCategories(
        isAllPeopleCategorySelected(unsavedCategories) ? unsavedCategories : [],
        setUnsavedCategories,
        allPeopleCategory
      );
    } else {
      addOrFilterRecipients(
        unsavedRecipients,
        setUnsavedRecipients,
        allAttendeeUsers
      );
    }
  }, [
    showCategories,
    unsavedCategories,
    setUnsavedCategories,
    unsavedRecipients,
    setUnsavedRecipients,
    allAttendeeUsers,
  ]);

  const onSelectAllStatusUsers = useCallback(
    async (status: SubSelectionStatusId) => {
      if (showCategories) {
        const statusCategory: SubSelectionItem = {
          type: SubSelectionType.STATUS,
          id: status,
          formOption: "",
        };
        if (status === SubSelectionStatusId.ATTENDEES) {
          const foundNonAttendeeCategories = unsavedCategories.filter(
            (category) =>
              category.id !== SubSelectionStatusId.CHECKED_IN &&
              category.id !== SubSelectionStatusId.NOT_CHECKED_IN
          );
          addOrFilterCategories(
            isAllAttendeesCategorySelected(unsavedCategories)
              ? unsavedCategories
              : foundNonAttendeeCategories,
            setUnsavedCategories,
            statusCategory
          );
        } else {
          addOrFilterCategories(
            unsavedCategories,
            setUnsavedCategories,
            statusCategory
          );
        }
      } else {
        if (
          status === SubSelectionStatusId.CHECKED_IN ||
          status === SubSelectionStatusId.NOT_CHECKED_IN
        ) {
          const checkInRecipients = getCheckInRecipientIds(
            status === SubSelectionStatusId.CHECKED_IN
          );
          addOrFilterRecipients(
            unsavedRecipients,
            setUnsavedRecipients,
            checkInRecipients
          );
        } else {
          addOrFilterRecipients(
            unsavedRecipients,
            setUnsavedRecipients,
            status === SubSelectionStatusId.ATTENDEES
              ? allAcceptedAttendeeIds
              : allRequestedAttendeeIds
          );
        }
      }
    },
    [
      showCategories,
      unsavedCategories,
      setUnsavedCategories,
      getCheckInRecipientIds,
      unsavedRecipients,
      setUnsavedRecipients,
      allAcceptedAttendeeIds,
      allRequestedAttendeeIds,
    ]
  );

  const onSelectAllTicketUsers = useCallback(
    async (customTicketId: string) => {
      // Custom Selection Categories
      if (showCategories) {
        const newSubSelectionItem: SubSelectionItem = {
          type: SubSelectionType.TICKET_TYPE,
          id: customTicketId,
          formOption: "",
        };
        addOrFilterCategories(
          unsavedCategories,
          setUnsavedCategories,
          newSubSelectionItem
        );
      } else {
        const foundAttendees =
          subscribedTicketTypeAttendees.get(customTicketId);
        if (foundAttendees) {
          addOrFilterRecipients(
            unsavedRecipients,
            setUnsavedRecipients,
            foundAttendees
          );
        }
      }
    },
    [
      showCategories,
      unsavedCategories,
      setUnsavedCategories,
      subscribedTicketTypeAttendees,
      unsavedRecipients,
      setUnsavedRecipients,
    ]
  );

  const renderPeopleTab = useMemo(
    () => (
      <>
        {isEventbriteExternal ? (
          <>
            <div className="ColumnNormal" style={{ gap: 4, paddingTop: 7 }}>
              <span className="bodyMedium">
                Only showing textable contacts for this event.
              </span>
              <span className="smallBodySubtext">
                Attendees with no phone number are not textable.
              </span>
            </div>
            <HorizontalDivider altMargin={10} />
          </>
        ) : null}
        {allAttendeeUsers.length > 0 || showCategories ? (
          <div>
            <MassTextsActionItem
              title={showCategories ? "All People" : "Select All People"}
              icon={<Icon icon="ion:people" height={32} color={iconColor} />}
              onPress={selectAllOnPress}
              subtext={
                loading
                  ? ""
                  : showCategories
                  ? isExternalLink
                    ? "All Submissions"
                    : "All Attendees, Requested, etc."
                  : `${allAttendeeUsers.length} ${
                      allAttendeeUsers.length !== 1 ? "People" : "Person"
                    }`
              }
              isCheckSelected={isItemGroupSelected(
                totalListToShow(allAttendeeUsers),
                unsavedRecipients,
                showCategories,
                {
                  type: SubSelectionType.ALL_PEOPLE,
                  id: "",
                  formOption: "",
                }
              )}
              checkColor={iconColor}
              disabled={showCategories && excludingMode}
            />
            <hr style={{ marginBlock: 10 }} />
          </div>
        ) : null}
        {!showCategories ? (
          <FlatList
            list={attendeeTicketsToShow}
            renderItem={(user) => {
              return (
                <MassTextsPersonItem
                  user={user}
                  unsavedPeople={unsavedRecipients}
                  setUnsavedPeople={setUnsavedRecipients}
                  excludingMode={excludingMode}
                  setProfileSelected={setProfileSelected}
                />
              );
            }}
            renderWhenEmpty={() => (
              <div>
                {isAttendeeLoading ? (
                  "Loading..."
                ) : (
                  <EmptySearchState mainText="No people to show" />
                )}
              </div>
            )}
            hasMoreItems={!isAttendeeFinished}
            loadMoreItems={() => {
              loadMoreAttendeeUsers();
            }}
            paginationLoadingIndicator={() =>
              isAttendeeLoading && !isAttendeeFinished ? (
                <CircularProgress
                  style={{ color: "#929292", alignSelf: "center" }}
                  size={20}
                />
              ) : null
            }
          />
        ) : null}
      </>
    ),
    [
      allAttendeeUsers,
      attendeeTicketsToShow,
      excludingMode,
      iconColor,
      isAttendeeFinished,
      isAttendeeLoading,
      isEventbriteExternal,
      isExternalLink,
      loadMoreAttendeeUsers,
      loading,
      selectAllOnPress,
      setProfileSelected,
      setUnsavedRecipients,
      showCategories,
      totalListToShow,
      unsavedRecipients,
    ]
  );

  const renderStatusTab = useMemo(
    () => (
      <>
        <RecipientsAddAllItemDropdown
          mainText="All Attendees"
          subText={
            !showCategories ? (
              <span style={styles.optionSubtext}>
                {allAcceptedAttendeeIds.length} {addAllItemSubtext}
              </span>
            ) : undefined
          }
          excludingMode={excludingMode}
          isAllSelected={isItemGroupSelected(
            totalListToShow(allAcceptedAttendeeIds),
            unsavedRecipients,
            showCategories,

            {
              type: SubSelectionType.STATUS,
              id: SubSelectionStatusId.ATTENDEES,
              formOption: "",
            }
          )}
          selectRecipients={allAcceptedAttendeeIds}
          onPress={() => setShowStatusSubOptions(!showStatusSubOptions)}
          onCheckPress={() =>
            onSelectAllStatusUsers(SubSelectionStatusId.ATTENDEES)
          }
          showDropdown={showStatusSubOptions}
          isCategories={showCategories}
          disabled={
            isAllPeopleCategorySelected(unsavedCategories) ||
            (showCategories && excludingMode)
          }
        />
        {showStatusSubOptions ? (
          <>
            <RecipientsAddAllItem
              label="Checked In"
              sublabel={addAllItemSubtext}
              selectRecipients={getCheckInRecipientIds(true)}
              isAllSelected={
                isItemGroupSelected(
                  totalListToShow(getCheckInRecipientIds(true)),
                  unsavedRecipients,
                  showCategories,
                  {
                    type: SubSelectionType.STATUS,
                    id: SubSelectionStatusId.CHECKED_IN,
                    formOption: "",
                  }
                ) || isAllAttendeesCategorySelected(unsavedCategories)
              }
              onPress={() =>
                onSelectAllStatusUsers(SubSelectionStatusId.CHECKED_IN)
              }
              excludingMode={excludingMode}
              isCategories={showCategories}
              disabled={
                isAllPeopleCategorySelected(unsavedCategories) ||
                isAllAttendeesCategorySelected(unsavedCategories)
              }
              noDivider
            />
            <RecipientsAddAllItem
              label="Not Checked In"
              sublabel={addAllItemSubtext}
              excludingMode={excludingMode}
              isAllSelected={
                isItemGroupSelected(
                  totalListToShow(getCheckInRecipientIds(false)),
                  unsavedRecipients,
                  showCategories,
                  {
                    type: SubSelectionType.STATUS,
                    id: SubSelectionStatusId.NOT_CHECKED_IN,
                    formOption: "",
                  }
                ) || isAllAttendeesCategorySelected(unsavedCategories)
              }
              selectRecipients={getCheckInRecipientIds(false)}
              onPress={() => {
                onSelectAllStatusUsers(SubSelectionStatusId.NOT_CHECKED_IN);
              }}
              isCategories={showCategories}
              disabled={
                isAllPeopleCategorySelected(unsavedCategories) ||
                isAllAttendeesCategorySelected(unsavedCategories)
              }
              noDivider
            />
          </>
        ) : null}
        <HorizontalDivider altMargin={10} />
        {selectedEvent && eventHasRequestTickets(selectedEvent) ? (
          <RecipientsAddAllItem
            label="Requested"
            sublabel={addAllItemSubtext}
            excludingMode={excludingMode}
            isAllSelected={isItemGroupSelected(
              totalListToShow(allRequestedAttendeeIds),
              unsavedRecipients,
              showCategories,
              {
                type: SubSelectionType.STATUS,
                id: SubSelectionStatusId.REQUESTED,
                formOption: "",
              }
            )}
            selectRecipients={allRequestedAttendeeIds}
            onPress={() =>
              onSelectAllStatusUsers(SubSelectionStatusId.REQUESTED)
            }
            isCategories={showCategories}
            disabled={isAllPeopleCategorySelected(unsavedCategories)}
          />
        ) : null}
      </>
    ),
    [
      addAllItemSubtext,
      allAcceptedAttendeeIds,
      allRequestedAttendeeIds,
      excludingMode,
      getCheckInRecipientIds,
      onSelectAllStatusUsers,
      selectedEvent,
      showCategories,
      showStatusSubOptions,
      styles.optionSubtext,
      totalListToShow,
      unsavedCategories,
      unsavedRecipients,
    ]
  );

  const renderResponsesTab = useMemo(
    () => (
      <>
        <FlatList
          list={eventFormQuestions}
          renderItem={(formQuestion) => (
            <SelectRecipientItemsFormQuestion
              formQuestion={formQuestion}
              formQuestionResponses={subscribedFormResponses}
              recipients={unsavedRecipients}
              setRecipients={setUnsavedRecipients}
              excludingMode={excludingMode}
              categoriesToShow={unsavedCategories}
              setCategoriesToShow={setUnsavedCategories}
              showCategories={showCategories}
            />
          )}
          renderWhenEmpty={() => (
            <EmptySearchState mainText="No form questions to show" />
          )}
        />
      </>
    ),
    [
      eventFormQuestions,
      excludingMode,
      setUnsavedCategories,
      setUnsavedRecipients,
      showCategories,
      subscribedFormResponses,
      unsavedCategories,
      unsavedRecipients,
    ]
  );

  const renderTicketsTab = useMemo(
    () => (
      <>
        <FlatList
          list={selectedEvent!.customTickets}
          renderItem={(ticket) => (
            <RecipientsAddAllItem
              label={ticket.label}
              sublabel={addAllItemSubtext}
              selectRecipients={
                subscribedTicketTypeAttendees.get(ticket.id) ?? []
              }
              isAllSelected={isItemGroupSelected(
                totalListToShow(
                  subscribedTicketTypeAttendees.get(ticket.id) || []
                ),
                unsavedRecipients,
                showCategories,
                {
                  type: SubSelectionType.TICKET_TYPE,
                  id: ticket.id,
                  formOption: "",
                }
              )}
              onPress={() => onSelectAllTicketUsers(ticket.id)}
              isCategories={showCategories}
              excludingMode={excludingMode}
              disabled={isAllPeopleCategorySelected(unsavedCategories)}
            />
          )}
          renderWhenEmpty={<EmptySearchState mainText="No tickets to show" />}
        />
      </>
    ),
    [
      addAllItemSubtext,
      excludingMode,
      onSelectAllTicketUsers,
      selectedEvent,
      showCategories,
      subscribedTicketTypeAttendees,
      totalListToShow,
      unsavedCategories,
      unsavedRecipients,
    ]
  );

  return (
    <>
      <div
        style={{ backgroundColor: Colors.GRAY6, padding: 10, borderRadius: 12 }}
      >
        <RenderedEventMassTextsModal event={selectedEvent!} disabled />
      </div>
      <SearchBoxContainer
        value={searchTerm}
        placeholder="Search People"
        onChange={(e) => handleSearch(e.target.value)}
      />
      <ListTabs
        tabLabels={
          isEventbriteExternal
            ? ["People", "Ticket"]
            : isExternalLink
            ? ["People", "Responses"]
            : ["People", "Status", "Responses", "Ticket"]
        }
        tabNumbers={
          isEventbriteExternal
            ? [allAttendeeUsers.length, -1]
            : isExternalLink
            ? [-1, -1]
            : [showCategories ? -1 : allAttendeeUsers.length, -1, -1, -1]
        }
        loading={loading}
        selectedValue={listView}
        onChange={setListView}
        containerStyles={{ paddingBlock: 12 }}
        altColor={iconColor}
      />
      <div className="HideScrollbar AllowScroll" style={{ paddingBottom: 200 }}>
        {/* People Tab */}
        {listView === 0 ? renderPeopleTab : null}
        {/* Status */}
        {listView === 1 && !isExternalLink ? renderStatusTab : null}
        {/* Responses */}
        {listView === 2 ||
        (listView === 1 && isExternalLink && !isEventbriteExternal)
          ? renderResponsesTab
          : null}
        {/* Ticket */}
        {(listView === 3 || (listView === 1 && isEventbriteExternal)) &&
        selectedEvent
          ? renderTicketsTab
          : null}
      </div>
    </>
  );
};

export default SelectRecipientsEvent;
