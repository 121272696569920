import { ReactNode, useCallback, useMemo, useState } from "react";
import { Campaign, Event, MassTextType } from "@markit/common.types";
import {
  formatDate,
  formatNumericDate,
  GetDateWithTime,
  handlePluralString,
  isEventExternalLink,
  LessThanDate,
} from "@markit/common.utils";
import { useTheme } from "../../../hooks/useTheme";
import { Colors } from "../../../utils/colors";
import ButtonWithDropdown from "../../Buttons/ButtonWithDropdown";
import { DropdownMenuItem } from "../../DropdownMenu";
import { useNavigate } from "react-router-dom";
import CampaignTypeItem from "../../CreatorDashboard/Campaigns/Items/CampaignTypeItem";
import CampaignRenameModal from "../../CreatorDashboard/Campaigns/Items/CampaignRenameModal";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { CampaignDetails } from "../../../redux/slices/campaignSlice";
import CampaignStatusLabel from "../../CreatorDashboard/Campaigns/Items/CampaignStatusLabel";
import { Icon } from "@iconify/react";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { useSelector } from "react-redux";
import { getEventState } from "../../../redux/slices/eventSlice";
import RectangleButton from "../../Buttons/RectangleButton";
import { onCampaignNavigatePath } from "../../../utils/navigationUtils";

export const AUTOMATION_ITEM_FLEX_SECTIONS = [2, 1, 1, 7];

type AutomationItemPreviewProps = {
  campaignItem: Campaign;
  campaignDetail: CampaignDetails | undefined;
  onEventDashboard?: boolean;
  setShowDeleteCampaign?: (showDeleteCampaign: Campaign) => void;
};

export const AutomationItemPreview = ({
  campaignItem,
  campaignDetail,
  onEventDashboard,
  setShowDeleteCampaign,
}: AutomationItemPreviewProps) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { events } = useSelector(getEventState).events;
  const [showDropdown, setShowDropdown] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);

  const styles = {
    itemBorder: { border: `1px solid ${theme.SubText.color}` },
    timelineItemContainer: {
      borderRadius: 8,
      padding: 4,
    },
    timelineItemHeader: { fontSize: 10, fontWeight: 500 },
  };

  const massTexts = useMemo(
    () =>
      campaignDetail
        ? campaignDetail.massTextDetails.map((detail) => detail.massText)
        : [],
    [campaignDetail]
  );

  const initialMassTextDetail = useMemo(
    () =>
      campaignDetail?.massTextDetails.find(
        (text) => text.massText.id === campaignItem.initialTextId
      ),
    [campaignDetail?.massTextDetails, campaignItem.initialTextId]
  );

  const foundEvent = useMemo(
    () => events.find((event) => event.id === campaignItem.eventId),
    [campaignItem.eventId, events]
  );

  const nonTriggerText = useMemo(() => {
    const nonTriggerTexts = massTexts.filter(
      (text) => text.type !== MassTextType.TRIGGERTEXT
    );
    if (nonTriggerTexts.length) {
      return nonTriggerTexts[0];
    }
  }, [massTexts]);

  // The number of texts excluding the trigger text
  const numNonTriggerTexts = useMemo(
    () => massTexts.length - 1,
    [massTexts.length]
  );

  const navigateCampaignItem = useCallback(
    () =>
      navigate(`/c/${campaignItem.id}`, {
        state: { campaignId: campaignItem.id },
      }),
    [campaignItem.id, navigate]
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const items: DropdownMenuItem[] = [
      {
        title: "View",
        icon: "ion:arrow-forward",
        key: "view",
        onPress: navigateCampaignItem,
      },
    ];
    if (campaignItem.eventId && setShowDeleteCampaign) {
      items.push(
        {
          title: "Rename",
          icon: "ion:pencil",
          key: "rename",
          onPress: () => {
            setShowRenameModal(true);
            setShowDropdown(false);
          },
        },
        {
          title: "Delete",
          icon: "ion:trash",
          key: "delete",
          onPress: () => {
            setShowDeleteCampaign(campaignItem);
            setShowDropdown(false);
          },
          isDestructive: true,
        }
      );
    }
    return items;
  }, [campaignItem, navigateCampaignItem, setShowDeleteCampaign]);

  const renderTimelineItem = useCallback(
    (
      leftComp: ReactNode,
      title: string,
      subtext: string | undefined,
      foundEvent: Event | undefined
    ) => (
      <StandardBorderedContainer containerStyles={styles.timelineItemContainer}>
        <div className="AlignedRow" style={{ gap: 7 }}>
          {leftComp}
          <div className="ColumnNormal" style={{ maxWidth: 120 }}>
            <span className="OneLineText" style={styles.timelineItemHeader}>
              {title}
            </span>
            {subtext ? (
              <span
                className="OneLineText"
                style={{ fontSize: 10, color: Colors.GRAY1 }}
              >
                {subtext}
              </span>
            ) : null}
            {foundEvent ? (
              <span
                className="OneLineText"
                style={{ fontSize: 10, color: Colors.GRAY1 }}
              >
                {formatDate(new Date(foundEvent.start), false, false, true)}
              </span>
            ) : null}
          </div>
        </div>
      </StandardBorderedContainer>
    ),
    [styles.timelineItemContainer, styles.timelineItemHeader]
  );

  const renderHorizontalLine = useMemo(
    () => (
      <div style={{ width: 22 }}>
        <HorizontalDivider />
      </div>
    ),
    []
  );

  // Display text item if there is only one non-trigger text
  const renderSingleText = useMemo(() => {
    if (!nonTriggerText) {
      return null;
    }
    const scheduledText =
      nonTriggerText.scheduled &&
      LessThanDate(new Date().toISOString(), nonTriggerText.sentAt);
    return (
      <div className="AlignedRow" style={{ gap: 4 }}>
        {renderHorizontalLine}
        {renderTimelineItem(
          <Icon
            icon={scheduledText ? "ion:timer" : "ion:paper-plane"}
            height={13}
            color={scheduledText ? Colors.BLUE5 : Colors.BLACK}
          />,
          scheduledText ? "Scheduled" : "Sent",
          formatNumericDate(new Date(nonTriggerText.sentAt)),
          undefined
        )}
      </div>
    );
  }, [nonTriggerText, renderHorizontalLine, renderTimelineItem]);

  if (!campaignDetail) {
    return null;
  }

  return (
    <>
      <div className="AlignedRow" style={{ height: 64 }}>
        <div
          className="ColumnNormalSelect"
          onClick={navigateCampaignItem}
          style={{ flex: AUTOMATION_ITEM_FLEX_SECTIONS[0], gap: 7 }}
        >
          <span className="AboutSubtitle OneLineText" style={{ fontSize: 14 }}>
            {campaignItem.title}
          </span>
          <CampaignTypeItem numMassTexts={massTexts.length} automation />
          <span className="smallBodySubtext">
            Last Updated: {GetDateWithTime(campaignItem.updatedAt, true)}
          </span>
        </div>
        <div style={{ flex: AUTOMATION_ITEM_FLEX_SECTIONS[1] }}>
          <CampaignStatusLabel
            campaign={campaignItem}
            massTexts={massTexts}
            initialMassTextDetail={initialMassTextDetail}
          />
        </div>
        <div
          className="ColumnNormal"
          style={{ flex: AUTOMATION_ITEM_FLEX_SECTIONS[2], gap: 4 }}
        >
          <div style={{ paddingLeft: 14 }}>
            <div className="AlignedRow" style={{ gap: 4 }}>
              <Icon icon={"ion:person"} height={14} />
              <span style={{ fontSize: 14, color: theme.PrimaryText.color }}>
                {campaignDetail.numRecipients}
              </span>
            </div>
            <span className="smallBodySubtext">Started</span>
          </div>
        </div>
        <div
          className="AlignedRowSpaced"
          style={{ flex: AUTOMATION_ITEM_FLEX_SECTIONS[3], gap: 24 }}
        >
          <div className="AlignedRow" style={{ gap: 4 }}>
            {renderTimelineItem(
              foundEvent ? (
                <img
                  src={foundEvent.photoURL}
                  alt={"AutomationEventItem"}
                  style={{ width: 29, height: 29, borderRadius: 4 }}
                />
              ) : (
                <div
                  className="Centering"
                  style={{
                    padding: 7,
                    borderRadius: 4,
                    backgroundColor: Colors.GRAY6,
                  }}
                >
                  <Icon icon="mdi:cursor-default-click" height={17} />
                </div>
              ),
              campaignItem.eventId
                ? foundEvent && isEventExternalLink(foundEvent.eventType)
                  ? "Submitted Information"
                  : "Got Ticket to Event"
                : "Clicked Subscribe",
              foundEvent ? foundEvent.title : undefined,
              foundEvent
            )}
            <div className="AlignedRow" style={{ gap: 4 }}>
              {renderHorizontalLine}
              {renderTimelineItem(
                <Icon icon={"ion:paper-plane"} height={13} />,
                campaignItem.eventId
                  ? foundEvent && isEventExternalLink(foundEvent.eventType)
                    ? "Welcome Text"
                    : "Ticket Text"
                  : "Follow Text",
                "On Trigger",
                undefined
              )}
            </div>
            {numNonTriggerTexts > 1 ? (
              <div className="AlignedRow" style={{ gap: 4 }}>
                {renderHorizontalLine}
                <StandardBorderedContainer
                  containerStyles={{
                    ...styles.timelineItemContainer,
                    paddingBlock: 11,
                    width: 80,
                  }}
                >
                  <div className="Centering">
                    <span style={{ fontSize: 10 }}>
                      +{numNonTriggerTexts}{" "}
                      {handlePluralString("Text", numNonTriggerTexts)}
                    </span>
                  </div>
                </StandardBorderedContainer>
              </div>
            ) : (
              renderSingleText
            )}
          </div>
          {onEventDashboard ? (
            <div>
              <RectangleButton
                buttonLabel="View Automation"
                onPress={() =>
                  navigate(onCampaignNavigatePath(campaignItem.id), {
                    state: { campaignId: campaignItem.id },
                  })
                }
                altBorderRadius={100}
                altPaddingHorz={14}
                altPaddingVert={10}
                containerStyles={{
                  border: `1px solid ${Colors.GRAY2}`,
                  whiteSpace: "nowrap",
                }}
                altColor={Colors.WHITE1}
                altTextColor={Colors.BLACK}
                iconRight={
                  <Icon
                    icon={"ion:arrow-forward"}
                    height={16}
                    style={{ transform: "rotateY(0deg) rotate(-45deg)" }}
                  />
                }
              />
            </div>
          ) : (
            <ButtonWithDropdown
              buttonLabel="View"
              textColor={Colors.BLACK}
              isOpenDropdown={showDropdown}
              setIsOpenDropdown={setShowDropdown}
              onPress={navigateCampaignItem}
              dropdownItems={dropdownItems}
              containerStyles={styles.itemBorder}
            />
          )}
        </div>
      </div>
      {showRenameModal ? (
        <CampaignRenameModal
          campaign={campaignItem}
          showModal={showRenameModal}
          setShowModal={setShowRenameModal}
        />
      ) : null}
    </>
  );
};
