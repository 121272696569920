import { useState } from "react";
import { Colors } from "../../../utils/colors";
import HelpModal from "../../Containers/HelpModal";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import SettingsOption from "../../EventDashboard/SettingsOption";
import { Campaign, CampaignType, MassText } from "@markit/common.types";
import { CampaignConfirmDeleteModal } from "./Items/CampaignConfirmDeleteModal";
import { SUBSCRIBED_CAMPAIGN_NAME } from "@markit/common.utils";

type CampaignSettingsPanelProps = {
  massTexts: MassText[];
  campaign: Campaign;
};

const CampaignSettingsPanel = (props: CampaignSettingsPanelProps) => {
  const { massTexts, campaign } = props;
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const styles = {
    container: { width: 400 },

    contentHeader: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 14,
    },

    optionsContainer: {
      backgroundColor: Colors.WHITE,
      width: "100%",
    },
  };

  return (
    <div>
      <div style={{ paddingInline: 30, paddingBlock: 20 }}>
        <div className={"RowNormal"} style={{ gap: 30 }}>
          <div style={styles.container}>
            <div style={styles.contentHeader}>Data</div>
            <StandardBorderedContainer
              containerStyles={styles.optionsContainer}
            >
              <div>
                <SettingsOption
                  title={"Export Data"}
                  subtext={"Download CSV file of all campaign data"}
                  iconName={"ion:download"}
                  onPress={() => alert("Coming soon...")}
                  disabled={false}
                />
                <HorizontalDivider />
                <SettingsOption
                  title={"Help"}
                  subtext={""}
                  iconName={"ion:help"}
                  onPress={() => setShowHelpModal(true)}
                  disabled={false}
                />
              </div>
            </StandardBorderedContainer>
          </div>
          {campaign.title !== SUBSCRIBED_CAMPAIGN_NAME ? (
            <div style={styles.container}>
              <div style={styles.contentHeader}>More</div>
              <StandardBorderedContainer
                containerStyles={styles.optionsContainer}
              >
                <div>
                  <SettingsOption
                    title={`Delete ${
                      campaign.type === CampaignType.AUTOMATION
                        ? "automation"
                        : "campaign"
                    }`}
                    subtext={"This can't be undone"}
                    iconName={"ion:trash"}
                    onPress={() => setShowDeleteModal(true)}
                    disabled={false}
                    altColor={Colors.RED1}
                  />
                </div>
              </StandardBorderedContainer>
            </div>
          ) : null}
        </div>
        <CampaignConfirmDeleteModal
          campaign={campaign}
          preventDelete={massTexts.some((massText) => massText.queued)}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      </div>
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
    </div>
  );
};

export default CampaignSettingsPanel;
