import "../../css/ProfilePage/ProfilePage.css";
import "../../css/GlobalStyles.css";
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Visibility,
  Event,
  AudienceList,
  SavedFormQuestion,
  AccountData,
  FollowerStatus,
} from "@markit/common.types";
import TopHeader from "../../components/TopHeader";
import ProfilePageHeader from "../../components/ProfilePage/ProfilePageHeader";
import Footer from "../../components/Footer";
import { isDesktop } from "react-device-detect";
import { useOnMount } from "../../hooks/useOnMount";
import { ProfilePageFollowForm } from "../../components/ProfilePage/ProfilePageFollowForm";
import CreatorTextManual from "../../components/CreatorTextManual";
import { Helmet } from "react-helmet";
import {
  useParametricSelector,
  useProfileSelector,
} from "../../hooks/useParametricSelector";
import { getLoadedUserByUsername } from "../../redux/slices/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";
import { MixpanelContext } from "../../context/AnalyticsService";
import { isProfileRCA } from "../../utils/globalUtils";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { API } from "../../API";
import { fetchUserPublicAudienceLists } from "../../utils/userUtils";
import { Colors } from "../../utils/colors";
import { getUserFollowerRef } from "../../utils/FirebaseUtils";
import { onSnapshot } from "../../firebase";
import { loadUserByUsername } from "../../redux/sagas/dataSagas";
import { getProfileFormQuestions } from "../../utils/eventUtils/formUtils";
import { SwitchToAlternateMode } from "../../components/ProfilePage/SwitchToAlternateMode";
import OrganizersInfoDisplay from "../../components/FullEventSubComponents/OrganizersInfoDisplay";
import {
  ProfileDisplayView,
  ProfilePageTimelineHeader,
  ProfileTimelineState,
} from "../../components/ProfilePage/ProfilePageTimelineHeader";
import ProfilePageTimeline from "../../components/ProfilePage/ProfilePageTimeline";
import {
  LessThanDate,
  datesAreOnSameDay,
  foundUserGenericLinks,
  hostedEvents,
  hostingLiveEvents,
  isEventExternalLink,
  isExternalGenericLink,
} from "@markit/common.utils";
import { getEventState } from "../../redux/slices/eventSlice";
import { fetchEventOrganizers } from "../../utils/eventUtils/eventUtils";
import useAsyncEffect from "../../hooks/useAsyncEffect";

type ProfilePageProps = {};
const ProfilePage = memo(function ProfilePageFn(props: ProfilePageProps) {
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn, appInitialized } = account;
  const { events: eventList } = useSelector(getEventState);
  const { events, tickets } = eventList;
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const [showForm, setShowForm] = useState(
    location.state?.showForm ? location.state.showForm : false
  );
  const [loading, setLoading] = useState(true);
  const [publicCreatorLists, setPublicCreatorLists] = useState<AudienceList[]>(
    []
  );
  const [profileFormQuestions, setProfileFormQuestions] = useState<
    SavedFormQuestion[]
  >([]);

  const [alreadyFollowing, setAlreadyFollowing] = useState(false);
  // Profile Timeline States
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingDates, setLoadingDates] = useState(true);
  const [loadingTimelineState, setLoadingTimelineState] = useState(true);
  const [userAttendingEvents, setUserAttendingEvents] = useState<Event[]>([]);
  const [userEvents, setUserEvents] = useState<Event[]>([]);
  const [eventOrganizers, setEventOrganizers] = useState<AccountData[]>([]);
  const [eventDates, setEventDates] = useState<string[]>([]);
  const [eventTimelineState, setEventTimelineState] =
    useState<ProfileTimelineState>(ProfileTimelineState.UPCOMING);
  const [eventDisplayView, setEventDisplayView] = useState<ProfileDisplayView>(
    ProfileDisplayView.CARD_VIEW
  );
  const [showMoreEvents, setShowMoreEvents] = useState(false);

  const username = useProfileSelector();
  const {
    isLoading: isLoadingUserData,
    data: userData,
    isError: isErrorUserData,
  } = useParametricSelector(getLoadedUserByUsername, username);

  const refContainer = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();

  useOnMount(() => {
    // If openModal is part of the url, show the follow modal
    const modalOpen = searchParams.get("openModal");
    if (modalOpen === "true") {
      setShowForm(true);
    }
    // If condensed is part of the url, show the grid view defaulted
    const condensedView = searchParams.get("condensed");
    if (condensedView === "true") {
      setEventDisplayView(ProfileDisplayView.GRID_VIEW);
    }
    mixpanel.track("Webapp: Opened Profile Link", {
      username: username,
    });
  });

  // prevent background scrolling when the follow modal is open
  useEffect(() => {
    if (showForm) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showForm]);

  const isMyAccount = useMemo(
    () =>
      loggedIn === LoginState.LOGGED_IN && accountData.uid === userData?.uid,
    [accountData.uid, loggedIn, userData?.uid]
  );

  const userDataToShow = useMemo(
    () => (isMyAccount ? accountData : userData),
    [accountData, isMyAccount, userData]
  );

  const isUserRCA = useMemo(
    () => (userData && isProfileRCA(userData.uid)) ?? false,
    [userData]
  );

  const showAllEvents = useMemo(
    () => showMoreEvents || eventDisplayView === ProfileDisplayView.GRID_VIEW,
    [eventDisplayView, showMoreEvents]
  );

  const hideProfileEventsSection = useMemo(
    () => !isMyAccount && userDataToShow?.hideProfileEvents,
    [isMyAccount, userDataToShow?.hideProfileEvents]
  );

  const userLinks = useMemo(() => {
    const links = isMyAccount
      ? foundUserGenericLinks(events, accountData.uid, "active")
      : userEvents.filter((event) => isExternalGenericLink(event.eventType));
    return links;
  }, [accountData.uid, events, isMyAccount, userEvents]);

  const userEventsNoLinks = useMemo(
    () => userEvents.filter((event) => !isExternalGenericLink(event.eventType)),
    [userEvents]
  );

  const upcomingEvents = useMemo(() => {
    const hosting = isMyAccount
      ? hostingLiveEvents(events, accountData.uid, true)
      : userEventsNoLinks.filter((event) =>
          LessThanDate(new Date().toISOString(), event.end)
        );
    return hosting;
  }, [accountData.uid, events, isMyAccount, userEventsNoLinks]);

  const pastEvents = useMemo(() => {
    const hosted = isMyAccount
      ? hostedEvents(events, accountData.uid)
      : userEventsNoLinks.filter((event) =>
          LessThanDate(event.end, new Date().toISOString())
        );
    return hosted.reverse();
  }, [accountData.uid, events, isMyAccount, userEventsNoLinks]);

  const attendingEvents = useMemo(() => {
    const attending = userAttendingEvents.filter((event) =>
      LessThanDate(new Date().toISOString(), event.end)
    );
    return attending;
  }, [userAttendingEvents]);

  const attendedEvents = useMemo(() => {
    const attended = userAttendingEvents.filter((event) =>
      LessThanDate(event.end, new Date().toISOString())
    );
    return attended.reverse();
  }, [userAttendingEvents]);

  const eventsToShow = useMemo(() => {
    switch (eventTimelineState) {
      case ProfileTimelineState.LINKS:
        return userLinks;
      case ProfileTimelineState.UPCOMING:
        return upcomingEvents;
      case ProfileTimelineState.PAST:
        return pastEvents;
      case ProfileTimelineState.ATTENDING:
        return attendingEvents;
      case ProfileTimelineState.ATTENDED:
        return attendedEvents;
    }
  }, [
    attendedEvents,
    attendingEvents,
    eventTimelineState,
    pastEvents,
    upcomingEvents,
    userLinks,
  ]);

  useEffect(() => {
    if (!userDataToShow || loggedIn !== LoginState.LOGGED_IN) {
      return;
    }

    const followerRef = getUserFollowerRef(userDataToShow.uid, accountData.uid);
    const unsubscribe = onSnapshot(followerRef, (doc) => {
      const data = doc.data();
      setAlreadyFollowing(
        data ? data.status === FollowerStatus.SUBSCRIBED : false
      );
    });

    return () => unsubscribe();
  }, [accountData.uid, loggedIn, userDataToShow]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (!userData) {
      setTimeout(() => {
        if (isErrorUserData && !isLoadingUserData) {
          // attempt a refetch of the profile data based on username
          loadUserByUsername(username);

          // mixpanel.track(
          //   "Webapp: Refetch Attempt For Error Loading Profile",
          //   { username: username }
          // );
          // let new data attempt to load before it sets loading to false
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      }, 5000);
      return;
    }

    let finalEvents: Event[] = [];
    if (loadingEvents) {
      if (isMyAccount) {
        // Return early if redux state is not loaded yet
        if (!appInitialized) {
          return;
        }
        // nonCreatedTickets refers to any event you aren't the explicit creator
        const nonCreatedTickets = tickets.filter(
          (ticket) => ticket.customTicketId !== ""
        );
        const nonCreatedEvents = events
          .filter((event) => !isEventExternalLink(event.eventType))
          .filter((myEvent: Event) =>
            nonCreatedTickets.find((tickets) => tickets.eventId === myEvent.id)
          );
        setUserAttendingEvents(
          nonCreatedEvents.sort((a, b) => {
            return new Date(a.start).getTime() - new Date(b.start).getTime();
          })
        );
        finalEvents = events;
      } else {
        const [otherWishlistResponse, wishlistResponse] = await Promise.all([
          API.user.otherUserWishList({
            uid: userData.uid,
          }),
          API.user.wishList({
            uid: userData.uid,
          }),
        ]);
        const { events: otherEvents } = otherWishlistResponse;
        const { events: ticketEvents, tickets: otherTickets } =
          wishlistResponse;
        setUserEvents(
          otherEvents.filter(
            (event) => !event.isDraft && event.visibility === Visibility.Public
          )
        );
        const organizerTickets = otherTickets.filter(
          (ticket) =>
            ticket.customTicketId === "" &&
            ticket.originalOwner !== userData.uid
        );
        // Filter out private events, tickets that you were an organizer for, and links
        setUserAttendingEvents(
          ticketEvents
            .filter(
              (event) =>
                event.visibility === Visibility.Public &&
                !isEventExternalLink(event.eventType)
            )
            .filter(
              (event) =>
                !organizerTickets.some((ticket) => ticket.eventId === event.id)
            )
            .sort((a: Event, b: Event) => {
              return new Date(a.start).getTime() - new Date(b.start).getTime();
            })
        );
        finalEvents = otherEvents.concat(ticketEvents);
      }

      const eventOrganizers = await fetchEventOrganizers(finalEvents);
      setEventOrganizers(eventOrganizers);
    }

    setLoadingEvents(false);
    dispatch(accountActions.setIsRCA(isUserRCA));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData.uid, appInitialized, dispatch, events, isErrorUserData, isLoadingUserData, isMyAccount, isUserRCA, loadingEvents, tickets, username]);

  // Load the default timeline state, which is determined after loading all the events
  useEffect(() => {
    if (!loadingEvents && loadingTimelineState) {
      setEventTimelineState(
        upcomingEvents.length === 0
          ? userLinks.length === 0
            ? pastEvents.length === 0
              ? attendingEvents.length === 0
                ? attendedEvents.length === 0
                  ? ProfileTimelineState.UPCOMING
                  : ProfileTimelineState.ATTENDED
                : ProfileTimelineState.ATTENDING
              : ProfileTimelineState.PAST
            : ProfileTimelineState.LINKS
          : ProfileTimelineState.UPCOMING
      );
      setLoadingTimelineState(false);
    }
  }, [attendedEvents.length, attendingEvents.length, loadingEvents, loadingTimelineState, pastEvents.length, upcomingEvents.length, userLinks.length]);

  // Load the unique event dates for the events loaded in for the timeline
  useEffect(() => {
    const finalEvents = showAllEvents ? eventsToShow : eventsToShow.slice(0, 3);
    if (!loadingEvents) {
      if (finalEvents.length > 0) {
        const foundEventDates: string[] = [];
        for (let i = 0; i < finalEvents.length; i++) {
          if (
            !foundEventDates.some((eventDate) =>
              datesAreOnSameDay(
                new Date(eventDate),
                new Date(finalEvents[i].start)
              )
            )
          ) {
            foundEventDates.push(finalEvents[i].start);
          }
        }
        setEventDates(foundEventDates);
      }
      setLoadingDates(false);
    }
  }, [eventsToShow, loadingEvents, showAllEvents, showMoreEvents]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (!userData) {
      setTimeout(() => {
        if (isErrorUserData && !isLoadingUserData) {
          setLoading(false);
        }
      }, 5000);
      return;
    }

    // fetch audience list of profile user
    const publicAudienceLists = await fetchUserPublicAudienceLists(
      userData.uid
    );
    setPublicCreatorLists(publicAudienceLists);

    // fetch the event form questions
    const formQuestions = await getProfileFormQuestions(userData.uid);
    setProfileFormQuestions(formQuestions);

    setLoading(false);
  }, [accountData.uid, isErrorUserData, isLoadingUserData, loggedIn, userData]);

  if (loading || isLoadingUserData) {
    return <LoadingScreen isLoadingPage={loading || isLoadingUserData} />;
  }

  if (isErrorUserData || userDataToShow == null) {
    return <LoadingScreen error />;
  }

  const title = `${userDataToShow.fullName} (@${userDataToShow.username}) on Markit`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="og:title" content={title} />
        <meta name="og:description" content={userDataToShow.username} />
      </Helmet>
      {showForm ? (
        <ProfilePageFollowForm
          creatorData={userDataToShow}
          profileFormQuestions={profileFormQuestions}
          publicCreatorLists={publicCreatorLists}
          alreadyFollowing={alreadyFollowing}
          setAlreadyFollowing={setAlreadyFollowing}
          setShowForm={setShowForm}
        />
      ) : null}
      <div ref={refContainer}>
        <div className="ProfileWrapper">
          <div className="ProfileHeaderDesktop">
            <TopHeader showRCA isOnProfilePage />
          </div>
          <div style={{ width: "100%" }}>
            <ProfilePageHeader
              alreadyFollowing={alreadyFollowing}
              setShowForm={setShowForm}
              otherUserData={userDataToShow}
            />
            {!isDesktop && !hideProfileEventsSection ? (
              <div style={{ paddingInline: 14 }}>
                <ProfilePageTimelineHeader
                  timelineState={eventTimelineState}
                  setTimelineState={setEventTimelineState}
                  displayView={eventDisplayView}
                  setDisplayView={setEventDisplayView}
                  loading={loadingTimelineState}
                  hasLinks={userLinks.length > 0}
                />
              </div>
            ) : null}
            <div
              className="RowNormal"
              style={{
                flexWrap: "wrap",
                gap: 24,
                backgroundColor: Colors.WHITE1,
                borderTop: "0.5px solid #EDEDED",
                borderBottom: "0.5px solid #EDEDED",
                marginBlock: isDesktop ? 40 : 14,
                paddingTop: isDesktop ? 40 : 24,
                paddingBottom: isDesktop ? 60 : 24,
                paddingInline: isDesktop ? "22vw" : "14px",
              }}
            >
              {hideProfileEventsSection ? null : (
                <div className="ColumnNormal" style={{ flex: 1, gap: 14 }}>
                  {isDesktop ? (
                    <ProfilePageTimelineHeader
                      timelineState={eventTimelineState}
                      setTimelineState={setEventTimelineState}
                      displayView={eventDisplayView}
                      setDisplayView={setEventDisplayView}
                      loading={loadingTimelineState}
                      hasLinks={userLinks.length > 0}
                    />
                  ) : null}
                  <ProfilePageTimeline
                    displayView={eventDisplayView}
                    timelineState={eventTimelineState}
                    userEventsToShow={eventsToShow}
                    userData={userData}
                    eventDates={eventDates}
                    eventOrganizers={eventOrganizers}
                    showMoreEvents={showAllEvents}
                    setShowMoreEvents={setShowMoreEvents}
                    loading={
                      loadingEvents || loadingDates || loadingTimelineState
                    }
                  />
                </div>
              )}
              <div
                className="ColumnNormal"
                style={{
                  width: isDesktop && !hideProfileEventsSection ? 320 : "100%",
                  minWidth:
                    isDesktop && !hideProfileEventsSection ? 320 : "100%",
                }}
              >
                {isMyAccount ? (
                  <SwitchToAlternateMode />
                ) : (
                  <div
                    className={
                      isDesktop && hideProfileEventsSection
                        ? "AlignedRow"
                        : "ColumnNormal"
                    }
                    style={{
                      gap: 14,
                      alignItems:
                        isDesktop && hideProfileEventsSection
                          ? "flex-start"
                          : undefined,
                    }}
                  >
                    {userData ? (
                      <div style={{ flex: 1 }}>
                        <OrganizersInfoDisplay
                          host={userData}
                          organizers={[userData]}
                          isProfilePage
                        />
                      </div>
                    ) : null}
                    <CreatorTextManual host={userDataToShow} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer showRCA />
      </div>
    </>
  );
});

export default ProfilePage;
