import { useCallback, useState } from "react";
import { AccountData } from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../redux/slices/accountSlice";
import { makeEmptyAccount } from "@markit/common.utils";

export const usePartialAccountUpdates = () => {
  const { accountData } = useSelector(getAccountState).account;
  const [accountSettings, setAccountSettings] = useState<AccountData>(
    accountData ?? makeEmptyAccount()
  );

  const updateAccountSettings = useCallback(
    (newAccountSettings: Partial<AccountData>) => {
      setAccountSettings((accountSettings) => {
        return { ...accountSettings, ...newAccountSettings };
      });
    },
    []
  );

  const resetAccountSettings = useCallback(() => {
    setAccountSettings(accountData ?? makeEmptyAccount());
  }, [accountData]);

  return {
    accountSettings,
    updateAccountSettings,
    resetAccountSettings,
  };
};
