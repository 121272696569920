import { MassTextScreenType, MassTextsSharedProps } from "../MassTextPanel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import {
  AccountData,
  AudienceList,
  CampaignType,
  ImportData,
  MassText,
  SavedFormQuestion,
  SelectRecipientGroupType,
  SelectRecipientType,
  SubSelectionItem,
} from "@markit/common.types";
import SelectRecipientsEvent from "./SelectEvents/SelectRecipientsEvent";
import SelectRecipientsAudienceList from "./SelectAudienceLists/SelectRecipientsAudienceList";
import SelectRecipientsCollectedData from "./SelectCollectedData/SelectRecipientsCollectedData";
import SelectRecipientHeader from "./SelectRecipientsHeader";
import SelectRecipientsOverview from "./SelectRecipientsOverview";
import { getEventState } from "../../../../redux/slices/eventSlice";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import SelectedRecipientsList from "./SelectedRecipientsList";
import SelectRecipientsEventLibrary from "./SelectEvents/SelectRecipientsEventLibrary";
import SelectRecipientsAudienceListLibrary from "./SelectAudienceLists/SelectRecipientsAudienceListLibrary";
import SelectRecipientsFollowers from "./SelectRecipientsFollowers";
import SelectRecipientsImportLibrary from "./SelectImports/SelectRecipientsImportLibrary";
import SelectRecipientsImport from "./SelectImports/SelectRecipientsImport";
import { arraysEqual } from "@markit/common.utils";
import MassTextBodyWrapper from "../MassTextBodyWrapper";
import { MassTextsWrapperType } from "../MassTextWrapperManager";

export type SelectRecipientsSharedProps = {
  unsavedRecipients: string[];
  setUnsavedRecipients: (unsavedRecipients: string[]) => void;
  unsavedCategories: SubSelectionItem[];
  setUnsavedCategories: (unsavedRecipients: SubSelectionItem[]) => void;
  excludingMode: boolean;
  setProfileSelected: (profileSelected: AccountData) => void;
  showCategories: boolean;
};

// for the header and the main file
export type SelectRecipientsMainSharedProps = {
  wrapperType: MassTextsWrapperType;
  excludingMode: boolean;
  setExcludingMode: (excludingMode: boolean) => void;
  massText?: MassText;
  updateMassTextSettings?: (massText: Partial<MassText>) => void;
  showCategories?: boolean;
  setSuggestedAction?: (suggestedAction: SubSelectionItem) => void;
  showDoneButton: boolean;
  setShowDoneButton: (showDoneButton: boolean) => void;
};

type SelectRecipientsScreensProps = MassTextsSharedProps & {
  allSelectedPeople: string[];
  setAllSelectedPeople: (allSelectedPeople: string[]) => void;
  allExcludedPeople: string[];
  setAllExcludedPeople: (allExcludedPeople: string[]) => void;
  setSuggestedAction: (suggestedAction: SubSelectionItem | undefined) => void;
};

const SelectRecipientsScreens = (props: SelectRecipientsScreensProps) => {
  const {
    wrapperType,
    campaign,
    setMainScreen,
    setProfileSelected,
    massTextSettings,
    updateMassTextSettings,
    allSelectedPeople,
    setAllSelectedPeople,
    allExcludedPeople,
    setAllExcludedPeople,
    setSuggestedAction,
  } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { events } = useSelector(getEventState).events;

  const [unsavedRecipients, setUnsavedRecipients] = useState<string[]>([]);
  const [unsavedCategories, setUnsavedCategories] = useState<
    SubSelectionItem[]
  >([]);

  const [selectedType, setSelectedType] = useState<SelectRecipientGroupType>({
    type: SelectRecipientType.NONE,
    selectedItem: undefined,
    eventTabValue: 0,
    isPreview: false,
  });
  const [excludingMode, setExcludingMode] = useState<boolean>(false);
  const [showDoneButton, setShowDoneButton] = useState(false);

  const foundEvent = useMemo(
    () => events.find((event) => event.id === massTextSettings.eventRefId),
    [events, massTextSettings.eventRefId]
  );

  const isHost = useMemo(
    () => foundEvent && accountData.uid === foundEvent.createdBy,
    [accountData.uid, foundEvent]
  );

  // Show categories if ALL are satisfied:
  // 1. Automation campaign with event trigger
  // 2. Only if they are the host of the event
  const showCategories = useMemo(() => {
    return !!(
      isHost &&
      campaign !== undefined &&
      campaign.type === CampaignType.AUTOMATION &&
      campaign.eventId !== undefined
    );
  }, [campaign, isHost]);

  const allRecipients = useMemo(
    () =>
      excludingMode
        ? showCategories
          ? massTextSettings.excludedSubSelectionIds
          : allExcludedPeople
        : showCategories
        ? massTextSettings.subSelectionIds
        : allSelectedPeople,
    [
      allExcludedPeople,
      allSelectedPeople,
      excludingMode,
      massTextSettings.excludedSubSelectionIds,
      massTextSettings.subSelectionIds,
      showCategories,
    ]
  );

  const madeChanges = useMemo(() => {
    if (selectedType.type !== SelectRecipientType.NONE) {
      return showCategories
        ? !arraysEqual(
            excludingMode
              ? massTextSettings.excludedSubSelectionIds.map((cat) => cat.id)
              : massTextSettings.subSelectionIds.map((cat) => cat.id),
            unsavedCategories.map((cat) => cat.id)
          )
        : !arraysEqual(
            excludingMode ? allExcludedPeople : allSelectedPeople,
            unsavedRecipients
          );
    }
    return false;
  }, [
    allExcludedPeople,
    allSelectedPeople,
    excludingMode,
    massTextSettings.excludedSubSelectionIds,
    massTextSettings.subSelectionIds,
    selectedType.type,
    showCategories,
    unsavedCategories,
    unsavedRecipients,
  ]);

  useEffect(() => {
    // Update the unsavedRecipients whenever you go to a selection screen again
    if (selectedType.type !== SelectRecipientType.NONE) {
      showCategories
        ? setUnsavedCategories(allRecipients as SubSelectionItem[])
        : setUnsavedRecipients(allRecipients as string[]);
    }
  }, [allRecipients, selectedType.type, showCategories]);

  useEffect(() => {
    // Clear the excluded recipients/categories if there are no selected recipients/categories
    if (allRecipients.length === 0 && !excludingMode) {
      updateMassTextSettings({ excludedSubSelectionIds: [] });
      setAllExcludedPeople([]);
    }
  }, [
    allRecipients.length,
    excludingMode,
    setAllExcludedPeople,
    updateMassTextSettings,
  ]);

  const updateSelectType = useCallback(
    (newSelectedType: Partial<SelectRecipientGroupType>) => {
      setSelectedType((prevSelectedType) => {
        return { ...prevSelectedType, ...newSelectedType };
      });
    },
    []
  );

  const backToOverview = useCallback(
    (numRecipients?: number) => {
      if (
        (numRecipients && numRecipients > 0) ||
        massTextSettings.subSelectionIds.length > 0 ||
        massTextSettings.excludedSubSelectionIds.length > 0 ||
        allSelectedPeople.length > 0 ||
        allExcludedPeople.length > 0
      ) {
        setSuggestedAction(undefined);
      }
      setMainScreen(MassTextScreenType.OVERVIEW);
    },
    [
      allExcludedPeople.length,
      allSelectedPeople.length,
      massTextSettings.excludedSubSelectionIds.length,
      massTextSettings.subSelectionIds.length,
      setMainScreen,
      setSuggestedAction,
    ]
  );

  const resetOnPress = useCallback(() => {
    setAllExcludedPeople([]);
    setAllSelectedPeople([]);
    if (updateMassTextSettings) {
      updateMassTextSettings({
        subSelectionIds: [],
        excludedSubSelectionIds: [],
      });
    }
    updateSelectType({ type: SelectRecipientType.NONE });
  }, [
    setAllExcludedPeople,
    setAllSelectedPeople,
    updateMassTextSettings,
    updateSelectType,
  ]);

  const sharedPropsPack = {
    unsavedRecipients: unsavedRecipients,
    setUnsavedRecipients: setUnsavedRecipients,
    unsavedCategories: unsavedCategories,
    setUnsavedCategories: setUnsavedCategories,
    excludingMode: excludingMode,
    setProfileSelected: setProfileSelected,
    showCategories: showCategories,
  };

  const mainSharedPropsPack = {
    wrapperType: wrapperType,
    excludingMode: excludingMode,
    setExcludingMode: setExcludingMode,
    massText: massTextSettings,
    updateMassTextSettings: updateMassTextSettings,
    showCategories: showCategories,
    setSuggestedAction: setSuggestedAction,
    showDoneButton: showDoneButton,
    setShowDoneButton: setShowDoneButton,
  };

  return (
    <div className="PreventScroll">
      <SelectRecipientHeader
        {...mainSharedPropsPack}
        backToOverview={backToOverview}
        selectType={selectedType}
        updateSelectType={updateSelectType}
        unsavedRecipients={unsavedRecipients}
        setUnsavedRecipients={setUnsavedRecipients}
        unsavedCategories={unsavedCategories}
        setUnsavedCategories={setUnsavedCategories}
        setAllExcludedPeople={setAllExcludedPeople}
        setAllSelectedPeople={setAllSelectedPeople}
        madeChanges={madeChanges}
      />
      <HorizontalDivider />
      <MassTextBodyWrapper wrapperType={wrapperType}>
        <div
          className="PreventScroll ColumnNormal"
          style={{
            padding: wrapperType !== MassTextsWrapperType.MODAL ? 20 : 14,
          }}
        >
          {selectedType.type === SelectRecipientType.NONE ? (
            <SelectRecipientsOverview
              {...mainSharedPropsPack}
              allSelectedPeople={allSelectedPeople}
              setAllSelectedPeople={setAllSelectedPeople}
              allExcludedPeople={allExcludedPeople}
              setAllExcludedPeople={setAllExcludedPeople}
              updateSelectType={updateSelectType}
              setProfileSelected={setProfileSelected}
              backToOverview={backToOverview}
            />
          ) : null}
          {/* Select by Followers Screen */}
          {selectedType.type === SelectRecipientType.PEOPLE ? (
            <SelectRecipientsFollowers {...sharedPropsPack} />
          ) : null}
          {/* Select by Event Screen */}
          {selectedType.type === SelectRecipientType.EVENTS ? (
            selectedType.selectedItem ? (
              <SelectRecipientsEvent
                selectedType={selectedType}
                {...sharedPropsPack}
              />
            ) : (
              <SelectRecipientsEventLibrary
                updateSelectType={updateSelectType}
              />
            )
          ) : null}
          {/* Select by Audience List Screen */}
          {selectedType.type === SelectRecipientType.LISTS ? (
            selectedType.selectedItem ? (
              <SelectRecipientsAudienceList
                selectedList={selectedType.selectedItem as AudienceList}
                {...sharedPropsPack}
              />
            ) : (
              <SelectRecipientsAudienceListLibrary
                excludingMode={excludingMode}
                updateSelectType={updateSelectType}
              />
            )
          ) : null}
          {/* Select by Collected Data Screen */}
          {selectedType.type === SelectRecipientType.COLLECTED_DATA ? (
            <SelectRecipientsCollectedData
              selectedQuestion={selectedType.selectedItem as SavedFormQuestion}
              updateSelectType={updateSelectType}
              {...sharedPropsPack}
            />
          ) : null}
          {/* Select by Import Screen */}
          {selectedType.type === SelectRecipientType.IMPORTS ? (
            selectedType.selectedItem ? (
              <SelectRecipientsImport
                selectedImport={selectedType.selectedItem as ImportData}
                {...sharedPropsPack}
              />
            ) : (
              <SelectRecipientsImportLibrary
                updateSelectType={updateSelectType}
              />
            )
          ) : null}
          {/* Selected Recipients Screen */}
          {selectedType.type === SelectRecipientType.RECIPIENTS ? (
            <SelectedRecipientsList
              {...mainSharedPropsPack}
              unsavedRecipients={unsavedRecipients}
              setUnsavedRecipients={setUnsavedRecipients}
              unsavedCategories={unsavedCategories}
              setUnsavedCategories={setUnsavedCategories}
              allSelectedPeople={allSelectedPeople}
              allExcludedPeople={allExcludedPeople}
              setProfileSelected={setProfileSelected}
              resetOnPress={resetOnPress}
            />
          ) : null}
        </div>
      </MassTextBodyWrapper>
    </div>
  );
};

export default SelectRecipientsScreens;
