import { Icon } from "@iconify/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Colors } from "../../../../utils/colors";
import PopupModalContainer from "../../../Containers/PopupModalContainer";
import { DarkTheme, LightTheme, ThemeStyle } from "../../../../hooks/useTheme";
import {
  AttachmentSourceType,
  Event,
  MassText,
  MassTextAttachment,
  MassTextAttachmentType,
} from "@markit/common.types";
import { EventContentMobile } from "../../../LandingPage/RenderedEvents";
import RectangleButton from "../../../Buttons/RectangleButton";
import QuantityTextField from "../../../QuantityTextField";
import {
  MARKITAI,
  attLabelPattern,
  attachmentDetails,
  filterUndefinedValues,
  groupByKey,
  hostingEventsCanMassTextAndSendPromoTickets,
  hostingLiveEvents,
  isFreeTickets,
  isRequestTickets,
  truncateString,
} from "@markit/common.utils";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import filter from "lodash.filter";
import { EmptySearchState } from "../../../EmptyStates/EmptySearchState";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { getEventState } from "../../../../redux/slices/eventSlice";
import { AttachmentMode } from "../../../../hooks/useMassTextManagement";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import { getUserWishlistSnap } from "../../../../utils/FirebaseUtils";
import useAsyncOnMount from "../../../../hooks/useAsyncEffectOnMount";

type MassTextsAttachmentModalProps = {
  setShowAttachmentModal: (showAttachmentModal: boolean) => void;
  generateNewMassTextMessage: (eventURL: string) => void;
  currAttachmentLink: string;
  setCurrAttachmentLink: (currAttachmentLink: string) => void;
  attachmentMode: AttachmentMode;
  attachmentSettings: MassTextAttachment;
  updateAttachmentSettings: (
    attachmentSettings: Partial<MassTextAttachment>
  ) => void;
  resetAttachmentState: () => void;
  massText?: MassText;
  attachmentSourceType: AttachmentSourceType;
  conversationUserId?: string;
  theme?: ThemeStyle;
};

const MassTextsAttachmentModal = (props: MassTextsAttachmentModalProps) => {
  const {
    setShowAttachmentModal,
    generateNewMassTextMessage,
    currAttachmentLink,
    setCurrAttachmentLink,
    attachmentMode,
    attachmentSettings,
    updateAttachmentSettings,
    resetAttachmentState,
    massText,
    attachmentSourceType,
    conversationUserId,
    theme,
  } = props;
  const [attachmentSearchTerm, setAttachmentSearchTerm] = useState<string>("");
  const [attachmentModalStep, setAttachmentModalStep] = useState<number>(0);
  const uid = useSelector(getAccountState).account.accountData.uid;
  const { events: eventList } = useSelector(getEventState);
  const { events } = eventList;
  const [conversationUserEvents, setConversationUserEvents] = useState<Event[]>(
    []
  );
  const [loadingTicketLinks, setLoadingTicketLinks] = useState(true);

  const eventSelectedForAttachment = useMemo(
    () => events.find((event) => event.id === attachmentSettings.eventId),
    [attachmentSettings.eventId, events]
  );

  const hostingEvents = useMemo(
    () => hostingLiveEvents(events, uid, true).reverse(),
    [uid, events]
  );

  useAsyncOnMount(async () => {
    if (
      (attachmentMode.label === MassTextAttachmentType.TICKET_LINK ||
        attachmentMode.label === MassTextAttachmentType.CALENDAR_INVITE) &&
      attachmentSourceType === AttachmentSourceType.CONVERSATION &&
      conversationUserId
    ) {
      // change this to backend function in future since it's in both webapp and frontend
      // get the conversation user's events that they're attending of the host
      const wishlistSnap = await getUserWishlistSnap(conversationUserId);
      const foundEvents = await Promise.all(
        wishlistSnap.docs.map((doc) => {
          const eventId = doc.data().eventId;
          const hostEvent = hostingLiveEvents(events, uid).find(
            (event) => event.id === eventId
          );
          if (hostEvent) {
            return hostEvent;
          }
          return undefined;
        })
      );
      const definedEvents: Event[] = filterUndefinedValues(foundEvents).sort(
        (a, b) => new Date(b.end).getTime() - new Date(a.end).getTime()
      );
      setConversationUserEvents(definedEvents);
    }
    setLoadingTicketLinks(false);
  });

  const eventsHostingCanMassText = useMemo(() => {
    if (
      attachmentSourceType === AttachmentSourceType.CONVERSATION &&
      (attachmentMode.label === MassTextAttachmentType.TICKET_LINK ||
        attachmentMode.label === MassTextAttachmentType.CALENDAR_INVITE)
    ) {
      return conversationUserEvents;
    }
    return hostingEvents.reverse();
  }, [
    attachmentMode.label,
    attachmentSourceType,
    conversationUserEvents,
    hostingEvents,
  ]);

  const eventsHostingCanMassTextAndSendPromoTickets = useMemo(
    () => hostingEventsCanMassTextAndSendPromoTickets(events, uid, true),
    [uid, events]
  );

  const renderMassTextsAttachmentModalHeader = useCallback(() => {
    let description = "";
    switch (attachmentMode.label) {
      case MassTextAttachmentType.LINK:
        description = "Select an event to insert as a link";
        break;
      case MassTextAttachmentType.TICKET_LINK:
        description = "This person has tickets to the following events.";
        break;
      case MassTextAttachmentType.CALENDAR_INVITE:
        description = "This person has tickets to the following events.";
        break;
      case MassTextAttachmentType.FREE_TICKET_LINK:
        description = "Select an event to create a free ticket";
        break;
      case MassTextAttachmentType.DISCOUNTED_TICKET_LINK:
        description =
          "Create and attach a discounted ticket link for one of your events for this text.";
        break;
      case MassTextAttachmentType.GENERATE_TICKET_BUNDLE:
        description =
          "Create and attach a ticket bundle for one of your events for this text.";
        break;
    }

    if (attachmentModalStep !== 0) {
      return (
        <div
          className="LargePopupPanelClose"
          style={theme?.TertiaryBG}
          onClick={() => {
            setAttachmentModalStep(0);
          }}
        >
          <Icon
            icon={"ion:chevron-back"}
            height={24}
            color={theme?.PrimaryText.color}
          />
        </div>
      );
    } else
      return (
        <div className="AlignedRow" style={{ gap: 14 }}>
          <div
            className="MassTextsModalAttachmentPill"
            style={theme?.TertiaryBG}
          >
            {attachmentMode.label ===
            MassTextAttachmentType.GENERATE_TICKET_BUNDLE ? (
              <>
                <Icon
                  icon={attachmentMode.icon}
                  height={14}
                  color={Colors.GRAY1}
                />
                <Icon
                  icon={attachmentMode.icon}
                  height={14}
                  color={Colors.GRAY1}
                />
              </>
            ) : (
              <Icon
                icon={attachmentMode.icon}
                height={20}
                color={Colors.GRAY1}
              />
            )}
          </div>
          <div className="ColumnNormal">
            <p className="AboutSubtitle">Insert {attachmentMode.label}</p>
            <p
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: Colors.GRAY1,
                paddingRight: 10,
              }}
            >
              {description}
            </p>
          </div>
        </div>
      );
  }, [
    attachmentMode.label,
    attachmentMode.icon,
    attachmentModalStep,
    theme?.TertiaryBG,
    theme?.PrimaryText.color,
  ]);

  const userEvents = useMemo(() => {
    const events =
      attachmentMode.label === MassTextAttachmentType.LINK ||
      attachmentMode.label === MassTextAttachmentType.TICKET_LINK ||
      attachmentMode.label === MassTextAttachmentType.CALENDAR_INVITE
        ? eventsHostingCanMassText
        : eventsHostingCanMassTextAndSendPromoTickets;

    if (attachmentSearchTerm === "") {
      return events;
    }
    let eventsList: Event[] = events;
    eventsList = filter(eventsList, (event: Event) => {
      return event.title.toLowerCase().includes(attachmentSearchTerm);
    });

    return eventsList;
  }, [
    attachmentMode,
    eventsHostingCanMassText,
    eventsHostingCanMassTextAndSendPromoTickets,
    attachmentSearchTerm,
  ]);

  const finalizeAttachment = useCallback(
    (attachmentLink: string) => {
      generateNewMassTextMessage(attachmentLink);
      setShowAttachmentModal(false);
      setAttachmentModalStep(0); // reset to initial modal screen
      resetAttachmentState();
    },
    [generateNewMassTextMessage, resetAttachmentState, setShowAttachmentModal]
  );

  useEffect(() => {
    if (
      attachmentSourceType === AttachmentSourceType.EVENT_TEXT &&
      massText &&
      massText.eventRefId !== ""
    ) {
      if (attachmentMode.label === MassTextAttachmentType.TICKET_LINK) {
        const attachmentLink = `${MARKITAI}/e/${massText.eventRefId}?type=ticket`;
        finalizeAttachment(attachmentLink);
      } else if (
        attachmentMode.label === MassTextAttachmentType.CALENDAR_INVITE
      ) {
        const attachmentLink = `${MARKITAI}/e/${massText.eventRefId}?type=calendar`;
        finalizeAttachment(attachmentLink);
      }
    }
  }, [
    attachmentMode.label,
    attachmentSourceType,
    eventSelectedForAttachment,
    finalizeAttachment,
    massText,
  ]);

  const renderEmptyState = useMemo(
    () =>
      attachmentSourceType === AttachmentSourceType.CONVERSATION &&
      (attachmentMode.label === MassTextAttachmentType.TICKET_LINK ||
        attachmentMode.label === MassTextAttachmentType.CALENDAR_INVITE) ? (
        <div className="AlignedColumn" style={{ marginTop: 150 }}>
          {loadingTicketLinks ? (
            <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
          ) : (
            <>
              <div
                style={{
                  paddingInline: 14,
                  paddingTop: 14,
                  paddingBottom: 10,
                  borderRadius: 12,
                  backgroundColor: Colors.GRAY6,
                }}
              >
                <Icon
                  icon={"ion-ticket"}
                  height={30}
                  style={{
                    color: theme ? theme.TertiaryBG : Colors.GRAY3,
                  }}
                />
              </div>
              <span
                style={{ fontWeight: "500", marginTop: 14, marginBottom: 7 }}
              >
                No Tickets to Show
              </span>
              <span style={{ fontSize: 12, color: Colors.GRAY3 }}>
                This attendee has no tickets to any upcoming events
              </span>
            </>
          )}
        </div>
      ) : (
        <EmptySearchState
          mainText="No events to show"
          altItemColor={theme?.TertiaryBG.backgroundColor}
          altTextColor={theme?.PrimaryText.color}
        />
      ),
    [attachmentMode.label, attachmentSourceType, loadingTicketLinks, theme]
  );

  const MassTextsAttachmentModalContent = useMemo(() => {
    if (
      attachmentMode.label === MassTextAttachmentType.GENERATE_TICKET_BUNDLE
    ) {
      return (
        <p style={theme?.PrimaryText}>
          Ticket bundle attachments coming soon...
        </p>
      );
    } else if (attachmentModalStep === 0) {
      return (
        <div style={{ minHeight: "50vh" }}>
          <p className="AboutSubtitle" style={theme?.PrimaryText}>
            Select{" "}
            {attachmentMode.label === MassTextAttachmentType.TICKET_LINK ||
            attachmentMode.label === MassTextAttachmentType.CALENDAR_INVITE
              ? "a Ticket"
              : "Event Link"}
          </p>
          <SearchBoxContainer
            placeholder="Search Events"
            onChange={(e) => setAttachmentSearchTerm(e.target.value)}
            value={attachmentSearchTerm}
            inputStyles={{ color: theme?.PrimaryText.color }}
          />
          <div
            className="ColumnNormal HideScrollbar"
            style={{
              marginTop: 14,
              gap: 14,
              height: "calc(100vh - 340px)",
              overflow: "scroll",
            }}
          >
            {userEvents.length > 0
              ? userEvents.map((event) => {
                  const { eventId } = attachmentDetails(currAttachmentLink);

                  const disabledEvent =
                    (attachmentMode.label !== MassTextAttachmentType.LINK &&
                      attachmentMode.label !==
                        MassTextAttachmentType.TICKET_LINK &&
                      attachmentMode.label !==
                        MassTextAttachmentType.CALENDAR_INVITE &&
                      (isFreeTickets(event) || isRequestTickets(event))) ||
                    (currAttachmentLink !== "" && event.id !== eventId);

                  return (
                    <div
                      key={event.id}
                      onClick={() => {
                        if (disabledEvent) {
                          return;
                        }

                        updateAttachmentSettings({
                          eventId:
                            eventSelectedForAttachment?.id === event.id
                              ? undefined
                              : event.id,
                        });
                      }}
                      style={{ opacity: disabledEvent ? 0.3 : 1 }}
                    >
                      <div className="AlignedRowSpaced">
                        {attachmentMode.label !==
                          MassTextAttachmentType.TICKET_LINK &&
                        attachmentMode.label !==
                          MassTextAttachmentType.CALENDAR_INVITE ? (
                          <EventContentMobile
                            event={event}
                            altSize="66px"
                            altFontSize={12}
                            theme={theme}
                            myEvent
                            eventTypeColor={Colors.WHITE1}
                          />
                        ) : (
                          <div className="AlignedRow">
                            <div style={{ position: "relative" }}>
                              <img
                                alt="RenderedEvent"
                                src={event.photoURL}
                                width={60}
                                height={60}
                                style={{
                                  borderRadius: 8,
                                  marginRight: "14px",
                                  cursor: "pointer",
                                  filter: "brightness(70%)",
                                }}
                              />
                              <Icon
                                icon={"ion-ticket"}
                                height={30}
                                color={Colors.WHITE}
                                style={{
                                  position: "absolute",
                                  top: 15,
                                  right: 0,
                                  left: 15,
                                  bottom: 0,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                            <div
                              className="ColumnNormalSelect"
                              style={{ gap: 7 }}
                            >
                              <span
                                style={{
                                  fontSize: 14,
                                  fontWeight: 500,
                                  ...theme?.PrimaryText,
                                }}
                              >
                                {truncateString(event.title, 27, "...")}
                              </span>
                              <span
                                style={{ ...theme?.PrimaryText, fontSize: 12 }}
                              >
                                Owns Ticket(s)
                              </span>
                            </div>
                          </div>
                        )}
                        <Icon
                          icon={
                            attachmentSettings.eventId === event.id
                              ? "mdi:check-circle"
                              : "mdi:checkbox-blank-circle-outline"
                          }
                          height={24}
                          style={{
                            cursor: "pointer",
                            color:
                              theme && theme === DarkTheme
                                ? Colors.WHITE
                                : Colors.BLACK,
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              : renderEmptyState}
          </div>
        </div>
      );
    } else if (attachmentModalStep === 1) {
      if (attachmentMode.label === MassTextAttachmentType.FREE_TICKET_LINK) {
        return (
          <div>
            <div className="AlignedRowSpaced">
              <span className="AboutSubtitle" style={theme?.PrimaryText}>
                Custom Ticket Label
              </span>
              <Select
                labelId="ticket-label-select-label"
                id="ticket-label-select"
                value={attachmentSettings.customTicketLabel}
                displayEmpty
                style={{
                  maxWidth: 240,
                  borderRadius: 8,
                  backgroundColor: theme
                    ? theme.TertiaryBG.backgroundColor
                    : "",
                  ...theme?.PrimaryText,
                }}
                onChange={(e: any) => {
                  const foundLabel = currAttachmentLink.match(attLabelPattern);
                  if (foundLabel && foundLabel[1]) {
                    setCurrAttachmentLink(
                      currAttachmentLink.replace(foundLabel[1], e.target.value)
                    );
                  }
                  updateAttachmentSettings({
                    customTicketLabel: e.target.value,
                  });
                }}
              >
                {eventSelectedForAttachment
                  ? groupByKey(
                      eventSelectedForAttachment.customTickets,
                      (elem) => elem.label
                    ).map((group) => {
                      return (
                        <MenuItem value={group[0].id} key={group[0].id}>
                          {group[0].label}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </div>
            <div className="AlignedRowSpaced" style={{ marginTop: 20 }}>
              <div className="ColumnCenter" style={{ gap: 2 }}>
                <span className="AboutSubtitle" style={theme?.PrimaryText}>
                  Percent Off
                </span>
                <span
                  className="AboutSubtitle"
                  style={{ fontSize: 14, color: Colors.GRAY1, fontWeight: 400 }}
                >
                  This is a free ticket link
                </span>
              </div>
              <QuantityTextField
                quantity={100}
                placeholder="100"
                minimum={100}
                maximum={100}
                onChange={(e: any) => {}}
                theme={theme ?? LightTheme}
                disabled={
                  attachmentMode.label ===
                  MassTextAttachmentType.FREE_TICKET_LINK
                }
              />
            </div>
            {attachmentMode.label !==
            MassTextAttachmentType.FREE_TICKET_LINK ? (
              <>
                <div
                  className="AlignedRowSpaced"
                  style={{ marginTop: 14, gap: 64 }}
                >
                  <div className="ColumnCenter" style={{ gap: 2 }}>
                    <span className="AboutSubtitle">Set Number Of Uses</span>
                    <span
                      className="AboutSubtitle"
                      style={{
                        fontSize: 14,
                        color: Colors.GRAY1,
                        fontWeight: 400,
                      }}
                    >
                      Choose the number of times this discounted ticket link can
                      be used
                    </span>
                  </div>
                  <QuantityTextField
                    quantity={attachmentSettings.numTickets}
                    placeholder="100"
                    minimum={1}
                    maximum={200}
                    onChange={(e: any) => {
                      updateAttachmentSettings({
                        numTickets: e.target.value,
                      });
                    }}
                    theme={theme ?? LightTheme}
                  />
                </div>
                <div style={{ marginTop: 14 }}>
                  <span
                    className="AboutSubtitle"
                    style={{
                      fontSize: 14,
                      color: Colors.GRAY1,
                      fontWeight: 400,
                    }}
                  >
                    Note: Default total number of uses set to the number of
                    total recipients for this text.
                  </span>
                </div>
              </>
            ) : null}
          </div>
        );
      } else if (
        attachmentMode.label === MassTextAttachmentType.DISCOUNTED_TICKET_LINK
      ) {
        return (
          <div>
            <div className="AlignedRowSpaced" style={{ gap: 10 }}>
              <div className="ColumnCenter" style={{ gap: 2, width: "90%" }}>
                <span className="AboutSubtitle" style={theme?.PrimaryText}>
                  Set Percent Off
                </span>
                <span
                  style={{ fontSize: 14, color: Colors.GRAY1, fontWeight: 400 }}
                >
                  Choose what percent off the original ticket prices you want
                  this discount link to provide
                </span>
              </div>
              <QuantityTextField
                quantity={attachmentSettings.discount}
                placeholder="25"
                minimum={1}
                maximum={99}
                onChange={(e: any) => {
                  updateAttachmentSettings({
                    discount: e.target.value,
                  });
                }}
                theme={theme ?? LightTheme}
              />
            </div>
            <div
              className="AlignedRowSpaced"
              style={{ marginTop: 14, gap: 10 }}
            >
              <div className="ColumnCenter" style={{ gap: 2, width: "90%" }}>
                <span className="AboutSubtitle" style={theme?.PrimaryText}>
                  Set Number Of Uses
                </span>
                <span
                  className="AboutSubtitle"
                  style={{ fontSize: 14, color: Colors.GRAY1, fontWeight: 400 }}
                >
                  Choose the number of times this discounted ticket link can be
                  used
                </span>
              </div>
              <QuantityTextField
                quantity={attachmentSettings.promoCodeQuantity}
                placeholder="100"
                minimum={1}
                maximum={200}
                onChange={(e: any) => {
                  updateAttachmentSettings({
                    promoCodeQuantity: e.target.value,
                  });
                }}
                theme={theme ?? LightTheme}
              />
            </div>
            <div style={{ marginTop: 14 }}>
              <span
                className="AboutSubtitle"
                style={{
                  fontSize: 14,
                  color: Colors.GRAY1,
                  fontWeight: 400,
                }}
              >
                Note: Default total number of uses set to the number of total
                recipients for this text.
              </span>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="AlignedRowSpaced" style={{ gap: 64 }}>
              <div className="ColumnCenter" style={{ gap: 2 }}>
                <span className="AboutSubtitle">Number of Tickets</span>
                <span
                  className="AboutSubtitle"
                  style={{ fontSize: 14, color: Colors.GRAY1, fontWeight: 400 }}
                >
                  Set the number of tickets that will be included in this ticket
                  bundle.
                </span>
              </div>
              <QuantityTextField
                quantity={attachmentSettings.numTickets}
                placeholder="5"
                minimum={1}
                maximum={200}
                onChange={(e: any) => {
                  updateAttachmentSettings({
                    numTickets: e.target.value,
                  });
                }}
                theme={theme ?? LightTheme}
              />
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }, [
    attachmentMode.label,
    attachmentModalStep,
    theme,
    attachmentSearchTerm,
    userEvents,
    renderEmptyState,
    currAttachmentLink,
    attachmentSettings.eventId,
    attachmentSettings.customTicketLabel,
    attachmentSettings.numTickets,
    attachmentSettings.discount,
    attachmentSettings.promoCodeQuantity,
    updateAttachmentSettings,
    eventSelectedForAttachment,
    setCurrAttachmentLink,
  ]);

  const MassTextsAttachmentModalButton = useCallback(() => {
    if (attachmentModalStep === 0) {
      return (
        <div>
          <hr style={theme?.DividerColor} />
          <div style={{ paddingInline: 14 }}>
            <RectangleButton
              buttonLabel={
                attachmentMode.label === MassTextAttachmentType.LINK
                  ? "Insert Event Link"
                  : attachmentMode.label === MassTextAttachmentType.TICKET_LINK
                  ? "Insert Link to Ticket"
                  : attachmentMode.label ===
                    MassTextAttachmentType.CALENDAR_INVITE
                  ? "Insert Calendar Invite"
                  : "Next"
              }
              onPress={() => {
                if (eventSelectedForAttachment === undefined) {
                  return;
                }

                let attachmentLink = MARKITAI;
                if (attachmentMode.label === MassTextAttachmentType.LINK) {
                  attachmentLink += `/e/${eventSelectedForAttachment.id}`;
                  finalizeAttachment(attachmentLink);
                } else if (
                  attachmentMode.label === MassTextAttachmentType.TICKET_LINK
                ) {
                  attachmentLink += `/e/${eventSelectedForAttachment.id}?type=ticket`;
                  finalizeAttachment(attachmentLink);
                } else if (
                  attachmentMode.label ===
                  MassTextAttachmentType.CALENDAR_INVITE
                ) {
                  attachmentLink += `/e/${eventSelectedForAttachment.id}?type=calendar`;
                  finalizeAttachment(attachmentLink);
                } else if (
                  attachmentMode.label ===
                  MassTextAttachmentType.FREE_TICKET_LINK
                ) {
                  const foundLabel = currAttachmentLink.match(attLabelPattern);
                  if (foundLabel && foundLabel[1] && foundLabel[1] !== "") {
                    updateAttachmentSettings({
                      customTicketLabel: foundLabel[1],
                    });
                  } else {
                    updateAttachmentSettings({
                      customTicketLabel:
                        eventSelectedForAttachment.customTickets[0].id,
                    });
                  }
                  setAttachmentModalStep(1);
                } else {
                  setAttachmentModalStep(1);
                }
              }}
              disabled={eventSelectedForAttachment === undefined}
              altTextColor={theme ? theme.ButtonText.color : Colors.WHITE}
              altColor={
                eventSelectedForAttachment === undefined
                  ? Colors.GRAY2
                  : theme
                  ? theme.ButtonBG.backgroundColor
                  : Colors.BLACK
              }
            />
          </div>
        </div>
      );
    } else if (attachmentModalStep === 1) {
      if (attachmentMode.label === MassTextAttachmentType.FREE_TICKET_LINK) {
        return (
          <div>
            <hr style={theme?.DividerColor} />
            <div style={{ paddingInline: 14 }}>
              <RectangleButton
                buttonLabel={"Insert Free Ticket Link"}
                onPress={() => {
                  if (eventSelectedForAttachment === undefined) {
                    return;
                  }

                  let attachmentLink = MARKITAI;
                  attachmentLink += `/e/${eventSelectedForAttachment.id}?type=free&label=${attachmentSettings.customTicketLabel}`;
                  setCurrAttachmentLink(attachmentLink);
                  finalizeAttachment(attachmentLink);
                }}
                altTextColor={theme ? theme.ButtonText.color : Colors.WHITE}
                altColor={theme ? theme.ButtonBG.backgroundColor : Colors.BLACK}
              />
            </div>
          </div>
        );
      } else if (
        attachmentMode.label === MassTextAttachmentType.DISCOUNTED_TICKET_LINK
      ) {
        return (
          <div>
            <hr />
            <div style={{ paddingInline: 14 }}>
              <RectangleButton
                buttonLabel={"Insert Discount Ticket Link"}
                onPress={() => {
                  if (eventSelectedForAttachment === undefined) {
                    return;
                  }

                  let attachmentLink = MARKITAI;
                  attachmentLink += `/e/${eventSelectedForAttachment.id}?type=discounted&discount=${attachmentSettings.discount}&quantity=${attachmentSettings.promoCodeQuantity}`;
                  setCurrAttachmentLink(attachmentLink);
                  updateAttachmentSettings({
                    customTicketLabel:
                      eventSelectedForAttachment.customTickets[0].id,
                  });
                  finalizeAttachment(attachmentLink);
                }}
                altTextColor={theme ? theme.ButtonText.color : Colors.WHITE}
                altColor={theme ? theme.ButtonBG.backgroundColor : Colors.BLACK}
              />
            </div>
          </div>
        );
      } else {
        // @TODO: Implement ticket bundle in the future
        return (
          <div>
            <hr />
            <div style={{ paddingInline: 14 }}>
              <RectangleButton
                buttonLabel={"Insert Ticket Bundle"}
                onPress={() => {
                  alert("Inserted Ticket Bundle");
                }}
                altTextColor={theme ? theme.ButtonText.color : Colors.WHITE}
                altColor={theme ? theme.ButtonBG.backgroundColor : Colors.BLACK}
              />
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }, [
    attachmentModalStep,
    theme,
    attachmentMode.label,
    eventSelectedForAttachment,
    finalizeAttachment,
    currAttachmentLink,
    updateAttachmentSettings,
    attachmentSettings.customTicketLabel,
    attachmentSettings.discount,
    attachmentSettings.promoCodeQuantity,
    setCurrAttachmentLink,
  ]);

  return (
    <PopupModalContainer
      headerComp={renderMassTextsAttachmentModalHeader()}
      subHeaderComp=""
      valueComp={MassTextsAttachmentModalContent}
      theme={theme}
      closeModal={() => {
        setCurrAttachmentLink("");
        setShowAttachmentModal(false);
        setAttachmentModalStep(0);
        resetAttachmentState();
      }}
      fixedBottomContent={<MassTextsAttachmentModalButton />}
    />
  );
};

export default MassTextsAttachmentModal;
