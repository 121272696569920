import { useCallback, useState } from "react";
import TextingPanelUsage from "../../../components/CreatorDashboard/TextingPanelUsage";
import { Colors } from "../../../utils/colors";
import MassTextPanel from "../../../components/CreatorDashboard/MassTexts/MassTextPanel";
import { TabPanel } from "../../../components/FullEventSubComponents/TabPanel";
import RectangleButtonCreatorPanel from "../../../components/Buttons/RectangleButtonCreatorPanel";
import MarkitPlusActionButton from "../../../components/Subscription/MarkitPlusActionButton";
import CreatorModePanelWrapper from "../../../components/CreatorDashboard/CreatorModePanelWrapper";
import CampaignsListPanel from "../../../components/CreatorDashboard/Campaigns/CampaignsListPanel";
import { useNavigate } from "react-router-dom";
import { onCampaignNavigatePath } from "../../../utils/navigationUtils";
import {
  MassTextSelectedType,
  MassTextsWrapperType,
} from "../../../components/CreatorDashboard/MassTexts/MassTextWrapperManager";

enum TABS {
  MANAGE_TEXTS = 0,
  TEXTING_USAGE = 1,
}

const CampaignsPanel = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(TABS.MANAGE_TEXTS);
  const [selectedTextType, setSelectedTextType] =
    useState<MassTextSelectedType>({ massText: undefined, isVisible: false });

  const styles = {
    textingPanelMainContentContainer: {
      backgroundColor:
        tabValue === TABS.MANAGE_TEXTS ? Colors.WHITE : Colors.WHITE1,
      paddingInline: 30,
      paddingTop: 30,
      height: "100%",
    },
  };

  const newMassTextOnPress = useCallback(
    (campaignId?: string) => {
      if (campaignId) {
        navigate(onCampaignNavigatePath(campaignId), {
          state: { campaignId: campaignId, isFollowUp: true },
        });
      } else {
        setSelectedTextType({ massText: undefined, isVisible: true });
      }
    },
    [navigate]
  );

  return (
    <CreatorModePanelWrapper
      title="Campaigns"
      headerRight={
        <div className="AlignedRow" style={{ gap: 14 }}>
          <MarkitPlusActionButton />
          <RectangleButtonCreatorPanel
            title="New Text"
            iconName="ion:create"
            onPress={() =>
              setSelectedTextType({ massText: undefined, isVisible: true })
            }
          />
        </div>
      }
      headerTabs={{
        tabNames: ["Manage", "Texting Usage"],
        tabValue: tabValue,
        setTabValue: setTabValue,
      }}
    >
      <div style={styles.textingPanelMainContentContainer}>
        <CampaignsListPanel
          tabValue={tabValue}
          newMassTextOnPress={newMassTextOnPress}
        />
        <TabPanel value={tabValue} index={1}>
          <TextingPanelUsage />
        </TabPanel>
      </div>
      {/* Mass Texts Popup Panel */}
      {selectedTextType.isVisible ? (
        <MassTextPanel
          wrapperType={MassTextsWrapperType.MODAL}
          selectedTextType={selectedTextType}
          setSelectedTextType={setSelectedTextType}
          campaign={undefined}
        />
      ) : null}
    </CreatorModePanelWrapper>
  );
};

export default CampaignsPanel;
