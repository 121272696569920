import React, { useCallback, useMemo, useState } from "react";
import "../../css/CreateEvent.css";
import {
  CustomTicketV2,
  Event,
  StripeAccountStatus,
  TicketType,
  testAccounts,
  NotificationType,
} from "@markit/common.types";
import { LightTheme, useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { IoAddCircleOutline, IoMenuOutline } from "react-icons/io5";
import { Icon } from "@iconify/react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CreateEventSectionHeader from "./CreateEventSectionHeader";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import {
  getTotalTicketsDistributedInGroup,
  getTotalTicketsSoldInGroup,
} from "../../utils/eventUtils/eventUtils";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import AlertButtonWrapper from "../Containers/AlertButtonWrapper";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import { useSelector, useDispatch } from "react-redux";
import { showNotificationBanner } from "../../utils/notificationUtils";

interface CreateEventTicketsProps {
  eventSettings: Event;
  updateEventSettings: (update: Partial<Event>) => void;
  setIsTicketsModalOpen: (ticketsModalOpen: boolean) => void;
  setCurrTicketNumber: (currTicketNumber: number) => void;
  showConnectStripeModal: () => void;
  readyForPayment: boolean;
}

const CreateEventTickets = (props: CreateEventTicketsProps) => {
  const {
    eventSettings,
    updateEventSettings,
    setIsTicketsModalOpen,
    setCurrTicketNumber,
    showConnectStripeModal,
    readyForPayment,
  } = props;
  const { loggedIn, accountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const { theme } = useTheme(eventSettings.theme);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isDeleteTicketModalOpen, setIsDeleteTicketModalOpen] = useState(false);
  const [ticketIdToDelete, setTicketIdToDelete] = useState("");
  const [alertText, setAlertText] = useState("");

  const styles = {
    placeholder: {
      fontSize: 12,
      color: Colors.GRAY2,
    },
  };

  const testStripe = useMemo(
    () => testAccounts.includes(accountData.uid),
    [accountData.uid]
  );

  const userStripeAccountStatus = useMemo(
    () =>
      testStripe
        ? accountData.testStripeAccountStatus
        : accountData.stripeAccountStatus,
    [
      accountData.stripeAccountStatus,
      accountData.testStripeAccountStatus,
      testStripe,
    ]
  );

  const stripeAccountId = useMemo(
    () =>
      testStripe
        ? accountData.testStripeAccountId || ""
        : accountData.stripeAccountId,
    [accountData.stripeAccountId, accountData.testStripeAccountId, testStripe]
  );

  const deleteTicketOption = useCallback(
    async (ticket: CustomTicketV2) => {
      // check if the user can delete the specified ticket option
      const [totalTicketsSoldInGroup, totalTicketsDistributedInGroup] =
        await Promise.all([
          getTotalTicketsSoldInGroup(eventSettings.id, ticket.id),
          getTotalTicketsDistributedInGroup(eventSettings.id, ticket.id),
        ]);
      if (totalTicketsSoldInGroup + totalTicketsDistributedInGroup === 0) {
        if (eventSettings.customTickets.length === 1) {
          setAlertText("You must have at least one ticket type");
          return;
        }
        setTicketIdToDelete(ticket.id);
        setIsDeleteTicketModalOpen(true);
      } else {
        setAlertText(
          "This ticket type cannot be deleted since one or more tickets have been sold or distributed."
        );
      }
    },
    [eventSettings.customTickets, eventSettings.id]
  );

  const resetDeleteTicketModal = useCallback(() => {
    setTicketIdToDelete("");
    setIsDeleteTicketModalOpen(false);
  }, []);

  const finalizeDeleteTicket = useCallback(() => {
    const newCustomTickets = eventSettings.customTickets.filter(
      (tix) => tix.id !== ticketIdToDelete
    );
    updateEventSettings({
      customTickets: newCustomTickets,
    });
    resetDeleteTicketModal();
    showNotificationBanner(
      dispatch,
      "Ticket Type Deleted",
      NotificationType.NEGATIVE
    );
  }, [
    dispatch,
    eventSettings.customTickets,
    resetDeleteTicketModal,
    ticketIdToDelete,
    updateEventSettings,
  ]);

  const handleTicketsOnDrag = useCallback(
    (result: any) => {
      if (!result.destination) return;

      const reorderedItem =
        eventSettings.customTickets[parseInt(result.source.index)];
      const newCustomTickets = [...eventSettings.customTickets];
      newCustomTickets.splice(result.source.index, 1);
      newCustomTickets.splice(result.destination.index, 0, reorderedItem);

      updateEventSettings({
        ...eventSettings,
        customTickets: newCustomTickets,
      });
    },
    [eventSettings, updateEventSettings]
  );

  const pendingStripeDescription = useMemo(() => {
    if (userStripeAccountStatus === StripeAccountStatus.INCOMPLETE) {
      if (stripeAccountId === "") {
        return "Connect to Stripe";
      } else {
        return "Finish Connecting Stripe";
      }
    } else {
      return "Stripe Pending";
    }
  }, [stripeAccountId, userStripeAccountStatus]);

  const ConnectToStripeMessage = useMemo(
    () =>
      loggedIn !== LoginState.LOGGED_IN ? (
        <div className="TicketTypeSubtext">
          Please login or sign up to enable paid ticketing.
        </div>
      ) : userStripeAccountStatus === StripeAccountStatus.INCOMPLETE ? (
        <div className="TicketTypeSubtext">
          <span
            onClick={showConnectStripeModal}
            style={{ color: Colors.BLUE5, cursor: "pointer" }}
          >
            {pendingStripeDescription}
          </span>{" "}
          to enable paid ticketing.
        </div>
      ) : userStripeAccountStatus === StripeAccountStatus.PENDING ? (
        <div className="TicketTypeSubtext">
          Your Stripe account is being verified and will take a few minutes.
        </div>
      ) : null,
    [
      loggedIn,
      pendingStripeDescription,
      showConnectStripeModal,
      userStripeAccountStatus,
    ]
  );

  const renderTicketPreviews = (
    ticket: CustomTicketV2,
    index: number
  ): JSX.Element => {
    return (
      <div key={index}>
        <div
          style={{
            backgroundColor: "#F4F5F6",
            padding: 14,
            borderRadius: 8,
            ...theme.TertiaryBG,
          }}
        >
          <div
            className="AlignedRow"
            style={{ justifyContent: "space-between" }}
          >
            <span className="AlignedRow">
              {eventSettings.customTickets.length > 1 && (
                <div style={{ cursor: "pointer" }}>
                  <IoMenuOutline
                    color={Colors.GRAY1}
                    size={24}
                    style={{ paddingTop: 4, paddingRight: 14 }}
                  />
                </div>
              )}
              <div className="ColumnNormal" style={{ gap: 4 }}>
                <span
                  style={{
                    ...theme.PrimaryText,
                    fontWeight: "500",
                    fontSize: 14,
                  }}
                >
                  {ticket.label}
                </span>
                <span style={styles.placeholder}>
                  {ticket.type === TicketType.PAID
                    ? "$" + ticket.price / 100
                    : ticket.type === TicketType.FREE
                    ? "Free"
                    : "Request"}
                </span>
                {ticket.type === TicketType.PAID && !readyForPayment
                  ? ConnectToStripeMessage
                  : null}
              </div>
            </span>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <AlertButtonWrapper
                buttonComp={
                  <Icon
                    className="TrashButton"
                    icon={"ion:trash-outline"}
                    height={20}
                    onClick={() => deleteTicketOption(ticket)}
                  />
                }
                alertTextHeader={alertText}
                clearAlert={() => setAlertText("")}
              />
              <div>
                <Icon
                  className={
                    "EditButton " +
                    (theme === LightTheme
                      ? "EditButtonLight"
                      : "EditButtonDark")
                  }
                  icon={"feather:edit"}
                  height={20}
                  onClick={() => {
                    setCurrTicketNumber(index);
                    setIsTicketsModalOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
          <hr style={theme.DividerColor} />
          <div className="ColumnNormal" style={{ gap: 2 }}>
            {ticket.quantityAvailable > 0 ? (
              <span style={{ ...styles.placeholder }}>
                {ticket.quantityAvailable} Available
              </span>
            ) : null}
            <span style={{ ...styles.placeholder }}>
              Min {ticket.minQuantity}, Max {ticket.maxQuantity}
            </span>
            {ticket.hideTicket ? (
              <span style={styles.placeholder}>Hide Ticket Type</span>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="PageDesktop" style={theme.SecondaryBG}>
      <div className="Container">
        <CreateEventSectionHeader
          title="Tickets"
          isMinimized={isMinimized}
          setIsMinimized={setIsMinimized}
          theme={theme}
        />
        <AnimatePresence>
          {!isMinimized ? (
            <LazyMotion features={domAnimation}>
              <m.div
                key="columnNormal"
                className="ColumnNormal HideScrollbar"
                style={{ marginTop: "17px", gap: 14 }}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.4, ease: "easeIn" }}
              >
                <DragDropContext
                  onDragEnd={(result) => handleTicketsOnDrag(result)}
                >
                  <Droppable droppableId="characters">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="characters"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 14,
                        }}
                      >
                        {eventSettings.customTickets.map((ticket, index) => {
                          return (
                            <Draggable
                              key={ticket.id}
                              draggableId={ticket.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {renderTicketPreviews(ticket, index)}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div
                  className={
                    "formQuestionAddButton " +
                    (theme === LightTheme
                      ? "formQuestionAddButtonLight"
                      : "formQuestionAddButtonDark")
                  }
                  onClick={() => {
                    setCurrTicketNumber(-1);
                    setIsTicketsModalOpen(true);
                  }}
                >
                  <span style={{ fontSize: 14, ...theme.PrimaryText }}>
                    New Ticket Type
                  </span>
                  <IoAddCircleOutline
                    size={18}
                    color={theme === LightTheme ? Colors.BLACK : Colors.WHITE}
                  />
                </div>
              </m.div>
            </LazyMotion>
          ) : null}
        </AnimatePresence>
      </div>
      <ConfirmDeleteModal
        heading="Are you sure you want to delete this ticket type?"
        subtext="You cannot undo this."
        deleteButtonText={"Delete"}
        hideModal={!isDeleteTicketModalOpen}
        setIsVisible={(open: boolean) => {
          setTicketIdToDelete("");
          setIsDeleteTicketModalOpen(open);
        }}
        deleteOnPress={finalizeDeleteTicket}
        theme={theme}
      />
    </div>
  );
};

export default CreateEventTickets;
