import { AccountData, FollowerStatus } from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import ConversationAttachmentButton from "./ConversationAttachmentButton";
import { TextareaAutosize } from "@mui/material";
import AlertButtonWrapper from "../../Containers/AlertButtonWrapper";
import { unsubscribedUserAlertText } from "../../../utils/alertUtils";
import { Icon } from "@iconify/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { censoredWordsDetected, detectedCensored } from "@markit/common.utils";
import { API } from "../../../API";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import ConfirmActionModal from "../../Containers/ConfirmPopups/ConfirmActionModal";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { saveMediaToFirestore } from "../../../utils/photoUtils";
import { generate } from "shortid";

type ConversationMessageInputProps = {
  conversationsMainUser: AccountData | undefined;
};

const ConversationMessageInput = (props: ConversationMessageInputProps) => {
  const { conversationsMainUser } = props;
  const { accountData, followersData } = useSelector(getAccountState).account;
  const mixpanel = useContext(MixpanelContext);
  const [alertText, setAlertText] = useState({ header: "", subHeader: "" });
  const [confirmSendImage, setConfirmSendImage] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [cursorPosition, setCursorPosition] = useState(message.length);
  const [image, setImage] = useState<string>("");

  const input = document.getElementById("conversationmainmessage");
  input?.addEventListener("click", showposition);

  function showposition(event: any) {
    setCursorPosition(event.target.selectionStart);
  }

  useEffect(() => {
    if (image) {
      setConfirmSendImage(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const followerData = useMemo(
    () =>
      followersData.find(
        (follower) =>
          conversationsMainUser && follower.uid === conversationsMainUser.uid
      ),
    [conversationsMainUser, followersData]
  );

  const sendTextMessage = useCallback(async () => {
    if (detectedCensored(message)) {
      setAlertText({
        header: "Content Violation!",
        subHeader: `You used the following banned terms in your message: '${censoredWordsDetected(
          message
        )}'. Remove these terms and resend.`,
      });
      return;
    }
    setIsSendingMessage(true);
    setMessage("");
    await API.creatorText
      .sendMessageToUser({
        twilioUser: accountData,
        attendeeUser: conversationsMainUser,
        message: message,
        mediaUrl: "",
      })
      .then(async () => {
        if (mixpanel) {
          mixpanel.track("Conversation: Creator Sent Message To Participant", {
            distinct_id: accountData.uid,
            recipient_id: conversationsMainUser?.uid,
            message: message,
            from: "webapp",
          });
        }
        setIsSendingMessage(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [accountData, conversationsMainUser, message, mixpanel, setMessage]);

  const sendImage = useCallback(async () => {
    let mediaUrl = "";
    if (image !== "") {
      setIsSendingMessage(true);
      const url = await saveMediaToFirestore(
        image,
        accountData.uid + "/textMedia/chats/" + generate()
      );
      mediaUrl = url;

      await API.creatorText
        .sendMessageToUser({
          twilioUser: accountData,
          attendeeUser: conversationsMainUser,
          message: "\u00A0", // Can't send an empty string as message so send blank character
          mediaUrl: mediaUrl,
        })
        .then(async () => {
          if (mixpanel) {
            mixpanel.track(
              "Conversation: Creator Sent Message To Participant",
              {
                recipient_id: conversationsMainUser?.uid ?? "",
                from: "webapp",
                message: "Attachment: 1 Image",
                mediaUrl: mediaUrl,
              }
            );
          }
          setIsSendingMessage(false);
          setImage("");
        })
        .catch((err) => {
          console.log(err.message);
          setIsSendingMessage(false);
          setImage("");
        });
    }
  }, [accountData, conversationsMainUser, image, setImage, mixpanel]);

  return (
    <>
      <ConversationAttachmentButton
        message={message}
        setMessage={setMessage}
        image={image}
        setImage={setImage}
        cursorPosition={cursorPosition}
        conversationMainUserId={conversationsMainUser?.uid ?? ""}
      />
      <div className="ConversationsInputContainer">
        <TextareaAutosize
          id="conversationmainmessage"
          value={message}
          placeholder={"Send a message..."}
          onChange={(e) => {
            setCursorPosition(e.target.selectionStart);
            setMessage(e.target.value);
          }}
          className="ConversationsInput HideScrollbar"
          maxRows={15}
        />
        <AlertButtonWrapper
          buttonComp={
            <div
              onClick={() => {
                followerData &&
                followerData.status !== FollowerStatus.SUBSCRIBED
                  ? setAlertText({
                      header: unsubscribedUserAlertText(followerData),
                      subHeader: "",
                    })
                  : sendTextMessage();
              }}
              style={{
                pointerEvents:
                  isSendingMessage || message === "" ? "none" : "all",
                cursor: "pointer",
                alignItems: isSendingMessage ? "center" : "flex-end",
                padding: 8,
              }}
            >
              {isSendingMessage ? (
                <div style={{ paddingInline: 8, paddingBlock: 6 }}>
                  <span className="bodySubtext">Sending...</span>
                </div>
              ) : (
                <div
                  className="CreatorChatSendButton"
                  style={{
                    backgroundColor:
                      message === "" ? Colors.GRAY1 : Colors.BLACK,
                    cursor: message === "" ? "not-allowed" : "pointer",
                  }}
                >
                  <Icon
                    icon="ion:paper-plane"
                    height={18}
                    style={{
                      paddingRight: 2,
                      paddingTop: 2,
                      color: Colors.WHITE,
                    }}
                  />
                </div>
              )}
            </div>
          }
          alertTextHeader={alertText.header}
          alertTextSubHeader={alertText.subHeader}
          clearAlert={() => setAlertText({ header: "", subHeader: "" })}
        />
      </div>
      <ConfirmActionModal
        heading="Ready to Send?"
        confirmButtonText="Send"
        icon={<Icon icon="ion:paper-plane" height={40} />}
        hideModal={!confirmSendImage}
        setIsVisible={setConfirmSendImage}
        confirmOnPress={() => sendImage()}
        cancelOnPress={() => setImage("")}
      />
    </>
  );
};

export default ConversationMessageInput;
