import { handlePluralString } from "@markit/common.utils";
import { useTheme } from "../../../../hooks/useTheme";
import { Colors } from "../../../../utils/colors";

type TextCreditsNoticeProps = {
  numCredits: number;
};

const TextCreditsNotice = (props: TextCreditsNoticeProps) => {
  const { numCredits } = props;
  const { theme } = useTheme();

  const styles = {
    container: {
      borderRadius: 12,
      padding: 14,
      backgroundColor: "rgba(0, 122, 255, 0.1)",
      border: `1px solid ${Colors.BLUE5}`,
      gap: 7,
    },
  };

  return numCredits >= 5 ? (
    <div className="ColumnNormal" style={styles.container}>
      <span className="bodyMedium" style={theme.PrimaryText}>
        High Credit Count Applied
      </span>
      <span style={{ fontSize: 12, ...theme.PrimaryText }}>
        Your text is using {numCredits}{" "}
        {handlePluralString("credit", numCredits)}. This results in higher send
        costs. You can shorten your message and remove emojis or images to
        reduce credit usage.
      </span>
    </div>
  ) : null;
};

export default TextCreditsNotice;
