import {
  attachmentDetails,
  calculateTextMessageCreditsEstimate,
  getEventAttachmentLinks,
  hostingLiveEvents,
  isEventExternalLink,
  isMarkitLink,
  massTextMessageConverter,
  splitPluginTextInputMessage,
  stringWithoutNewLines,
} from "@markit/common.utils";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import { ThemeStyle } from "../../../../hooks/useTheme";
import {
  AttachmentSourceType,
  Event,
  MassText,
  MassTextAttachment,
  MassTextAttachmentType,
  MassTextType,
  TextPluginOptions,
} from "@markit/common.types";
import {
  AttachmentMode,
  attachmentModes,
  useMassTextManagement,
} from "../../../../hooks/useMassTextManagement";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { getEventState } from "../../../../redux/slices/eventSlice";
import MassTextsAttachmentModal from "./MassTextsAttachmentModal";
import type { MenuProps } from "antd";
import { InsertPluginTextInput } from "./InsertPluginTextInput";
import { massTextEmptyPlaceholder } from "@markit/common.utils/dist/globalConstants";
import { AttachmentsActionBar } from "./AttachmentsActionBar";
import ConfirmDeleteModal from "../../../Containers/ConfirmPopups/ConfirmDeleteModal";
import ComplianceMessageTag from "../../../Compliance/ComplianceMessageTag";
import { fetchNumFollowersNoComplianceSent } from "../../../../utils/userUtils";
import useAsyncOnMount from "../../../../hooks/useAsyncEffectOnMount";
import { CircularProgress } from "@mui/material";
import TextCreditsNotice from "./TextCreditsNotice";

type MassTextMessageDraftingProps = {
  massText: MassText;
  updateMassTextSettings: (massText: Partial<MassText>) => void;
  finalRecipients: string[];
  disableEventLinks: boolean;
  massTextAttachments?: MassTextAttachment[];
  event?: Event;
  theme?: ThemeStyle;
};

export const MassTextMessageDrafting = (
  props: MassTextMessageDraftingProps
) => {
  const {
    massText,
    updateMassTextSettings,
    finalRecipients,
    disableEventLinks,
    massTextAttachments,
    event,
    theme,
  } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { events: eventList } = useSelector(getEventState);
  const { events } = eventList;

  const styles = {
    smallSubtext: { color: Colors.GRAY1, fontWeight: 400, fontSize: 12 },
    subtext: { color: Colors.GRAY1, fontWeight: 400, fontSize: 14 },
  };

  const {
    attachmentSettings,
    updateAttachmentSettings,
    resetMassTextAttachment,
  } = useMassTextManagement({});

  const [attachmentMode, setAttachmentMode] = useState<AttachmentMode>(
    attachmentModes[0]
  );
  const [showAttachmentModal, setShowAttachmentModal] =
    useState<boolean>(false);
  const [currAttachmentLink, setCurrAttachmentLink] = useState("");
  const [cursorPosition, setCursorPosition] = useState(massText.message.length);
  const [pluginWords, setPluginWords] = useState<Set<string>>(new Set());
  const [isRemoveMediaModalOpen, setIsRemoveMediaModalOpen] = useState(false);
  const [numFollowersNotCompliant, setNumFollowersNotCompliant] = useState(0);

  const input = document.getElementById("masstextmessage");
  input?.addEventListener("click", showposition);

  function showposition(event: any) {
    setCursorPosition(event.target.selectionStart);
  }

  useAsyncOnMount(async () => {
    // add detected markit links to recognition table
    const convertedMessage = splitPluginTextInputMessage(
      massTextMessageConverter(massText.message, massTextAttachments)
    );
    const pluginsToAdd: Set<string> = new Set<string>(
      TextPluginOptions.map((option) => option.name)
    );
    for (let i = 0; i < convertedMessage.length; i++) {
      if (isMarkitLink(convertedMessage[i])) {
        pluginsToAdd.add(convertedMessage[i].trim());
      } else if (
        event?.externalLink !== "" &&
        convertedMessage[i].trim() === event?.externalLink
      ) {
        pluginsToAdd.add(convertedMessage[i].trim());
      }
    }

    setPluginWords(pluginsToAdd);

    // calculate the number of selected followers with complianceMessageSent as false
    const numFollowersNotCompliant = await fetchNumFollowersNoComplianceSent(
      accountData.uid,
      finalRecipients
    );
    setNumFollowersNotCompliant(numFollowersNotCompliant);
  });

  // set the num quantity for discounted ticket case
  useEffect(() => {
    if (
      attachmentMode === attachmentModes[2] &&
      attachmentSettings.promoCodeQuantity === 1
    ) {
      updateAttachmentSettings({
        promoCodeQuantity:
          finalRecipients.length > 0 ? finalRecipients.length : 1,
      });
    }
  }, [
    attachmentMode,
    attachmentSettings.promoCodeQuantity,
    finalRecipients,
    updateAttachmentSettings,
  ]);

  const allLiveEvents = useMemo(
    () => hostingLiveEvents(events, accountData.uid),
    [accountData.uid, events]
  );

  const convertedMessage = useMemo(
    () => massTextMessageConverter(massText.message, massTextAttachments),
    [massTextAttachments, massText.message]
  );

  const eventAttachmentLinks = useMemo(() => {
    const allEventIds = event
      ? allLiveEvents.map((event) => event.id).concat(event.id)
      : allLiveEvents.map((event) => event.id);
    return getEventAttachmentLinks(convertedMessage, allEventIds);
  }, [allLiveEvents, convertedMessage, event]);

  const numCredits = useMemo(
    () =>
      calculateTextMessageCreditsEstimate(
        convertedMessage,
        massText.mediaUrl,
        event
      ),
    [convertedMessage, event, massText.mediaUrl]
  );

  const setCustomImage = useCallback(
    (customImage: string) =>
      updateMassTextSettings({
        mediaUrl: customImage,
      }),
    [updateMassTextSettings]
  );

  const clearAttachmentOnPress = useCallback(
    (attachmentLink: string) => {
      const messageArr = stringWithoutNewLines(convertedMessage).split(" ");
      const replace = messageArr.filter((str) => str !== attachmentLink);
      updateMassTextSettings({ message: replace.join(" ") });
    },
    [convertedMessage, updateMassTextSettings]
  );

  const generateNewMassTextMessage = useCallback(
    (eventURL: string) => {
      const newPluginWords = pluginWords.add(eventURL.trim());
      setPluginWords(newPluginWords);

      if (currAttachmentLink !== "") {
        updateMassTextSettings({
          message: massText.message.replace(currAttachmentLink, eventURL),
        });
      } else {
        const messageWithEventURL =
          massText.message.slice(0, cursorPosition) +
          " " +
          eventURL +
          " " +
          massText.message.slice(cursorPosition);
        updateMassTextSettings({ message: messageWithEventURL });
      }
      setCurrAttachmentLink("");
    },
    [
      currAttachmentLink,
      cursorPosition,
      massText.message,
      updateMassTextSettings,
      pluginWords,
    ]
  );

  const editAttachmentOnPress = useCallback(
    (
      attachmentLink: string,
      eventId: string,
      massTextType: MassTextAttachmentType
    ) => {
      updateAttachmentSettings({
        eventId: eventId,
      });
      const modalType =
        massTextType === MassTextAttachmentType.LINK
          ? attachmentModes[0]
          : massTextType === MassTextAttachmentType.FREE_TICKET_LINK
          ? attachmentModes[1]
          : massTextType === MassTextAttachmentType.DISCOUNTED_TICKET_LINK
          ? attachmentModes[2]
          : massTextType === MassTextAttachmentType.TICKET_LINK
          ? attachmentModes[3]
          : massTextType === MassTextAttachmentType.CALENDAR_INVITE
          ? attachmentModes[4]
          : attachmentModes[5];
      setCurrAttachmentLink(attachmentLink);
      setShowAttachmentModal(true);
      setAttachmentMode(modalType);
      setCursorPosition(massText.message.indexOf(attachmentLink));
    },
    [massText.message, updateAttachmentSettings]
  );

  const onClick: MenuProps["onClick"] = ({ key }: any) => {
    const isEventSpecificLink =
      key === "ticket_link" || key === "calendar_invite";
    if (disableEventLinks && isEventSpecificLink) {
      alert(
        "You can't insert event specific links to people that aren't attendees of the event. Consider sending with categories instead."
      );
      return;
    }
    setCurrAttachmentLink("");
    setShowAttachmentModal(true);

    switch (key) {
      case "event_link":
        setAttachmentMode(attachmentModes[0]);
        return;
      case "discount_ticket":
        setAttachmentMode(attachmentModes[2]);
        return;
      case "free_ticket":
        setAttachmentMode(attachmentModes[1]);
        return;
      case "ticket_link":
        if (event) {
          updateMassTextSettings({ eventRefId: event.id });
        }
        setAttachmentMode(attachmentModes[3]);
        return;
      case "calendar_invite":
        if (event) {
          updateMassTextSettings({ eventRefId: event.id });
        }
        setAttachmentMode(attachmentModes[4]);
        return;
      default:
        setAttachmentMode(attachmentModes[0]);
    }
  };

  const onExternalClick: MenuProps["onClick"] = ({ key }: any) => {
    setCurrAttachmentLink("");
    switch (key) {
      case "markit_event_link":
        setShowAttachmentModal(true);
        setAttachmentMode(attachmentModes[0]);
        return;
      case "external_event_link":
        generateNewMassTextMessage(event ? event.externalLink : "");
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: Colors.WHITE,
          paddingInline: 14,
          overflow: "hidden",
          flex: 1,
        }}
        className="ColumnNormal"
      >
        <div style={{ flexGrow: 1, overflowY: "auto", paddingBottom: 14 }}>
          {massTextAttachments !== undefined ? (
            <InsertPluginTextInput
              message={convertedMessage}
              setMessage={(message: string) =>
                updateMassTextSettings({ message: message })
              }
              placeholder={massTextEmptyPlaceholder(massText.eventRefId !== "")}
              pluginWords={pluginWords}
              image={massText.mediaUrl}
              removeImage={() => setIsRemoveMediaModalOpen(true)}
              cursorPosition={cursorPosition}
              setCursorPosition={setCursorPosition}
              hideEventPlugins={!massText.eventRefId}
              isMassText
              fixedBottomText={
                numFollowersNotCompliant > 0 ||
                massText.type === MassTextType.TRIGGERTEXT ? (
                  <ComplianceMessageTag
                    alternateMessage={
                      massText.subSelectionIds.length > 0
                        ? "If this is the first text someone has been sent, this compliance message must be included"
                        : `${numFollowersNotCompliant} of your selected recipients have not received a compliance message. For these recipients, this additional default message will be added to your text.`
                    }
                  />
                ) : undefined
              }
            />
          ) : (
            <div className="Centering" style={{ height: "100%" }}>
              <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
            </div>
          )}
        </div>
        <div
          className="ColumnNormal"
          style={{
            position: "sticky",
            bottom: 14,
            left: 14,
            right: 14,
            paddingTop: 24,
            gap: 10,
          }}
        >
          {eventAttachmentLinks.map((attachment, index) => {
            const { eventId, type, discount, quantity } =
              attachmentDetails(attachment);
            const eventTitle =
              allLiveEvents.find((event) => event.id === eventId)?.title ||
              "Markit link";

            return (
              <div
                key={index}
                style={{
                  backgroundColor: Colors.GRAY6,

                  padding: 10,
                  borderRadius: 8,
                  cursor: "pointer",
                }}
              >
                <div className="AlignedRowSpacedSelect">
                  <div
                    className="AlignedRowSelect"
                    onClick={() =>
                      editAttachmentOnPress(attachment, eventId, type)
                    }
                  >
                    <Icon
                      icon="feather:paperclip"
                      height={14}
                      color={Colors.BLACK}
                    />
                    <h4
                      style={{
                        fontSize: 14,
                        paddingLeft: 7,
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    >
                      {type === MassTextAttachmentType.LINK
                        ? "Event Link"
                        : type === MassTextAttachmentType.FREE_TICKET_LINK
                        ? "Free Ticket"
                        : type === MassTextAttachmentType.DISCOUNTED_TICKET_LINK
                        ? "Discount Ticket"
                        : type === MassTextAttachmentType.TICKET_LINK
                        ? "Link to Ticket"
                        : type === MassTextAttachmentType.CALENDAR_INVITE
                        ? "Calendar Invite"
                        : "Ticket Bundle"}
                    </h4>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => clearAttachmentOnPress(attachment)}
                  >
                    <Icon
                      icon="ion:close-outline"
                      height={20}
                      color={Colors.BLACK}
                    />
                  </div>
                </div>
                <div
                  className="AlignedRow"
                  style={{ paddingTop: 5, cursor: "pointer" }}
                  onClick={() =>
                    editAttachmentOnPress(attachment, eventId, type)
                  }
                >
                  <h4 style={styles.smallSubtext}>
                    {type === MassTextAttachmentType.TICKET_LINK
                      ? "Unique link to each recipient's ticket"
                      : type === MassTextAttachmentType.CALENDAR_INVITE
                      ? "Unique link to each recipient's ticket to add to calendar"
                      : eventTitle}
                  </h4>
                  {type === MassTextAttachmentType.FREE_TICKET_LINK ||
                  type === MassTextAttachmentType.DISCOUNTED_TICKET_LINK ? (
                    <div className="AlignedRow">
                      <h4 style={styles.smallSubtext}>, {discount}% off</h4>
                      {type ===
                        MassTextAttachmentType.DISCOUNTED_TICKET_LINK && (
                        <h4 style={styles.smallSubtext}>
                          , {quantity} use{quantity !== 1 ? "s" : ""}
                        </h4>
                      )}
                    </div>
                  ) : null}
                  {type === MassTextAttachmentType.GENERATE_TICKET_BUNDLE && (
                    <h4 style={styles.smallSubtext}>
                      , {quantity} Bundled tickets
                    </h4>
                  )}
                </div>
              </div>
            );
          })}
          <TextCreditsNotice numCredits={numCredits} />
          <AttachmentsActionBar
            message={convertedMessage}
            image={massText.mediaUrl}
            setImage={setCustomImage}
            actionItemOnPress={
              event && isEventExternalLink(event.eventType)
                ? onExternalClick
                : onClick
            }
            attachmentSourceType={
              massText.eventRefId
                ? AttachmentSourceType.EVENT_TEXT
                : AttachmentSourceType.MASS_TEXT
            }
            disableEventLinks={disableEventLinks}
            event={event}
          />
        </div>
      </div>
      <ConfirmDeleteModal
        heading="Remove Media?"
        subtext="This cannot be undone"
        deleteButtonText={"Remove"}
        hideModal={!isRemoveMediaModalOpen}
        setIsVisible={(open: boolean) => {
          setIsRemoveMediaModalOpen(open);
        }}
        deleteOnPress={() => {
          setCustomImage("");
        }}
        theme={theme}
      />
      {/* Mass Texts Attachment Modal */}
      {showAttachmentModal ? (
        <MassTextsAttachmentModal
          setShowAttachmentModal={setShowAttachmentModal}
          generateNewMassTextMessage={generateNewMassTextMessage}
          currAttachmentLink={currAttachmentLink}
          setCurrAttachmentLink={setCurrAttachmentLink}
          attachmentMode={attachmentMode}
          attachmentSettings={attachmentSettings}
          updateAttachmentSettings={updateAttachmentSettings}
          resetAttachmentState={resetMassTextAttachment}
          theme={theme}
          massText={massText}
          attachmentSourceType={
            massText.eventRefId
              ? AttachmentSourceType.EVENT_TEXT
              : AttachmentSourceType.MASS_TEXT
          }
        />
      ) : null}
    </>
  );
};
