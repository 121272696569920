import "../../css/GlobalStyles.css";
import React, { ReactNode, memo, useRef, useState } from "react";
import TopHeader from "../../components/TopHeader";
import { useSelector, useDispatch } from "react-redux";
import {
  LoginState,
  getAccountState,
  toggleInCreatorMode,
} from "../../redux/slices/accountSlice";
import CreatorModeSidebar from "../../components/CreatorDashboard/CreatorModeSidebar";
import CreatorModeTopHeader from "../../components/CreatorDashboard/CreatorModeTopHeader";
import EmptyViewStateMobile from "../../components/EmptyStates/EmptyViewStateMobile";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "../../hooks/useNavigate";
import { useOnMount } from "../../hooks/useOnMount";

type CreatorModeWrapperProps = {
  children: ReactNode;
  hideSidebar?: boolean;
  preventScroll?: boolean;
};

const CreatorModeWrapper = (props: CreatorModeWrapperProps) => {
  const { children, hideSidebar, preventScroll } = props;
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States for sidebar
  const refContainer = useRef<HTMLDivElement>(null);
  const [morePanelVisible, setMorePanelVisible] = useState(false);

  useOnMount(() => {
    // Handle Redirecting
    if (loggedIn === LoginState.LOGGED_OUT) {
      navigate("/welcome");
    } else if (!accountData.inCreatorMode) {
      dispatch(toggleInCreatorMode(accountData.uid, accountData.inCreatorMode));
    }
  });

  if (!isDesktop) {
    return <EmptyViewStateMobile />;
  }

  return (
    <div
      ref={refContainer}
      className="ColumnNormal"
      style={{ height: "100vh", overflow: "hidden" }}
    >
      {accountData.inCreatorMode ? <CreatorModeTopHeader /> : <TopHeader />}
      <div
        className={`RowNormal ${preventScroll ? "PreventScroll" : ""}`}
        style={{ height: "100%", width: "100%", overflowX: "scroll" }}
      >
        {accountData.inCreatorMode && !hideSidebar ? (
          <CreatorModeSidebar
            morePanelVisible={morePanelVisible}
            setMorePanelVisible={setMorePanelVisible}
          />
        ) : null}
        <div
          className={`ColumnNormal ${preventScroll ? "PreventScroll" : ""}`}
          style={{
            height: "100%",
            width: "100%",
            overflowY: preventScroll ? "hidden" : "auto",
            overflowX: "scroll",
            minWidth: "1000px",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default memo(CreatorModeWrapper);
