import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "../../../css/CreateEvent.css";
import "../../../css/FullEvent/EventDashboard.css";
import "../../../css/GlobalStyles.css";
import {
  AccountData,
  Event,
  OrganizerType,
  TicketV2,
  NotificationType,
} from "@markit/common.types";
import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import {
  filterUndefinedValues,
  hostedOnlyEvents,
  hostingOnlyEvents,
  isEventTicketsPaid,
  uniqueVals,
} from "@markit/common.utils";
import { useTheme } from "../../../hooks/useTheme";
import FlatList from "flatlist-react/lib";
import { checkForPaymentEnabled } from "../../../utils/stripeAccountUtils";
import { API } from "../../../API";
import NumberInput from "../../NumberInput";
import SwitchToggleItem from "../../SwitchToggleItem";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { useDispatch, useSelector } from "react-redux";
import {
  accountActions,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import { getEventState } from "../../../redux/slices/eventSlice";
import SelectItemRow from "../../Buttons/SelectItemRow";
import LargePopupModalContainer from "../../Containers/LargePopupModalContainer";
import BlackHoverButton from "../../Buttons/BlackHoverButton";
import { fetchSingleUser } from "../../../utils/FetchSingleData";
import filter from "lodash.filter";
import SearchBoxContainer from "../../Containers/SearchBoxContainer";
import { SelectRecipientItem } from "../../DisplayItem/SelectRecipientItem";
import EmptyStateButton from "../../Buttons/EmptyStateButton";
import AlertContainer from "../../Containers/AlertContainer";
import ConfirmDeleteModal from "../../Containers/ConfirmPopups/ConfirmDeleteModal";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";
import { CircularProgress } from "@mui/material";
import { showNotificationBanner } from "../../../utils/notificationUtils";

interface EventRoleModalProps {
  event: Event;
  organizerTicketItem: TicketV2 | undefined;
  organizerData: AccountData | undefined;
  setOrganizerTicketItem: (
    value: React.SetStateAction<TicketV2 | undefined>
  ) => void;
  setOrganizerData: (
    value: React.SetStateAction<AccountData | undefined>
  ) => void;
  isEventRoleModalOpen: boolean;
  setIsEventRoleModalOpen: (formsModalOpen: boolean) => void;
  setProfilePreviewSelected: (profilePreviewSelected: AccountData) => void;
}

const EventRoleModal = (props: EventRoleModalProps) => {
  const {
    event,
    organizerTicketItem,
    organizerData,
    setOrganizerTicketItem,
    setOrganizerData,
    isEventRoleModalOpen,
    setIsEventRoleModalOpen,
    setProfilePreviewSelected,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();
  const { account } = useSelector(getAccountState);
  const { accountData, followingAccountData } = account;
  const { events: eventState } = useSelector(getEventState);
  const { events } = eventState;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [currScreen, setCurrScreen] = useState<number>(0);
  const [selectedRole, setSelectedRole] = useState<OrganizerType>(
    organizerTicketItem ? organizerTicketItem.role.type : OrganizerType.COHOST
  );
  const [isAlternatePaymentCollector, setIsAlternatePaymentCollector] =
    useState(
      organizerTicketItem
        ? organizerTicketItem.role.alternatePaymentCollector
        : false
    );
  const [percentSplit, setPercentSplit] = useState(
    organizerTicketItem ? organizerTicketItem.role.revenuePercentSplit : 0
  );
  const [selectedRoleUser, setSelectedRoleUser] = useState<AccountData>();
  const [searchTerm, setSearchTerm] = useState("");
  const [recentRoleUserData, setRecentRoleUserData] = useState<AccountData[]>(
    []
  );

  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const [exitConfirm, setExitConfirm] = useState(false);

  const hasPaidTickets: boolean = useMemo(
    () => isEventTicketsPaid(event),
    [event]
  );

  const isEditingRole = useMemo(
    () => organizerData !== undefined,
    [organizerData]
  );

  const invalidStripeAccountMessage = useMemo(
    () =>
      isEditingRole
        ? "Must be a user with a connected stripe account to change to a payment collector role."
        : "Must select a user with a connected stripe account to create a payment collector role.",
    [isEditingRole]
  );

  const alternatePaymentCollectorDescription = useMemo(
    () =>
      !hasPaidTickets
        ? "Available for paid ticketed events"
        : organizerTicketItem &&
          organizerTicketItem.role.alternatePaymentCollector &&
          event.stripePaymentCollector !== ""
        ? "You already have a collector set"
        : "Divert payments to a user for this event",
    [event.stripePaymentCollector, hasPaidTickets, organizerTicketItem]
  );

  const revenueSharingDescription = useMemo(
    () =>
      hasPaidTickets
        ? "Share a set percentage of sales when the user uses their own share link"
        : "Available for paid ticketed events",
    [hasPaidTickets]
  );

  const canSetPaymentCollector: boolean = useMemo(
    () => hasPaidTickets && event.stripePaymentCollector === "",
    [event.stripePaymentCollector, hasPaidTickets]
  );

  const styles = {
    buttonView: {
      whiteSpace: "nowrap",
      borderRadius: 100,
      paddingBlock: 10,
      paddingInline: 12,
    },
  };

  useEffect(() => {
    if (organizerData && organizerTicketItem) {
      setSelectedRoleUser(organizerData);
      setSelectedRole(organizerTicketItem.role.type);
      setPercentSplit(organizerTicketItem.role.revenuePercentSplit);
      setIsAlternatePaymentCollector(
        organizerTicketItem.role.alternatePaymentCollector
      );
    }
  }, [organizerData, organizerTicketItem, setIsEventRoleModalOpen]);

  const clearFields = useCallback(() => {
    setIsEventRoleModalOpen(false);
    setCurrScreen(0);
    setSelectedRoleUser(undefined);
    setOrganizerTicketItem(undefined);
    setOrganizerData(undefined);
    setSelectedRole(OrganizerType.COHOST);
    setPercentSplit(0);
    setIsAlternatePaymentCollector(false);
  }, [setIsEventRoleModalOpen, setOrganizerData, setOrganizerTicketItem]);

  const verifyEventRole = useCallback(
    async (isUnassigned: boolean) => {
      // check if selected user already has a role
      const finalSelectedRoleUserId = isUnassigned ? "" : selectedRoleUser?.uid;
      const alreadyHasRole =
        finalSelectedRoleUserId &&
        // TODO: This will need to be refactored when we deprecated these arrays.
        // Ticket data and access levels will be needed
        (event.cohosts.includes(finalSelectedRoleUserId) ||
          event.scanners.includes(finalSelectedRoleUserId) ||
          event.performers.includes(finalSelectedRoleUserId) ||
          event.promoters.includes(finalSelectedRoleUserId));
      if (alreadyHasRole && !isEditingRole) {
        setAlertText({
          heading: "This user cannot be selected.",
          subHeading: "They already have a role.",
        });
        return false;
      } else if (isAlternatePaymentCollector && isUnassigned) {
        setAlertText({
          heading: "Unable to save and skip.",
          subHeading: invalidStripeAccountMessage,
        });
        return false;
      } else if (
        (isAlternatePaymentCollector || percentSplit !== 0) &&
        selectedRoleUser
      ) {
        // check if user is connected with a stripe account
        const readyForPayment = await checkForPaymentEnabled(selectedRoleUser);
        if (!readyForPayment) {
          setAlertText({
            heading: isEditingRole
              ? "Cannot change to this role"
              : "Please select someone else.",
            subHeading: invalidStripeAccountMessage,
          });
          return false;
        }
      }
      return true;
    },
    [
      event.cohosts,
      event.performers,
      event.promoters,
      event.scanners,
      invalidStripeAccountMessage,
      isAlternatePaymentCollector,
      isEditingRole,
      percentSplit,
      selectedRoleUser,
    ]
  );

  const saveEventRolesPress = useCallback(
    async (isUnassigned: boolean) => {
      const isVerified = await verifyEventRole(isUnassigned);
      if (!isVerified) {
        return;
      }
      const finalSelectedRoleUserId = isUnassigned ? "" : selectedRoleUser?.uid;
      await API.events.createOrganizerTickets({
        event: event,
        selectedRoleUser: finalSelectedRoleUserId || "",
        organizerType: selectedRole,
        isPaymentCollector: isAlternatePaymentCollector,
        revenuePercentSplit: percentSplit,
      });
      clearFields();
      mixpanel.track("Assigned Event Role", {
        event_id: event.id,
        selected_user_id: finalSelectedRoleUserId,
        is_unassigned: isUnassigned,
        organizer_role: selectedRole,
        is_payment_collector: isAlternatePaymentCollector,
        revenue_percent_split: percentSplit,
      });
      showNotificationBanner(
        dispatch,
        "Role Created!",
        NotificationType.AFFIRMATIVE
      );
    },
    [
      clearFields,
      dispatch,
      event,
      isAlternatePaymentCollector,
      mixpanel,
      percentSplit,
      selectedRole,
      selectedRoleUser?.uid,
      verifyEventRole,
    ]
  );

  const percentSharedComponent = (
    <div className="AlignedRowSpaced" style={{ marginTop: 10, width: "100%" }}>
      <span style={{ fontSize: 14, fontWeight: "500" }}>
        Percent shared ticketing revenue
      </span>
      <div style={{ width: "30%" }}>
        <NumberInput
          placeholder="20"
          min={1}
          max={99}
          value={percentSplit}
          onChange={(percent) => (percent ? setPercentSplit(percent) : null)}
        />
      </div>
    </div>
  );

  const madeChanges = useMemo(
    () =>
      organizerTicketItem
        ? organizerTicketItem.role.type !== selectedRole ||
          organizerTicketItem.role.alternatePaymentCollector !==
            isAlternatePaymentCollector ||
          organizerTicketItem.role.revenuePercentSplit !== percentSplit
        : false,
    [
      isAlternatePaymentCollector,
      organizerTicketItem,
      percentSplit,
      selectedRole,
    ]
  );

  const navigateSaveOnPress = useCallback(async () => {
    if (organizerTicketItem) {
      try {
        const isVerified = await verifyEventRole(false);
        if (!isVerified) {
          return;
        }
        await API.events.updateOrganizerTickets({
          event: event,
          userId: organizerTicketItem.uid,
          ticketId: organizerTicketItem.id,
          selectedRole: selectedRole,
          isAlternatePaymentCollector: isAlternatePaymentCollector,
          percentSplit: percentSplit,
          previousRole: organizerTicketItem.role,
        });

        clearFields();
      } catch (err: any) {
        console.error(err.message);
      }
    }
  }, [
    clearFields,
    event,
    isAlternatePaymentCollector,
    organizerTicketItem,
    percentSplit,
    selectedRole,
    verifyEventRole,
  ]);

  const closeOrBackOnPress = useCallback(async () => {
    if (currScreen === 1) {
      setCurrScreen(0);
    } else {
      if (madeChanges) {
        setExitConfirm(true);
      } else {
        clearFields();
      }
    }
  }, [clearFields, currScreen, madeChanges]);

  // Assigning role logic
  const recentRoles = useMemo(
    () =>
      uniqueVals(
        hostingOnlyEvents(events, accountData.uid)
          .concat(hostedOnlyEvents(events, accountData.uid))
          .map((event) =>
            event.cohosts
              .concat(event.scanners)
              .concat(event.promoters)
              .concat(
                event.stripePaymentCollector ? event.stripePaymentCollector : []
              )
          )
          .flat()
          .filter(
            (r) =>
              !event.cohosts.includes(r) &&
              !event.scanners.includes(r) &&
              !event.promoters.includes(r) &&
              event.stripePaymentCollector !== r
          )
      ),
    [
      accountData.uid,
      event.cohosts,
      event.promoters,
      event.scanners,
      event.stripePaymentCollector,
      events,
    ]
  );

  const handleSearch = useCallback((text: string) => {
    setSearchTerm(text.toLowerCase());
  }, []);

  const containsUser = (user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query.toLowerCase());
  };

  const itemsToShow = useMemo(() => {
    if (searchTerm === "") {
      return recentRoleUserData;
    }

    const userList: AccountData[] = filter(
      recentRoleUserData,
      (user: AccountData) => {
        return containsUser(user, searchTerm);
      }
    );

    return userList;
  }, [recentRoleUserData, searchTerm]);

  useAsyncOnMount(async () => {
    const users = await Promise.all(
      recentRoles.map((userId) => fetchSingleUser(userId, followingAccountData))
    );
    const usersDefined: AccountData[] = filterUndefinedValues(users);
    dispatch(accountActions.addMultipleFollowingAccountData(usersDefined));
    setRecentRoleUserData(
      usersDefined.sort((a, b) => {
        if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
          return 1;
        }
        if (b.fullName.toLowerCase() > a.fullName.toLowerCase()) {
          return -1;
        }
        return 0;
      })
    );
    setLoading(false);
  });

  return (
    <>
      <LargePopupModalContainer
        showModal={isEventRoleModalOpen}
        headerComp={
          <div className="AlignedRowSpaced">
            <div className="LargePopupPanelClose" onClick={closeOrBackOnPress}>
              <Icon
                icon={currScreen === 0 ? "mdi:close" : "ion:chevron-back"}
                height={24}
              />
            </div>
            <div className="AlignedRow" style={{ gap: 10 }}>
              {currScreen === 0 ? (
                organizerTicketItem ? (
                  <BlackHoverButton
                    title="Save"
                    onPress={() => navigateSaveOnPress()}
                    containerStyles={styles.buttonView}
                    permanentHover
                  />
                ) : (
                  <BlackHoverButton
                    title="Next"
                    onPress={() => setCurrScreen(1)}
                    iconName="ion:arrow-forward-circle"
                    iconSize={17}
                    containerStyles={styles.buttonView}
                    permanentHover
                  />
                )
              ) : (
                <>
                  {!loading ? (
                    <>
                      <BlackHoverButton
                        title="Save & Skip"
                        onPress={() => saveEventRolesPress(true)}
                        containerStyles={styles.buttonView}
                      />
                      {itemsToShow.length > 0 ? (
                        <BlackHoverButton
                          title="Assign"
                          onPress={() => saveEventRolesPress(false)}
                          containerStyles={styles.buttonView}
                          permanentHover
                          disabled={!selectedRoleUser}
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        }
        valueComp={
          <div>
            <div style={{ fontSize: 16, fontWeight: 500 }}>
              {currScreen === 1
                ? "Assign Role"
                : organizerTicketItem && organizerData
                ? "Edit " + organizerData.fullName + "'s role"
                : organizerTicketItem
                ? "Edit unassigned role"
                : "Select Organizer Role"}
            </div>
            <div
              style={{
                marginTop: 7,
                fontSize: 14,
                color: theme.SubText.color,
              }}
            >
              {currScreen === 1
                ? "Assign this organizer role to a user below. Save & Skip to share a link to this role with new organizers."
                : organizerTicketItem
                ? "Options shown from highest to lowest dashboard access level."
                : "Choose the organizer role you want to add. Options shown from highest to lowest dashboard access level."}
            </div>
            {currScreen === 0 ? (
              // TODO (jonathan): this gray box is a candidate to turn into a component if used more often
              <div
                className="ColumnNormal"
                style={{
                  gap: 24,
                  marginTop: 20,
                  paddingBlock: 24,
                  paddingInline: 14,
                  backgroundColor: theme.PrimaryBG.backgroundColor,
                  borderRadius: 12,
                }}
              >
                <SelectItemRow
                  title="Co-Host"
                  description={
                    "Allows for full editing capabilities, mass texting using their audience, and event dashboard access. Also will be listed on event as a Co-Host."
                  }
                  onPress={() => {
                    if (selectedRole !== OrganizerType.COHOST) {
                      setIsAlternatePaymentCollector(false);
                      setPercentSplit(0);
                      setSelectedRole(OrganizerType.COHOST);
                    }
                  }}
                  selected={selectedRole === OrganizerType.COHOST}
                  subComp={
                    <div style={{ marginTop: 24 }}>
                      <SwitchToggleItem
                        title="Make alternate payment collector"
                        description={alternatePaymentCollectorDescription}
                        disabled={
                          !(
                            organizerTicketItem &&
                            organizerTicketItem.role.alternatePaymentCollector
                          ) && !canSetPaymentCollector
                        }
                        toggleValue={isAlternatePaymentCollector}
                        onChange={() => {
                          if (percentSplit !== 0) {
                            setPercentSplit(0);
                          }
                          setIsAlternatePaymentCollector(
                            !isAlternatePaymentCollector
                          );
                        }}
                      />
                      <div style={{ marginTop: 24 }}>
                        <div>
                          <SwitchToggleItem
                            title="Enable Revenue Sharing"
                            description={revenueSharingDescription}
                            disabled={!hasPaidTickets}
                            toggleValue={percentSplit > 0}
                            onChange={() => {
                              if (isAlternatePaymentCollector) {
                                setIsAlternatePaymentCollector(false);
                              }
                              percentSplit > 0
                                ? setPercentSplit(0)
                                : setPercentSplit(20);
                            }}
                          />
                          {percentSplit > 0 ? (
                            <div style={{ marginTop: 10 }}>
                              {percentSharedComponent}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  }
                />
                <SelectItemRow
                  title="Scanner"
                  description="Scanning and guest list check in, but limited event dashboard access. No editing, mass texting, or revenue visibility. Also not listed on event."
                  onPress={() => {
                    if (selectedRole !== OrganizerType.SCANNER) {
                      setIsAlternatePaymentCollector(false);
                      setSelectedRole(OrganizerType.SCANNER);
                    }
                  }}
                  selected={selectedRole === OrganizerType.SCANNER}
                />
                <SelectItemRow
                  title="Promoter"
                  description={revenueSharingDescription}
                  onPress={() => {
                    if (
                      selectedRole !== OrganizerType.PROMOTER &&
                      hasPaidTickets
                    ) {
                      setPercentSplit(20);
                      setSelectedRole(OrganizerType.PROMOTER);
                    }
                  }}
                  selected={
                    selectedRole === OrganizerType.PROMOTER && hasPaidTickets
                  }
                  subComp={percentSharedComponent}
                  disabled={!hasPaidTickets}
                />
                <SelectItemRow
                  title="Alternate Payment Collector"
                  description={alternatePaymentCollectorDescription}
                  onPress={() => {
                    if (
                      selectedRole !==
                        OrganizerType.ALTERNATEPAYMENTCOLLECTOR &&
                      ((organizerTicketItem &&
                        organizerTicketItem.role.alternatePaymentCollector) ||
                        canSetPaymentCollector)
                    ) {
                      setIsAlternatePaymentCollector(true);
                      setSelectedRole(OrganizerType.ALTERNATEPAYMENTCOLLECTOR);
                    }
                  }}
                  selected={
                    selectedRole === OrganizerType.ALTERNATEPAYMENTCOLLECTOR &&
                    ((organizerTicketItem &&
                      organizerTicketItem.role.alternatePaymentCollector) ||
                      canSetPaymentCollector)
                  }
                  disabled={!canSetPaymentCollector}
                />
                {/* @TODO: Once Performer is implemented uncomment the following */}
                {/* <SelectItemRow
                  title="Performer"
                  description={
                    '(Coming soon) No editing abilities, scanning, or dashboard access. Just adds label as "performer" to guest list.'
                  }
                  onPress={() => {}}
                  selected={false}
                  disabled={true}
                /> */}
              </div>
            ) : (
              <>
                {!loading ? (
                  <div>
                    <SearchBoxContainer
                      placeholder={"Search"}
                      onChange={(e) => handleSearch(e.target.value)}
                      containerStyles={{ marginBlock: 20 }}
                    />
                    <div
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                      }}
                      className="HideScrollbar"
                    >
                      <FlatList
                        list={itemsToShow}
                        scrollEnabled={false}
                        keyExtractor={(item: AccountData) => item.uid}
                        renderItem={(userData, index) => {
                          const selectedUserId = selectedRoleUser
                            ? selectedRoleUser.uid
                            : "";
                          const selected = selectedUserId === userData.uid;

                          return (
                            <SelectRecipientItem
                              item={userData}
                              isSelected={selected}
                              iconColor={Colors.BLUE5}
                              selectOnPress={() => {
                                if (
                                  selectedRoleUser &&
                                  selectedRoleUser.uid === userData.uid
                                ) {
                                  setSelectedRoleUser(undefined);
                                } else {
                                  setSelectedRoleUser(userData);
                                }
                              }}
                              selectPreviewOnPress={() =>
                                setProfilePreviewSelected(userData)
                              }
                            />
                          );
                        }}
                        renderWhenEmpty={() =>
                          recentRoleUserData.length === 0 ? (
                            <EmptyStateButton
                              title={"No Recently Assigned"}
                              btnText="Save & Skip"
                              description={
                                "You have never assigned a role before. Save & Skip to share a link to this role with new organizers."
                              }
                              icon={
                                <Icon
                                  icon={"ion:people"}
                                  height={49}
                                  style={{ color: Colors.GRAY1 }}
                                />
                              }
                              iconBox={73}
                              onPress={() => saveEventRolesPress(true)}
                              containerStyles={{ paddingTop: 120 }}
                            />
                          ) : itemsToShow.length === 0 ? (
                            <EmptyStateButton
                              title="No Results"
                              btnText="Save & Skip"
                              description="Check for typos or Save & Skip to share a link to this role with new organizers."
                              icon={
                                <Icon
                                  icon="ion:search"
                                  height={40}
                                  color={Colors.GRAY1}
                                />
                              }
                              onPress={() => saveEventRolesPress(true)}
                              containerStyles={{ paddingTop: 120 }}
                            />
                          ) : null
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="Centering" style={{ height: "80vh" }}>
                    <CircularProgress
                      style={{ color: Colors.GRAY1 }}
                      size={30}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        }
      />
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
      <ConfirmDeleteModal
        heading="If you exit, your changes will be discarded."
        subtext="Continue?"
        deleteButtonText="Discard Changes"
        hideModal={!exitConfirm}
        setIsVisible={setExitConfirm}
        deleteOnPress={() => clearFields()}
      />
    </>
  );
};

export default EventRoleModal;
