import { MassText, ScheduleTextInfo } from "@markit/common.types";
import { MassTextSendTime } from "../Message/MassTextSendTime";
import { ThemeStyle } from "../../../../hooks/useTheme";
import { useEffect } from "react";
import QuietHoursNotice from "../Message/QuietHoursNotice";

export type MassTextsSendScheduleProps = {
  massText: MassText;
  scheduleTextInfo: ScheduleTextInfo;
  setScheduleTextInfo: (scheduleTextInfo: ScheduleTextInfo) => void;
  isEditing: boolean;
  theme?: ThemeStyle;
};

const MassTextsSendSchedule = (props: MassTextsSendScheduleProps) => {
  const { massText, scheduleTextInfo, setScheduleTextInfo, isEditing, theme } =
    props;

  // update the local send state
  useEffect(() => {
    setScheduleTextInfo({
      sendTime: new Date(massText.sentAt),
      scheduleToggle: massText.scheduled,
      catchUpChecked: massText.catchUpEnabled,
    });
  }, [
    massText.catchUpEnabled,
    massText.scheduled,
    massText.sentAt,
    setScheduleTextInfo,
  ]);

  return (
    <div
      className="ColumnNormal"
      style={{ height: "100%", padding: 14, gap: 14 }}
    >
      <MassTextSendTime
        scheduleTextInfo={scheduleTextInfo}
        setScheduleTextInfo={setScheduleTextInfo}
        isEditing={isEditing}
        theme={theme}
      />
      {/** TODO: Hidden after new automation stuff, re-add it down the road when we want this functionality back */}
      {/* {massText.eventRefId ? (
        <MassTextCatchUp
          massText={massText}
          catchUpChecked={scheduleTextInfo.catchUpChecked}
          setCatchUpChecked={(catchUp: boolean) =>
            setScheduleTextInfo({
              ...scheduleTextInfo,
              catchUpChecked: catchUp,
            })
          }
          theme={theme}
        />
      ) : null} */}
      <QuietHoursNotice scheduleTextInfo={scheduleTextInfo} />
    </div>
  );
};

export default MassTextsSendSchedule;
